import { useEffect, useState } from 'react';
import moment from 'moment';

import { DEFAULT_TOTAL_ADULTS } from '@fire/constants';
import useHasMounted from '@hooks/useHasMounted';
import { getInitialState } from '@utils/availability';

export default function useAvailabilityState(hasDefaultValues = true) {
  const hasMounted = useHasMounted();
  const [currentSuggestion, setCurrentSuggestion] = useState();
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [kidsAges, setKidsAges] = useState();
  const [totalAdults, setTotalAdults] = useState(
    hasDefaultValues ? DEFAULT_TOTAL_ADULTS : 0
  );

  useEffect(() => {
    if (hasMounted) {
      const state = getInitialState({
        hasDefaultValues,
        query: window?.location?.search,
      });

      state.date_from && setDateFrom(moment(state.date_from));
      state.date_to && setDateTo(moment(state.date_to));
      setKidsAges(state.kidsAges);
      setTotalAdults(state.totalAdults);
    }
  }, [hasDefaultValues, hasMounted]);

  return {
    currentSuggestion,
    setCurrentSuggestion,
    dateFrom,
    setDateFrom,
    dateTo,
    setDateTo,
    kidsAges,
    setKidsAges,
    totalAdults,
    setTotalAdults,
  };
}
