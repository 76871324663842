export const START_DATE_FIELD = 'startDate';

export const onDatesChange =
  (setDateFrom, setDateTo) =>
  ({ startDate, endDate }) => {
    setDateFrom(startDate);
    setDateTo(endDate);
  };

export const onFocusChange = setFocusedInput => newFocusedInput => {
  setFocusedInput(newFocusedInput);
};
