import React from 'react';
import PropTypes from 'prop-types';

import { GlobalStyles } from '@fire/globalStyles';

const Layout = ({ children }) => (
  <div id="main">
    <GlobalStyles />
    {children}
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
