import { css } from '@emotion/react';
import { charcoal, glass, gray7 } from '@fire/_color';
import { dateInputLabel } from '@fire/_const';
import { bold, fontSizeBody, fontSizeMedium, regular } from '@fire/_fonts';
import { media } from '@fire/_mediaQueries';

const margin = '20px';

export const wrapper = css`
  border-radius: 15px;
  background: ${glass};
  width: 100%;
  padding: 16px;

  ${media.md} {
    padding: ${margin};
  }

  h1 {
    color: ${charcoal};
    font-weight: ${bold};
    font-size: ${fontSizeMedium};
    margin-bottom: 8px;

    ${media.md} {
      font-size: 20px;
    }
  }

  h2 {
    color: ${charcoal};
    font-weight: ${regular};
    font-size: ${fontSizeBody};
  }

  .DateRangePickerInput {
    border-color: ${gray7};
    border-radius: 8px;
  }
`;

export const mobile = css`
  display: inherit;

  ${media.md} {
    display: none;
  }
`;

export const desktop = css`
  display: none;

  ${media.md} {
    display: inherit;
  }
`;

export const guestPicker = css`
  .guestPickerContainer {
    ${media.md} {
      width: calc(420px - ${margin} * 2);
      margin-left: 50px;
      top: 275px;
    }

    ${media.xl} {
      margin-left: ${margin};
    }
  }
`;

export const guestForm = css`
  .guestForm {
    padding: 16px 0;
  }
`;

export const searchBar = css`
  > div {
    border: 1px solid ${gray7};
    box-shadow: none;
    margin: 16px 0;

    ${media.lg} {
      max-width: 500px;
    }
  }
`;

export const labelsWrapper = css`
  position: relative;
`;

export const labels = css`
  ${dateInputLabel}
`;
