import { css } from '@emotion/react';
import { gray5, red6, red9, white } from '@fire/_color';
import { boxShadow, zIndexTop } from '@fire/_const';
import { fontSizeSmall } from '@fire/_fonts';
import { media, mediaMax } from '@fire/_mediaQueries';

const mapboxControlGroup = css`
  .mapboxgl-ctrl-group {
    border-radius: 0;
  }
  .mapboxgl-ctrl-icon {
    ${mediaMax.lg} {
      display: none;
    }
  }
`;

export const fullsize = css`
  position: relative;
  width: 100%;
  height: 100vh;
  bottom: 0;

  /* Hack to make fullscreen work on Safari */
  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;

    > div > div {
      height: -webkit-fill-available !important;
    }
  }

  ${media.lg} {
    height: calc(100vh - 90px);
  }
`;

export const mapRoot = css`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const pin = css`
  background-color: ${red9};
  position: absolute;
  transition: 0.2s;
  width: 20px;
  height: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 1px 1px 2px 0;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  border-color: ${white};
  border-image: initial;
  cursor: pointer;
  &:hover {
    background-color: ${white}!important;
  }
`;

export const nonInteractivePin = css`
  ${pin}
  cursor: default;
  &:hover {
    background-color: ${red9} !important;
  }
`;

export const visitedPin = css`
  ${pin}
  background-color: ${red6};
`;

export const highlightedPin = css`
  ${pin}
  background-color: ${white};
  border-color: ${white};
  transform: scale('1.2');
`;

export const mapTile = css`
  height: auto;
  max-width: 250px;
  background-color: white !important;
`;

export const fullScreenControl = css`
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: ${zIndexTop};

  ${mapboxControlGroup}
`;

export const mapTileWrapper = css`
  z-index: 3;
  .mapboxgl-popup-content {
    padding: 0;
  }
`;

export const styleButtonWrapper = css`
  position: absolute;
  bottom: 65px;
  left: 12px;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  ${boxShadow};

  ${media.lg} {
    bottom: 40px;
  }
`;

export const styleButton = css`
  &:nth-of-type(1) {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-right: 1px solid ${gray5};
  }
  &:nth-of-type(2) {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  text-transform: uppercase;
  padding: 8px 12px;
  background-color: ${white};
  z-index: ${zIndexTop};
`;

export const navigationControls = css`
  z-index: ${zIndexTop};
  position: absolute;
  left: 12px;
  top: 12px;
  text-align: center;

  ${mapboxControlGroup}

  &:hover {
    background-color: ${gray5};
  }
`;

const searchControl = css`
  position: absolute;
  background-color: ${white};
  padding: 0 4px 0 8px;
  z-index: ${zIndexTop};

  label {
    font-size: ${fontSizeSmall} !important;
  }

  .checkmark {
    // To overide the 3px that are used in forms
    margin-top: 0px !important;
  }
`;

export const mapBoxSeachControl = css`
  ${searchControl};
  left: 10px;
  top: 12px;

  ${media.lg} {
    left: 52px;
  }
`;

export const userLocationButton = css`
  position: absolute;
  z-index: ${zIndexTop};
  bottom: 66px;
  right: 10px;

  button {
    width: 41px;
    height: 41px;
  }
`;
