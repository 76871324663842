import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { T } from '@fire/i18n/i18n-constants';

import CheckboxList from './CheckboxList';
import * as ß from './styles';

const namespace = T?.SearchResultFilter ?? '';

const CheckboxGroup = ({ items, label }) => {
  const { t } = useTranslation(namespace);

  return (
    <div css={ß.filterCategory}>
      <h3 css={ß.categoryTitle}>{t(label)}</h3>
      <CheckboxList list={items} namespace={namespace} />
    </div>
  );
};

CheckboxGroup.propTypes = {
  items: PropTypes.array,
  label: PropTypes.string,
};

CheckboxGroup.defaultProps = {
  items: [],
};

export default CheckboxGroup;
