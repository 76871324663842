import { useState } from 'react';

import useEventListener from '@hooks/useEventListener';

const useKeyPress = targetKey => {
  const [keyPressed, setKeyPressed] = useState(false);

  const downHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  };

  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  useEventListener('keydown', downHandler);
  useEventListener('keyup', upHandler);

  return keyPressed;
};

export default useKeyPress;
