import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { isEmptyOrNil } from '@utils/generic';

import MoreButton from './MoreButton';

export const Truncate = ({ limit, words, prefix }) => {
  const [isTruncated, setIsTruncated] = useState(true);

  if (isEmptyOrNil(words)) return <></>;

  const shouldTruncate = isTruncated && words.length > limit;
  const handleOnClick = () => setIsTruncated(false);

  return (
    <>
      {shouldTruncate ? (
        <>
          {words.slice(0, limit)}&hellip;
          <MoreButton onClick={handleOnClick} prefix={prefix} />
        </>
      ) : (
        <>{words}</>
      )}
    </>
  );
};

Truncate.defaultProps = {
  limit: 170,
};

Truncate.propTypes = {
  limit: PropTypes.number.isRequired,
  words: PropTypes.string.isRequired,
  prefix: PropTypes.string.isRequired,
};

export default Truncate;
