import React from 'react';
import PropTypes from 'prop-types';
import { withPrefix } from 'gatsby';
import { pipe } from 'ramda';

import { asProp } from '@components/utils';

import * as ß from './styles';

const checkIcon = withPrefix('/icons/check.svg');

export const CheckIcon = () => (
  <img src={checkIcon} css={ß.checklistIcon} alt="checklist icon" />
);

const ChecklistItem = ({ children: v, showIcon }) => (
  <div css={ß.item} key={v}>
    {showIcon && <CheckIcon />}
    <div css={ß.checkListitem}>{v}</div>
  </div>
);

ChecklistItem.propTypes = {
  children: PropTypes.node,
  showIcon: PropTypes.bool.isRequired,
};

ChecklistItem.defaultProps = {
  showIcon: true,
};

export const ChecklistItemString = pipe(asProp('children'), ChecklistItem);

export default ChecklistItem;
