import React from 'react';
import PropTypes from 'prop-types';

import Link from '@components/Link';

const TitleWithUrl = ({ title, url }) =>
  url ? <Link to={url}>{title}</Link> : <>{title}</>;

TitleWithUrl.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

TitleWithUrl.defaultProps = {
  url: false,
};

export default TitleWithUrl;
