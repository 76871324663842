import { arrayOf, shape, string } from 'prop-types';

export const AmenityType = {
  id: string,
  name: string,
  nodes: arrayOf(shape(this)),
  value: string,
};

export default AmenityType;
