import React from 'react';
import PropTypes from 'prop-types';
import { withPrefix } from 'gatsby';

import * as ß from './styles';

const arrow = withPrefix('/icons/tiny-arrow-charcoal.svg');

const CarouselArrow = ({ onClick, direction, position }) => {
  const isNextArrow = direction === 'next';
  const arrowStyle = [ß.arrow(position), isNextArrow && ß.arrowNext];
  const alt = `Carousel arrow ${direction}`;

  return (
    <button onClick={onClick} css={arrowStyle} data-campfire-btn={alt}>
      <img alt={alt} src={arrow} />
    </button>
  );
};

CarouselArrow.propTypes = {
  direction: PropTypes.string,
  onClick: PropTypes.func,
  arrow: PropTypes.object,
  position: PropTypes.string,
};

CarouselArrow.defaultProps = {
  position: 'top',
};

export default CarouselArrow;
