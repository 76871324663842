import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { curry } from 'ramda';

import TileLoading from '@contexts/TileLoading';
import { GATSBY_ENTRYPOINT } from '@fire/constants';

import 'lazysizes';
import SlideshowButtons from './SlideshowButtons';
import * as ß from './styles';
import { getImageOrPlaceholder } from './utils';

export const left = x => x - 1;
export const right = x => x + 1;
// add length before modulo to cancel out negative indexes
export const updateIndex = (index, length, direction) =>
  (direction(index) + length) % length;

const Slideshow = ({
  disableQueryString,
  images,
  style,
  isAd,
  hasPrefixImage,
  gatsbyFluid,
  hasUrlPrefix,
  name,
  imageLoading,
}) => {
  const siteUrl = GATSBY_ENTRYPOINT;
  const [imageIndex, setImageIndex] = useState(0);
  const handleClick = curry((imageKey, direction, e) => {
    e.preventDefault();
    setImageIndex(updateIndex(imageKey, images.length, direction));
  });
  const goRight = handleClick(imageIndex, right);
  const goLeft = handleClick(imageIndex, left);
  const urlPrefix = hasUrlPrefix ? siteUrl : '';
  const hasImages = images.length > 0;
  const hasSingleImage = images.length === 1;
  // if image exists and image has url, return url,
  // else return image (x which might be an url instead of an object)
  const mappedImages = images.map(x => (x && x.url ? x.url : x));

  const isLoading = useContext(TileLoading);

  return isLoading ? (
    <div css={ß.skeletonPlaceholder} />
  ) : (
    <div css={[ß.slideshow, style]}>
      <div css={ß.slideshowImage}>
        <div css={ß.slideshowImageWrapper}>
          {gatsbyFluid ? (
            <Img
              fluid={gatsbyFluid}
              css={ß.image()}
              fadeIn={false}
              loading={imageLoading}
            />
          ) : (
            getImageOrPlaceholder(
              hasPrefixImage,
              hasImages,
              imageIndex,
              imageLoading,
              mappedImages,
              siteUrl,
              urlPrefix,
              name,
              disableQueryString
            )
          )}
        </div>
      </div>
      {hasImages && !hasSingleImage && !isAd && (
        <SlideshowButtons goLeft={goLeft} goRight={goRight} />
      )}
    </div>
  );
};

Slideshow.propTypes = {
  gatsbyFluid: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  disableQueryString: PropTypes.bool,
  hasPrefixImage: PropTypes.bool,
  hasUrlPrefix: PropTypes.bool,
  images: PropTypes.array,
  isAd: PropTypes.bool,
  imageLoading: PropTypes.string,
  name: PropTypes.string,
  style: PropTypes.object,
};

Slideshow.defaultProps = {
  disableQueryString: false,
  hasPrefixImage: false,
  hasUrlPrefix: false,
  images: [],
  isAd: false,
  imageLoading: 'lazy',
  style: {},
};

export default React.memo(Slideshow);
