import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Button from '@components/Button';
import { T } from '@fire/i18n/i18n-constants';
import { ADACAttributesDefaultProps, ADACAttributesType } from '@types';

import Accommodations from './Accommodations';
import NoDatesRow from './NoDatesRow';
import * as ß from './styles';

const namespace = T?.Tile ?? '';

const TileFooter = ({
  adacAttributes,
  availabilities,
  ctaLink,
  dateFrom,
  dateTo,
  isAd,
  isCustomer,
  isFallbackList,
  onBookClick,
  onCtaClick,
  onPriceDisplayClick,
  path,
  openInNewWindow,
  isMapTile,
  showLinkoutCTA,
}) => {
  const { t } = useTranslation(namespace);
  const [hasPickedDates, setHasPickedDates] = useState(false);
  const totalPitches = adacAttributes?.amount_pitches_total;
  const totalRentals = adacAttributes?.amount_rental_accommodations;

  useEffect(() => {
    if (dateFrom && dateTo) {
      setHasPickedDates(true);
    }
  }, [setHasPickedDates, dateFrom, dateTo]);

  const hasAvailabilities = hasPickedDates && availabilities?.length > 0;
  const isPriceVisible = !isAd && !isFallbackList;

  const renderPriceRow = () =>
    hasAvailabilities ? (
      <Accommodations
        availabilities={availabilities}
        path={path}
        onBookClick={onBookClick}
        openInNewWindow={openInNewWindow}
      />
    ) : (
      <NoDatesRow
        totalPitches={totalPitches}
        totalRentals={totalRentals}
        onClick={onPriceDisplayClick}
      />
    );

  return (
    <>
      {isPriceVisible && (
        <div css={ß.prices}>
          <div css={ß.priceRow(isMapTile)}>{renderPriceRow()}</div>
        </div>
      )}
      {isCustomer && showLinkoutCTA && (
        <div css={ß.tileFooterLink}>
          <Button
            variant="linkout"
            isExternalUrl
            openInNewWindow
            onClick={onCtaClick}
            to={ctaLink}
          >
            {t('Campingplatz Homepage')}
          </Button>
        </div>
      )}
    </>
  );
};

TileFooter.propTypes = {
  adacAttributes: ADACAttributesType,
  availabilities: PropTypes.array,
  ctaLink: PropTypes.string,
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  isAd: PropTypes.bool,
  isCustomer: PropTypes.bool,
  isFallbackList: PropTypes.bool,
  onBookClick: PropTypes.func,
  onCtaClick: PropTypes.func,
  onPriceDisplayClick: PropTypes.func,
  parkingSpaces: PropTypes.number,
  path: PropTypes.string,
  openInNewWindow: PropTypes.bool.isRequired,
  isMapTile: PropTypes.bool,
  showLinkoutCTA: PropTypes.bool,
};

TileFooter.defaultProps = {
  adacAttributes: ADACAttributesDefaultProps,
  isAd: false,
  isCustomer: false,
  isFallbackList: false,
  onBookClick: () => {},
  onPriceDisplayClick: () => {},
  openInNewWindow: true,
  isMapTile: false,
  showLinkoutCTA: false,
};

export default memo(TileFooter);
