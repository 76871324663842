import React from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';

import Link from '@components/Link';
import { GATSBY_ENTRYPOINT } from '@fire/constants';

import * as ß from './styles';

const cropConfig =
  'min-w=350&min-h=233&max-w=430&max-h=300&auto=compress,format&crop=edges&fit=crop';
export const IMAGE_PLACEHOLDER = () =>
  `${GATSBY_ENTRYPOINT}/i/placeholder-image.jpg?handle=KXy7SucqTjGP8gSNZEPR&${cropConfig}`;

const buildImageUrl = image => {
  const imageFileName = propOr(false, 'fileName', image);
  const imageHandle = propOr(false, 'handle', image);
  return imageFileName && imageHandle
    ? `${GATSBY_ENTRYPOINT}/i/${encodeURIComponent(
        imageFileName
      )}?handle=${imageHandle}&${cropConfig}`
    : IMAGE_PLACEHOLDER();
};

export const getImage = item => {
  if (item?.image) {
    return buildImageUrl(item?.image);
  }
  return IMAGE_PLACEHOLDER();
};

const GroupItem = props => {
  const { index, item } = props;
  const itemName = propOr(false, 'name', item);
  const itemUrl = propOr(false, 'path', item);
  return (
    <li
      css={ß.groupItem({
        ...props,
        image: getImage(item),
        placeholder: IMAGE_PLACEHOLDER(),
      })}
      key={index}
    >
      <Link
        to={itemUrl}
        rel="noopener noreferrer"
        target="_blank"
        title={itemName}
      >
        <p className="title">{itemName}</p>
      </Link>
    </li>
  );
};

GroupItem.propTypes = {
  index: PropTypes.number,
  item: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
  }),
};

export default GroupItem;
