import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const InfoMessageContext = createContext({});

InfoMessageContext.displayName = 'InfoMessageContext';

export const InfoMessageProvider = ({ children }) => {
  const [showMessage, setShowMessage] = useState(false);
  const [infoMessage, setInfoMessage] = useState();

  return (
    <InfoMessageContext.Provider
      value={{
        infoMessage,
        setInfoMessage,
        showMessage,
        setShowMessage,
      }}
    >
      {children}
    </InfoMessageContext.Provider>
  );
};

InfoMessageProvider.propTypes = {
  children: PropTypes.node,
};

export default InfoMessageContext;
