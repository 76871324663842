import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { pathOr } from 'ramda';

import FormDataContext from '@contexts/FormData';
import MaybeCampsite from '@contexts/MaybeCampsite';
import MaybeData from '@contexts/MaybeData';
import { T } from '@fire/i18n/i18n-constants';

import * as ß from './styles';
import { trackSendReview } from './tracking';

const prefix = pathOr('', ['ReviewPage'], T);

const Step2 = () => {
  const {
    state: {
      formData: { email, travelDateMonth, travelDateYear },
    },
  } = useContext(FormDataContext);
  const [trackingDone, setTrackingDone] = useState(false);
  const campsiteData = useContext(MaybeCampsite);
  const pageContext = useContext(MaybeData);

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (!trackingDone) {
      trackSendReview({
        campsiteData,
        pageContext,
        travelDateMonth,
        travelDateYear,
      });
      setTrackingDone(true);
    }
  }, [
    campsiteData,
    pageContext,
    trackingDone,
    setTrackingDone,
    travelDateMonth,
    travelDateYear,
  ]);

  const { t } = useTranslation(prefix);

  return (
    <div css={ß.contentWidth}>
      <h2>{t('step-2-form.title')}</h2>
      <Trans t={t} i18nKey={'step-2-form.text-1'}>
        <p css={ß.step2paragraph}></p>
      </Trans>
      <strong css={ß.step2paragraph}>{`"${email}"`}</strong>
      <Trans t={t} i18nKey={'step-2-form.text-2'}>
        <p css={ß.step2paragraph}></p>
      </Trans>
      <Trans t={t} i18nKey={'step-2-form.text-3'}>
        <p css={ß.step2paragraph}></p>
      </Trans>
      <Trans t={t} i18nKey={'step-2-form.text-4'}>
        <p css={ß.step2paragraph} style={{ marginBottom: 50 }}></p>
      </Trans>
    </div>
  );
};

export default Step2;
