import moment from 'moment';

import { DATE_FORMAT_ISO8601 } from '@fire/constants';
import { isEmptyOrNil } from '@fire/utils/generic';

export const getDatePickerId = ({ isAvailabilitySection, isMobile }) => {
  const sectionSuffix = isAvailabilitySection ? '-availability' : '';
  const mobileSuffix = isMobile ? '-mobile' : '';
  const idSuffix = `${sectionSuffix}${mobileSuffix}`;
  const startDateId = `start-date-id${idSuffix}`;
  const endDateId = `end-date-id${idSuffix}`;

  return { startDateId, endDateId };
};

const firstUnavailableDate = ({ dateFrom, selectedUnavailableDates }) => {
  if (dateFrom && selectedUnavailableDates) {
    const date = selectedUnavailableDates.find(day =>
      moment(day).isAfter(dateFrom)
    );

    return new Date(date).getTime();
  }
};

export const isDayBlocked =
  ({ dateFrom, isPickingStartDate, selectedUnavailableDates }) =>
  day => {
    const dayISO = day?.format(DATE_FORMAT_ISO8601);
    const dayIsBlocked = selectedUnavailableDates?.includes(dayISO);

    if (!isPickingStartDate) {
      const firstUnavailableDay = firstUnavailableDate({
        dateFrom,
        selectedUnavailableDates,
      });
      const dayTimestamp = new Date(dayISO).getTime();
      const dayIsAfterFirstUnavailableDay = dayTimestamp > firstUnavailableDay;
      const dayIsNotFirstUnavailableDay = dayTimestamp !== firstUnavailableDay;

      return (
        dayIsAfterFirstUnavailableDay ||
        (dayIsBlocked && dayIsNotFirstUnavailableDay)
      );
    }

    return dayIsBlocked;
  };

export const calculateLongestAvailableStay = (dateStart, unavailableDates) => {
  const res = unavailableDates?.find(date => moment(date).isAfter(dateStart));

  return moment(res);
};

const getUnavailableDatesFromList = ({ unavailable_dates }) =>
  unavailable_dates;

const getIntersectionOfDates = (a, b) => a.filter(date => b.includes(date));

export const getConsolidatedUnavailableDates = list => {
  if (isEmptyOrNil(list)) {
    return [];
  }

  return [...list].reverse().reduce((acc, { accommodations, category }) => {
    const categoryOrganized = {
      category,
      list: accommodations.map(getUnavailableDatesFromList),
    };

    if (accommodations.length > 0) {
      categoryOrganized.list = categoryOrganized.list.reduce(
        getIntersectionOfDates
      );
    }

    return [...acc, categoryOrganized];
  }, []);
};

export const getTabs = (unavailableDates, unavailableDatesFromContext) =>
  isEmptyOrNil(unavailableDates)
    ? getConsolidatedUnavailableDates(unavailableDatesFromContext)
    : [{ list: unavailableDates }];
