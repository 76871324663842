import React, { useContext } from 'react';
import { pathOr } from 'ramda';

import Button from '@components/Button';
import SmallTile from '@components/SmallTile';
import MaybeData from '@contexts/MaybeData';
import { magazinePageContext } from '@root/staticPageContexts/HomePage/magazine';
import { isSwiss } from '@utils/platform';

import * as ß from './styles';

export const MagazineTeaser = () => {
  const pageContext = useContext(MaybeData);
  const isSwitzerland = isSwiss(pageContext);
  const language = pathOr('de', ['language'], pageContext);
  const magazineContent = pathOr({}, [`${language}`], magazinePageContext);

  return (
    <div css={ß.container}>
      <div css={ß.wrapper}>
        <div css={ß.heroTeaser}>
          <div
            css={ß.heroTeaserImage(
              pathOr('', ['heroTeaser', 'image'], magazineContent)
            )}
          >
            <br />
          </div>
          <div css={ß.heroTeaserContent}>
            <h3>{pathOr('', ['heroTeaser', 'headline'], magazineContent)}</h3>
            <p>{pathOr('', ['heroTeaser', 'text'], magazineContent)}</p>
            <Button
              variant="primary"
              size="large"
              openInNewWindow={isSwitzerland}
              isExternalUrl
              to={pathOr('', ['heroTeaser', 'button', 'url'], magazineContent)}
            >
              {pathOr('', ['heroTeaser', 'button', 'text'], magazineContent)}
            </Button>
          </div>
        </div>
        <div css={ß.sideKick}>
          {pathOr({}, ['sideKick', 'squares'], magazineContent).map(
            (e, key) => (
              <SmallTile
                openInNewWindow={isSwitzerland}
                isExternalUrl
                anchor
                key={key}
                title={e.title}
                url={e.url}
                image={e.image}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default MagazineTeaser;
