import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { formatDate } from '@utils/dateTime';

import { PREFIX } from './consts';

const DateRangeAndNight = ({ arrivalDate, departureDate, nights: count }) => {
  const { t } = useTranslation(PREFIX);
  return arrivalDate && departureDate ? (
    <p>
      {formatDate(arrivalDate, 'DD.MM')} - {formatDate(departureDate, 'DD.MM')}
      <span>, {t('night', { count })}</span>
    </p>
  ) : (
    ''
  );
};

DateRangeAndNight.propTypes = {
  arrivalDate: PropTypes.string,
  departureDate: PropTypes.string,
  nights: PropTypes.number,
};

export default DateRangeAndNight;
