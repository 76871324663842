import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Spinner from '@components/Spinner';
import NearbyIcon from '@fire/assets/nearby.svg';

import * as ß from './styles';

const CampsiteNearby = ({
  description,
  handleOnClick,
  headline,
  isActive: propsIsActive,
  isLoading,
}) => {
  const [isActive, setIsActive] = useState(propsIsActive);

  const checkState = permissionStatus =>
    permissionStatus?.state === 'denied' && setIsActive(false);

  useEffect(() => {
    navigator?.permissions
      ?.query({ name: 'geolocation' })
      .then(function (permissionStatus) {
        checkState(permissionStatus);
        permissionStatus.addEventListener('change', function () {
          checkState(this);
        });
      });
  }, []);

  useEffect(() => {
    setIsActive(propsIsActive);
  }, [propsIsActive]);

  const onClick = () => isActive && handleOnClick();

  return (
    <>
      <div css={ß.headlineWrapper}>
        <div css={ß.headlineLeft}>
          <NearbyIcon alt="nearby-icon" css={ß.icon} />
          <p css={ß.headline}>{headline}</p>
        </div>
        {isLoading && <Spinner />}
      </div>
      <span css={ß.headlineTitle(isActive)} onClick={onClick}>
        {description}
      </span>
    </>
  );
};

CampsiteNearby.propTypes = {
  isActive: PropTypes.bool,
  isLoading: PropTypes.bool,
  handleOnClick: PropTypes.func,
  description: PropTypes.string,
  headline: PropTypes.string,
};

CampsiteNearby.defaultProps = {
  isActive: true,
};

export default CampsiteNearby;
