import { arrayOf, bool, float, int, shape, string } from 'prop-types';

export const CampsiteDataType = shape({
  acceptDogs: bool,
  avgUserRating: float,
  bookable: bool,
  comparison_price: shape({
    amount: string,
    prefix: string,
    currency: string,
  }),
  contactData: shape({
    city: string,
    phone: string,
    region: string,
    street: string,
    website: string,
  }),
  ctaLink: string,
  hasBeach: bool,
  hasBreadRollService: bool,
  hasKidsActivities: bool,
  hasRestaurant: bool,
  hasShops: bool,
  hasSwimmingPool: bool,
  hasWifi: bool,
  images: arrayOf(
    shape({
      altText: string,
      campsite: string,
      crop: shape({
        x: string,
        width: string,
        height: string,
      }),
      img: string,
      isMain: bool,
      position: int,
      url: string,
    })
  ),
  latitude: float,
  longitude: float,
  name: string,
  path: string,
  plan: string,
  prn: string,
  rating: float,
  userRating: float,
});

export default CampsiteDataType;
