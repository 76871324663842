import React from 'react';

import Brand from '@components/Brand';
import LanguageSelect from '@components/LanguageSelect';
import PlatformLogo from '@components/PlatformLogo';
import { GATSBY_ENTRYPOINT } from '@fire/constants';

import { wrapper, wrapperLeft, wrapperRight } from './styles';

export const HeaderBooking = () => (
  <>
    <LanguageSelect />
    <div css={wrapper}>
      <div css={wrapperLeft}>
        <Brand />
      </div>
      <div css={wrapperRight}>
        <PlatformLogo siteUrl={GATSBY_ENTRYPOINT} language="de" />
      </div>
    </div>
  </>
);

export default HeaderBooking;
