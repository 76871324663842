import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { assoc, pipe, propOr } from 'ramda';

import LinkedResources from '@components/LinkedResources';
import { HrefLang, MetaBreadcrumbs, SocialMedia } from '@components/Meta';
import SEO from '@components/seo';
import { useProsciuttoState } from '@hooks/useProsciutto';
import { PageContextType } from '@types';

import { getLocizedMetas } from './utils';

const SmartMetaAndSEO = ({ pageContext, namespace }) => {
  const { t } = useTranslation();
  const prosciuttoState = useProsciuttoState();
  const count = propOr(0, 'count', prosciuttoState);
  const meta = getLocizedMetas(t, count, {
    namespace,
    ...pageContext,
  });
  const [title, metaDescription] = meta;
  const pageContextWithLocizedMeta = pipe(
    assoc('title', title),
    assoc('metaDescription', metaDescription)
  )(pageContext);

  return (
    <>
      <SEO pageContext={pageContextWithLocizedMeta} />
      <HrefLang />
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <LinkedResources pageContext={pageContextWithLocizedMeta} />
      <SocialMedia pageContext={pageContextWithLocizedMeta} />
      <MetaBreadcrumbs pageContext={pageContext} />
    </>
  );
};

SmartMetaAndSEO.propTypes = {
  namespace: PropTypes.string,
  pageContext: PageContextType,
};

export default SmartMetaAndSEO;
