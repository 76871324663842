import React, { useLayoutEffect, useState } from 'react';

import BlockHeader from '@components/BlockHeader';
import useReviews from '@hooks/useReviews';

import Review from './Review';
import * as ß from './styles';
import { prefix, takeThreeRandomReviews } from './utils';

const HomePageReviews = () => {
  const allReviews = useReviews();
  const [reviews, setReviews] = useState(takeThreeRandomReviews(allReviews));

  useLayoutEffect(() => {
    setReviews(takeThreeRandomReviews(allReviews));
  }, []);

  return (
    <>
      <BlockHeader title="Top Bewertungen" prefix={prefix} />
      <div css={ß.reviews}>
        {reviews.map(props => (
          // eslint-disable-next-line react/prop-types
          <Review key={`review-${props.campsite.prn}`} {...props} />
        ))}
      </div>
    </>
  );
};

export default HomePageReviews;
