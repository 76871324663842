import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Star } from '@components/StarRating';
import CloseIcon from '@fire/assets/close-tiny.svg';
import useTracking from '@hooks/useTracking';
import { MomentType } from '@types';

import Counter from './Counter';
import { Arrow } from './Icons';
import RemoveAllFiltersButton from './RemoveAllFiltersButton';
import * as ß from './styles';
import { getDatepickerText, getGuestpickerText } from './utils';

const FilterContainerDesktop = memo(function FilterContainerDesktop({
  activeFilterItems,
  activeFilters,
  dateFrom,
  dateTo,
  hasAnyActiveFilter,
  isGeneralModalOpen,
  isRatingsModalOpen,
  namespace,
  resetForm,
  setIsAvailabilityModalOpen,
  setIsGeneralModalOpen,
  setIsRatingsModalOpen,
  totalAdults,
  kidsAges,
  count,
}) {
  const {
    prepareToTrackEvent: { clickEvent },
  } = useTracking('filter');
  const { t } = useTranslation(namespace);
  const hasDates = dateFrom && dateTo;
  const datepickerText = getDatepickerText({
    dateFrom,
    dateTo,
    fallbackText: t('Reisedaten'),
  });

  const guestpickerText = getGuestpickerText({
    dateFrom,
    dateTo,
    kidsAges,
    totalAdults,
    t,
  });

  const trackOpenForm = clickEvent('click open calendar');

  const openAvailabilityModal = () => {
    setIsAvailabilityModalOpen(true);
    trackOpenForm();
  };

  return (
    <div css={ß.filterContainerDesktop}>
      <div css={ß.filterContainerButtonPanel}>
        <div css={ß.filterButton} onClick={openAvailabilityModal} role="button">
          <span>{datepickerText}</span>
          <span>{guestpickerText}</span>
          {hasDates && (
            <a
              href="#"
              css={ß.resetFormButton}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                resetForm();
              }}
            >
              <CloseIcon />
            </a>
          )}
        </div>
        <button
          css={ß.filterButton}
          onClick={() => setIsRatingsModalOpen(!isRatingsModalOpen)}
        >
          <Star cssMod={ß.icon} color="YELLOW" />
          <p css={ß.filterButtonText}>{t('Bewertung')}</p> <Arrow />
        </button>
        <button
          css={ß.filterButton}
          onClick={() => setIsGeneralModalOpen(!isGeneralModalOpen)}
        >
          <p css={ß.filterButtonText}>{t('Weitere Filter')}</p> <Arrow />
        </button>
        <Counter count={count} />
      </div>
      <div css={ß.activeFiltersPanel}>
        <RemoveAllFiltersButton
          activeFilters={activeFilters}
          hasAnyActiveFilter={hasAnyActiveFilter}
          namespace={namespace}
        />
        {activeFilterItems}
      </div>
    </div>
  );
});

FilterContainerDesktop.propTypes = {
  activeFilterItems: PropTypes.array,
  activeFilters: PropTypes.array,
  dateFrom: MomentType,
  dateTo: MomentType,
  hasActiveFilters: PropTypes.func,
  hasAnyActiveFilter: PropTypes.bool,
  isGeneralModalOpen: PropTypes.bool,
  isRatingsModalOpen: PropTypes.bool,
  namespace: PropTypes.string,
  resetForm: PropTypes.func,
  setIsAvailabilityModalOpen: PropTypes.func,
  setIsGeneralModalOpen: PropTypes.func,
  setIsRatingsModalOpen: PropTypes.func,
  kidsAges: PropTypes.array,
  totalAdults: PropTypes.number,
  count: PropTypes.number.isRequired,
};

export default FilterContainerDesktop;
