import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { T } from '@fire/i18n/i18n-constants';

const PREFIX = T?.SearchResultSummary;

import * as ß from './styles';

const Counter = ({ count }) => {
  const { t } = useTranslation(PREFIX);

  return <span css={ß.counter}>{t('count-results', { count })}</span>;
};

Counter.propTypes = {
  count: PropTypes.number.isRequired,
};

export default Counter;
