import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { oneLine, stripIndents } from 'common-tags';

import MaybeData from '@contexts/MaybeData';

import { getOrgLDJson } from './utils';

const OrgLDJson = () => {
  const pageContext = useContext(MaybeData);
  const ldJson = getOrgLDJson(pageContext);
  const parsedJson = stripIndents(oneLine)`${JSON.stringify(ldJson)}`;
  return (
    <Helmet>
      <script type="application/ld+json" id="org-ld-json">
        {parsedJson}
      </script>
    </Helmet>
  );
};

export default OrgLDJson;
