import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';

import Button from '@components/Button';
import DatePickerContext from '@contexts/DatePicker';
import InfoMessageContext from '@contexts/InfoMessage';
import MaybeData from '@contexts/MaybeData';
import { DATE_FORMAT_ISO8601 } from '@fire/constants';
import { MomentType } from '@types';
import { saveBookingData } from '@utils/availability';
import { scrollToElemById } from '@utils/scroll';

import * as ß from './styles';
import { trackDateSearch } from './tracking';
import { hasDatesChanged } from './utils';

export const BackButton = ({ handleReset, isResetDisabled, prefix }) => {
  const { t } = useTranslation(prefix);

  return (
    <button
      css={ß.resetButton}
      disabled={isResetDisabled}
      onClick={handleReset}
      type="button"
    >
      {t('Löschen')}
    </button>
  );
};

BackButton.propTypes = {
  handleReset: PropTypes.func.isRequired,
  isResetDisabled: PropTypes.bool.isRequired,
  prefix: PropTypes.string.isRequired,
};

export const BookingButton = ({
  bookingUrl,
  dateFrom,
  dateTo,
  hideBookingModal,
  kidsAges,
  totalAdults,
  prefix,
}) => {
  const datePickerContext = useContext(DatePickerContext);
  const pageContext = useContext(MaybeData);
  const isCampsitePage = pageContext?.pageType === 'Campsite';

  const { setShowMessage, showMessage, infoMessage } =
    useContext(InfoMessageContext);
  const hasDates = !isNil(dateFrom) && !isNil(dateTo);
  const { t } = useTranslation(prefix);

  const submit = event => {
    if (!hasDates) {
      event?.preventDefault?.();
      datePickerContext?.openDatePicker?.();
      return;
    }

    const bookingData = {
      dateFrom: dateFrom?.format(DATE_FORMAT_ISO8601),
      dateTo: dateTo?.format(DATE_FORMAT_ISO8601),
      kidsAges,
      totalAdults,
    };

    hasDatesChanged({
      dateFrom: bookingData.dateFrom,
      dateTo: bookingData.dateTo,
    }) &&
      trackDateSearch({
        event: isCampsitePage
          ? 'profile_send-date-search'
          : 'list_send-date-search',
        category: isCampsitePage ? 'profile' : 'list',
        dateFrom: bookingData.dateFrom,
        dateTo: bookingData.dateTo,
        totalAdults,
        kidsAges,
      });

    saveBookingData(bookingData);
    hideBookingModal();
    infoMessage?.bookable === false && setShowMessage?.(!showMessage);
    scrollToElemById('accommodations');
  };

  return (
    <Button
      isFullWidth
      onClick={submit}
      size="large"
      to={bookingUrl}
      variant="primary"
    >
      {hasDates ? t('see-offers') : t('change-travel-dates')}
    </Button>
  );
};

BookingButton.defaultProps = {
  prefix: 'availability-box',
};

BookingButton.propTypes = {
  bookingUrl: PropTypes.string.isRequired,
  dateFrom: MomentType,
  dateTo: MomentType,
  hideBookingModal: PropTypes.func,
  kidsAges: PropTypes.arrayOf(PropTypes.number),
  submitText: PropTypes.string,
  totalAdults: PropTypes.number,
  prefix: PropTypes.string.isRequired,
};

export const NextButton = ({ handleNext, prefix }) => {
  const { t } = useTranslation(prefix);

  return (
    <Button
      isFullWidth
      onClick={handleNext}
      size="large"
      type="button"
      variant="primary"
    >
      {t('Anwenden')}
    </Button>
  );
};

NextButton.propTypes = {
  handleNext: PropTypes.func.isRequired,
  prefix: PropTypes.string.isRequired,
};
