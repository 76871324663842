import {
  addIndex,
  concat,
  curry,
  either,
  isEmpty,
  isNil,
  map,
  pair,
  pipe,
  pluck,
  prop,
  reduce,
  sortBy,
} from 'ramda';

export const box = x => [x];
export const concatAll = reduce(concat, []);
export const isEmptyOrNil = either(isEmpty, isNil);
export const mapIndexed = addIndex(map);
export const merge = curry((a, b) => Object.assign({}, a, b));

export const shuffleArray = pipe(
  map(x => pair(Math.random(), x)),
  sortBy(prop(0)),
  pluck(1)
);

export const meterOrKilometer = value =>
  value < 1 ? `${value * 1000} m` : `${value} km`;

export const everyTruthy = arr =>
  arr.every(x => x !== null && x !== undefined && x !== false);

export const objectToArrayPair = obj => {
  let arr = [];
  for (const [key, value] of Object.entries(obj)) {
    arr = [...arr, [key, value]];
  }
  return arr;
};
