import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { map, range } from 'ramda';

import StarIcon from '@fire/assets/star-rating/empty.svg';

import * as ß from './styles';

const RateInput = ({ labels, onChange, selectedValue }) => {
  const [hoverLabel, setHoverLabel] = useState('');
  const [hoverIndex, setHoverIndex] = useState(0);

  const shouldStarShine = idx => {
    if (hoverLabel.length > 0) {
      return idx > hoverIndex;
    }
    return idx > Number.parseInt(selectedValue) - 1;
  };

  const stars = map(
    idx => (
      <label key={idx}>
        <StarIcon
          key={idx}
          css={shouldStarShine(idx) ? ß.starIcon : ß.starIconShine}
          onMouseEnter={() => setHoverLabel(labels[idx]) || setHoverIndex(idx)}
        />
        <input
          type="radio"
          onChange={onChange}
          name="rating"
          value={idx + 1}
          css={ß.radioInput}
        />
      </label>
    ),
    range(0, 5)
  );

  return (
    <div>
      <span onMouseLeave={() => setHoverLabel('') || setHoverIndex(0)}>
        {stars}
      </span>
      <div css={ß.rateLabelContainer}>
        {hoverLabel.length > 0 && <p css={ß.rateLabel}>{hoverLabel}</p>}
      </div>
    </div>
  );
};

RateInput.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  selectedValue: PropTypes.string,
};

// The only reson this is here
// is because handleChange is undefined
// on initial render
RateInput.defaultProps = {
  onChange: () => {},
};

export default RateInput;
