import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';

import Carousel from '@components/Carousel';
import TileMicrocamping from '@components/Tile/Microcamping';
import { MaybeData } from '@fire/contexts';
import { useDimensions } from '@fire/hooks/useDimensions';
import { T } from '@fire/i18n/i18n-constants';
import { isADAC } from '@fire/utils/platform';
import useProsciutto from '@hooks/useProsciutto';
import { everyTruthy, isEmptyOrNil } from '@utils/generic';

import { MAX_GUESTS, MIN_MICROCAMPING } from './consts';
import * as ß from './styles';
import { trackOnView } from './tracking';
import {
  consolidateViewport,
  fetchMicrocamping,
  validateOrigin,
} from './utils';

const PREFIX = T?.Tile;

const CarouselMicrocamping = () => {
  const pageContext = useContext(MaybeData);
  const { width, height } = useDimensions();
  const { ref, inView } = useInView();
  const [prosciuttoState] = useProsciutto();
  const { t } = useTranslation(PREFIX);
  const [data, setData] = useState([]);

  const { length: microcampingLength = 0 } = data;
  const {
    params = {},
    viewport: prosciuttoViewport,
    userInteraction,
  } = prosciuttoState;

  const viewport = consolidateViewport(
    { width, height },
    params,
    prosciuttoViewport
  );

  const {
    date_from: dateFrom,
    date_to: dateTo,
    adults = 2,
    children = '',
  } = params;

  const totalGuests = adults + children?.length;

  const isDE = isADAC(pageContext);

  const hasMicrocamping =
    isDE &&
    dateFrom &&
    dateTo &&
    totalGuests <= MAX_GUESTS &&
    microcampingLength >= MIN_MICROCAMPING;

  const { isInteracting, type: interactionType } = userInteraction;
  const {
    unitialized: viewportUnitialized,
    latitude: rawLatitude,
    longitude: rawLongitude,
  } = viewport;

  useEffect(() => {
    // sane check:
    // dateFrom && dateTo has to be present
    // viewportUnitialized is undefined when it finished their calculations
    // isInteracting has to be false, meaning the user finished interacting
    // interactionType has to be empty, meaning the user is not doing dragging or other interaction
    // all culminate into being able to fire the API less times

    const check = everyTruthy([
      dateFrom,
      dateTo,
      !viewportUnitialized,
      !isInteracting,
      isEmptyOrNil(interactionType),
    ]);

    if (check) {
      const latitude = Number.parseFloat(rawLatitude);
      const longitude = Number.parseFloat(rawLongitude);
      if (validateOrigin(latitude, longitude)) {
        const fetchParams = {
          dateFrom,
          dateTo,
          adults,
          children,
          totalGuests,
          latitude,
          longitude,
        };
        fetchMicrocamping(fetchParams).then(setData).catch(console.error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dateFrom,
    dateTo,
    viewportUnitialized,
    rawLatitude,
    rawLongitude,
    isInteracting,
    interactionType,
  ]);

  useEffect(() => {
    inView && hasMicrocamping && trackOnView(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMicrocamping, inView, trackOnView]);

  return hasMicrocamping ? (
    <>
      <h2>{t('microcamping-title')}</h2>
      <p>{t('microcamping-subtitle')}</p>
      <div css={ß.centerHack} ref={ref}>
        <Carousel tileCount={microcampingLength}>
          {data.map((microCampsite, index) => (
            <TileMicrocamping
              index={index}
              key={`carousel-microcamping-tile-${index}`}
              {...microCampsite}
            />
          ))}
        </Carousel>
      </div>
    </>
  ) : (
    false
  );
};

export default CarouselMicrocamping;
