import { AVAILABILITIES_API } from '@fire/constants';
import { T } from '@fire/i18n/i18n-constants';

export const ACCOMMODATIONS_ID = '#accommodations';

export const ACCOMMODATIONS_ENDPOINT_WITH_PARAMS = `${AVAILABILITIES_API}/live-prices`;

export const SHOW_MORE_THRESHOLD = 2;

export const PRICE_WITHOUT_CENTS = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
};

export const PREFIX = T.Accommodations ?? '';

export const TAB_OPTIONS = ['all', 'pitch', 'home'];
