import { graphql, useStaticQuery } from 'gatsby';

export const LoyaltyQueryAll = graphql`
  query LoyaltyQueryAll {
    allLoyalties {
      ...LoyaltyQuery
    }
  }
`;

const useLoyaltyAll = () => {
  const data = useStaticQuery(LoyaltyQueryAll);
  return data?.allLoyalties?.nodes ?? [];
};

export default useLoyaltyAll;
