import React from 'react';
import { useTranslation } from 'react-i18next';

import { formatDate } from '@utils/dateTime';

import { NAMESPACE } from './constants';
import * as ß from './styles';
import { DetailsType } from './types';
import { getGuestList, getKeyWithPrefix } from './utils';

const Details = ({
  checkIn,
  checkOut,
  city,
  email,
  firstName,
  guests,
  lastName,
  message,
  nights,
  type,
  vehicleSize,
  params,
}) => {
  const { t } = useTranslation(NAMESPACE);

  const getKey = getKeyWithPrefix(params, t);
  const guestList = getGuestList(guests, getKey);

  return (
    <section css={ß.details}>
      <div>
        <h4>{getKey('guest-contact')}</h4>
        <p>
          {getKey('guest')}: {firstName} {lastName}
        </p>
        <p>
          {getKey('place')}: {city}
        </p>
        {email && (
          <p>
            {getKey('email')}: <a href={`mailto:${email}`}>{email}</a>
          </p>
        )}
      </div>
      <div>
        <h4>{getKey('reservation-details')}</h4>
        <p>
          {getKey('nights-and-dates', {
            count: nights,
            checkIn: formatDate(checkIn),
            checkOut: formatDate(checkOut),
          })}
        </p>
        <p>
          {type} {vehicleSize && `(${vehicleSize})`}
        </p>
        <p>{guestList}</p>
      </div>
      {message && (
        <div>
          <h4>{getKey('guest-message')}</h4>
          <p>{message}</p>
        </div>
      )}
    </section>
  );
};

Details.propTypes = DetailsType;

export default Details;
