import axios from 'axios';
import moment from 'moment';
import {
  always,
  assoc,
  complement,
  constructN,
  curry,
  dissoc,
  either,
  equals,
  fromPairs,
  invoker,
  isEmpty,
  keys,
  merge,
  omit,
  path,
  pipe,
  prop,
  propOr,
  toPairs,
  when,
} from 'ramda';

import { FLEXIBLE_DATES_LIST_API, PAGE_SIZE } from '@fire/constants';
import { TILES_ENDPOINT, TILES_LIST_WITH_FALLBACK } from '@hooks/constants';
import { isSearch } from '@utils/platform';

import 'url-search-params-polyfill';
import { parseURLSearchParams } from './url';

export const makeURLSearchParams = prosciuttoState =>
  pipe(
    omit(keys(prosciuttoState.contextParams || {})),
    constructN(1, URLSearchParams),
    invoker(0, 'toString')
  )(prosciuttoState.params);

const handlePossibleMultiPrn = params => {
  const prns = params.prn;

  if (prns) {
    const searchParamsObj = new URLSearchParams(dissoc('prn', params));
    prns.split(',').forEach(prn => searchParamsObj.append('prn', prn));

    return searchParamsObj;
  }

  return new URLSearchParams(params);
};

const getOffset = params => {
  const page = propOr(0, 'page', params) - 1;
  const offset = Math.max(0, page) * PAGE_SIZE;

  return `offset=${offset}`;
};

const getPlatformAndLanguage = ({ language, platform }) =>
  `language=${language}&platform=${platform}`;

const getBaseQuery = ({ language, params, platform }) => {
  const limit = `limit=${PAGE_SIZE}`;
  const offset = getOffset(params);
  const platformAndLanguage = getPlatformAndLanguage({
    language,
    platform,
  });

  return `${platformAndLanguage}&${limit}&${offset}`;
};

const clearSearchParams = () =>
  window.history.replaceState({}, {}, window.location.pathname);

export const handleParamChanged = curry((dispatch, state) => {
  const urlSearchParams = makeURLSearchParams(state);

  isEmpty(urlSearchParams)
    ? clearSearchParams()
    : history[state.paginationChanged ? 'pushState' : 'replaceState'](
        {},
        'search',
        `?${unescape(urlSearchParams)}`
      );

  Promise.all(state.newTilesRequest(state.params)).then(values => {
    try {
      const [tilesResponse, maybeFlexibleDates] = values;

      const tilesData = tilesResponse?.data;
      const tiles = Array.isArray(tilesData) ? tilesData : [tilesData];
      const [list1, list2] = tiles;

      const payload = {
        list1,
        list2,
        flexibleDates: maybeFlexibleDates?.data?.results ?? [], // update to the correct one once API is ready
      };
      dispatch({ type: 'handleResponse', payload });
    } catch (error) {
      console.error(error);
      dispatch({ type: 'handleResponse', payload: {} });
    }
  });
});

const fixPitches = ([key, value]) => {
  switch (key) {
    case 'pitches-lteq-20':
      return ['pitches', 20];
    case 'pitches-lteq-100':
      return ['pitches', 100];
    case 'pitches-lteq-300':
      return ['pitches', 300];
    case 'category-pitch':
      return ['category', 'pitch'];
    case 'category-rental':
      return ['category', 'home'];
    case 'small-and-charming':
      return ['small_and_charming', 1];
    default:
      return [key, value];
  }
};

const getTilesOrAvailabilityWithFlexibleDates = ({ date_from, date_to }) =>
  date_from && date_to
    ? [TILES_LIST_WITH_FALLBACK, FLEXIBLE_DATES_LIST_API]
    : [TILES_ENDPOINT];

const purgeLimitFromFlexibleQuery = (query, index) => {
  if (index > 0) {
    const nolimitQuery = new URLSearchParams(query);
    nolimitQuery.delete('limit');
    return nolimitQuery.toString();
  }
  return query;
};

const getUrlWithParams = (baseQuery, params) => (url, index) => {
  const baseQueryAndParams = `${purgeLimitFromFlexibleQuery(
    baseQuery,
    index
  )}&${params.toString()}`;
  const divider = url.includes('?') ? '&' : '?';
  return `${url}${divider}${baseQueryAndParams}`;
};

const makePromises = url => axios.get(url);

export const makeTilesRequester = (platform, language) => params => {
  const baseQuery = getBaseQuery({ language, platform, params });

  const paramsArray = toPairs(params);
  const fixedParamsArray = paramsArray.map(fixPitches);
  const paramsObject = fromPairs(fixedParamsArray);
  const paramsWithMultiPRN = handlePossibleMultiPrn(paramsObject);

  const getTilesAPI = getTilesOrAvailabilityWithFlexibleDates(params);

  const appendParamsAndUrls = getUrlWithParams(baseQuery, paramsWithMultiPRN);

  const getPromisedAPI = getTilesAPI.map(appendParamsAndUrls).map(makePromises);

  return getPromisedAPI;
};

const notEquals = complement(equals);

export const didPaginationChange = curry((state, action) =>
  notEquals(path(['params', 'page'], state), path(['params', 'page'], action))
);

export const typeEq = (typeX, typeY) => always(equals(typeX, typeY));

export const requestDispatchMiddleware = dispatch => action => {
  if (
    either(
      equals('setUrlParams'),
      equals('handlePaginationHistoryChanged')
    )(action.type)
  ) {
    return dispatch(
      assoc('handleParamChanged', handleParamChanged(dispatch), action)
    );
  }

  return dispatch(action);
};

export const getContextParams = pageCtx =>
  pipe(
    when(
      () => prop('query', pageCtx),
      merge(parseURLSearchParams(propOr('', 'query', pageCtx)))
    ),
    when(
      () => prop('searchQuery', pageCtx),
      merge(parseURLSearchParams(propOr('', 'searchQuery', pageCtx)))
    ),
    when(() => isSearch(propOr('', 'prn', pageCtx)), merge({ term: '' }))
  )({});

export const getInitialSearchParams = () =>
  parseURLSearchParams(propOr('', 'search', window.location));

export const handleHistoryChange = (state, dispatch, pageContext) => () => {
  const newParams = getInitialSearchParams(pageContext);
  const paginationChanged = didPaginationChange(state, { params: newParams });

  if (paginationChanged) {
    const newState = pipe(
      assoc('params', newParams),
      assoc('isLoading', true)
    )(state);
    dispatch({ type: 'setState', state: newState });
    handleParamChanged(dispatch, newState);
  }
};

export const parseInitialStateFromQuery = query => {
  const kidsAges = query.kidsAges ? { children: query.kidsAges } : {};
  const adults = query.totalAdults ? { adults: query.totalAdults } : {};

  if (query.dateFrom && query.dateTo) {
    return {
      date_from: moment(query.dateFrom).format('YYYY-MM-DD'),
      date_to: moment(query.dateTo).format('YYYY-MM-DD'),
      ...kidsAges,
      ...adults,
    };
  }

  return {};
};
