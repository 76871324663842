import { css } from '@emotion/react';
import { blue9 } from '@fire/_color';

export const link = css`
  margin: 12px 20px 0 0;
  font-size: 16px;
  order: 3;

  &:hover {
    color: ${blue9};
  }
`;
