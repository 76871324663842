import React from 'react';
import PropTypes from 'prop-types';

import * as ß from './styles';

const TileLabel = ({ message, isBookable }) => {
  const styles = [ß.messageLabel, isBookable && ß.greenLabel].filter(Boolean);

  return message ? <span css={styles}>{message}</span> : <></>;
};

TileLabel.propTypes = {
  message: PropTypes.string.isRequired,
  isBookable: PropTypes.bool,
};

export default TileLabel;
