export const heroMenuContentITCH = [
  {
    title: 'Destinazioni',
    url: '/vacanza-campeggio',
    titles: {
      leftTitle: 'Mappa interattiva',
      upperRightTitle: 'Destinazioni',
      downRightTitle: 'Regioni popolari',
    },
    children: [
      {
        title: 'Svizzera',
        url: '/svizzera',
        image: {
          name: 'schweiz-alpenland',
          handle: 'IDJYCsrTfCWE8zFc9OKw',
        },
      },
      {
        title: 'Italia',
        url: '/italia',
        image: {
          name: 'italien-weingut-toskana-destination-italien',
          handle: 'ALmxRNZoSGWXQvIUMEwJ',
        },
      },
      {
        title: 'Francia',
        url: '/francia',
        image: {
          name: '',
          handle: 's02gTlEOT8i4m13mhIq2',
        },
      },
      {
        title: 'Croazia',
        url: '/croazia',
        image: {
          name: '',
          handle: 'Kl54itb5QLO8uhqIBCXz',
        },
      },
      {
        title: 'Spagna',
        url: '/spagna',
        image: {
          name: 'spanien-mittelmeer-bucht-mallorca-country',
          handle: 'pfpHE79LQSW0X1zHCDXH',
        },
      },
      {
        title: 'Germania',
        url: '/germania',
        image: {
          name: 'deutschland-stage.jpg',
          handle: 'j3j56n35TvK7yAI1E0yU',
        },
      },
      {
        title: 'Tutti i paesi',
        url: '/tutti-i-paesi',
        image: {
          name: '',
          handle: 'tBphsgeSQNazBt7j3j9o',
        },
      },
    ],
    links: [
      {
        title: 'Giura Tre Laghi',
        url: '/vacanza-campeggio/giura-tre-laghi',
      },
      {
        title: 'Lago Maggiore',
        url: '/vacanza-campeggio/lago-maggiore',
      },
      {
        title: 'Grigioni',
        url: '/vacanza-campeggio/grigioni',
      },
      {
        title: 'Oberland Bernese',
        url: '/vacanza-campeggio/oberland-bernese',
      },
      {
        title: 'Alto Adige',
        url: '/vacanza-campeggio/alto-adige',
      },
      {
        title: 'Toscana',
        url: '/italia/toscana',
      },
      {
        title: 'Costa Azzurra',
        url: '/vacanza-campeggio/costa-azzurra',
      },
      {
        title: 'Ardèche',
        url: '/vacanza-campeggio/ardeche',
      },
    ],
  },
  {
    title: 'I migliori camping',
    url: '/camping-par-thematiques/5-etoiles',
    titles: {
      leftTitle: 'I migliori camping',
      upperRightTitle: '',
      downRightTitle: 'I migliori camping nelle regioni popolari',
    },
    children: [
      {
        title: 'Italia',
        url: '/campeggio-temi/5-stelle/italia',
        image: {
          name: '',
          handle: 'BUIczBzESXGepAluWfuv',
        },
      },
      {
        title: 'Francia',
        url: '/campeggio-temi/5-stelle/francia',
        image: {
          name: '',
          handle: 'TrgzuwdFRc6goGXcH2LS',
        },
      },
      {
        title: 'Croazia',
        url: '/campeggio-temi/5-stelle/croazia',
        image: {
          name: '',
          handle: 'qiw6kYvlR0aZQyg2jzL8',
        },
      },
      {
        title: 'Spagna',
        url: '/campeggio-temi/5-stelle/spagna',
        image: {
          name: 'spanien-mittelmeer-bucht-mallorca-country',
          handle: 'pfpHE79LQSW0X1zHCDXH',
        },
      },
      {
        title: 'Svizzera',
        url: '/campeggio-temi/4-stelle/svizzera',
        image: {
          name: 'schweiz-alpenland',
          handle: 'IDJYCsrTfCWE8zFc9OKw',
        },
      },
    ],
    links: [
      {
        title: 'Lago Maggiore',
        url: '/campeggio-temi/4-stelle/lago-maggiore',
      },
      {
        title: 'Alto Adige',
        url: '/campeggio-temi/5-stelle/alto-adige',
      },
      {
        title: 'Adriatico',
        url: '/campeggio-temi/5-stelle/adriatico',
      },
      {
        title: 'Toscana',
        url: '/campeggio-temi/4-stelle/toscana',
      },
      {
        title: 'Costa Azzurra',
        url: '/campeggio-temi/5-stelle/costa-azzurra',
      },
      {
        title: 'Nouvelle-Aquitaine',
        url: '/campeggio-temi/4-stelle/nuova-aquitania',
      },
      {
        title: 'I migliori camping al mare',
        url: '/campeggio-temi/5-stelle/al-mare',
      },
      {
        title: 'I migliori camping con cani ammessi',
        url: '/campeggio-temi/5-stelle/con-cane',
      },
      {
        title: 'I migliori camping con piscina',
        url: '/campeggio-temi/5-stelle/con-piscina',
      },
    ],
  },
  {
    title: 'Campeggi a tema',
    url: '/campeggio-temi',
    titles: {
      leftTitle: 'Campeggi a tema',
      upperRightTitle: '',
      downRightTitle: 'Ricerca frequente: Campeggio...',
    },
    children: [
      {
        title: 'Campeggio con piscina',
        url: '/campeggio-temi/con-piscina',
        image: {
          name: 'Swimmingpool.jpg',
          handle: '7vZO0msRRVCzhxRJoLW9',
        },
      },
      {
        title: 'Campeggio al mare',
        url: '/campeggio-temi/al-mare',
        image: {
          name: '',
          handle: 'wwNDHth2SMC2Y1cTf74O',
        },
      },
      {
        title: 'Campeggio con il cane',
        url: '/campeggio-temi/con-cane',
        image: {
          name: '',
          handle: 'gj1nXuXjTAGhcfYOUnf3',
        },
      },
      {
        title: 'Campeggio con i bambini',
        url: '/campeggio-temi/con-bambini',
        image: {
          name: '',
          handle: 'PRabDvOTjq9NwBmHrDNt',
        },
      },
      {
        title: 'Glamping',
        url: '/campeggio-temi/glamping',
        image: {
          name: 'Glamping.jpg',
          handle: 'NVMvDXjlRmqSBCvjESFS',
        },
      },
      {
        title: 'Campeggio al lago',
        url: '/campeggio-temi/al-lago',
        image: {
          name: '',
          handle: 'ud7WjvQaT8yGSPKd3lkD',
        },
      },
      {
        title: 'Tutti i campeggi a tema A-Z',
        url: '/temi-sul-campeggio',
        image: {
          name: '',
          handle: 'BUa4r7zJT8mM3TeLb99w',
        },
      },
    ],
    links: [
      {
        title: 'con i bambini in Svizzera',
        url: '/campeggio-temi/con-bambini/svizzera',
      },
      {
        title: 'con i bambini in Italia',
        url: '/campeggio-temi/con-bambini/italia',
      },
      {
        title: 'al mare in Italia',
        url: '/campeggio-temi/al-mare/italia',
      },
      {
        title: 'al lago in Svizzera',
        url: '/campeggio-temi/al-lago/svizzera',
      },
      {
        title: 'con il cane in Italia',
        url: '/campeggio-temi/con-cane/italia',
      },
      {
        title: 'al mare in Francia',
        url: '/campeggio-temi/al-mare/francia',
      },
    ],
  },
  {
    title: 'TCS Magazin',
    url: 'https://www.tcs.ch/it/camping-viaggi/camping-insider/',
    linkout: true,
  },
  {
    title: 'Prodotti TCS',
    url: 'https://www.tcs.ch/it/prodotti/',
    linkout: true,
  },
];
