import React, { useState } from 'react';
import { DayPickerRangeController } from 'react-dates';
import PropTypes from 'prop-types';
import moment from 'moment';

import ArrowCircle from '@fire/assets/arrowCircle.svg';
import { MomentType } from '@types';

import * as ß from './styles';
import { onDatesChange, onFocusChange, START_DATE_FIELD } from './utils';

const isOutsideRange = day => day.isSameOrBefore(moment().subtract(1, 'day'));

const DayPicker = ({ dateFrom, dateTo, setDateFrom, setDateTo }) => {
  const [focusedInput, setFocusedInput] = useState(START_DATE_FIELD);
  const next = <ArrowCircle css={ß.next} />;
  const prev = <ArrowCircle css={ß.prev} />;

  return (
    <div css={ß.dateRangePicker}>
      <DayPickerRangeController
        endDate={dateTo}
        focusedInput={focusedInput}
        hideKeyboardShortcutsPanel
        keepOpenOnDateSelect
        navNext={next}
        navPrev={prev}
        noBorder
        numberOfMonths={2}
        onDatesChange={onDatesChange(setDateFrom, setDateTo)}
        onFocusChange={onFocusChange(setFocusedInput)}
        startDate={dateFrom}
        isOutsideRange={isOutsideRange}
      />
    </div>
  );
};

export default DayPicker;

DayPicker.propTypes = {
  dateFrom: MomentType,
  dateTo: MomentType,
  setDateFrom: PropTypes.func,
  setDateTo: PropTypes.func,
};
