import { css } from '@emotion/react';

export const wrapper = css`
  width: 100%;
  margin: 0 auto;
  position: relative;
`;

export const tile = css`
  width: 100%;
  padding: 12px;
`;

export const center = css`
  text-align: center;
`;

export const title = css`
  padding-top: 40px;
  padding-bottom: 18px;
  padding-left: 8px;
  ${center}
`;

export const subtitle = css`
  ${center}
  margin-bottom: 35px;
`;
