import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { curry, omit, propOr } from 'ramda';

import useHasMounted from '@hooks/useHasMounted';
import { PageContextType } from '@types';
import { getInitialState } from '@utils/availability';
import { getLocaleData, isSearch } from '@utils/platform';
import {
  didPaginationChange,
  getContextParams,
  getInitialSearchParams,
  handleHistoryChange,
  handleParamChanged,
  makeTilesRequester,
  parseInitialStateFromQuery,
  requestDispatchMiddleware,
} from '@utils/prosciutto';
import { isTestingWithJest } from '@utils/testing';
import { parseURLSearchParams } from '@utils/url';

import 'url-search-params-polyfill';

export const ProsciuttoStateContext = createContext();
export const ProsciuttoDispatchContext = createContext();
ProsciuttoStateContext.displayName = 'ProsciuttoStateContext';
ProsciuttoDispatchContext.displayName = 'ProsciuttoDispatchContext';

const shouldDisableHydration = pageContext => {
  const DISABLED = [
    'prn:page:destination-shape-bodensee',
    'prn:page:destination-shape-oberbayern',
  ];
  const prn = pageContext?.prn;
  return DISABLED.includes(prn);
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'handleResponse': {
      const payload = action?.payload;
      const { list1, list2, flexibleDates } = payload;
      const firstList = {
        count: list1?.total_records ?? 0,
        pages: list1?.total_pages ?? 0,
        tiles: list1?.tiles ?? [],
      };
      const secondList = list2
        ? {
            count: list2.total_records,
            pages: list2.total_pages,
            tiles: list2?.tiles?.map(campsite => ({
              ...campsite,
              isFallbackList: true,
            })),
          }
        : {};

      return {
        ...state,
        count: firstList.count + (secondList?.tiles?.length ?? 0),
        isLoading: false,
        paginationChanged: false,
        lists: [firstList, secondList],
        flexibleDates,
      };
    }

    case 'resetProsciutto': {
      return {
        lists: [],
        loading: false,
        newTilesRequest: state.newTilesRequest,
        paginationChanged: false,
        params: parseURLSearchParams(window.location),
      };
    }

    case 'setState':
      return action.state;

    case 'setUrlParams': {
      const allParams = { ...state?.params, ...action?.params };
      const newState = {
        ...state,
        isLoading: true,
        paginationChanged: didPaginationChange(state, action),
        params: omit(action?.removeParams ?? [], allParams),
      };
      action.handleParamChanged(newState);

      return newState;
    }

    default:
      break;
  }
};

export const ProsciuttoProvider = ({ children, pageContext }) => {
  const hasMounted = useHasMounted();
  const localeData = getLocaleData(pageContext);
  const platform = localeData?.platform;
  const language = localeData?.language;
  const disableHydration = shouldDisableHydration(pageContext);
  const isSearchPage = isSearch(propOr('', 'prn', pageContext));
  const pageContextState = pageContext?.initialState ?? {};
  const initialTiles = useMemo(
    () => ({
      count: pageContextState?.total_records ?? 0,
      pages: pageContextState?.total_pages ?? 0,
      lists: [
        {
          count: pageContextState?.total_records ?? 0,
          page: pageContextState?.total_pages ?? 0,
          tiles: pageContextState?.tiles ?? [],
        },
      ],
      flexibleDates: [],
    }),
    [
      pageContextState?.tiles,
      pageContextState?.total_pages,
      pageContextState?.total_records,
    ]
  );

  const [state, dispatch] = useReducer(
    reducer,
    isSearchPage ? {} : initialTiles
  );

  const initialDispatch = useCallback(
    (contextParams, initialParams, hackySearchPageContextParams) =>
      dispatch({
        type: 'setState',
        state: {
          ...initialTiles,
          paginationChanged: false,
          contextParams: isSearchPage
            ? hackySearchPageContextParams
            : contextParams,
          params: initialParams,
          newTilesRequest: makeTilesRequester(platform, language),
          isLoading: false,
        },
      }),
    [initialTiles, isSearchPage, language, platform]
  );

  const initialSetUrl = useCallback(
    (contextParams, hackySearchPageContextParams) =>
      dispatch({
        type: 'setUrlParams',
        params: isSearchPage ? hackySearchPageContextParams : contextParams,
        handleParamChanged: handleParamChanged(dispatch),
      }),
    [isSearchPage]
  );

  useEffect(() => {
    if (!hasMounted || isTestingWithJest()) return;

    const contextParams = getContextParams(pageContext);
    const initialSearchParams = getInitialSearchParams() || {};
    const initialState = getInitialState({ query: initialSearchParams });
    const initialQueryParams = parseInitialStateFromQuery(initialState);
    const initialParams = {
      ...contextParams,
      ...initialSearchParams,
      ...initialQueryParams,
    };

    // search page has no context-params
    // but needs to behave a little like it has one
    // when we are on /suche without search-term
    const hackySearchPageContextParams = initialParams?.term
      ? {}
      : { term: '' };

    initialDispatch(contextParams, initialParams, hackySearchPageContextParams);
    (!disableHydration || Object.keys(initialQueryParams).length > 0) &&
      initialSetUrl(contextParams, hackySearchPageContextParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDispatch, initialTiles, initialSetUrl, pageContext, hasMounted]);

  // feed the closure here to pass the newest updated state to handler
  const onHistoryChange = handleHistoryChange(state, dispatch, pageContext);

  useEffect(() => {
    window.addEventListener('popstate', onHistoryChange);

    return () => {
      window.removeEventListener('popstate', onHistoryChange);
    };
  }, [onHistoryChange, pageContext, state]);

  const [viewport, setViewport] = useState({
    zoom: 6,
    latitude: 0,
    longitude: 0,
    width: 0,
    height: 0,
    uninitialized: true,
  });

  const [userInteraction, setUserInteraction] = useState({
    isInteracting: false,
    type: '',
  });

  return (
    <ProsciuttoStateContext.Provider
      value={{
        ...state,
        viewport,
        setViewport,
        userInteraction,
        setUserInteraction,
      }}
    >
      <ProsciuttoDispatchContext.Provider
        value={requestDispatchMiddleware(dispatch)}
      >
        {children}
      </ProsciuttoDispatchContext.Provider>
    </ProsciuttoStateContext.Provider>
  );
};

export const withProsciutto = curry((C, props) => (
  <ProsciuttoProvider pageContext={props.pageContext}>
    <C {...props} />
  </ProsciuttoProvider>
));

export const useProsciuttoState = () => {
  const context = useContext(ProsciuttoStateContext);
  if (context === undefined) {
    throw new Error(
      'useProsciuttoState must be used within a ProsciuttoProvider'
    );
  }
  return context;
};

// Specifically intented for SearchSuggestion
// which in most cases doesn't need prosciutto
export const useMaybeProsciuttoDispatch = () => {
  const context = useContext(ProsciuttoDispatchContext);

  if (context === undefined) {
    return false;
  }

  return context;
};

export const useProsciuttoDispatch = () => {
  const context = useContext(ProsciuttoDispatchContext);

  if (context === undefined) {
    throw new Error(
      'useProsciuttoDispatch must be used within a ProsciuttoProvider'
    );
  }

  return context;
};

export const useProsciutto = () => [
  useProsciuttoState(),
  useProsciuttoDispatch(),
];

ProsciuttoProvider.propTypes = {
  children: PropTypes.node,
  pageContext: PageContextType,
};

export default useProsciutto;
