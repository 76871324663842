import React, {
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { equals } from 'ramda';

import useEventListener from '@hooks/useEventListener';

const DimensionsStateContext = createContext({ width: 1200 });

export const DimensionsProvider = memo(function DimensionProvider({
  children,
}) {
  const [sizes, setSizes] = useState({ width: 0, height: 0 });
  // Event handler utilizing useCallback
  // so that reference never changes.
  const handler = useCallback(() => {
    // on Android active input & scroll trigger (vertical) resize, which
    // disables modal state. so we only update when width changes.
    const { innerWidth, innerHeight } = window;
    !equals(innerWidth, sizes.width) &&
      setSizes({
        width: innerWidth,
        height: innerHeight,
      });
  }, [setSizes, sizes]);
  useEventListener('resize', handler);
  // run handler one single time :)
  useEffect(() => handler(), [handler]);

  return (
    <DimensionsStateContext.Provider value={sizes}>
      {children}
    </DimensionsStateContext.Provider>
  );
});

DimensionsProvider.propTypes = {
  children: PropTypes.node,
};

export const useDimensions = () => {
  const context = useContext(DimensionsStateContext);
  if (context === undefined) {
    throw new Error('useDimensions must be used within a DimensionsProvider');
  }
  return context;
};
