import { css } from '@emotion/react';
import { black, white } from '@fire/_color';
import { zIndex } from '@fire/_const';
import { media } from '@fire/_mediaQueries';

export const heroImage = css`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  ${media.md} {
    height: 60vw;
    max-height: 75vh;
    justify-content: center;
  }
  ${media.xl} {
    align-items: center;
    justify-content: space-around;
    height: 34vw;
  }
`;

export const slideshow = css`
  width: 100%;
  z-index: 0;
  height: 60vw;
  img {
    height: 100%;
  }
  ${media.md} {
    position: absolute;
    bottom: 0;
    height: 100%;
  }
`;

export const imageContainer = css`
  position: relative;
  height: 60vw;
  max-height: 75vh;
  ${media.xl} {
    height: 34vw;
  }
`;

export const content = css`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;

  h1,
  h3 {
    color: ${white};
  }

  h1 {
    margin-bottom: 20px;
    text-align: center;

    ${media.md} {
      text-align: left;
    }
  }

  h3 {
    display: none;
  }

  ${media.md} {
    h3 {
      display: block;
    }
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`;

export const search = css`
  max-width: 100%;
`;

export const overlay = css`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${black};
  opacity: 0.2;
  z-index: 1;
`;

export const mobile = css`
  display: inherit;
  ${media.md} {
    display: none;
  }
`;

export const desktop = css`
  display: none;
  ${media.md} {
    display: inherit;
  }
`;

export const wrapper = css`
  align-items: center;
  ${media.md} {
    margin-left: 30px;
  }
  ${media.xl} {
    margin-left: 0;
  }
`;

export const heroImageContentWrapper = css`
  z-index: ${zIndex.layerTwo};
  align-self: flex-end;
  margin-bottom: 10px;
  position: absolute;
  top: -43px;
  ${media.md} {
    top: unset;
    position: relative;
    margin-bottom: unset;
  }
`;
