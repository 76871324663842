import { css } from '@emotion/react';
import { blue9, charcoal, gray8, red8 } from '@fire/_color';
import { bold, fontFace, fontSizeMedium } from '@fire/_fonts';

const bottomBorder = css`
  background-repeat: no-repeat;
  background-size: 100% 2px;
  background-position: bottom, 50% calc(100% - 1px);
`;

export const wrapper = css`
  position: relative;
  width: 100%;
`;

export const input = css`
  ${fontFace};
  font-size: ${fontSizeMedium};
  font-weight: ${bold};
  color: ${charcoal};
  position: relative;
  width: 100%;
  padding: 16px 12px 0px;
  border: solid 1px ${gray8};
  border-radius: 8px;
  margin: 0;
  background-image: none;
  ${bottomBorder}

  &:focus {
    outline: none;
    background-image: linear-gradient(${blue9}, ${blue9});
  }
`;

export const error = css`
  border: solid 1px ${red8};
`;
