import React, { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';

import { trackOpenForm } from '@components/DatePicker/tracking';
import FavoriteHeart from '@components/FavoriteHeart';
import Link from '@components/Link';
import Slideshow from '@components/Slideshow';
import InfoMessageContext from '@contexts/InfoMessage';
import LastClickedContext from '@contexts/LastClicked';
import TileLoading from '@contexts/TileLoading';
import { T } from '@fire/i18n/i18n-constants';
import { isEmptyOrNil } from '@fire/utils/generic';
import { ADACAttributesDefaultProps, ADACAttributesType } from '@types';
import { isCustomer } from '@utils/customer';

import * as ß from '../styles';
import TileContent from '../TileContent';
import TileFooter from '../TileFooter';
import TileLabel from '../TileLabel';

const namespace = pathOr('', ['Tile'], T);

const TileCampsite = ({
  adac_attributes: adacAttributes,
  availabilities,
  bookable,
  bookingParams,
  ctaLink,
  clickOutObject,
  dateFrom,
  dateTo,
  gatsbyFluid,
  hasUrlPrefix,
  homepage,
  imageLoading,
  images,
  isAd,
  isExternalUrl,
  isFallbackList,
  isLoading,
  isMapTile,
  name,
  onBookClick,
  onClick,
  onCtaClick,
  onMouseEnter,
  onMouseLeave,
  openInNewWindow,
  path,
  prn,
  plan,
  rating,
  region,
  showLinkoutCTA,
  tcs_attributes,
  userRating,
  userRatingCount,
}) => {
  const tcsRating = tcs_attributes?.rating;
  const isPayingCustomer = isCustomer(plan) && !isEmptyOrNil(ctaLink);
  const { t } = useTranslation(namespace);
  const { setInfoMessage, setShowMessage } = useContext(InfoMessageContext);
  const { setLastClickedPRN } = useContext(LastClickedContext);
  const pathWithBookingParams = path + bookingParams;

  const onPriceDisplayClick = () => {
    setLastClickedPRN(prn);
    setInfoMessage({
      bookable,
      ctaLink,
      clickOutObject,
      isCustomer: isPayingCustomer,
      message: t('not-bookable'),
      name,
      onCtaClick,
      path,
      prn,
    });
    trackOpenForm(isMapTile ? 'map' : 'list');
    setShowMessage(false);
  };

  const linkProps = {
    isExternalUrl,
    onClick,
    onMouseEnter,
    onMouseLeave,
    openInNewWindow,
    to: pathWithBookingParams,
  };

  const contentProps = {
    adacAttributes,
    linkProps,
    name,
    namespace,
    onClick,
    rating,
    region,
    tcsRating,
    userRating,
    userRatingCount,
    isMapTile,
  };

  const footerProps = {
    adacAttributes,
    availabilities,
    ctaLink,
    dateFrom,
    dateTo,
    isAd,
    isCustomer: isPayingCustomer,
    isFallbackList,
    onBookClick,
    onCtaClick,
    onPriceDisplayClick,
    path: pathWithBookingParams,
    openInNewWindow,
    isMapTile,
    showLinkoutCTA,
  };

  const getTileMessage = () => {
    if (isAd) {
      return <TileLabel message={t('Anzeige')} />;
    }
    if (!isAd && bookable) {
      return <TileLabel isBookable message={t('Sofort buchbar')} />;
    }
    return <></>;
  };

  return (
    <TileLoading.Provider value={isLoading}>
      <div css={ß.tileAnchorsWrapper}>
        <div
          css={ß.safetyWrapper}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <div css={ß.tile(homepage)}>
            <FavoriteHeart prn={prn} />
            <Link onClick={onClick} {...linkProps}>
              <div css={ß.imagesWrapper}>
                {getTileMessage()}
                <Slideshow
                  gatsbyFluid={gatsbyFluid}
                  hasPrefixImage={homepage}
                  hasUrlPrefix={hasUrlPrefix}
                  images={images}
                  isAd={isAd}
                  imageLoading={imageLoading}
                  name={name}
                />
              </div>
            </Link>
            <div css={ß.tileWrapper}>
              <TileContent {...contentProps} />
              <TileFooter {...footerProps} />
            </div>
          </div>
        </div>
      </div>
    </TileLoading.Provider>
  );
};

TileCampsite.propTypes = {
  adac_attributes: ADACAttributesType,
  availabilities: PropTypes.array,
  bookable: PropTypes.bool,
  bookingParams: PropTypes.string,
  clickOutObject: PropTypes.object,
  ctaLink: PropTypes.string,
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  gatsbyFluid: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  hasUrlPrefix: PropTypes.bool,
  homepage: PropTypes.bool,
  imageLoading: PropTypes.string,
  images: PropTypes.array,
  isAd: PropTypes.bool,
  isExternalUrl: PropTypes.bool,
  isFallbackList: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMapTile: PropTypes.bool,
  marketing_data: PropTypes.shape({
    open_from: PropTypes.string,
    open_this_year: PropTypes.bool,
  }),
  name: PropTypes.string,
  onBookClick: PropTypes.func,
  onClick: PropTypes.func,
  onCtaClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  openFrom: PropTypes.string,
  openInNewWindow: PropTypes.bool,
  openThisYear: PropTypes.bool,
  path: PropTypes.string,
  prn: PropTypes.string,
  plan: PropTypes.string,
  rating: PropTypes.number,
  region: PropTypes.string,
  showLinkoutCTA: PropTypes.bool,
  tcs_attributes: PropTypes.object,
  userRating: PropTypes.number,
  userRatingCount: PropTypes.number,
};

TileCampsite.defaultProps = {
  adac_attributes: ADACAttributesDefaultProps,
  availabilities: [],
  bookingParams: '',
  clickOutObject: {},
  ctaLink: '',
  fluid: false,
  hasUrlPrefix: false,
  homepage: false,
  imageLoading: 'lazy',
  isAd: false,
  isExternalUrl: true,
  isFallbackList: false,
  isLoading: false,
  isMapTile: false,
  marketing_data: {},
  onBookClick: () => {},
  onClick: () => {},
  onCtaClick: () => {},
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  openInNewWindow: false,
  path: '',
  plan: 'None',
  showLinkoutCTA: false,
};

export default memo(TileCampsite);
