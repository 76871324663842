import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@components/Button';
import Loading from '@components/Loading';
import TimeLeftUntilDate from '@components/TimeLeftUntilDate';
import useHasMounted from '@hooks/useHasMounted';
import useReservationRequest from '@hooks/useReservationRequest';
import {
  URL_CAMPSITE_ACCEPTED,
  URL_CAMPSITE_REFUSED,
} from '@hooks/useReservationRequest/constants';
import { useLocation } from '@reach/router';
import { queryToObject } from '@utils/url';

import { NAMESPACE } from './constants';
import Details from './Details';
import * as ß from './styles';
import { getKeyWithPrefix } from './utils';

const ReservationRequestConfirmation = () => {
  const { search } = useLocation();
  const params = queryToObject(search);
  const hasMounted = useHasMounted();
  const { getDetails, details, isLoading, isValid } = useReservationRequest({
    requestId: params.request_id,
    sessionId: params.session_id,
  });
  const { t } = useTranslation(NAMESPACE);

  const query = `?request_id=${params?.request_id}&session_id=${params?.session_id}`;
  const acceptLink = `${URL_CAMPSITE_ACCEPTED}${query}`;
  const refuseLink = `${URL_CAMPSITE_REFUSED}${query}`;

  const getKey = getKeyWithPrefix(params, t);

  useEffect(() => {
    getDetails();
  }, []);

  return (
    hasMounted && (
      <>
        <h1 css={ß.title}>{!isLoading && getKey('title')}</h1>
        {isLoading ? (
          <div css={ß.loading}>
            <Loading />
          </div>
        ) : isValid ? (
          <>
            <h2 css={ß.subtitle}>
              {getKey('time-left')}{' '}
              <TimeLeftUntilDate to={details?.expiration} />.
            </h2>
            <p>{getKey('time-left-warning')}</p>
            <h3 css={ß.confirm}>{getKey('question-accept-request')}</h3>
            <div css={ß.buttons}>
              <Button size="large" to={acceptLink} variant="success">
                {getKey('yes')}
              </Button>
              <Button size="large" to={refuseLink} variant="danger">
                {getKey('no')}
              </Button>
            </div>

            <Details {...details} params={params} />
          </>
        ) : (
          <h2 css={ß.subtitle}>{getKey('reservation-not-found')}</h2>
        )}
      </>
    )
  );
};

export default ReservationRequestConfirmation;
