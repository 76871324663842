import { css } from '@emotion/react';
import { blue9, gray8 } from '@fire/_color';
import { nonTouch } from '@fire/_mediaQueries';

export const numberPicker = css`
  display: flex;

  button {
    background: none;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid ${blue9};

    &:disabled {
      border: 2px solid ${gray8};
      opacity: 0.3;

      div {
        background: ${gray8};
      }
    }

    &:active {
      border: 2px solid ${blue9};

      div {
        background: ${blue9};
      }
    }

    &:focus {
      outline: 0;
    }
  }

  ${nonTouch} {
    button:not(:disabled):hover {
      cursor: pointer;
      opacity: 0.6;
    }
  }
`;

export const value = css`
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  width: 44px;
`;

export const minus = css`
  position: relative;

  div {
    background: ${blue9};
  }

  div:nth-of-type(1) {
    width: 10px;
    height: 2px;
  }
`;

export const plus = css`
  position: relative;

  div {
    background: ${blue9};
  }

  div:nth-of-type(1) {
    height: 10px;
    width: 2px;
    left: 4px;
    bottom: -4px;
    position: absolute;
  }

  div:nth-of-type(2) {
    width: 10px;
    height: 2px;
  }
`;
