import React from 'react';
import PropTypes from 'prop-types';

import Link from '@components/Link';
import { GATSBY_ENTRYPOINT } from '@fire/constants';
import useTracking from '@hooks/useTracking';
import { isEmptyOrNil } from '@utils/generic';
import { getFixedSrcSet } from '@utils/image';

import 'lazysizes';
import * as ß from './styles';

export const PremiumPartnerItem = ({
  id,
  trackingId,
  clickoutUrl,
  logo: { handle, fileName, altText },
}) => {
  const {
    prepareToTrackEvent: { clickEvent },
  } = useTracking('footer premium partner');

  const siteUrl = GATSBY_ENTRYPOINT;
  const hasImage = !isEmptyOrNil(handle) && !isEmptyOrNil(fileName);

  const trackClick = clickEvent(trackingId);

  const { src } = getFixedSrcSet(
    `${siteUrl}/i/${encodeURIComponent(
      fileName
    )}?handle=${handle}&w=90&auto=compress,format&q=100`
  );

  return hasImage ? (
    <div css={ß.item} key={id}>
      <Link
        css={ß.link}
        to={clickoutUrl}
        openInNewWindow
        isExternalUrl
        onClick={trackClick}
      >
        <img css={ß.image} className="lazyload" data-src={src} alt={altText} />
      </Link>
    </div>
  ) : (
    false
  );
};

PremiumPartnerItem.propTypes = {
  id: PropTypes.string,
  trackingId: PropTypes.string,
  clickoutUrl: PropTypes.string,
  logo: PropTypes.shape({
    handle: PropTypes.string,
    fileName: PropTypes.string,
    altText: PropTypes.string,
  }),
};

export default PremiumPartnerItem;
