import { css } from '@emotion/react';
import { gray5, gray8 } from '@fire/_color';
import { bold, fontSizeSmall } from '@fire/_fonts';
import { media } from '@fire/_mediaQueries';

export const button = css`
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  ::after {
    transform: rotate(45deg);
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid ${gray8};
    border-right: 2px solid ${gray8};
  }
`;

export const title = css`
  font-size: 20px;
  font-weight: ${bold};
  outline: none;

  a {
    font-weight: ${bold};
    text-decoration: none;
  }

  ${media.md} {
    display: flex;
    margin-bottom: 8px;
  }
`;

export const item = css`
  padding: 12px;
  border-top: 1px solid ${gray5};

  ${media.xs} {
    padding: 12px 40px;
  }

  [aria-expanded='true'] {
    ${button}::after {
      transform: rotate(-135deg);
      margin-top: 10px;
    }
  }
`;

export const footerLinks = css`
  li a {
    font-size: ${fontSizeSmall};
  }
`;
