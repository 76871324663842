import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import {
  curry,
  filter,
  identity as I,
  keys,
  map,
  pathOr,
  pipe,
  prop,
  propEq,
  slice,
} from 'ramda';

import MaybeData from '@contexts/MaybeData';
import { T } from '@fire/i18n/i18n-constants';
import { isEmptyOrNil } from '@fire/utils/generic';
import { isSwiss } from '@utils/platform';

import 'url-search-params-polyfill';
import * as ß from './styles';

const getAvailableLanguages = pathOr(false, ['page', 'availableLanguages']);
const getActualLanguage = pipe(pathOr(false, ['language']), slice(0, 2));

const prefix = T?.LanguageSelector ?? '';

const redirect = curry((availableLanguages, isHome, event) => {
  const value = pathOr(false, ['currentTarget', 'value'], event);
  const urlPrefix = event?.currentTarget?.urlPrefix ?? '';
  if (value) {
    window.location = isHome
      ? `${urlPrefix}/${value}`
      : `${urlPrefix}/${value}${availableLanguages.ch[value]}${window.location.search}`;
  }
});

const getSelectOptions = curry((pageContext, onChange, urlPrefix, t) =>
  pipe(
    getAvailableLanguages,
    prop('ch'),
    filter(I),
    keys,
    map(key => (
      <div
        value={key}
        key={key}
        css={ß.option}
        onClick={() => onChange({ currentTarget: { value: key, urlPrefix } })}
      >
        {t(`language-selector.${key}`)}
      </div>
    ))
  )(pageContext)
);

const Select = ({ actualLanguage, options }) => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation(prefix);

  const optionsClassname = visible ? 'visible' : 'hidden';

  return (
    <div css={ß.select}>
      <span onClick={() => setVisible(!visible)}>
        {t(`language-selector.${actualLanguage}`)}
      </span>
      <div css={ß.options} className={optionsClassname}>
        {options}
      </div>
    </div>
  );
};

Select.propTypes = {
  actualLanguage: PropTypes.string,
  t: PropTypes.func,
  options: PropTypes.node,
};

export const LanguageSelect = () => {
  const pageContext = useContext(MaybeData);
  const { t } = useTranslation(prefix);

  const availableLanguages = getAvailableLanguages(pageContext);
  const actualLanguage = getActualLanguage(pageContext);
  // DELETEME: workaround on an errorous homepage availLang data
  const isHome = propEq('prn', 'prn:page:home', pageContext);
  const onChange = redirect(availableLanguages, isHome);
  const selectOptions = getSelectOptions(pageContext, onChange);
  const showLanguageSelect = isSwiss(pageContext);

  const data = useStaticQuery(graphql`
    query LanguageSelector {
      site {
        siteMetadata {
          siteUrl
          branchSlug
        }
      }
    }
  `);

  if (availableLanguages && showLanguageSelect) {
    const siteMetadata = data?.site?.siteMetadata ?? {};
    const siteUrl = siteMetadata?.siteUrl ?? '';
    const branchSlug = siteMetadata?.branchSlug ?? '';
    const urlPrefix = isEmptyOrNil(branchSlug)
      ? siteUrl
      : `${siteUrl}${branchSlug}`; // branchSlug already has a `/`

    return (
      <div css={ß.wrapper}>
        <span>{t(`label`, `Sprache:`)}</span>
        <Select
          options={selectOptions(urlPrefix, t)}
          actualLanguage={actualLanguage}
        />
      </div>
    );
  }
  return <></>;
};

LanguageSelect.propTypes = {
  pageContext: PropTypes.object,
};

LanguageSelect.defaultProps = {
  pageContext: {},
};

const getSelectOptionsMobile = curry((pageContext, onChange, t) =>
  pipe(
    getAvailableLanguages,
    prop('ch'),
    keys,
    map(key => ({
      title: t(`language-selector.${key}`),
      url: '',
      action: () => onChange({ currentTarget: { value: key } }),
    }))
  )(pageContext)
);

export const LanguageSelector = pageContext => {
  const { t } = useTranslation(prefix);
  const availableLanguages = getAvailableLanguages(pageContext);
  // DELETEME: workaround on an errorous homepage availLang data
  const isHome = propEq('prn', 'prn:page:home', pageContext);
  const onChange = redirect(availableLanguages, isHome);
  const actualLanguage = getActualLanguage(pageContext);
  const showLanguageSelect = isSwiss(pageContext);

  const Title = () => (
    <span key={'language-selector-title'}>
      {t('label', 'Sprache:')}
      <strong>{t(`language-selector.${actualLanguage}`)}</strong>
    </span>
  );

  if (availableLanguages && showLanguageSelect) {
    return [
      {
        title: <Title />,
        url: '',
        children: getSelectOptionsMobile(pageContext, onChange, t),
      },
    ];
  }
  return [];
};

export default LanguageSelect;
