import { isEmpty, pathOr, toLower } from 'ramda';

import { getInterlinkingData } from '@components/DrilldownList/utils';
import {
  getCountryForGoogle,
  getLanguageForGoogle,
  safeDataFromPageContext,
} from '@utils/tracking';

import 'url-search-params-polyfill';

export const getSearchQuery = pageContext => {
  const searchQuery = pathOr('', ['searchQuery'], pageContext);
  const urlSearch = new URLSearchParams(searchQuery);
  urlSearch.delete('tag');
  return urlSearch.toString();
};

const pageViewData = pageContext => {
  const {
    pagePrn,
    locale,
    geoType,
    interlinkingTopTopics,
    interlinkingTopRegions,
  } = safeDataFromPageContext(pageContext);

  return {
    event: 'pageview',
    page: {
      id: pagePrn,
      language: getLanguageForGoogle(locale),
      country: getCountryForGoogle(locale),
      'search-query': getSearchQuery(pageContext),
      'geographic-type': toLower(geoType),
    },
    content: {
      'drilldown displayed':
        getInterlinkingData({
          interlinkingTopTopics,
          interlinkingTopRegions,
        }) && true,
    },
  };
};

export const parsePageViewData = pageContext => {
  // for JS tracking use trackObjects located on src/utils.js
  const pageData = pageViewData(pageContext);
  return !isEmpty(pageData) && pageData;
};
