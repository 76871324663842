import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { withPrefix } from 'gatsby';
import { prop, propOr } from 'ramda';

import { useFilters } from '@contexts/Filters';
import MaybeData from '@contexts/MaybeData';
import { T } from '@fire/i18n/i18n-constants';
import { satisfiesPlatformRestrictionIfSet } from '@utils/platform';

import { ratingFilters } from './filters.json';
import RatingRadioGroup from './RatingRadioGroup';
import * as ß from './styles';

const namespace = prop('SearchResultFilter', T);

const tipp = withPrefix('/icons/tipp-2023.svg');
const superplatz = withPrefix('/icons/superplatz-2023.svg');

const RatingFilters = () => {
  const icons = { tipp, superplatz };
  const pageContext = useContext(MaybeData);
  const [stateContext] = useFilters();
  const { t } = useTranslation(namespace);

  return (
    <>
      <h2 css={ß.filterTitle}>{t('Bewertung')}</h2>
      <div css={ß.gridWrap}>
        {ratingFilters
          .filter(satisfiesPlatformRestrictionIfSet(pageContext))
          .map(ratingFilter => (
            <RatingRadioGroup
              activeValue={propOr(0, ratingFilter.id, stateContext)}
              extraLabels={propOr([], 'extraLabels', ratingFilter).map(
                ({ stars, label, picture }) => ({
                  stars,
                  label: t(label),
                  picture: icons?.[picture],
                })
              )}
              filter={ratingFilter}
              key={ratingFilter.id}
              starColor={ratingFilter?.color}
            />
          ))}
      </div>
    </>
  );
};
export default RatingFilters;
