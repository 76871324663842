import { css } from '@emotion/react';
import { red9 } from '@fire/_color';
import { bold } from '@fire/_fonts';
import { media } from '@fire/_mediaQueries';

export const wrapper = css`
  margin-left: 8px;
`;

export const item = css`
  list-style: 'none';
  word-break: break-word;
  display: inline-flex;
  margin-right: 0;
`;

export const list = css`
  list-style: none;
  margin-bottom: 36px;
  margin-top: 0;
  padding: 0;

  li {
    margin-bottom: 4px;
  }
`;

export const checkListitem = css`
  margin-right: 24px;
`;

export const orderedList = css`
  list-style: decimal;
  margin: 16px 0;

  li {
    counter-increment: list;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    list-style: none;
    width: 100%;

    &:before {
      color: ${red9};
      content: counter(list) '.';
      text-align: right;
      width: 20px;
      margin-right: 15px;
      font-size: 24px;
      font-weight: ${bold};
      letter-spacing: 5px;
    }
  }
`;

export const checklist = css`
  display: inline-block;
  width: 100%;
`;

export const checklistTitle = css`
  align-items: flex-start;
  display: flex;
  margin-top: 0;
  margin-bottom: 12px;

  span {
    margin-right: 4px;
    padding-top: 2px;
    width: 25px;
  }
`;

export const checklistList = css`
  margin-bottom: 0;
  padding-bottom: 0;
  margin-top: 15px;
  column-count: 1;
  border-bottom-color: transparent;
`;

export const checklistPayment = css`
  margin-bottom: 32px;
  padding-bottom: 0;
  column-count: 1;
  margin-top: 15px;
  margin-left: 8px;

  h3 {
    margin-top: 24px;
    margin-bottom: 12px;

    ${media.md} {
      font-size: 26px;
    }
  }
`;

export const checklistIcon = css`
  width: 20px;
  min-width: 20px;
  height: 21px;
  margin-right: 8px;
`;
