import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import ReactTooltip from 'react-tooltip';
import { withPrefix } from 'gatsby';
import { isEmpty, pathOr } from 'ramda';

import MaybeData from '@contexts/MaybeData';
import { blue10 } from '@fire/_color';
import { LOCALES } from '@fire/constants';
import { T } from '@fire/i18n/i18n-constants';
import { trackObject } from '@utils/tracking';

import allCountries from './countries';
import geo from './europeMin.json';
import * as ß from './styles';

const { DE, CH_DE, CH_IT, CH_FR } = LOCALES;

const countryPaths = {
  [DE]: 'pathDE',
  [CH_DE]: 'pathDE',
  [CH_IT]: 'pathITCH',
  [CH_FR]: 'pathFRCH',
};
const prefix = pathOr('', ['MenuMap'], T);

const getCountryName = (id, t) => {
  if (allCountries[id]) {
    // pathDE in this case is the id used for translation
    const countryName = pathOr('', [id, 'pathDE'], allCountries);
    return t(`${prefix}:${countryName}`);
  }
  return '';
};

const darkBlueColor = blue10;
const whiteFogColor = '#edf3f7';
const whiteColor = '#ffffff';
const lightBlueColor = '#d0e6f2';
const darkGreyColor = '#607D8B';
const redColor = '#E42612';

const getGeographyStyles = (geographyId, language) => {
  const outline = 'none';
  const country = pathOr('', [geographyId, language], allCountries);
  const isAvailable = !isEmpty(country);
  return {
    default: {
      fill: `${isAvailable ? darkBlueColor : whiteFogColor}`,
      stroke: whiteColor,
      strokeWidth: 0.5,
      outline,
    },
    hover: {
      fill: `${isAvailable ? lightBlueColor : whiteFogColor}`,
      stroke: `${isAvailable ? lightBlueColor : whiteColor}`,
      strokeWidth: 0.75,
      outline,
    },
    pressed: {
      fill: `${isAvailable ? redColor : whiteFogColor}`,
      stroke: `${isAvailable ? darkGreyColor : whiteColor}`,
      strokeWidth: 0.75,
      outline,
    },
  };
};

const track = countryName => {
  trackObject({
    event: 'navigation-portal_click-navigation-map',
    category: 'navigation-portal',
    action: 'click navigation map',
    label: countryName,
  });
};

const MenuMap = () => {
  const [mapContent, setMapContent] = useState('');
  const { t } = useTranslation();
  const pageContext = useContext(MaybeData);
  const language = pathOr(DE, ['language'], pageContext);

  const handleClick = geography => {
    const country = allCountries[geography.id];

    if (country[countryPaths[language]].length === 0) return;
    track(country.name);
    window.location.assign(`${withPrefix(country[countryPaths[language]])}`);
  };

  const renderMap = geographies =>
    geographies.map(
      (geography, i) =>
        // Removing Israel from map
        geography.id !== 'IL' && (
          <Geography
            key={i}
            geography={geography}
            onClick={() => handleClick(geography)}
            style={getGeographyStyles(geography.id, countryPaths[language])}
            onMouseEnter={() => {
              setMapContent(getCountryName(geography.id, t));
            }}
            onMouseLeave={() => {
              setMapContent('');
            }}
          />
        )
    );

  return (
    <div css={ß.mapContainer}>
      <ComposableMap
        data-tip=""
        projection="geoAzimuthalEqualArea"
        projectionConfig={{
          rotate: [-20, -52, 0],
          scale: 550,
        }}
        width={453}
        height={437}
      >
        <Geographies geography={geo}>
          {({ geographies }) => renderMap(geographies)}
        </Geographies>
      </ComposableMap>
      <ReactTooltip>{mapContent}</ReactTooltip>
    </div>
  );
};

export default MenuMap;
