import smoothscroll from 'smoothscroll-polyfill';

import { checkWindowExists } from './window';

Math.easeInOutQuad = (t, b, c, d) => {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t -= 1;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};

const dispatchScroll = () => {
  if (!checkWindowExists()) return false;

  const isDOMish = typeof Event === 'function';
  const scrollEvent = isDOMish
    ? new Event('scroll')
    : document.createEvent('event');

  if (!isDOMish) scrollEvent.initEvent('scroll', true, true);

  return document.dispatchEvent(scrollEvent);
};

export const goToTop = () => {
  window.scrollTo(0, 0);
  dispatchScroll();
};

export const scrollTo = (y, duration = 0) => {
  if (duration > 0) {
    const start = window.scrollY || document.documentElement.scrollTop;
    let currentTime;

    const animateScroll = timestamp => {
      if (!currentTime) currentTime = timestamp;
      const progress = timestamp - currentTime;
      const val = Math.ceil(
        Math.easeInOutQuad(progress, start, y - start, duration)
      );
      window.scrollTo(0, val);
      if (progress < duration) {
        window.requestAnimationFrame(animateScroll);
      }
    };
    window.requestAnimationFrame(animateScroll);
  } else {
    window.scrollTo(0, y);
  }
};

//  Use this function for secure smooth scroll even in browsers like Safari and IE that don't support smooth scroll.
// Fallback is scrollTo function

export const smoothScrollTo = top => {
  if ('scrollBehavior' in document.documentElement.style) {
    window.scrollTo({
      top,
      behavior: 'smooth',
    });
    return;
  }
  scrollTo(top, 1000);
};

export const scrollToElemBySelector = selector => {
  if (!checkWindowExists()) return;

  try {
    smoothscroll.polyfill();
    document?.querySelector(selector)?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  } catch (e) {
    console.error(e);
  }
};

export const scrollToElemById = elemId => scrollToElemBySelector(`#${elemId}`);

export const scrollToTop = () => {
  if (!checkWindowExists) return;

  try {
    smoothscroll.polyfill();
    window.scrollTo({ behavior: 'smooth', left: 0, top: 0 });
  } catch (e) {
    console.error(e);
  }
};
