import { trackObject } from '@utils/tracking';

export const trackClick = prn => {
  trackObject({
    action: 'click drilldown',
    category: 'list',
    label: prn,
    event: 'list_click-drilldown',
  });
};

export const trackArrowRightClick = () => {
  trackObject({
    action: 'click drilldown more',
    category: 'list',
    label: '(not set)', // This is how we want it
    event: 'list_click-drilldown-more',
  });
};
