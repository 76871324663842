import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Loading from '@components/Loading';
import useReservationRequest from '@hooks/useReservationRequest';
import { useLocation } from '@reach/router';
import { queryToObject } from '@utils/url';

import { NAMESPACE } from './constants';
import Details from './Details';
import * as ß from './styles';
import { getKeyWithPrefix } from './utils';

const Accepted = () => {
  const { search } = useLocation();
  const params = queryToObject(search);
  const { acceptCampsite, details, isLoading, isValid } = useReservationRequest(
    {
      requestId: params?.request_id,
      sessionId: params?.session_id,
    }
  );
  const email = details?.email;
  const { t } = useTranslation(NAMESPACE);

  const getKey = getKeyWithPrefix(params, t);

  useEffect(() => {
    acceptCampsite();
  }, []);

  return (
    <>
      <h1 css={ß.title}>{getKey('title')}</h1>
      {isLoading ? (
        <div css={ß.loading}>
          <Loading />
        </div>
      ) : isValid ? (
        <>
          <p css={ß.feedback}>{getKey('send-offer-guest')}</p>
          <h2 css={ß.subtitle}>{getKey('contact-guest')}</h2>
          {email && (
            <p css={ß.feedback}>
              ({getKey('guest-email')}: <a href={`mailto:${email}`}>{email}</a>)
            </p>
          )}

          <Details {...details} params={params} />
        </>
      ) : (
        <h2 css={ß.subtitle}>{getKey('reservation-not-found')}</h2>
      )}
    </>
  );
};

export default Accepted;
