import axios from 'axios';
import { Future } from 'fluture';
import { partial } from 'ramda';

export const makeRequest = opts =>
  Future((bad, good) => {
    const cancelTokenSource = axios.CancelToken.source();
    axios
      .request({ ...opts, cancelToken: cancelTokenSource.token })
      .then(good)
      .catch(bad);
    return partial(cancelTokenSource.cancel, ['cancelled']);
  });
