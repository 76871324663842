import React, { useEffect, useState } from 'react';

import Link from '@components/Link';
import useAvocado from '@hooks/useAvocado';
import { localStorage } from '@utils/local-storage';

import avocado from './avocado.json';
import * as ß from './styles';

const reviewBannerKey = 'reviewBannerIdle';
const getTopBannerLocalStorage = () => {
  const date = localStorage.getItem(reviewBannerKey);
  if (date === false) return false;
  if (!date) return true;
  const now = new Date();
  return Date.parse(date) < now.setDate(now.getDate() - 8);
};

const setTopBannerLocalStorage = setTopBannerState => {
  localStorage.setItem(reviewBannerKey, new Date());
  setTopBannerState(false);
};

const TopBanner = () => {
  const content = useAvocado(avocado);

  const [showTopBanner, setTopBannerState] = useState(false);
  useEffect(() => setTopBannerState(getTopBannerLocalStorage()), []);
  return (
    <div css={showTopBanner ? ß.reviewBanner : ß.hidden}>
      <div css={ß.text}>
        <h3>{content?.text}</h3>
        {content?.link && (
          <Link
            onClick={() => setTopBannerLocalStorage(setTopBannerState)}
            to={content?.link}
          >
            {content?.button}
          </Link>
        )}
      </div>
      <div
        onClick={() => setTopBannerLocalStorage(setTopBannerState)}
        css={ß.clickableTopBanner}
      />
    </div>
  );
};

export default TopBanner;
