export const MICROCAMPING_URL =
  'https://www.pincamp.de/camperland/query?page=1&include=images&fields.listing=title,description,publicData,images,price&availability=full&limit.images=1&per_page=5&fields.image=variants.tile&imageVariant.tile=w:322;h:140;fit:crop';

export const CAMPERLAND_FEE = 1.1;

export const MICROCAMPING_PATH_PREFIX = 'https://camperland.de/stellplatz/';

export const MIN_MICROCAMPING = 3;
export const MAX_GUESTS = 4;

export const REF =
  'ref=pincamp&utm_source=pincamp&utm_medium=listinglink&utm_campaign=pclisting&utm_id=pc1';
