import { css } from '@emotion/react';
import { blue9, charcoal, gray7, gray10, red8 } from '@fire/_color';
import { bold, fontFace, fontSizeMedium } from '@fire/_fonts';

const labelTransform = css`
  transform: translate(0, -8px) scale(0.85);
  color: ${gray10};
`;

const bottomBorder = css`
  background-repeat: no-repeat;
  background-size: 100% 2px;
  background-position: bottom, 50% calc(100% - 1px);
`;

export const wrapper = css`
  position: relative;
  width: 100%;
`;

export const input = css`
  ${fontFace};
  font-size: ${fontSizeMedium};
  font-weight: ${bold};
  color: ${charcoal};
  position: relative;
  min-height: 52px;
  width: 100%;
  padding: 16px 12px 0px;
  border: solid 1px ${gray7};
  border-radius: 8px;
  margin: 0;
  background-image: none;
  ${bottomBorder}

  &:focus {
    outline: none;
    background-image: linear-gradient(${blue9}, ${blue9});

    + label {
      ${labelTransform}
    }
  }

  &:not(:placeholder-shown) {
    + label {
      ${labelTransform}
    }
  }
`;

export const error = css`
  border: solid 1px ${red8};
`;

export const label = css`
  position: absolute;
  pointer-events: none;
  transform-origin: 0 0;
  transform: translate3d(0, 0, 0);
  transition: transform 0.2s ease;
  top: 12px;
  left: 12px;
`;
