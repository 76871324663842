import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';

import {
  ACCOMMODATIONS_ID,
  PRICE_WITHOUT_CENTS,
} from '@components/AccommodationList/constants';
import Button from '@components/Button';
import { T } from '@fire/i18n/i18n-constants';
import { formatPrice } from '@utils/money';

import * as ß from './styles';

const namespace = pathOr('', ['Tile'], T);

const Accommodations = ({
  availabilities,
  path,
  onBookClick,
  openInNewWindow,
}) => {
  const { t } = useTranslation(namespace);
  return (
    <div css={ß.accommodationList}>
      {availabilities.map(item => {
        const rates = item.accommodations[0].rates[0];
        const onClick = onBookClick(item.category);
        return (
          <div key={item.category} css={ß.listItem}>
            <div css={ß.info}>
              <p>
                <b>{t(item.category)}</b>
              </p>
              <p>{t('available_accommodations', { count: item.count })}</p>
            </div>
            <Button
              isTwoLines
              size="small"
              variant="primary"
              to={`${path}${ACCOMMODATIONS_ID}`}
              openInNewWindow={openInNewWindow}
              onClick={onClick}
            >
              <span>
                <b>
                  {t('Ab', {
                    price: formatPrice(
                      rates.total,
                      rates.currency,
                      PRICE_WITHOUT_CENTS
                    ),
                  })}
                </b>
              </span>
              <span>
                {t('Nacht', {
                  price: formatPrice(
                    rates.daily,
                    rates.currency,
                    PRICE_WITHOUT_CENTS
                  ),
                })}
              </span>
            </Button>
          </div>
        );
      })}
    </div>
  );
};
Accommodations.propTypes = {
  availabilities: PropTypes.array,
  path: PropTypes.string,
  onBookClick: PropTypes.func,
  openInNewWindow: PropTypes.bool.isRequired,
};

Accommodations.defaultProps = {
  availabilities: [],
  onBookClick: () => {},
  openInNewWindow: true,
};

export default Accommodations;
