import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const DatePickerContext = createContext();

export const DatePickerProvider = ({ children }) => {
  const [isFocused, setIsFocused] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const closeDatePicker = () => setIsFocused(false);
  const openDatePicker = () => setIsFocused(true);

  return (
    <DatePickerContext.Provider
      value={{
        isFocused,
        closeDatePicker,
        openDatePicker,
        selectedTab,
        setSelectedTab,
      }}
    >
      {children}
    </DatePickerContext.Provider>
  );
};

DatePickerProvider.propTypes = {
  children: PropTypes.node,
};

export default DatePickerContext;
