// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-outdated-js": () => import("./../../../src/pages/outdated.js" /* webpackChunkName: "component---src-pages-outdated-js" */),
  "component---src-pages-reservation-request-campsite-accepted-js": () => import("./../../../src/pages/reservation-request/campsite/accepted.js" /* webpackChunkName: "component---src-pages-reservation-request-campsite-accepted-js" */),
  "component---src-pages-reservation-request-campsite-refused-js": () => import("./../../../src/pages/reservation-request/campsite/refused.js" /* webpackChunkName: "component---src-pages-reservation-request-campsite-refused-js" */),
  "component---src-pages-reservation-request-campsite-show-detail-js": () => import("./../../../src/pages/reservation-request/campsite/show-detail.js" /* webpackChunkName: "component---src-pages-reservation-request-campsite-show-detail-js" */),
  "component---src-pages-reservation-request-guest-accepted-js": () => import("./../../../src/pages/reservation-request/guest/accepted.js" /* webpackChunkName: "component---src-pages-reservation-request-guest-accepted-js" */),
  "component---src-pages-reservation-request-guest-resolved-js": () => import("./../../../src/pages/reservation-request/guest/resolved.js" /* webpackChunkName: "component---src-pages-reservation-request-guest-resolved-js" */),
  "component---src-templates-destination-page-index-js": () => import("./../../../src/templates/DestinationPage/index.js" /* webpackChunkName: "component---src-templates-destination-page-index-js" */),
  "component---src-templates-home-page-index-js": () => import("./../../../src/templates/HomePage/index.js" /* webpackChunkName: "component---src-templates-home-page-index-js" */),
  "component---src-templates-index-page-index-js": () => import("./../../../src/templates/IndexPage/index.js" /* webpackChunkName: "component---src-templates-index-page-index-js" */),
  "component---src-templates-review-landing-page-index-js": () => import("./../../../src/templates/ReviewLandingPage/index.js" /* webpackChunkName: "component---src-templates-review-landing-page-index-js" */),
  "component---src-templates-review-page-index-js": () => import("./../../../src/templates/ReviewPage/index.js" /* webpackChunkName: "component---src-templates-review-page-index-js" */),
  "component---src-templates-standard-page-index-js": () => import("./../../../src/templates/StandardPage/index.js" /* webpackChunkName: "component---src-templates-standard-page-index-js" */)
}

