import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';

import MaybeData from '@contexts/MaybeData';
import { PLATFORMS } from '@fire/constants';
import { isEmptyOrNil, objectToArrayPair } from '@fire/utils/generic';

import { DISABLED_PAGE } from './consts';

export const HrefLang = () => {
  const pageContext = useContext(MaybeData);
  const availableLanguages = pageContext?.page?.availableLanguages ?? {};

  const prefixUrl = 'https://www.';
  const { ch, de } = availableLanguages;

  const defaultPath = de?.de ?? '/';

  const defaultAlternateObj = {
    rel: 'alternate',
    hreflang: 'x-default',
    href: `${prefixUrl}${PLATFORMS.DE}${defaultPath}`,
  };

  const defaultBaseObj = {
    ...defaultAlternateObj,
    hreflang: 'de-DE',
  };

  const othersArrayObj = !isEmptyOrNil(ch)
    ? objectToArrayPair(ch).map(alt => {
        const [language, path] = alt;
        return {
          ...defaultAlternateObj,
          hreflang: `${language}-CH`,
          href: `${prefixUrl}${PLATFORMS.CH}/${language}${path}`,
        };
      })
    : [];

  const alternateLanguages = !DISABLED_PAGE.includes(pageContext?.prn)
    ? [defaultBaseObj, defaultAlternateObj, ...othersArrayObj]
    : [];

  return <Helmet link={alternateLanguages} />;
};
