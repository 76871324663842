import React from 'react';
import PropTypes from 'prop-types';

import * as ß from './styles';

const WhiteRectangleWithBoxShadow = ({
  topLeft,
  topRight,
  bottomLeft,
  bottomRight,
  onClick,
}) => (
  <div css={ß.rectangleBoxWithShadow} onClick={onClick}>
    <div css={ß.row}>
      {topLeft && topLeft}
      {topRight && topRight}
    </div>
    <div css={ß.row}>
      {bottomLeft && bottomLeft}
      {bottomRight && bottomRight}
    </div>
  </div>
);

WhiteRectangleWithBoxShadow.propTypes = {
  topLeft: PropTypes.node,
  topRight: PropTypes.node,
  bottomLeft: PropTypes.node,
  bottomRight: PropTypes.node,
  onClick: PropTypes.func,
};

WhiteRectangleWithBoxShadow.defaultProps = {
  onClick: () => {},
};

export default WhiteRectangleWithBoxShadow;
