import { css } from '@emotion/react';
import { gray5, red10 } from '@fire/_color';
import { media } from '@fire/_mediaQueries';

const headerHeight = '91px';
const headerHeightMobile = '70px';

export const header = css`
  display: flex;
  height: ${headerHeightMobile};
  width: 100%;
  color: white;
  border-bottom: 1px solid ${gray5};
  ${media.lg} {
    height: ${headerHeight};
  }
`;

export const navigation = css`
  display: flex;
  flex-direction: row;
`;

export const wrapper = css`
  display: inline-flex;
  justify-content: space-between;
  height: ${headerHeightMobile};
  width: 100%;
  padding: 10px 12px;
  border-bottom: 1px solid ${gray5};
  ${media.lg} {
    height: ${headerHeight};
  }
`;

export const wrapperLeft = css`
  display: flex;
  align-items: center;
  flex: 1;
  max-width: 70%;

  ${media.lg} {
    max-width: 100%;
  }
`;

export const wrapperRight = css`
  display: flex;
  justify-content: flex-end;
  justify-self: flex-end;
  align-items: center;
  padding: 8px;
  ${media.lg} {
    flex: 0 0 720px;
  }
`;

const burgerLineStyle = css`
  background-color: ${red10};
  border-radius: 2px;
  display: block;
  height: 3px;
  width: 20px;
`;

export const burgerIcon = css`
  ${burgerLineStyle}
  margin-left: 12px;
  position: relative;

  ${media.lg} {
    display: none;
  }

  ::after {
    ${burgerLineStyle}
    content: '';
    position: absolute;
    top: 6px;
  }

  ::before {
    ${burgerLineStyle}
    content: '';
    position: absolute;
    top: -6px;
  }
`;

export const hideOnDesktop = css`
  ${media.lg} {
    display: none;
  }
`;

export const hideOnMobile = css`
  display: none;

  ${media.lg} {
    display: block;
  }
`;
