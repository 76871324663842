import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withPrefix } from 'gatsby';
import { isNil, pathOr, propOr } from 'ramda';

import { T } from '@fire/i18n/i18n-constants';
import { slugify } from '@utils/string';
import { trackObject } from '@utils/tracking';

import HeroMenuBigTile from '../HeroMenuBigTile';
import HeroMenuLink from '../HeroMenuLink';
import HeroMenuTiles from '../HeroMenuTiles';
import * as ß from './styles';

const getLinks = ({ links, parentTitle }) =>
  links.map((tile, key) => (
    <HeroMenuLink parentTitle={parentTitle} tile={tile} key={key} />
  ));

const closeIcon = withPrefix('/icons/close.svg');

export const HeroMenuContent = ({
  heroMenuContent,
  activeItem,
  changeActiveItem,
}) => {
  const prefix = pathOr('', ['HeroMenu'], T);
  const { t } = useTranslation();
  const currentContent = heroMenuContent[activeItem];
  const { leftTitle, upperRightTitle, downRightTitle } = propOr(
    {},
    'titles',
    currentContent
  );
  const children = propOr([], 'children', currentContent);
  const links = propOr([], 'links', currentContent);
  const parentTitle = propOr('', 'title', currentContent);

  const handleCloseClick = (e, title) => {
    e.preventDefault();
    changeActiveItem(-1);

    trackObject({
      event: 'navigation-portal_click-close-navigation',
      category: 'navigation-portal',
      action: 'click close navigation',
      label: title,
    });
  };
  const bigTileContent = children[0];
  if (!isNil(bigTileContent)) {
    return (
      <div css={ß.heroMenu}>
        <div id={parentTitle} css={ß.parentContainer}>
          <div css={ß.contentWrapper}>
            <div
              css={ß.close}
              onClick={e => handleCloseClick(e, currentContent.title)}
            >
              <img src={closeIcon} />
            </div>
            <div css={ß.content}>
              <HeroMenuBigTile
                activeChild={activeItem}
                image={bigTileContent.image}
                url={bigTileContent.url}
                title={leftTitle}
                parentTitle={parentTitle}
                description={bigTileContent.title}
              />

              <div css={ß.smallContent}>
                <p css={ß.smallImagesHeadline}>
                  {upperRightTitle !== '' &&
                    t(
                      `${prefix}:upper-right-title.${slugify(upperRightTitle)}`,
                      upperRightTitle
                    )}
                </p>
                <div css={ß.smallContentImages}>
                  <HeroMenuTiles
                    css={ß.smallImages}
                    content={children}
                    activeItem={activeItem}
                    parentTitle={parentTitle}
                  />
                </div>
                <div css={ß.linkList}>
                  <p css={ß.smallImagesHeadline}>
                    {downRightTitle !== '' &&
                      t(
                        `${prefix}:down-right-title.${slugify(downRightTitle)}`,
                        downRightTitle
                      )}
                  </p>
                  <div css={ß.smallLinks}>
                    {getLinks({ links, parentTitle })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <></>;
};

HeroMenuContent.propTypes = {
  activeItem: PropTypes.number,
  changeActiveItem: PropTypes.func.isRequired,
  heroMenuContent: PropTypes.array,
};

HeroMenuContent.defaultProps = {
  activeItem: -1,
  heroMenuContent: [],
};

export default memo(HeroMenuContent);
