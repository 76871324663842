import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const TileControlAvailabilityModal = createContext({});

TileControlAvailabilityModal.displayName = 'TileControlAvailabilityModal';

export const TileControlAvailabilityModalProvider = ({ children }) => {
  const [isAvailabilityModalOpen, setIsAvailabilityModalOpen] = useState(false);

  return (
    <TileControlAvailabilityModal.Provider
      value={{ isAvailabilityModalOpen, setIsAvailabilityModalOpen }}
    >
      {children}
    </TileControlAvailabilityModal.Provider>
  );
};

TileControlAvailabilityModalProvider.propTypes = {
  children: PropTypes.node,
};

export default TileControlAvailabilityModal;
