import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import { imgWithAttrs, poweredBy } from './utils';

export const Twitter = ({ title, description, image, pageContext }) => (
  <Helmet>
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content="@PiNCAMP_DE" />
    <meta name="twitter:title" content={poweredBy(title, pageContext)} />
    <meta name="twitter:description" content={description} />
    <meta
      name="twitter:image"
      content={imgWithAttrs({ width: 300, height: 157 }, image)}
    />
  </Helmet>
);

Twitter.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string,
  pageContext: PropTypes.object,
};

Twitter.defaultProps = {
  title: '',
  description: '',
  pageContext: {},
};

export default Twitter;
