import React from 'react';
import PropTypes from 'prop-types';
import { withPrefix } from 'gatsby';

import Link from '@components/Link';
import { withData } from '@fire/hoc/maybe';
import { isADAC, shortenLanguageName } from '@utils/platform';

import * as ß from './styles';

export const getLogoPath = isAdac => (isAdac ? 'ADAC' : 'TCS');
export const getLinkWithLanguage = ({ language, isAdac }) => {
  const lang = language.split('-')[0];
  return isAdac ? 'https://www.adac.de/' : `https://www.tcs.ch/${lang}`;
};

const ADACLogo = withPrefix('/icons/adac-powered.svg');
const TCSLogo = withPrefix('/icons/tcs-logo.svg');

export const PlatformLogoSelector = ({ pageContext }) => {
  const isAdac = isADAC(pageContext);
  const language = shortenLanguageName(pageContext?.language ?? 'de');
  const href = getLinkWithLanguage({ language, isAdac });
  const partner = isAdac ? 'ADAC' : 'TCS';
  const altText = `Powered by ${partner}`;
  const logo = isAdac ? ADACLogo : TCSLogo;

  return (
    <Link to={href} openInNewWindow isExternalUrl>
      <img alt={altText} css={ß.logo} src={logo} />
    </Link>
  );
};

PlatformLogoSelector.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export const PlatformLogo = props => <PlatformLogoSelector {...props} />;

export default withData(PlatformLogo);
