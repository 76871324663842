import { css } from '@emotion/react';
import { gray5, white } from '@fire/_color';
import { bold } from '@fire/_fonts';
import { media } from '@fire/_mediaQueries';

export const heroMenu = css`
  display: none;
  justify-content: center;
  align-items: center;
  background: ${white};
  position: relative;
  z-index: 3;
  border-bottom: 1px solid ${gray5};
  ${media.lg} {
    display: flex;
  }
`;

export const close = css`
  cursor: pointer;
  margin: 5px;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 16px;
`;

export const contentWrapper = css`
  max-width: 1800px;
  height: 540px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 100px 32px 100px;
`;

export const parentContainer = css`
  width: 100%;
`;

export const content = css`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 16px;
  max-width: 100%;
  min-width: 0;
`;

export const smallContent = css`
  flex-shrink: 1;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  flex-direction: column;
  overflow: hidden;
  margin-left: 20px;
`;

export const smallImages = css`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-bottom: 32px;
  margin-top: 20px;
`;

export const smallImagesHeadline = css`
  height: 21px;
  font-size: 20px;
  font-weight: ${bold};
  margin-bottom: 22px;
  text-decoration: none;
`;

export const smallContentImages = css`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 14px;
`;

export const linkList = css`
  margin-top: 22px;
  width: 100%;
  order: 4;
`;

export const smallLinks = css`
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;

  & > a {
    margin: 0;
    margin-right: 12px;
    display: inline-block;
    white-space: nowrap;
  }
`;
