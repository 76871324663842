import { brandSwisscampPink, gray7, red9, yellow9 } from '@fire/_color';

export const STAR_STATES = {
  FULL: 'FULL',
  HALF: 'HALF',
  EMPTY: 'EMPTY',
};

export const COLOR_TABLE = {
  YELLOW: yellow9,
  RED: red9,
  PINK: brandSwisscampPink,
  EMPTY: gray7,
};
