import React, {
  lazy,
  memo,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import Sticky from 'react-sticky-el';
import PropTypes from 'prop-types';
import moment from 'moment';
import { filter, head, last, lensProp, map, pipe, toPairs, view } from 'ramda';

import { trackResetForm } from '@components/DatePicker/tracking';
import { useFilters } from '@contexts/Filters';
import InfoMessageContext from '@contexts/InfoMessage';
import TileControlAvailabilityModal from '@contexts/TileControlAvailabilityModal';
import { BREAKPOINTS } from '@fire/_mediaQueries';
import { ACCOMMODATION_PARAMS, DEFAULT_TOTAL_ADULTS } from '@fire/constants';
import { useDimensions } from '@hooks/useDimensions';
import useHasMounted from '@hooks/useHasMounted';
import useProsciutto from '@hooks/useProsciutto';
import useTracking from '@hooks/useTracking';
import { deleteBookingData } from '@utils/availability';
import { isEmptyOrNil } from '@utils/generic';
import { scrollToTop } from '@utils/scroll';

import FilterContainerDesktop from './FilterContainerDesktop';
import FilterContainerMobile from './FilterContainerMobile';
import FilterPin from './FilterPin';
import RemoveAllFiltersButton from './RemoveAllFiltersButton';
import * as ß from './styles';
import {
  allRatingIds,
  filterRemoveableParams,
  generalFilterIds,
  getActiveFilters,
  notAllowedFilters,
  pickRelevantParams,
  prepareFiltersForTracking,
} from './utils';

const FilterAvailability = lazy(() => import('./FilterAvailability'));
const FilterModal = lazy(() => import('./FilterModal'));

const DestinationFilters = ({ namespace }) => {
  const {
    prepareToTrackEvent: { clickEvent: calendarEventTrack },
  } = useTracking('calendar');
  const {
    prepareToTrackEvent: { clickEvent: filterEventTrack },
  } = useTracking('filter');

  const hasMounted = useHasMounted();
  const { width } = useDimensions();
  const [stateContext, dispatchContext] = useFilters();
  const [prosciuttoState, prosciuttoDispatch] = useProsciutto();
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [kidsAges, setKidsAges] = useState([]);
  const [totalAdults, setTotalAdults] = useState(DEFAULT_TOTAL_ADULTS);
  const { isAvailabilityModalOpen, setIsAvailabilityModalOpen } = useContext(
    TileControlAvailabilityModal
  );
  const [isGeneralModalOpen, setIsGeneralModalOpen] = useState(false);
  const [isRatingsModalOpen, setIsRatingsModalOpen] = useState(false);
  const { setShowMessage } = useContext(InfoMessageContext);

  const isMobile = width < BREAKPOINTS.xs;

  const openMobileFilter = () => {
    setIsGeneralModalOpen(true);
    setIsRatingsModalOpen(true);
  };

  const closeFilterModals = useCallback(() => {
    setIsAvailabilityModalOpen(false);
    setIsGeneralModalOpen(false);
    setIsRatingsModalOpen(false);
  }, [setIsAvailabilityModalOpen]);

  const resetForm = () => {
    setKidsAges([]);
    setTotalAdults(DEFAULT_TOTAL_ADULTS);
    setDateFrom();
    setDateTo();
    prosciuttoDispatch({
      type: 'setUrlParams',
      removeParams: [...ACCOMMODATION_PARAMS, 'page'],
    });
    deleteBookingData();
    trackResetForm('filter');
    setShowMessage(false);
    scrollToTop();
  };

  const trackSearchWithoutDates = calendarEventTrack('search without dates');
  const trackSearchWithDates = calendarEventTrack('search with dates');

  useEffect(() => {
    if (hasMounted) {
      const adults = prosciuttoState?.params?.adults ?? DEFAULT_TOTAL_ADULTS;
      const preChildren = prosciuttoState?.params?.children;
      const checkIn = prosciuttoState?.params?.date_from;
      const checkOut = prosciuttoState?.params?.date_to;
      const children = !isEmptyOrNil(preChildren) ? preChildren : [];

      if (checkIn && checkOut) {
        setDateFrom(moment(checkIn));
        setDateTo(moment(checkOut));
        setKidsAges(children);
        setTotalAdults(adults);
      }
    }
  }, [hasMounted, prosciuttoState.params]);

  useEffect(() => {
    if (hasMounted) {
      const params = prosciuttoState?.params;

      const hasDates =
        !isEmptyOrNil(params?.date_to) && !isEmptyOrNil(params?.date_from);
      const hasNoDates = !isEmptyOrNil(params) && !hasDates;

      hasNoDates && trackSearchWithoutDates();
      hasDates && trackSearchWithDates();

      const filtersFiltered = prepareFiltersForTracking(params);
      !isEmptyOrNil(filtersFiltered) && filterEventTrack(filtersFiltered)();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMounted, prosciuttoState.params]);

  const handleApplyFilters = useCallback(
    (_, maybeStateContext) => {
      const finalStateContext = maybeStateContext || stateContext;
      // first let's pick the items which the filters control (omitting stuff like coords)
      const relevantParams = pickRelevantParams(finalStateContext);
      // then let's try to see if there's some noise params to remove
      const maybeRemoveParams = filterRemoveableParams(
        prosciuttoState.params,
        finalStateContext
      );

      prosciuttoDispatch({
        type: 'setUrlParams',
        params: relevantParams,
        removeParams: [...maybeRemoveParams, 'page'],
      });

      scrollToTop();
      closeFilterModals();
    },
    [stateContext, prosciuttoDispatch, prosciuttoState, closeFilterModals]
  );

  const handleCancel = () => {
    closeFilterModals();
    dispatchContext({ type: 'setFilters', payload: prosciuttoState.params });
  };

  const activeFilters = getActiveFilters(
    pickRelevantParams(prosciuttoState.params)
  );

  const hasActiveFilters = filterIds =>
    activeFilters.some(activeFilter => filterIds.includes(activeFilter));

  const modalProps = {
    handleApplyFilters,
    handleCancel,
    isGeneralModalOpen,
    isRatingsModalOpen,
    namespace,
  };

  const removeActiveFilter = filterId => () => {
    dispatchContext({
      type: 'setFilters',
      payload: { [filterId]: 0 },
    });

    prosciuttoDispatch({
      type: 'setUrlParams',
      removeParams: [filterId, 'page'],
    });
  };

  const activeFilterItems = pipe(
    view(lensProp('params')),
    pickRelevantParams,
    toPairs,
    filter(notAllowedFilters),
    map(filterAr => (
      <FilterPin
        filterId={head(filterAr)}
        key={head(filterAr)}
        namespace={namespace}
        removeActiveFilter={removeActiveFilter}
        value={last(filterAr)}
      />
    ))
  )(prosciuttoState);

  const hasAnyActiveFilter =
    hasActiveFilters(allRatingIds) || hasActiveFilters(generalFilterIds);

  const count = prosciuttoState?.count ?? 0;

  return (
    <div css={ß.filterContainerWrapper}>
      {isMobile ? (
        <FilterContainerMobile
          dateFrom={dateFrom}
          dateTo={dateTo}
          hasActiveFilters={hasActiveFilters}
          kidsAges={kidsAges}
          namespace={namespace}
          openMobileFilter={openMobileFilter}
          resetForm={resetForm}
          totalAdults={totalAdults}
          count={count}
        />
      ) : (
        <Sticky stickyClassName="onTop" boundaryElement=".content">
          <FilterContainerDesktop
            activeFilterItems={activeFilterItems}
            activeFilters={activeFilters}
            dateFrom={dateFrom}
            dateTo={dateTo}
            hasActiveFilters={hasActiveFilters}
            hasAnyActiveFilter={hasAnyActiveFilter}
            isGeneralModalOpen={isGeneralModalOpen}
            isRatingsModalOpen={isRatingsModalOpen}
            namespace={namespace}
            resetForm={resetForm}
            setIsAvailabilityModalOpen={setIsAvailabilityModalOpen}
            setIsGeneralModalOpen={setIsGeneralModalOpen}
            setIsRatingsModalOpen={setIsRatingsModalOpen}
            totalAdults={totalAdults}
            kidsAges={kidsAges}
            count={count}
          />
        </Sticky>
      )}

      <div css={ß.activeFiltersPanelMobile}>
        <RemoveAllFiltersButton
          activeFilters={activeFilters}
          hasAnyActiveFilter={hasAnyActiveFilter}
          namespace={namespace}
        />
        {activeFilterItems}
      </div>

      {(isRatingsModalOpen || isGeneralModalOpen) && (
        <Suspense fallback={<div></div>}>
          <FilterModal {...modalProps} />
        </Suspense>
      )}

      {!isMobile && isAvailabilityModalOpen && (
        <Suspense fallback={<></>}>
          <FilterAvailability
            dateFrom={dateFrom}
            dateTo={dateTo}
            handleApplyFilters={handleApplyFilters}
            handleCancel={handleCancel}
            kidsAges={kidsAges}
            namespace={namespace}
            resetForm={resetForm}
            setDateFrom={setDateFrom}
            setDateTo={setDateTo}
            setKidsAges={setKidsAges}
            setTotalAdults={setTotalAdults}
            totalAdults={totalAdults}
          />
        </Suspense>
      )}
    </div>
  );
};

DestinationFilters.propTypes = {
  namespace: PropTypes.string,
};

DestinationFilters.defaultProps = {
  namespace: 'pt-search-result-filter',
};

export default memo(DestinationFilters);
