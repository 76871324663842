export const DISABLED_PAGE = ['prn:page:review'];

export const orgLDJsonDefault = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'PiNCAMP',
  legalName: 'PiNCAMP',
  award: [
    'Deutschlands beste Online-Portale 2018, Innovativstes Reise-Startup 2019',
  ],
  brand: ['PiNCAMP'],
  address: {
    '@type': 'PostalAddress',
  },
  contactPoint: {
    '@type': 'ContactPoint',
    contactType: 'customer support',
  },
};

export const orgLDJsonDE = {
  ...orgLDJsonDefault,
  url: 'https://www.pincamp.de',
  email: 'service@pincamp.de',
  sameAs: ['https://www.pincamp.ch'],
  logo: 'https://www.pincamp.de/icons/logo-pincamp.svg',
  address: {
    '@type': 'PostalAddress',
    streetAddress: 'Hansastr. 19',
    addressLocality: 'München',
    addressRegion: 'München',
    postalCode: '80686',
    addressCountry: 'Deutschland',
  },
  contactPoint: {
    '@type': 'ContactPoint',
    contactType: 'customer support',
    email: 'service@pincamp.de',
  },
  ownershipFundingInfo: 'https://www.pincamp.de/unternehmen/',
};

export const orgLDJsonCHDE = {
  ...orgLDJsonDefault,
  url: 'https://www.pincamp.ch/de/',
  email: 'service@pincamp.ch',
  sameAs: ['https://www.pincamp.de'],
  logo: 'https://www.pincamp.ch/de/icons/logo-pincamp.svg',
  address: {
    '@type': 'PostalAddress',
    streetAddress: 'Bahnhofstrasse 5',
    addressLocality: 'Schönbühl',
    addressRegion: 'Schönbühl',
    postalCode: '3322',
    addressCountry: 'Schweiz',
  },
  contactPoint: {
    '@type': 'ContactPoint',
    contactType: 'customer support',
    email: 'service@pincamp.ch',
  },
};

export const orgLDJsonCHFR = {
  ...orgLDJsonCHDE,
  url: 'https://www.pincamp.ch/fr/',
};

export const orgLDJsonCHIT = {
  ...orgLDJsonCHDE,
  url: 'https://www.pincamp.ch/it/',
};

export const orgLDJsonCH = {
  de: orgLDJsonCHDE,
  fr: orgLDJsonCHFR,
  it: orgLDJsonCHIT,
};
