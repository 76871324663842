import { css } from '@emotion/react';
import { red9, white } from '@fire/_color';
import { fontSizeBody, fontSizeSmall } from '@fire/_fonts';
import { media } from '@fire/_mediaQueries';

export const extraLinks = css`
  width: 100%;
  background-color: ${red9};
  padding: 15px 12px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  ${media.md} {
    padding: 20px 39px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  ${media.xl} {
    padding: 32px 68px;
  }
`;

export const extraLinksList = css`
  justify-content: flex-start;
  display: block;
  column-count: 2;
  font-size: ${fontSizeSmall};
  margin-bottom: 18px;

  ${media.md} {
    margin-bottom: 0;
  }

  ${media.lg} {
    display: flex;
    font-size: ${fontSizeBody};

    li {
      margin-right: 32px;
    }
  }

  a {
    color: ${white};
  }
`;

export const socialLinks = css`
  display: flex;

  a {
    width: 24px;
    height: 24px;
    margin-right: 28px;
  }
`;
