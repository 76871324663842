import { trackObject } from '@utils/tracking';

export const suggestion = ({ name, term, type }) => {
  const label = `${type.toLowerCase()}|${name}`;

  trackObject({
    action: 'click search suggest',
    category: 'search',
    label,
    event: 'search_click-search-suggestion',
    content: {
      'suggest-term': term,
    },
  });
};

export const search = ({ term }) => {
  trackObject({
    action: 'use-search',
    category: 'search',
    label: term,
    event: 'search_use-search',
    content: {
      'suggest-term': term,
    },
  });
};
