import { css } from '@emotion/react';
import { blue9, charcoal, white } from '@fire/_color';
import { bold, fontFace } from '@fire/_fonts';

export const flexCenter = css`
  display: flex;
  align-items: center;
`;

export const navigator = css`
  ${flexCenter};
  flex-direction: column;
  font-size: 16px;

  color: ${charcoal};
`;

export const resultInfos = css`
  margin-bottom: 12px;
`;

export const styledButtons = css`
  display: flex;
`;

const buttonSize = 34;

export const buttonAnchor = css`
  text-decoration: none;
`;

export const button = css`
  ${flexCenter};
  ${fontFace}
  background-color: transparent;
  width: ${buttonSize}px;
  height: ${buttonSize}px;
  padding: 0;
  border: none;
  justify-content: center;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: auto;
    pointer-events: none;
  }

  &:focus {
    outline: none;
  }
`;

export const pageButton = ({ current }) => css`
  ${flexCenter};
  justify-content: center;
  width: ${(buttonSize / 4) * 3}px;
  height: ${(buttonSize / 4) * 3}px;
  font-size: 16px;
  font-weight: ${bold};
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${current ? white : charcoal};
  background-color: ${current ? blue9 : 'transparent'};
  border-radius: 50%;
  border: none;
  cursor: pointer;

  :disabled {
    cursor: auto;
  }

  &:hover {
    color: ${white};
    background-color: ${blue9};
  }
`;

export const arrow = css`
  background-color: transparent;
  width: ${(buttonSize / 4) * 3}px;
  height: ${(buttonSize / 4) * 3}px;
  border-radius: ${((buttonSize / 4) * 3) / 2}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${blue9};

  &:hover {
    background-color: ${blue9};
    svg {
      path {
        stroke: ${white};
      }
    }
  }
`;

const chevron = css`
  path {
    stroke: ${blue9};
    stroke-width: 2;
  }
  height: 6.5px;
  width: 10.5px;
`;

export const chevronLeft = css`
  ${chevron};
  margin-left: 0;
  transform: rotate(90deg);
`;

export const chevronRight = css`
  ${chevron};
  margin-left: 1px;
  transform: rotate(-90deg);
`;

export const dots = css`
  padding: 0 4px;
`;
