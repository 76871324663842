import React from 'react';
import PropTypes from 'prop-types';

import useTracking from '@hooks/useTracking';

import Logo from './Logo';
import PlaceholderList from './PlaceholderList';
import { partnerClickout } from './tracking';

const Logos = ({ logos, siteUrl, pagePath, isLoading, amount }) => {
  const {
    prepareToTrackEvent: { clickEvent },
  } = useTracking('selected campsites');

  const handleClick = prn => () => {
    clickEvent(prn)();
    partnerClickout({ prn, pagePath });
  };

  return isLoading ? (
    <PlaceholderList amount={amount} />
  ) : (
    logos.map(({ logo, url, name, prn }) => (
      <Logo
        onClick={handleClick(prn)}
        key={name}
        logo={`${siteUrl}${logo}`}
        name={name}
        url={url}
      />
    ))
  );
};

Logos.propTypes = {
  logos: PropTypes.array,
  siteUrl: PropTypes.string,
  pagePath: PropTypes.string,
  isLoading: PropTypes.bool,
  amount: PropTypes.number,
};

export default Logos;
