import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';

import useHasMounted from '@fire/hooks/useHasMounted';
import { isEmptyOrNil, shuffleArray } from '@fire/utils/generic';

import BannerAd from './component';

const getAdByPosition = (ads, position) => {
  if (ads?.length >= position + 1) {
    return ads?.[position];
  }
  return ads?.length > 0 ? ads?.[0] : [];
};

export const specialAdsFragment = graphql`
  fragment SpecialAdsFragment on SpecialAdsConnection {
    nodes {
      trackingId
      pageType
      logo {
        altText
        filename
        handle
        url
      }
      headline
      description
      buttonText
      buttonLink
      background {
        altText
        filename
        handle
        url
      }
    }
  }
`;

const SpecialAdsListPage = memo(function SpecialAdsListPage({ position }) {
  const hasMounted = useHasMounted();

  const data = useStaticQuery(graphql`
    query {
      specialAdsList: allSpecialAds(filter: { pageType: { eq: "list" } }) {
        ...SpecialAdsFragment
      }
    }
  `);
  const [ad, setAd] = useState();

  const ads = data?.specialAdsList?.nodes || [];
  const hasAds = !isEmptyOrNil(ads);

  // try to avoid ads blinks by running one time only
  useEffect(() => {
    const shuffledAds = shuffleArray(ads);
    hasAds && setAd(getAdByPosition(shuffledAds, position));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return hasMounted && hasAds ? <BannerAd {...ad} category="list" /> : false;
});

SpecialAdsListPage.propTypes = {
  position: PropTypes.number,
};

SpecialAdsListPage.defaultProps = {
  position: 0,
};

export default SpecialAdsListPage;
