import { css } from '@emotion/react';
import { blue9, charcoal } from '@fire/_color';
import { bold } from '@fire/_fonts';
import { media } from '@fire/_mediaQueries';

export const navigation = css`
  color: ${charcoal};
  display: none;
  ${media.lg} {
    display: block;
  }
  & ul {
    white-space: nowrap;
  }
`;

export const item = css`
  color: $charcoal;
  font-size: 14px;
  display: inline;
  margin: 0 16px;
  text-transform: uppercase;

  &:hover {
    font-weight: ${bold};
    border-bottom: 4px solid ${blue9};
  }
`;
