import moment from 'moment';

import { DATE_FORMAT_ISO8601 } from '@fire/constants';

export const formatDate = (date, format = 'DD.MM.YYYY') =>
  moment(date).format(format);

export const formatDateByLocale = (date, format = 'L', locale = __LOCALE__) =>
  moment(date).locale(locale).format(format);

export const formatDateISO8601 = date =>
  date
    ? (moment.isMoment(date) ? date : moment(date))?.format(DATE_FORMAT_ISO8601)
    : undefined;

export const isAfterNow = date => moment(date).isAfter();

export const isTodayOrAfter = date => {
  const today = moment().format('YYYY-MM-DD');

  return moment(date).isSameOrAfter(today);
};

export const numberOfNights = (firstDate, secondDate) => {
  const from = moment(firstDate);
  const to = moment(secondDate);

  return to.diff(from, 'days');
};
/*
 * Makes sure the date range is valid, otherwise
 * invert them if start is after end,
 * return default dates (today and tomorrow)
 * or undefined
 */
export const getValidDateRange = (from, to) => {
  const range = {
    startDate: from && moment(from),
    endDate: to && moment(to),
  };

  if (range?.startDate?.isAfter?.(range.endDate)) {
    return getValidDateRange(range.endDate, range.startDate);
  }

  if (!isTodayOrAfter(range.startDate)) {
    range.startDate = moment();
    range.endDate = moment().add(1, 'd');
  }

  if (from && to && from === to) {
    range.endDate = moment(range.endDate).add(1, 'd');
  }
  if (!range.startDate?.isValid() || !range.endDate?.isValid()) {
    return {};
  }

  return range;
};

export const timeUntilDate = ({ from, to }) => {
  const start = moment(from);
  const end = moment(to);
  const isValid = start?.isValid() && end?.isValid() && end.isAfter(start);

  if (!isValid) return;

  const difference = end.diff(start);
  const { days, hours, minutes } = moment.duration(difference)._data;

  return { days, hours, minutes };
};

export const dateToISOString = date => new Date(date).toISOString();
