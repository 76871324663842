import { css } from '@emotion/react';
import { blue9, blue10, white } from '@fire/_color';
import { bold } from '@fire/_fonts';
import { media, nonTouch } from '@fire/_mediaQueries';

export const mapToggleWrapper = css`
  width: 100%;
  position: sticky;
  bottom: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 24px;
`;

const mobileHover = css`
  color: ${blue10};
  border-color: ${blue10};
`;

export const mapToggleButton = css`
  background-color: ${white};
  padding: 12px;
  height: 42px;
  font-weight: ${bold};
  color: ${blue9};
  border-radius: 8px;
  border: solid 2px ${blue9};
  justify-content: center;
  align-items: center;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  ${media.lg} {
    display: none;
  }
  &:active {
    ${mobileHover}
  }
  ${nonTouch} {
    &:hover {
      ${mobileHover};
    }
  }
`;

export const mapTogglePin = css`
  margin-right: 8px;
`;
