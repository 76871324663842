import { curry, filter, join, pipe, replace } from 'ramda';

const filterFalsy = filter(i => i);
const isType = curry((expected, x) => typeof x === expected);
const isString = isType('string');

export const replaceDot = x => {
  const xString = x && isString(x) ? x : x + '';
  return replace(/\.(?=[^.]*$)/, ',', xString);
};

export const semicolonSeparateValues = pipe(filterFalsy, join(';'));

export const slugify = string => {
  const a = 'àáäâãåèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;';
  const b = 'aaaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------';
  const p = new RegExp([...a].join('|'), 'g');

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters in a with b
    .replace(/&/g, '-and-') // Replace & with ‘and’
    .replace(/[^\w-]+/g, '') // Remove all non-word characters such as spaces or tabs
    .replace(/--+/g, '-') // Replace multiple — with single -
    .replace(/^-+/, '') // Trim — from start of text
    .replace(/-+$/, ''); // Trim — from end of text
};

export const humanizeList = ({ lastSeparator, list, separator = ', ' }) => {
  if (!list) {
    return;
  }

  if (list?.length < 2) {
    return list.join('');
  }

  const lastItem = list.slice(-1)[0];
  const listButLast = list.slice(0, -1).join(separator);

  return `${listButLast}${lastSeparator ?? separator}${lastItem}`;
};

export const truncateString = (str, n) =>
  str.length > n ? str.slice(0, Math.max(0, n - 1)) + '...' : str;
