import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import * as ß from './styles';

// some errors translations come with html embeded.

export const FormError = ({ error, isTouched }) => {
  const { t } = useTranslation();

  const [ns, key] = error?.includes(':') ? error.split(':') : ['', error];

  if (error && isTouched) {
    return (
      <span
        css={ß.error}
        data-scroll-to="error"
        dangerouslySetInnerHTML={{ __html: t(key, { ns }) }}
      ></span>
    );
  }
  return <></>;
};

FormError.defaultProps = {
  isTouched: false,
};

FormError.propTypes = {
  error: PropTypes.string,
  isTouched: PropTypes.bool,
};

export default FormError;
