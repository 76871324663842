import React from 'react';
import moment from 'moment';
import {
  concat,
  contains,
  difference,
  equals,
  filter,
  includes,
  indexBy,
  keys,
  pick,
  pickBy,
  pipe,
  pluck,
  prop,
  propEq,
  values,
} from 'ramda';

import { Star } from '@components/StarRating';
import { getBookingData } from '@utils/availability';
import { formatDate } from '@utils/dateTime';
import { concatAll, isEmptyOrNil } from '@utils/generic';
import { isSwiss } from '@utils/platform';

import { filterGroups, ratingFilters } from './filters.json';
import * as ß from './styles';

export const filtersById = type =>
  pipe(
    filter(propEq('type', type)),
    pluck('items'),
    concatAll,
    indexBy(prop('id'))
  );

const allCheckboxFiltersById = filtersById('checkbox-group')(filterGroups);
const allCheckboxIds = keys(allCheckboxFiltersById);
const allRadioFiltersById = filtersById('radio-group')(filterGroups);
const allRadioIds = keys(allRadioFiltersById);
const allHiddenFiltersById = filtersById('hidden-group')(filterGroups);
const allHiddenIds = keys(allHiddenFiltersById);

export const allRatingIds = ['user-reviews', 'adac-stars', 'tcs-stars'];
export const generalFilterIds = concatAll([
  allCheckboxIds,
  allRadioIds,
  allHiddenIds,
]);

export const allFilterIds = concatAll([generalFilterIds, allRatingIds]);

export const fixI18nLabelForStars = (label, value) =>
  value >= 5 ? `${label}-${value}` : label;

export const ICON_LIST = {
  'user-reviews': <Star cssMod={ß.icon} color="RED" />,
  'adac-stars': <Star cssMod={ß.icon} color="YELLOW" />,
  'tcs-stars': <Star cssMod={ß.icon} color="PINK" />,
};

export const ALL_FILTERS = [
  ...values(allCheckboxFiltersById),
  ...values(allRadioFiltersById),
  ...values(allHiddenFiltersById),
  ...ratingFilters,
];

// this dude is responsible to hide filters from the `RemoveAllFiltersButton`
export const notAllowedFilters = ([key, value]) =>
  !(
    contains('-any', key) ||
    equals(value, 0) ||
    equals(key, 'date_from') ||
    equals(key, 'date_to') ||
    equals(key, 'adults') ||
    equals(key, 'children')
  );

export const getActiveFilters = allFilters =>
  Object.entries(allFilters)
    .filter(notAllowedFilters)
    .map(([key]) => key);

export const filterRemoveableParams = (prosciuttoParams, stateContext) =>
  pipe(
    pick(allFilterIds),
    keys,
    filter(k => propEq(k, 0, stateContext)),
    // here we add "zombie" params
    concat(
      difference(
        keys(pick(allFilterIds, prosciuttoParams)),
        keys(pick(allFilterIds, stateContext))
      )
    )
  )(stateContext);

const pickPreservingOrder = ids => pickBy((v, k) => includes(k, ids));

export const pickRelevantParams = pickPreservingOrder(allFilterIds);

export const toggleBodyOverflowStyle = hide => {
  document.body.style.overflow = hide ? 'hidden' : '';
};

export const getAvailabilityBoxMobileProps = pageContext => ({
  campsitePath: pageContext.pagePath,
  isSwitzerland: isSwiss(pageContext),
  language: pageContext.language,
  isMobile: true,
  prn: pageContext.entity,
  siteUrl: pageContext.siteUrl,
});

export const DATE_FORMAT_FULL = 'DD.MM.YYYY';
export const DATE_FORMAT_SMALL = 'DD.MM';

export const getDatepickerText = ({
  dateFrom,
  dateFormat = DATE_FORMAT_SMALL,
  dateTo,
  fallbackText,
}) => {
  const startDate = dateFrom && formatDate(dateFrom, dateFormat);
  const endDate = dateTo && formatDate(dateTo, dateFormat);

  if (startDate && endDate) {
    return `${startDate} - ${endDate}`;
  }

  return fallbackText;
};

export const getDatesFromParams = state => {
  const bookingData = getBookingData();
  const hasLocalStorageDates = bookingData?.dateFrom && bookingData?.dateTo;
  const hasQueryDates = state?.params?.dateFrom && state?.params?.dateTo;

  if (hasQueryDates) {
    return {
      startDate: moment(state.params.dateFrom),
      endDate: moment(state.params.dateTo),
    };
  }

  if (hasLocalStorageDates) {
    return {
      startDate: moment(bookingData.dateFrom),
      endDate: moment(bookingData.dateTo),
    };
  }

  return;
};

export const getGuestsFromParams = state => {
  const bookingData = getBookingData();
  const personAge = state?.params?.personAge;
  const paramsChildren = state?.params?.children;
  const paramsAdults = state?.params?.adults;

  if (personAge) {
    const ages = personAge.split(',').map(Number);
    const adults = ages.filter(age => age >= 18).length;
    const kids = ages.filter(age => age < 18);
    const total = adults + kids.length;

    return { adults, kids, total };
  }

  if (bookingData) {
    const kids = bookingData?.kidsAges || [];
    const adults = bookingData?.totalAdults || 0;
    const total = adults + kids.length;

    return { adults, kids, total };
  }

  if (paramsAdults) {
    const totalKids = paramsChildren ? paramsChildren.split(',').length : 0;
    const totalGuests = paramsAdults + totalKids;
    return {
      adults: paramsAdults,
      kids: paramsChildren,
      total: totalGuests,
    };
  }

  return;
};

export const getAvailabilityDataFromParams = state => ({
  ...getDatesFromParams(state),
  ...getGuestsFromParams(state),
});

export const getGuestpickerText = ({
  dateFrom,
  dateTo,
  kidsAges,
  totalAdults,
  t,
}) => {
  const totalChildren = kidsAges?.length || 0;
  const totalGuests = totalChildren + totalAdults;

  return dateFrom && dateTo
    ? t('text-guest', { count: totalGuests })
    : t('Guests');
};

export const prepareFiltersForTracking = params => {
  if (!isEmptyOrNil(params)) {
    const relevantParams = pickRelevantParams(params);
    const cleanUpFilters = Object.keys(relevantParams)
      .filter(key => notAllowedFilters([key, relevantParams[key]]))
      .join('|');
    return cleanUpFilters;
  }
};
