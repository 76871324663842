import { css } from '@emotion/react';
import { white } from '@fire/_color';
import { borderRadius } from '@fire/_const';
import { media } from '@fire/_mediaQueries';

const BOX_SHADOW = css`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
`;
const HOVER_BOX_SHADOW = css`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
`;
const GAP = '18px';
const HALF_GAP = '9px';

export const rectangleBoxWithShadow = css`
  ${BOX_SHADOW};
  background-color: ${white};
  border-radius: ${borderRadius};
  transition: box-shadow 0.2s ease-in-out;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin-bottom: ${GAP};
  flex-basis: 100%;
  &:hover {
    ${HOVER_BOX_SHADOW};
  }

  ${media.md} {
    flex-basis: calc(50% - ${HALF_GAP});
    margin-left: ${HALF_GAP};
    &:nth-of-type(odd) {
      margin-left: 0;
      margin-right: ${HALF_GAP};
    }
  }
`;

export const row = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 12px;
`;
