import { css } from '@emotion/react';
import { gray5 } from '@fire/_color';
import { media } from '@fire/_mediaQueries';

export const superWrapper = css`
  width: 100%;
  position: relative;
  padding-left: 12px;
`;

export const carouselWrapper = css`
  min-height: 216px;
  ${media.lg} {
    min-height: 275px;
  }
`;

export const title = css`
  margin: 0 0 16px;
  text-align: center;
`;

export const subtitle = css`
  text-align: center;
  position: relative;
  margin-bottom: 35px;
`;

export const divider = css`
  height: 1px;
  margin: 36px auto;
  width: 40%;
  border-bottom: 1px solid ${gray5};
`;
