import React from 'react';
import PropTypes from 'prop-types';
import { withPrefix } from 'gatsby';

import * as ß from './styles';

const tinyArrow = withPrefix('/icons/tiny-arrow.svg');

const Select = ({ name, options, label, onChange, value }) => {
  const id = `select-review-${name}`;

  const makeOption = option => (
    <option key={option.value} value={option.value} css={ß.selectOption}>
      {option.label}
    </option>
  );

  return (
    <div css={ß.selectContainer}>
      <select
        id={id}
        name={name}
        component="select"
        onChange={onChange}
        value={value}
        css={ß.selectButton}
      >
        <option value={''} disabled>
          {label}
        </option>
        {options.map(makeOption)}
      </select>
      <img src={tinyArrow} css={ß.selectArrow} />
    </div>
  );
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(String),
  onChange: PropTypes.func,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

Select.defaultValue = {
  options: [],
};

export default React.memo(Select);
