import { css } from '@emotion/react';
import { carouselWrapper } from '@fire/_const';
import { media } from '@fire/_mediaQueries';

export const tile = css`
  width: 100%;
  padding: 12px;

  [class*='productTileFooter'] {
    flex-direction: column-reverse;
    align-items: initial;

    [class*='logo'] {
      display: flex;
      width: auto;
      margin-top: 18px;

      img {
        max-width: 50%;
        object-fit: scale-down;
        object-position: left top;
      }

      ${media.md} {
        height: 50px;
      }
    }
  }
`;

export const wrapper = css`
  ${carouselWrapper}
`;

export const title = css`
  margin: 0 0 16px;
`;
