import { timeUntilDate } from '@utils/dateTime';
import { isEmptyOrNil } from '@utils/generic';
import { humanizeList } from '@utils/string';

export const translateTimeLeft = (list, t) =>
  Object.entries(list)
    .filter(([, count]) => count)
    .flatMap(([key, count]) => t(key, { count }));

export const getHumanizedTimeLeft = ({
  from,
  to,
  t,
  lastSeparator = ` ${t('and')} `,
  separator,
}) => {
  const timeLeft = timeUntilDate({ from, to });

  if (isEmptyOrNil(timeLeft)) return;

  const list = translateTimeLeft(timeLeft, t);

  return humanizeList({
    lastSeparator,
    list,
    separator,
  });
};
