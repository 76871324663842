import React, { lazy, Suspense, useState } from 'react';
import PropTypes from 'prop-types';

import useEventListener from '@hooks/useEventListener';
import useHasMounted from '@hooks/useHasMounted';

const Modal = lazy(() => import('@components/Modal'));

export const useModal = () => {
  const [isVisible, setIsVisible] = useState(false);

  const show = () => setIsVisible(true);
  const hide = () => setIsVisible(false);

  const handleKeyPress = e => (e?.keyCode === 27 || e?.which === 27) && hide();

  useEventListener('keydown', handleKeyPress);

  const RenderModal = ({ children, ...rest }) => {
    const hasMounted = useHasMounted();

    if (!hasMounted) return <></>;

    return (
      <>
        <Suspense fallback={<></>}>
          <Modal closeModal={hide} isModalVisible={isVisible} {...rest}>
            {children}
          </Modal>
        </Suspense>
      </>
    );
  };

  RenderModal.propTypes = {
    children: PropTypes.node,
  };

  return {
    show,
    hide,
    Modal: RenderModal,
  };
};

export default useModal;
