import React, { useContext } from 'react';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';

import Link from '@components/Link';
import MaybeData from '@contexts/MaybeData';
import { getDataProtectionUrl, getTermOfUseUrl } from '@utils/platform';

import * as ß from '../styles';

/* eslint-disable prettier/prettier */
/* prettier-ignore */
const CheckboxDataPrivacyAcceptTerms = ({t, openLottoAccordion}) => {
const pageContext = useContext(MaybeData);
const dataProtectionUrl = getDataProtectionUrl(pageContext);
const termOfUseUrl = getTermOfUseUrl(pageContext);
return (
<Trans i18nKey={`step-1-form.checkbox-data-privacy-accept-terms`} t={t}>
<p css={ß.checkboxTextElement}>
Ich erkläre mich mit der Speicherung meines angegebenen Namens oder Pseudonyms und meiner
E-Mail-Adresse zum Zweck der Bewertungsabgabe und des Gewinnspiels gemäß{' '}
<Link to={dataProtectionUrl} openInNewWindow>
Datenschutzerklärung
</Link>{' '}
sowie mit den{' '}
<Link to={termOfUseUrl} openInNewWindow>
Nutzungsbedingungen
</Link>{' '}
und den{' '}
<a href="#teilnahmebedingungen" onClick={openLottoAccordion}>
Teilnahmebedingungen zum Gewinnspiel
</a>{' '}
einverstanden. Insbesondere bestätige ich, dass diese
Bewertung meine eigene Erfahrung als Urlauber auf dem
Campingplatz wahrheitsgemäß wiedergibt.
</p>
</Trans>
);
};
CheckboxDataPrivacyAcceptTerms.propTypes = {
  t: PropTypes.func.isRequired,
  openLottoAccordion: PropTypes.func.isRequired,
};

export default CheckboxDataPrivacyAcceptTerms;
