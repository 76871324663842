import React from 'react';
import PropTypes from 'prop-types';

import { COLOR_TABLE, STAR_STATES } from './consts';
import { Star } from './Star';
import * as ß from './styles';

const EMPTY_STARS = Array.from({ length: 5 }).fill(STAR_STATES.EMPTY);

export const starMeter = rating =>
  EMPTY_STARS.map((emptyStar, index) => {
    if (rating - index > 0.75) {
      return STAR_STATES.FULL;
    }

    if (rating - index > 0.25) {
      return STAR_STATES.HALF;
    }

    return emptyStar;
  });

const makeStars = (color, big, state) =>
  state.map((s, i) => {
    const halfStar = s === STAR_STATES.HALF;
    const realColor = s === STAR_STATES.EMPTY ? 'EMPTY' : color;

    return <Star key={i} color={realColor} isHalfStar={halfStar} big={big} />;
  });

export const StarRating = ({ rating, label, color, big }) => (
  <div css={ß.starRating}>
    <div css={ß.rating}>{makeStars(color, big, starMeter(rating))}</div>
    {label && <span css={ß.caption}>{label}</span>}
  </div>
);

StarRating.propTypes = {
  big: PropTypes.bool,
  color: PropTypes.oneOf(Object.keys(COLOR_TABLE)),
  label: PropTypes.string,
  rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

StarRating.defaultProps = {
  big: false,
  color: 'RED',
  rating: 0,
};

export default StarRating;
