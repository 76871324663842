import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import { getCanonicalInfo } from '@components/seo';

const LinkedResources = ({ pageContext }) => (
  // 03/02/20 Anne wanted to ignore alternative for time being
  // For reimplementation of alternative, find it here
  // https://github.com/adac-camping/campfire/blob/0875e1ae994f31e8c07bd3260fcd63e72fb205ec/src/components/LinkedResources/component.js#L9-L41

  <Helmet link={[getCanonicalInfo(pageContext)]} />
);
LinkedResources.defaultProps = {
  pageContext: {},
};

LinkedResources.propTypes = {
  pageContext: PropTypes.object,
};

export default LinkedResources;
