import { graphql, useStaticQuery } from 'gatsby';

export const LoyaltyQueryGold = graphql`
  query LoyaltyQueryGold {
    allLoyalties(filter: { category: { eq: "GOLD" } }) {
      ...LoyaltyQuery
    }
  }
`;

const useLoyaltyGold = () => {
  const data = useStaticQuery(LoyaltyQueryGold);
  return data?.allLoyalties?.nodes ?? [];
};

export default useLoyaltyGold;
