import { clone } from 'ramda';

import { debugTrack } from './debugger';
import dimensionsTranslator from './dimensionsTranslator';
import { trackObject } from './index';

const clickEvent =
  (label = '', list = '') =>
  (value, options = {}) =>
  () => {
    const { group, groupEnd } = debugTrack();
    group(`tracking event click - ${label} - ${list}`);
    const clonedValue = clone(value);
    const position = options?.position ? options?.position + 1 : undefined;
    const translatedOptions = dimensionsTranslator(options);
    trackObject({
      track: 'event',
      group: list,
      name: label,
      value: clonedValue,
      interactive: false,
      ...translatedOptions,
      position,
    });
    groupEnd(`tracking event click - ${label} - ${list}`);
  };

export const prepareToTrackEvent = (label = '', list = '') => ({
  clickEvent: clickEvent(label, list),
});
