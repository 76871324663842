import React from 'react';
import PropTypes from 'prop-types';
import { assocPath } from 'ramda';

import Link from '@components/Link';
import { makeURLSearchParams } from '@utils/prosciutto';

const NextPageLinkWrapper = ({
  itemCount,
  pageCount,
  pagePath,
  prosciuttoState,
  currentPage,
  children,
}) => {
  if (itemCount === 0 || pageCount < 2) {
    return <></>;
  } else if (currentPage === pageCount) {
    return <>{children}</>;
  }
  const nextPage = currentPage + 1;
  const nextProsciuttoState = assocPath(
    ['params', 'page'],
    nextPage,
    prosciuttoState
  );
  const nextPageHref = `${pagePath}?${makeURLSearchParams(
    nextProsciuttoState
  )}`;

  return <Link to={nextPageHref}>{children}</Link>;
};

NextPageLinkWrapper.propTypes = {
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  pagePath: PropTypes.string,
  prosciuttoState: PropTypes.object,
  currentPage: PropTypes.number,
  children: PropTypes.node,
};

export default NextPageLinkWrapper;
