import React, { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Button from '@components/Button';
import Nights from '@components/DatePicker/Nights';
import DayPicker from '@components/DayPicker';
import { useFilters } from '@contexts/Filters';
import InfoMessageContext from '@contexts/InfoMessage';
import { DATE_FORMAT_ISO8601 } from '@fire/constants';
import { useProsciuttoState } from '@hooks/useProsciutto';
import { MomentType } from '@types';
import { saveBookingData } from '@utils/availability';

import GuestPicker from './GuestPicker';
import * as ß from './styles';
import { DATE_FORMAT_FULL, toggleBodyOverflowStyle } from './utils';

const FilterAvailability = ({
  dateFrom,
  dateTo,
  handleApplyFilters,
  handleCancel,
  kidsAges,
  namespace,
  resetForm,
  setDateFrom,
  setDateTo,
  setKidsAges,
  setTotalAdults,
  totalAdults,
}) => {
  const prosciuttoState = useProsciuttoState();
  const [, dispatchContext] = useFilters();
  const { t } = useTranslation(namespace);
  const startDateFormatted = dateFrom ? dateFrom.format(DATE_FORMAT_FULL) : '-';
  const endDateFormatted = dateTo ? dateTo.format(DATE_FORMAT_FULL) : '-';
  const { setShowMessage, showMessage, infoMessage } =
    useContext(InfoMessageContext);

  useEffect(() => {
    toggleBodyOverflowStyle(true);

    return () => toggleBodyOverflowStyle(false);
  }, []);

  useEffect(() => {
    dispatchContext({ type: 'setFilters', payload: prosciuttoState.params });

    return () => {
      dispatchContext({ type: 'clearFilters' });
    };
  }, [dispatchContext, prosciuttoState.params]);

  const applyLocalFiltersToProsciutto = useCallback(
    (filter, value) =>
      dispatchContext({
        type: 'setFilters',
        payload: { [filter]: value },
      }),
    [dispatchContext]
  );

  useEffect(() => {
    if (dateFrom && dateTo) {
      applyLocalFiltersToProsciutto(
        'date_from',
        dateFrom.format(DATE_FORMAT_ISO8601)
      );
      applyLocalFiltersToProsciutto(
        'date_to',
        dateTo.format(DATE_FORMAT_ISO8601)
      );
      applyLocalFiltersToProsciutto('adults', totalAdults);
      applyLocalFiltersToProsciutto('children', kidsAges);

      saveBookingData({
        dateFrom: dateFrom?.format(DATE_FORMAT_ISO8601),
        dateTo: dateTo?.format(DATE_FORMAT_ISO8601),
        kidsAges,
        totalAdults,
      });
      infoMessage?.bookable === false && setShowMessage(!showMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFrom, dateTo, totalAdults, applyLocalFiltersToProsciutto, kidsAges]);

  return (
    <>
      <div css={ß.filterModal}>
        <div
          css={ß.filterModalOverlay}
          id="filterModalOverlay"
          onClick={handleCancel}
        />
        <div css={ß.filterModalContent}>
          <div css={ß.desktopFilters}>
            <div css={ß.title}>{t('Reisedaten')}</div>
            <div css={ß.datesHeader}>
              <div css={ß.dates}>
                <span css={ß.subtitle}>{t('Anreise')}</span>
                <span>{startDateFormatted}</span>
              </div>
              <div css={ß.dates}>
                <span css={ß.subtitle}>{t('Abreise')}</span>
                <span>{endDateFormatted}</span>
              </div>
            </div>
            <DayPicker
              dateFrom={dateFrom}
              dateTo={dateTo}
              setDateFrom={setDateFrom}
              setDateTo={setDateTo}
            />
            <div css={ß.info}>
              <Nights dateStart={dateFrom} dateEnd={dateTo} />
              <span css={ß.resetButton} onClick={resetForm}>
                {t('Zurücksetzen')}
              </span>
            </div>
            <div css={ß.guests}>
              <div css={ß.subtitle}>{t('Guests')}</div>
              <GuestPicker
                kidsAges={kidsAges}
                setKidsAges={setKidsAges}
                setTotalAdults={setTotalAdults}
                totalAdults={totalAdults}
              />
            </div>
          </div>
        </div>
        <div css={ß.filterModalFooter}>
          <span css={ß.textActingLikeAButton} onClick={handleCancel}>
            {t('Abbrechen')}
          </span>
          <Button variant="primary" size="large" onClick={handleApplyFilters}>
            {t('Filter anwenden')}
          </Button>
        </div>
      </div>
    </>
  );
};

FilterAvailability.propTypes = {
  dateFrom: MomentType,
  dateTo: MomentType,
  handleApplyFilters: PropTypes.func,
  handleCancel: PropTypes.func,
  kidsAges: PropTypes.arrayOf(PropTypes.number),
  isDatepickerOpen: PropTypes.bool,
  namespace: PropTypes.string,
  resetForm: PropTypes.func,
  setDateFrom: PropTypes.func,
  setDateTo: PropTypes.func,
  setKidsAges: PropTypes.func,
  setTotalAdults: PropTypes.func,
  totalAdults: PropTypes.number,
};

FilterAvailability.defaultProps = {
  namespace: 'pt-search-result-filter',
};

export default FilterAvailability;
