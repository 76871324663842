import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { formatPrice } from '@utils/money';

import { PREFIX } from './consts';
import * as ß from './styles';

const PriceFrom = ({ price, currency }) => {
  const { t } = useTranslation(PREFIX);

  return (
    price &&
    currency && (
      <p css={[ß.textAlignRight, ß.verticalAlignBaseline, ß.noLineBreak]}>
        {t('price-from', {
          price: formatPrice(price, currency),
        })}
      </p>
    )
  );
};

PriceFrom.propTypes = {
  price: PropTypes.number,
  currency: PropTypes.string,
};

export default PriceFrom;
