import { once, pathOr, replace } from 'ramda';

import { trackObject as tp } from '@utils/tracking';

const trackObject = obj =>
  tp({
    category: 'map list',
    event: `map-list_${replace(/\s/g, '-', pathOr('', ['action'], obj))}`,
    label: '(not set)',
    ...obj,
  });

const trackFullscreen = on =>
  once(() =>
    trackObject({
      action: on ? 'click open full view map' : 'click close full view map',
    })
  );

const trackZoom = on =>
  once(() =>
    trackObject({
      action: 'use map zoom',
      label: on ? 'zoom in' : 'zoom out',
    })
  );

const trackSearchMoveCheckbox = on =>
  once(() =>
    trackObject({
      action: on
        ? 'tick search when moving map'
        : 'untick search when moving map',
    })
  );

export const trackMove = once(() =>
  trackObject({
    action: 'move map',
  })
);

export const trackClickShowTile = () =>
  trackObject({
    action: 'click show tile',
  });

export const trackZoomIn = trackZoom(true);
export const trackZoomOut = trackZoom(false);
export const trackSearchMoveCheckboxTick = trackSearchMoveCheckbox(true);
export const trackSearchMoveCheckboxUntick = trackSearchMoveCheckbox(false);

export const trackFullscreenOn = trackFullscreen(true);
export const trackFullscreenOff = trackFullscreen(false);
