import { GATSBY_ENTRYPOINT } from '@fire/constants';

export const heroMenuContentDE = [
  {
    title: 'Urlaubsziele',
    url: '/campingurlaub',
    titles: {
      leftTitle: 'Klickbare Landkarte',
      upperRightTitle: 'Urlaubsziele',
      downRightTitle: 'Populäre Gegenden',
    },
    children: [
      {
        title: 'Deutschland',
        url: '/deutschland',
        image: {
          name: 'deutschland-stage.jpg',
          handle: 'j3j56n35TvK7yAI1E0yU',
        },
      },
      {
        title: 'Frankreich',
        url: '/frankreich',
        image: {
          name: '',
          handle: 's02gTlEOT8i4m13mhIq2',
        },
      },
      {
        title: 'Kroatien',
        url: '/kroatien',
        image: {
          name: '',
          handle: 'Kl54itb5QLO8uhqIBCXz',
        },
      },
      {
        title: 'Italien',
        url: '/italien',
        image: {
          name: '',
          handle: 'zMWwUVuFS5aVnfOVlwGw',
        },
      },
      {
        title: 'Niederlande',
        url: '/niederlande',
        image: {
          name: '',
          handle: 'm5tYYo5gSc6bolsTCsBh',
        },
      },
      {
        title: 'Österreich',
        url: '/oesterreich',
        image: {
          name: '',
          handle: 'JgGPb0W4SgSXTYyX2Avi',
        },
      },
      {
        title: 'Alle Länder',
        url: '/alle-laender',
        image: {
          name: '',
          handle: 'tBphsgeSQNazBt7j3j9o',
        },
      },
    ],
    links: [
      {
        title: 'Gardasee',
        url: '/campingurlaub/gardasee',
      },
      {
        title: 'Ostsee',
        url: '/campingurlaub/ostsee',
      },
      {
        title: 'Bayern',
        url: '/deutschland/bayern',
      },
      {
        title: 'Bodensee',
        url: '/campingurlaub/bodensee',
      },
      {
        title: 'Toskana',
        url: '/italien/toskana',
      },
      {
        title: 'Nordsee',
        url: '/campingurlaub/nordsee',
      },
      {
        title: 'Lago Maggiore',
        url: '/campingurlaub/lago-maggiore',
      },
      {
        title: 'Südtirol',
        url: '/campingurlaub/suedtirol',
      },
    ],
  },
  {
    title: 'Beste Campingplätze',
    url: '/campingthemen/5-sterne',
    titles: {
      leftTitle: 'Beste Campingplätze',
      upperRightTitle: '',
      downRightTitle: 'Beste Campingplätze in populären Urlaubsregionen',
    },
    children: [
      {
        title: 'Deutschland',
        url: '/campingthemen/5-sterne/deutschland',
        image: {
          name: '',
          handle: '5hQrwwaSkSEF2z7EqBrt',
        },
      },
      {
        title: 'Frankreich',
        url: '/campingthemen/5-sterne/frankreich',
        image: {
          name: '',
          handle: 'TrgzuwdFRc6goGXcH2LS',
        },
      },
      {
        title: 'Kroatien',
        url: '/campingthemen/5-sterne/kroatien',
        image: {
          name: '',
          handle: 'qiw6kYvlR0aZQyg2jzL8',
        },
      },
      {
        title: 'Italien',
        url: '/campingthemen/5-sterne/italien',
        image: {
          name: '',
          handle: 'BUIczBzESXGepAluWfuv',
        },
      },
      {
        title: 'Niederlande',
        url: '/campingthemen/5-sterne/niederlande',
        image: {
          name: '',
          handle: 'SFEMsu7QsWgsBCe4535A',
        },
      },
      {
        title: 'Österreich',
        url: '/campingthemen/5-sterne/oesterreich',
        image: {
          handle: 'JgGPb0W4SgSXTYyX2Avi',
        },
      },
      {
        title: 'Alle 5-Sterne ADAC Superplätze',
        url: '/campingthemen/5-sterne',
        image: {
          name: '',
          handle: 'bUY9TcBZQP2X7lAzDmma',
        },
      },
    ],
    links: [
      {
        title: 'Bayern',
        url: '/campingthemen/5-sterne/bayern',
      },
      {
        title: 'Adria',
        url: '/campingthemen/5-sterne/adria',
      },
      {
        title: 'Gardasee',
        url: '/campingthemen/5-sterne/gardasee',
      },
      {
        title: 'Südfrankreich',
        url: '/campingthemen/5-sterne/suedfrankreich',
      },
      {
        title: 'Nordsee',
        url: '/campingthemen/5-sterne/nordsee',
      },
      {
        title: 'Ostsee',
        url: '/campingthemen/5-sterne/ostsee',
      },
      {
        title: 'Beste Campingplätze am Meer',
        url: '/campingthemen/5-sterne/am-meer',
      },
      {
        title: 'Beste Campingplätze für Hundefreunde',
        url: '/campingthemen/5-sterne/mit-hund',
      },
      {
        title: 'Beste Campingplätze mit Pool',
        url: '/campingthemen/5-sterne/mit-pool',
      },
    ],
  },
  {
    title: 'Camping-Themen',
    url: '/campingthemen',
    titles: {
      leftTitle: 'Camping-Themen',
      upperRightTitle: '',
      downRightTitle: 'Wird oft gesucht: Camping …',
    },
    children: [
      {
        title: 'Camping am Meer',
        url: '/campingthemen/am-meer',
        image: {
          name: '',
          handle: 'wwNDHth2SMC2Y1cTf74O',
        },
      },
      {
        title: 'Camping mit Kindern',
        url: '/campingthemen/mit-kindern',
        image: {
          name: '',
          handle: 'PRabDvOTjq9NwBmHrDNt',
        },
      },
      {
        title: 'Camping am See',
        url: '/campingthemen/am-see',
        image: {
          name: '',
          handle: 'NVMvDXjlRmqSBCvjESFS',
        },
      },
      {
        title: 'Camping mit Hund',
        url: '/campingthemen/mit-hund',
        image: {
          name: '',
          handle: 'gj1nXuXjTAGhcfYOUnf3',
        },
      },
      {
        title: 'FKK Camping',
        url: '/campingthemen/fkk',
        image: {
          name: '',
          handle: 'OhYoiVHpRQKLKn3K5iuv',
        },
      },
      {
        title: '4 Sterne Camping',
        url: '/campingthemen/4-sterne',
        image: {
          name: '',
          handle: 'Lzc6ZDQi0kIx6bWJ2Bwa',
        },
      },
      {
        title: 'Camping für Angler',
        url: '/campingthemen/angler',
        image: {
          name: '',
          handle: 'Nok62WXQ7K7CHftXhUxk',
        },
      },
      {
        title: 'Alle Camping-Themen A-Z',
        url: '/campingthemen',
        image: {
          name: '',
          handle: 'BUa4r7zJT8mM3TeLb99w',
        },
      },
    ],
    links: [
      {
        title: 'mit Kindern in Deutschland',
        url: '/campingthemen/mit-kindern/deutschland',
      },
      {
        title: 'mit Kindern in den Niederlanden',
        url: '/campingthemen/mit-kindern/niederlande',
      },
      {
        title: 'am Meer in Italien',
        url: '/campingthemen/am-meer/italien',
      },
      {
        title: 'am Meer in Holland',
        url: '/campingthemen/am-meer/holland',
      },
      {
        title: 'mit Hund in Deutschland',
        url: '/campingthemen/mit-hund/deutschland',
      },
      {
        title: 'am Meer in Kroatien',
        url: '/campingthemen/am-meer/kroatien',
      },
      {
        title: 'am Meer in Frankreich',
        url: '/campingthemen/am-meer/frankreich',
      },
    ],
  },
  {
    title: 'Magazin',
    url: `${GATSBY_ENTRYPOINT}/magazin`,
    linkout: false,
    isExternalUrl: true,
  },
  {
    title: 'ADAC Produkte',
    url: `${GATSBY_ENTRYPOINT}/unternehmen/produkte/`,
    linkout: false,
    isExternalUrl: true,
    titles: {
      leftTitle: 'ADAC Produkte',
      upperRightTitle: '',
      downRightTitle: '',
    },
    children: [
      {
        title: 'ADAC Camping- und Stellplatzführer (Bücher)',
        url: `${GATSBY_ENTRYPOINT}/unternehmen/produkte/adac-camping-und-stellplatzfuehrer/`,
        linkout: false,
        isExternalUrl: true,
        image: {
          name: '',
          handle: 'KDfVudJSISab4TlXflay',
        },
      },
      {
        title: 'ADAC Camping- und Stellplatzführer App 2023',
        url: `${GATSBY_ENTRYPOINT}/unternehmen/produkte/adac-camping-stellplatzfuehrer-app/`,
        linkout: false,
        isExternalUrl: true,
        image: {
          name: '',
          handle: 'Pkyy9ksRg2st8O5sY9zD',
        },
      },

      {
        title: 'ADAC Campcard',
        url: `${GATSBY_ENTRYPOINT}/unternehmen/produkte/adac-campcard/`,
        linkout: false,
        isExternalUrl: true,
        image: {
          name: '',
          handle: 'fcGiFFZjT1ecCrHbEljK',
        },
      },

      {
        title: 'ADAC Routenplaner',
        url: `${GATSBY_ENTRYPOINT}/unternehmen/produkte/routenplaner/`,
        linkout: false,
        isExternalUrl: true,
        image: {
          name: '',
          handle: 'yZN71PnzT69qE2AahwKL',
        },
      },
      {
        title: 'Fahrsicherheits-Training',
        url: `${GATSBY_ENTRYPOINT}/unternehmen/produkte/fahrsicherheitstraining/`,
        linkout: false,
        isExternalUrl: true,
        image: {
          name: 'ADAC-FSZ-Nav',
          handle: 'rY9PLVLTBuaor1VCTHwb',
        },
      },
      {
        title: 'ADAC Wohnmobil-Vermietung',
        url: `${GATSBY_ENTRYPOINT}/unternehmen/produkte/adac-wohnmobil-vermietung/`,
        linkout: false,
        isExternalUrl: true,
        image: {
          name: '',
          handle: '9AkSiAxJTTydKT4MHrzS',
        },
      },
      {
        title: 'Maut und Vignetten',
        url: `${GATSBY_ENTRYPOINT}/unternehmen/produkte/maut-vignetten/`,
        linkout: false,
        isExternalUrl: true,
        image: {
          name: '',
          handle: 'tyssVUQ4R5a9HCfnQTsN',
        },
      },
      {
        title: 'Alle Produkte',
        url: `${GATSBY_ENTRYPOINT}/unternehmen/produkte/`,
        image: {
          name: '',
          handle: 'wos1dV8SVimHdPUtcnNg',
        },
      },
    ],
    links: [
      {
        title: 'ADAC Fähren',
        url: `${GATSBY_ENTRYPOINT}/unternehmen/produkte/faehren-buchen/`,
        linkout: false,
        isExternalUrl: true,
      },
      {
        title: 'ADAC Wohnwagenvermietung',
        url: `${GATSBY_ENTRYPOINT}/unternehmen/produkte/adac-wohnwagen-vermietung/`,
        linkout: false,
        isExternalUrl: true,
      },
      {
        title: 'ADAC Versicherungen',
        url: `${GATSBY_ENTRYPOINT}/unternehmen/produkte/versicherungen/`,
        linkout: false,
        isExternalUrl: true,
      },
      {
        title: 'ADAC Autokredit',
        url: `${GATSBY_ENTRYPOINT}/unternehmen/produkte/adac-autokredit/`,
        linkout: false,
        isExternalUrl: true,
      },
    ],
  },
];
