import React, { useContext } from 'react';
import { graphql, useStaticQuery, withPrefix } from 'gatsby';
import { pathOr } from 'ramda';

import BlockHeader from '@components/BlockHeader';
import Carousel from '@components/Carousel';
import TileDestination from '@components/Tile/Destination';
import MaybeData from '@contexts/MaybeData';
import { LOCALES } from '@fire/constants';
import { T } from '@fire/i18n/i18n-constants';

import regionsAllLang from './fixture';
import * as ß from './styles';

const namespace = pathOr('', ['HomePage'], T);
const { DE } = LOCALES;

const mapMarker = withPrefix('/icons/map-marker.svg');

const RegionCarousel = () => {
  const images = useStaticQuery(graphql`
    query {
      gardasee: file(
        relativePath: { eq: "homepage/regions-carousel/gardasee-torbole.jpg" }
      ) {
        childImageSharp {
          resize(width: 360, toFormat: JPG, quality: 100) {
            src
          }
        }
      }
      istrien: file(
        relativePath: { eq: "homepage/regions-carousel/Istrien.jpg" }
      ) {
        childImageSharp {
          resize(width: 360, toFormat: JPG, quality: 100) {
            src
          }
        }
      }
      lagoMaggiore: file(
        relativePath: {
          eq: "homepage/regions-carousel/lago-maggiore-st-caterina.jpg"
        }
      ) {
        childImageSharp {
          resize(width: 360, toFormat: JPG, quality: 100) {
            src
          }
        }
      }
      ostsee: file(
        relativePath: { eq: "homepage/regions-carousel/ostsee-stage.jpg" }
      ) {
        childImageSharp {
          resize(width: 360, toFormat: JPG, quality: 100) {
            src
          }
        }
      }
      suedfrankreich: file(
        relativePath: {
          eq: "homepage/regions-carousel/suedfrankreich-provence.jpg"
        }
      ) {
        childImageSharp {
          resize(width: 360, toFormat: JPG, quality: 100) {
            src
          }
        }
      }
      toskana: file(
        relativePath: { eq: "homepage/regions-carousel/toskana.jpeg" }
      ) {
        childImageSharp {
          resize(width: 360, toFormat: JPG, quality: 100) {
            src
          }
        }
      }
      coteDazur: file(
        relativePath: { eq: "homepage/regions-carousel/cote-azur.jpg" }
      ) {
        childImageSharp {
          resize(width: 360, toFormat: JPG, quality: 100) {
            src
          }
        }
      }
      atlantikkuesteFrankreich: file(
        relativePath: {
          eq: "homepage/regions-carousel/atlantikkueste-frankreich-etretat.jpg"
        }
      ) {
        childImageSharp {
          resize(width: 360, toFormat: JPG, quality: 100) {
            src
          }
        }
      }
      suedtirol: file(
        relativePath: {
          eq: "homepage/regions-carousel/suedtirol-dolomiten.jpg"
        }
      ) {
        childImageSharp {
          resize(width: 360, toFormat: JPG, quality: 100) {
            src
          }
        }
      }
      bayern: file(
        relativePath: { eq: "homepage/regions-carousel/bayern-stage.jpg" }
      ) {
        childImageSharp {
          resize(width: 360, toFormat: JPG, quality: 100) {
            src
          }
        }
      }
    }
  `);

  const pageContext = useContext(MaybeData);
  const language = pathOr(DE, ['language'], pageContext);
  const regions = regionsAllLang[language];
  return (
    <div css={ß.wrapper}>
      <BlockHeader
        description="popular-region>description"
        prefix={namespace}
        title="popular-region>title"
      />
      <Carousel>
        {regions.map((region, index) => (
          <div key={index} css={ß.tile}>
            <TileDestination
              images={[
                pathOr(
                  [],
                  [region.imageKey, 'childImageSharp', 'resize', 'src'],
                  images
                ),
              ]}
              path={pathOr('', ['url'], region)}
              siteUrl={pageContext.siteUrl}
              isExternalUrl={false}
              homepage
              item={region}
              logo={mapMarker}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default RegionCarousel;
