import React from 'react';
import PropTypes from 'prop-types';

import { COLOR_TABLE } from './consts';
import * as ß from './styles';

export const Star = ({ cssMod, color, big, isHalfStar }) => (
  <span
    css={[ß.star(COLOR_TABLE[color], big), isHalfStar && ß.half(big), cssMod]}
    className="star"
  />
);

Star.propTypes = {
  cssMod: PropTypes.object,
  color: PropTypes.oneOf(Object.keys(COLOR_TABLE)),
  big: PropTypes.bool,
  isHalfStar: PropTypes.bool,
};

Star.defaultProps = {
  cssMode: {},
  color: 'RED',
  big: false,
  isHalfStar: false,
};
