import React, { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import TileControlAvailabilityModal from '@contexts/TileControlAvailabilityModal';
import { T } from '@fire/i18n/i18n-constants';
import useProsciutto from '@hooks/useProsciutto';

import { NOCAMPINGNOCRY } from './const';

const prefix = T?.SearchResult ?? '';
import Button from '@components/Button';
import { trackOpenForm } from '@components/DatePicker/tracking';

import * as ß from './styles';

const MessageContainer = ({ children }) => (
  <div css={ß.infoContainer}>
    <div css={ß.messageContainer}>
      <div css={ß.message}>{children}</div>
    </div>
    <div>
      <img src={NOCAMPINGNOCRY} alt="no result" width="170" height="88" />
    </div>
  </div>
);

MessageContainer.propTypes = {
  children: PropTypes.node,
};

const NoResultMessageWithoutDates = ({ fallbackTiles }) => {
  const { t } = useTranslation(prefix);

  return fallbackTiles?.length === 0 ? (
    <p css={ß.noResultParagraph}>
      {t(
        'Wir konnten zu deiner Suche leider nichts finden. Probiere doch was anderes.'
      )}
    </p>
  ) : (
    <></>
  );
};

NoResultMessageWithoutDates.propTypes = {
  fallbackTiles: PropTypes.array,
};

NoResultMessageWithoutDates.displayName = 'NoResultMessageWithoutDates';

const NoResultMessageWithDates = ({ bookableTiles }) => {
  const { t } = useTranslation(prefix);
  const { setIsAvailabilityModalOpen } = useContext(
    TileControlAvailabilityModal
  );

  const openAvailabilityModal = () => {
    setIsAvailabilityModalOpen(true);
    trackOpenForm('notification-change');
  };

  const message =
    bookableTiles?.length === 0
      ? t('No bookable offers')
      : t('No further bookable offers');

  return (
    <>
      <div css={ß.message}>{message}</div>
      <Button
        onClick={openAvailabilityModal}
        variant="primaryOutlined"
        size="small"
      >
        {t('change-travel-dates')}
      </Button>
    </>
  );
};

NoResultMessageWithDates.propTypes = {
  bookableTiles: PropTypes.array,
};

const NoResultMessage = ({ lists }) => {
  const [prosciuttoState, prosciuttoDispatch] = useProsciutto();

  const bookableTiles = lists[0]?.tiles ?? [];
  const fallbackTiles = lists[1]?.tiles ?? [];

  const hasEnteredDates =
    prosciuttoState?.params?.date_from && prosciuttoState?.params?.date_to;
  const noResults = bookableTiles?.length === 0;

  if (hasEnteredDates && bookableTiles?.length < 16) {
    return (
      <MessageContainer>
        <NoResultMessageWithDates
          bookableTiles={bookableTiles}
          fallbackTiles={fallbackTiles}
          prosciuttoDispatch={prosciuttoDispatch}
        />
      </MessageContainer>
    );
  }

  if (!hasEnteredDates && noResults) {
    return (
      <MessageContainer>
        <NoResultMessageWithoutDates fallbackTiles={fallbackTiles} />
      </MessageContainer>
    );
  }

  return <></>;
};

NoResultMessage.propTypes = {
  lists: PropTypes.object,
  count: PropTypes.number,
};

export default memo(NoResultMessage);
