import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { graphql, useStaticQuery } from 'gatsby';

import BlockHeader from '@components/BlockHeader';
import Carousel from '@components/Carousel';
import TileDestination from '@components/Tile/Destination';
import { GATSBY_ENTRYPOINT } from '@fire/constants';
import lazy from '@fire/hoc/lazy';
import { T } from '@fire/i18n/i18n-constants';
import useHasMounted from '@hooks/useHasMounted';
import useTracking from '@hooks/useTracking';
import { isEmptyOrNil, shuffleArray } from '@utils/generic';

import * as ß from './styles';

const namespace = T?.HomePage ?? '';

const cropConfig = 'auto=compress,format&crop=edges&fit=crop';

const buildImageUrl = image => {
  const imageFileName = image?.fileName ?? false;
  const imageHandle = image?.handle ?? false;
  return `${GATSBY_ENTRYPOINT}/i/${encodeURIComponent(
    imageFileName
  )}?handle=${imageHandle}&${cropConfig}`;
};

const SpecialAdsCarousel = () => {
  const { prepareToTrackSpecialAds } = useTracking('carousel special ads');

  const hasMounted = useHasMounted();
  const { t } = useTranslation(namespace);
  const { ref, inView } = useInView();

  const data = useStaticQuery(graphql`
    query {
      specialAdsHome: allSpecialAds(filter: { pageType: { eq: "home" } }) {
        ...SpecialAdsFragment
      }
    }
  `);
  const [ads, setAds] = useState([]);
  const specialAdsHome = data?.specialAdsHome?.nodes || [];
  const hasAds = !isEmptyOrNil(specialAdsHome) && specialAdsHome.length >= 3;
  const tileCount = Math.min(ads?.length, 3);

  const trackImpressions = prepareToTrackSpecialAds.specialAdsImpressions(ads);

  // try to avoid ads blinks by running one time only
  useEffect(() => {
    const shuffledAds = shuffleArray(specialAdsHome);
    hasAds && setAds(shuffledAds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hasAds && inView) {
      trackImpressions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return hasMounted && hasAds ? (
    <div css={ß.wrapper} ref={ref}>
      <BlockHeader
        description="premium-partner-text>description"
        prefix={namespace}
        title="premium-partner-text>title"
      />
      <Carousel tileCount={tileCount}>
        {ads.map((ad, index) => {
          const props = {
            onClick: prepareToTrackSpecialAds.specialAdsClick(ad, index),
            images: !isEmptyOrNil(ad?.background)
              ? [ad?.background]
              : undefined,
            logo: !isEmptyOrNil(ad?.logo) ? buildImageUrl(ad?.logo) : undefined,
            path: ad?.buttonLink,
            item: {
              subtext: ad?.description,
              title: ad?.headline,
              url: ad?.buttonLink,
              link: t('Mehr Informationen'),
            },
          };
          return (
            <div key={ad.trackingId} id={ad.trackingId} css={ß.tile}>
              <TileDestination openInNewWindow isExternalUrl isAd {...props} />
            </div>
          );
        })}
      </Carousel>
    </div>
  ) : (
    false
  );
};

const MemoizedSpecialAdsCarousel = memo(SpecialAdsCarousel);
export default lazy(MemoizedSpecialAdsCarousel);
