import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import * as ß from './styles';

const BlockHeader = ({ description, prefix, title }) => {
  const { t } = useTranslation(prefix);

  return (
    <div css={ß.blockHeader}>
      <h2>{t(title)}</h2>
      {description && <p>{t(description)}</p>}
    </div>
  );
};

BlockHeader.propTypes = {
  description: PropTypes.string,
  prefix: PropTypes.string,
  title: PropTypes.string,
};

const MemoizedBlockHeader = memo(BlockHeader);

export default MemoizedBlockHeader;
