import React, { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';

import Button from '@components/Button';
import TileControlAvailabilityModal from '@contexts/TileControlAvailabilityModal';
import { T } from '@fire/i18n/i18n-constants';
import useTracking from '@hooks/useTracking';

import * as ß from './styles';

const namespace = pathOr('', ['Tile'], T);

const NoDatesRow = ({ totalPitches, totalRentals, onClick }) => {
  const {
    prepareToTrackEvent: { clickEvent },
  } = useTracking('tile');
  const { t } = useTranslation(namespace);

  const { setIsAvailabilityModalOpen } = useContext(
    TileControlAvailabilityModal
  );

  const trackClickShowPrice = clickEvent('click show price');
  const openAvailabilityModal = () => {
    trackClickShowPrice();
    onClick();
    setIsAvailabilityModalOpen(true);
  };

  const addBreakLine = (item, index) => (
    <Fragment key={index}>
      {item}
      <br />
    </Fragment>
  );

  const showPriceText = t('show-price');
  const splittedShowPriceText = showPriceText.split('\\n');
  const priceTextWithBreakline =
    splittedShowPriceText.length > 1
      ? splittedShowPriceText.map(addBreakLine)
      : splittedShowPriceText[0];

  return (
    <div css={ß.listItem}>
      <div css={ß.info}>
        {totalPitches > 0 && (
          <p>
            {totalPitches} {t('pitch', { count: totalPitches })}
          </p>
        )}
        {totalRentals > 0 && (
          <p>
            {totalRentals} {t('rental', { count: totalRentals })}
          </p>
        )}
      </div>
      <Button
        isTwoLines
        size="small"
        variant="primary"
        onClick={openAvailabilityModal}
      >
        {priceTextWithBreakline}
      </Button>
    </div>
  );
};

NoDatesRow.propTypes = {
  totalPitches: PropTypes.number,
  totalRentals: PropTypes.number,
  onClick: PropTypes.func,
};

NoDatesRow.defaultProps = {
  onClick: () => {},
};

export default NoDatesRow;
