import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { PREFIX } from '@components/AccommodationList/constants';
import DatePickerContext from '@fire/contexts/DatePicker';

import * as ß from './styles';

const Tabs = ({ resetDates, tabs }) => {
  const { setSelectedTab, selectedTab } = useContext(DatePickerContext);
  const { t } = useTranslation(PREFIX);

  const selectTab = tab => e => {
    e.preventDefault();
    resetDates();
    setSelectedTab(tab);
  };

  return (
    <div css={ß.tabs}>
      {tabs?.map(({ category }, index) => (
        <button
          aria-selected={selectedTab === index}
          key={category}
          onClick={selectTab(index)}
          role="tab"
        >
          {t(`category-${category}`)}
        </button>
      ))}
    </div>
  );
};

export default Tabs;

Tabs.propTypes = {
  resetDates: PropTypes.func,
  setSelectedUnavailableDates: PropTypes.func,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      list: PropTypes.arrayOf(PropTypes.string),
    })
  ),
};
