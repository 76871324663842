import React from 'react';
import {
  Accordion as ReactAccordion,
  AccordionItem as ReactAccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';
import PropTypes from 'prop-types';

import * as ß from './styles';

export const AccordionItem = ({ title, body, id }) => (
  <ReactAccordionItem css={ß.accordionContainer} id={id} key={id}>
    <AccordionItemHeading id={`${id}-button`}>
      <AccordionItemState>
        {state => (
          <AccordionItemButton css={ß.accordionTitle}>
            {title}
            <span
              css={state.expanded ? ß.accordionArrowUp : ß.accordionArrowDown}
            />
          </AccordionItemButton>
        )}
      </AccordionItemState>
    </AccordionItemHeading>
    <AccordionItemPanel>{body}</AccordionItemPanel>
  </ReactAccordionItem>
);

export const Accordion = ({ children }) => (
  <ReactAccordion allowMultipleExpanded allowZeroExpanded css={ß.contentWidth}>
    {children}
  </ReactAccordion>
);

Accordion.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
};

AccordionItem.propTypes = {
  body: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};
