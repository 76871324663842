import { css } from '@emotion/react';
import { blue9, charcoal } from '@fire/_color';
import { bold } from '@fire/_fonts';

export const navigation = css`
  color: ${charcoal};
`;

export const highlight = css`
  font-weight: ${bold};
  border-bottom: 4px solid ${blue9};
  text-decoration: none;
`;

export const item = active => css`
  display: block;
  color: ${charcoal};
  font-size: 16px;

  text-decoration: underline;
  ${active ? highlight : ''}
  &:hover {
    ${highlight}
  }
`;

export const listItem = css`
  display: inline-block;
  margin: 0 12px;
  height: 18px;
  z-index: 4;
  position: relative;
`;
