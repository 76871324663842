import React from 'react';
import handleViewport from 'react-in-viewport/dist/index';

import { higherOrderName } from '@utils/hoc';

const quitter = C => handleViewport(C, {}, { disconnectOnLeave: true });

export const lazy = Component =>
  higherOrderName(
    'lazy',
    Component,
    quitter(props => {
      const loaded = props.enterCount > 0;
      return (
        <div ref={props.innerRef} className={props.className}>
          <Component {...props} loaded={loaded} />
        </div>
      );
    })
  );

export default lazy;
