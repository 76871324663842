import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { NAMESPACE } from '@components/ReservationRequestConfirmation/constants';

import { getHumanizedTimeLeft } from './utils';

const TimeLeftUntilDate = ({ from, to, separator }) => {
  const { t } = useTranslation(NAMESPACE);
  const timeLeft = getHumanizedTimeLeft({ from, separator, t, to });

  return <>{timeLeft}</>;
};

TimeLeftUntilDate.propTypes = {
  from: PropTypes.string,
  separator: PropTypes.string,
  to: PropTypes.string.isRequired,
};

TimeLeftUntilDate.defaultProps = {
  separator: ', ',
};

export default TimeLeftUntilDate;
