import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import DateRangeAndNight from '@components/AccommodationList/DateRangeAndNight';
import WhiteRectangleWithBoxShadow from '@components/WhiteRectangleWithBoxShadow';

import AvailableCampingPlace from './AvailableCampingPlace';
import { PREFIX } from './consts';
import FlexibleDatesContext from './context';
import DaysApart from './DaysApart';
import PriceFrom from './PriceFrom';
import { container, subtitle, textAlignRight } from './styles';
import { trackClickFlexibleDate } from './tracking';

const FlexibleDates = () => {
  const { hasFlexibleDates, flexibleDates, dispatch, originalDateFrom } =
    useContext(FlexibleDatesContext);

  const { t } = useTranslation(PREFIX);

  const onClickChange = (dateFrom, dateTo) => event => {
    event?.preventDefault?.();
    trackClickFlexibleDate(dateFrom, dateTo);
    dispatch(dateFrom, dateTo);
  };

  const shouldShowTitleAndSubtitle =
    flexibleDates?.filter(x => x.from_price > 0)?.length > 0;

  return (
    hasFlexibleDates && (
      <>
        {shouldShowTitleAndSubtitle ? (
          <>
            <h3>{t('title')}</h3>
            <p css={subtitle}>{t('subtitle')}</p>
          </>
        ) : (
          <></>
        )}
        <div css={container}>
          {flexibleDates.map(
            (
              {
                arrival_date: arrivalDate,
                departure_date: departureDate,
                deviation_days: deviationDays,
                hits,
                from_price: fromPrice,
                currency,
                nights,
              },
              index
            ) =>
              fromPrice !== 0 ? (
                <WhiteRectangleWithBoxShadow
                  key={`${index}-${departureDate}-${arrivalDate}-${deviationDays}`}
                  onClick={onClickChange(arrivalDate, departureDate)}
                  topLeft={<AvailableCampingPlace count={hits} />}
                  topRight={<PriceFrom price={fromPrice} currency={currency} />}
                  bottomLeft={
                    <DaysApart
                      dateFrom={arrivalDate}
                      originalDateFrom={originalDateFrom}
                      deviationDays={deviationDays}
                    />
                  }
                  bottomRight={
                    <span css={textAlignRight}>
                      <DateRangeAndNight
                        arrivalDate={arrivalDate}
                        departureDate={departureDate}
                        nights={nights}
                      />
                    </span>
                  }
                />
              ) : (
                <></>
              )
          )}
        </div>
      </>
    )
  );
};

export default FlexibleDates;
