import { humanizeList } from '@utils/string';

export const getGuestList = (guests, t) => {
  const lastSeparator = t('and');
  const kidsAges = humanizeList({
    list: guests?.kidsAges,
    lastSeparator,
  });

  const list = [
    guests?.adults && t('adult', { count: guests?.adults }),
    guests?.kids &&
      t('kid', {
        ages: kidsAges,
        count: guests?.kids,
      }),
    guests?.dogs && t('dog', { count: guests?.dogs }),
  ].filter(Boolean);

  return humanizeList({ list, lastSeparator });
};

export const getKeyWithPrefix =
  (params, t) =>
  (key, opts = {}) => {
    const lang = params?.language;
    if (lang && lang === 'en') {
      return t(`${key}-${lang}`, opts);
    }
    return t(key, opts);
  };
