import React from 'react';
import PropTypes from 'prop-types';

import { css } from '@emotion/react';

const markdownStyle = css`
  img {
    max-width: 100%;
  }
  ul li {
    list-style: disc inside;
    p {
      display: inline-block;
    }
  }
`;

export const HTMLText = ({ props, source }) => (
  <div
    {...props}
    dangerouslySetInnerHTML={{ __html: source }}
    css={markdownStyle}
  />
);

HTMLText.propTypes = {
  props: PropTypes.object,
  source: PropTypes.string,
};
