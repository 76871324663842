import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import { schemaItemsWithType, schemaWithType } from './utils';

const makeSchema = schemaWithType('BreadcrumbList', '#breadcrumb');

export const SchemaBreadcrumbs = ({ breadcrumbs, pagePath }) => {
  const schemafy = schemaItemsWithType(pagePath, 'ListItem');
  const data = schemafy(breadcrumbs);
  const schema = makeSchema(data);

  return (
    <Helmet>
      <script type="application/ld+json">{schema}</script>
    </Helmet>
  );
};

SchemaBreadcrumbs.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
  pagePath: PropTypes.string.isRequired,
};

export default SchemaBreadcrumbs;
