import React from 'react';

import { LOADING_SPINNER } from './consts';
import * as ß from './styles';

const Loader = () => (
  <img
    css={ß.loader}
    src={LOADING_SPINNER}
    width={32}
    height={32}
    alt="Loading spinner..."
  />
);

export default Loader;
