import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery, withPrefix } from 'gatsby';

import chromeLogo from '@browser-logos/chrome/chrome_24x24.png';
import edgeLogo from '@browser-logos/edge/edge_24x24.png';
import firefoxLogo from '@browser-logos/firefox/firefox_24x24.png';
import safariLogo from '@browser-logos/safari/safari_24x24.png';
import Brand from '@components/Brand';
import { css, Global } from '@emotion/react';
import { gray5 } from '@fire/_color';
import { secondaryButton } from '@fire/_const';
import { media } from '@fire/_mediaQueries';
import { globalStyles } from '@fire/globalStyles';

const styles = css`
  body {
    position: relative;
    margin: 0;
    padding: 0;
  }

  header {
    position: relative;
    height: 70px;
    width: 100%;
    padding: 0;
    border-bottom: 1px solid ${gray5};

    ${media.lg} {
      height: 90px;
    }

    > div {
      position: absolute;
      top: 12px;
      left: 12px;
      ${media.lg} {
        top: 10px;
        left: 24px;
      }
    }

    > .adac-logo {
      position: absolute;
      height: 37px;
      top: 12px;
      right: 12px;

      ${media.lg} {
        height: 48px;
        top: 21px;
        right: 10px;
      }
    }
  }

  button {
    ${secondaryButton}
    padding: 0;
    position: relative;
    white-space: nowrap;
    span,p {
      display: inline-block;
    }
    p {
      height: 100%;
      padding: 12px;
      padding-left: 42px;
    }
    img {
      width: 12px;
      position: absolute;
      left: 4px;
      top: 4px;
    }
  }

  main {
    display: block;
    margin 0 24px;
    max-width: 400px;
    position: relative;
    padding: 0;
    padding-top: 60px;

    ${media.xs} {
      margin: 0 auto;
      left: 0;
      right: 0;
    }

    > div {
      text-align: center
    }
    .vendor-logo {
      zoom: 2;
      margin-bottom: 24px;
    }
    .camping-caravan {
      padding-bottom: 32px;
      width: 72px;

      ${media.lg} {
        padding-bottom: 46px;
        width: 120px;
      }
    }

    p {
      padding-bottom: 12px;
    }
  }

  ul {
    margin: 0;
    padding: 0
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    li {
      width: 150px;
      margin: 0;
      padding: 0;
      list-style: none;
      margin-top: 12px;
      margin-right: 30px;
      a {
        display: block;
      }
    }
  }
`;

const campingIcon = withPrefix('/icons/auto-camping-caravan.svg');

const OutdatedBrowserPage = () => {
  const {
    ADACLogo: { publicURL: ADACLogoPath },
  } = useStaticQuery(graphql`
    query {
      ADACLogo: file(relativePath: { eq: "adac-powered.png" }) {
        publicURL
        childImageSharp {
          fixed(height: 48, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `);

  return (
    <>
      <Global styles={[globalStyles, styles]} />
      <header>
        <Brand isHyperLink={false} />
        <img className="adac-logo" src={ADACLogoPath} />
      </header>
      <main id="content">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Outdated Browser</title>
        </Helmet>
        <img className="camping-caravan" src={campingIcon} />
        <h5>Sorry, your web browser is outdated</h5>
        <p>
          Please give one of these new and free browsers below a try for a
          better PiNCAMP experience:
        </p>
        <h5>Siamo spiacenti, il tuo browser non è aggiornato</h5>
        <p>
          Ti preghiamo di utilizzare uno dei questi nuovi browser gratuiti
          riportati di seguito per provare una migliore esperienza su PiNCAMP:
        </p>
        <h5>Désolé, votre navigateur Web est obsolète</h5>
        <p>
          Veuillez essayer l&apos;un de ces nouveaux navigateurs gratuits
          ci-dessous pour une meilleure expérience PiNCAMP:
        </p>
        <h5>Dein Webbrowser ist leider veraltet</h5>
        <p>
          Bitte nutze einen dieser neuen und kostenlosen Browser für ein
          besseres PiNCAMP Erlebnis:
        </p>
        <ul>
          <li>
            <a
              href="https://www.google.com/chrome/browser/desktop/"
              rel="noreferrer"
              target="_blank"
              title="Google Chrome"
            >
              <button>
                <img className="vendor-logo" src={chromeLogo} />
                <p>Google Chrome</p>
              </button>
            </a>
          </li>
          <li>
            <a
              href="https://www.mozilla.org/en-US/firefox/new/"
              rel="noreferrer"
              target="_blank"
              title="Mozilla Firefox"
            >
              <button>
                <img className="vendor-logo" src={firefoxLogo} />
                <p>Mozilla Firefox</p>
              </button>
            </a>
          </li>
          <li>
            <a
              href="https://www.microsoft.com/en-us/windows/microsoft-edge"
              rel="noreferrer"
              target="_blank"
              title="Microsoft Edge"
            >
              <button>
                <img className="vendor-logo" src={edgeLogo} />
                <p>Microsoft Edge</p>
              </button>
            </a>
          </li>
          <li>
            <a
              href="https://support.apple.com/en-us/HT204416"
              rel="noreferrer"
              target="_blank"
              title="Apple Safari"
            >
              <button>
                <img className="vendor-logo" src={safariLogo} />
                <p>Apple Safari</p>
              </button>
            </a>
          </li>
        </ul>
      </main>
    </>
  );
};

export default OutdatedBrowserPage;
