import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { getDaysApartKey } from '@components/AccommodationList/utils';
import { everyTruthy } from '@utils/generic';

import { PREFIX } from './consts';

const DaysApart = ({ dateFrom, originalDateFrom, deviationDays }) => {
  const { t } = useTranslation(PREFIX);
  return (
    everyTruthy([dateFrom, originalDateFrom, deviationDays]) && (
      <p>
        {t(getDaysApartKey(dateFrom, originalDateFrom), {
          count: deviationDays,
        })}
      </p>
    )
  );
};

DaysApart.propTypes = {
  dateFrom: PropTypes.string,
  originalDateFrom: PropTypes.string,
  deviationDays: PropTypes.number,
};

export default DaysApart;
