import { checkAvailabilities } from '@components/AccommodationList/utils';
import { getAvailabilityLink } from '@fire/utils/availability';
import { everyTruthy, isEmptyOrNil } from '@fire/utils/generic';
import { objectToQuery } from '@fire/utils/url';

/**
 * This is adequate for rough locations on a global scale,
 * for detailed work it will be inadequate.
 *
 * Table of equivalencies:
 * https://www.usna.edu/Users/oceano/pguth/md_help/html/approx_equivalents.htm
 * https://adac-camping.atlassian.net/browse/PIN-8382?focusedCommentId=21887
 *
 * @param {float} lat
 * @param {float} lon
 * @param {float} distance - Distance in meters, the resulting distance will be approximated.
 */

function calculateViewport(lat, lon, distance) {
  const measureInDegrees = 0.001;
  const measureInMeters = 111;
  const degrees = (distance * measureInDegrees) / measureInMeters;
  const bottomRightLat = !isEmptyOrNil(lat) ? lat - degrees : undefined;
  const topLeftLon = !isEmptyOrNil(lon) ? lon - degrees * 2 : undefined;
  const topLeftLat = !isEmptyOrNil(lat) ? lat + degrees : undefined;
  const bottomRightLon = !isEmptyOrNil(lon) ? lon + degrees * 2 : undefined;

  if (everyTruthy([bottomRightLat, topLeftLon, topLeftLat, bottomRightLon])) {
    return `${bottomRightLat},${topLeftLon},${topLeftLat},${bottomRightLon}`;
  }

  return;
}

export const getSearchFallbackPath = (
  latitude,
  longitude,
  availabilities,
  prn
) => {
  const viewport = calculateViewport(latitude, longitude, 100000);

  const { list, dateFrom, dateTo } = availabilities || {};

  const shouldHaveFallback = everyTruthy([
    !checkAvailabilities({ list }),
    !isEmptyOrNil(dateFrom),
    !isEmptyOrNil(dateTo),
  ]);

  const query = objectToQuery({
    viewport,
    excludePrns: prn,
  });

  const pagePath = `/search${query}`;

  const fallbackSearchPath = shouldHaveFallback
    ? getAvailabilityLink({
        ...availabilities,
        pagePath,
      })
    : '';

  return fallbackSearchPath;
};
