import React, { memo, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { take } from 'ramda';

import MaybeData from '@contexts/MaybeData';
import { T } from '@fire/i18n/i18n-constants';
import useGetGatsbySiteUrl from '@hooks/useGetGatsbySiteUrl';
import useLoyaltyGold from '@hooks/useLoyalty/gold';
import useLoyaltyPlatinum from '@hooks/useLoyalty/platinum';
import { isCustomer } from '@utils/customer';
import { shuffleArray } from '@utils/generic';

import LogosList from './LogosList';
import * as ß from './styles';

const namespace = T?.PartnerAds ?? '';

const PartnerLogos = ({ amount, isCenter }) => {
  const { t } = useTranslation(namespace);
  const [loyalties, setLoyalties] = useState([]);
  const goldLoyalties = useLoyaltyGold();
  const platinumLoyalties = useLoyaltyPlatinum();
  const siteUrl = useGetGatsbySiteUrl();
  const pageContext = useContext(MaybeData);
  const { related = {}, pagePath } = pageContext;
  const plan = related?.plan;
  const hasNoPlan = !isCustomer(plan);
  const isLoading = loyalties.length === 0;

  useEffect(() => {
    const shuffledLoyalties = take(
      amount,
      shuffleArray([...goldLoyalties, ...platinumLoyalties])
    );

    setLoyalties(shuffledLoyalties);
  }, []);

  return hasNoPlan ? (
    <div css={ß.container(isCenter)}>
      <div css={ß.texts}>
        <h2>{t('Ausgewählte Campingplätze')}</h2>
      </div>

      <div css={ß.logos({ amount })} className={isLoading && 'placeholder'}>
        <LogosList
          logos={loyalties}
          siteUrl={siteUrl}
          pagePath={pagePath}
          isLoading={isLoading}
          amount={amount}
        />
      </div>
    </div>
  ) : (
    <></>
  );
};

PartnerLogos.defaultProps = {
  amount: 6,
  isCenter: false,
};

PartnerLogos.propTypes = {
  amount: PropTypes.number,
  isCenter: PropTypes.bool,
};

export default memo(PartnerLogos);
