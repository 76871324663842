import { css } from '@emotion/react';
import { skeletonBackground } from '@fire/_const';
import { media } from '@fire/_mediaQueries';

export const container = isCenter => css`
  padding: 16px 8px;
  text-align: ${isCenter ? 'center' : 'left'};
`;

export const texts = css`
  padding-bottom: 24px;

  h2 {
    font-size: 34px;
  }
`;

export const logos = ({ amount }) => css`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 10px 10px;

  ${media.xl} {
    grid-template-columns: repeat(${amount}, 1fr);
    grid-template-rows: 1fr;
  }

  img {
    width: 100%;
    object-fit: scale-down;
  }
`;

export const logo = css`
  display: flex;
  background: white;
  height: 100px;
  padding: 10px;

  .placeholder & {
    ${skeletonBackground}
  }
`;
