import { graphql, useStaticQuery } from 'gatsby';

import { getCorrespondingImage } from '@components/HeroImage/utils';

export const heroImageQuery = graphql`
  query HeroImagesQuery {
    images: allFile(
      filter: { relativePath: { glob: "homepage/heroImages/*" } }
      sort: { fields: name }
    ) {
      nodes {
        childImageSharp {
          fluid(
            toFormat: JPG
            quality: 100
            srcSetBreakpoints: [370, 570, 760, 990, 1200, 1450, 1600]
            maxWidth: 2000
          ) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;

export const useHeroImage = prn => {
  const data = useStaticQuery(heroImageQuery);
  if (prn) {
    const images = data?.images?.nodes ?? [];
    const image = getCorrespondingImage({
      prn,
      images,
    });
    return image;
  }
  return new Error('Partner prn needed');
};
