import { css } from '@emotion/react';
import { blue9, blue10, charcoal, lightShadow, yellow9 } from '@fire/_color';
import { borderRadiusLarge } from '@fire/_const';
import { bold } from '@fire/_fonts';
import { media } from '@fire/_mediaQueries';

// Main
export const root = css`
  height: auto;
  position: relative;
  z-index: 999;

  & > div:first-of-type {
    position: relative;
  }
`;

export const wrapper = css`
  background: ${yellow9};
  border-radius: ${borderRadiusLarge};
  margin-bottom: 25px;
  position: relative;
  width: 100%;
`;

export const wrapperStacked = css`
  ${wrapper}

  box-shadow: 0 4px 8px 0 ${lightShadow};
`;

export const container = css`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
`;

// Availability Box
export const availabilityBox = css`
  display: block;
  margin: 0;
  position: relative;
  width: 100%;

  > form {
    display: block;
  }

  [class*='formRow'] {
    padding: 0;
  }
`;

// Header
export const header = css`
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 16px;
  padding-bottom: 8px;

  :only-child {
    padding-bottom: 24px;
  }
`;

export const title = css`
  color: ${charcoal};
  font-size: 22px;

  overflow: hidden;

  ${media.xl} {
    font-size: 20px;
  }
`;

// Comparison Price
export const price = css`
  color: ${charcoal};
  display: flex;
  flex-direction: column;
  font-size: 26px;
  font-weight: ${bold};
  justify-content: flex-start;
  margin-bottom: 5px;
  margin-right: 5px;
`;

export const info = css`
  display: flex;
  font-size: 12px;
`;

export const iconInfo = css`
  fill: ${blue9};
  margin-left: 8px;
`;

// Sidebar Ad
export const sidebarAd = css`
  margin: 24px 0;

  & a > div {
    height: 460px;
  }
`;

export const loading = css`
  min-height: 250px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const awards = css`
  display: none;
  padding: 20px;
  border-radius: ${borderRadiusLarge};
  box-shadow: 0 4px 8px 0 ${lightShadow};

  ${media.xl} {
    display: flex;
    margin-bottom: 25px;
  }
`;

export const desktopView = css`
  display: none;

  ${media.xl} {
    display: block;
  }
`;

export const homepageLink = css`
  color: ${blue9};
  display: block;
  font-size: 18px;
  font-weight: bold;
  padding: 0;
  transition: color 0.25s ease;

  :hover {
    color: ${blue10};
  }

  svg {
    margin-left: 4px;
    transform: rotate(-90deg);

    path {
      stroke: ${blue9};
    }
  }
`;
