import { css } from '@emotion/react';
import { white } from '@fire/_color';
import { bold, fontSizeMedium, regular } from '@fire/_fonts';
import { media } from '@fire/_mediaQueries';

export const wrapper = css`
  display: block;
  padding: 7px;
  background-color: ${white};
  text-decoration: none;

  ${media.xl} {
    min-width: 380px;
    padding: 7px 7px 7px 12px;
  }
`;

export const title = css`
  margin-bottom: 0px;
  font-weight: ${regular};
  font-size: ${fontSizeMedium};

  ${media.xl} {
    margin-bottom: 5px;
    font-weight: ${bold};
    font-size: 20px;
  }
`;

export const stars = css`
  display: none;

  ${media.xl} {
    display: block;
  }
`;

export const region = css`
  display: none;

  ${media.xl} {
    display: block;
  }
`;
