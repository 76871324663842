import { GATSBY_NEWSLETTER_SIGNUP } from '@fire/constants';
import { T } from '@fire/i18n/i18n-constants';

export const PREFIX = T.FooterSignup;

export const handleInput = callback => event => {
  callback();
  event?.target?.setCustomValidity('');
};

export const handleInvalid = message => event => {
  const target = event?.target;
  const errorMessage = !target?.validity?.valid ? message : '';

  target?.setCustomValidity(errorMessage);
};

export const registerForNewsletter = async ({
  email: recipient,
  locale = 'de',
  source: optin_source = 'signup',
}) => {
  let response;
  try {
    response = await fetch(GATSBY_NEWSLETTER_SIGNUP, {
      body: JSON.stringify({
        locale,
        optin_source,
        recipient,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });

    const result = await response.json();

    return result;
  } catch {
    return {
      code: 'unknown-error',
      status: 'error',
      statusCode: response?.status,
    };
  }
};

export const INITIAL_STATE = {
  errorMessage: '',
  hasError: false,
  showMessage: false,
};

export const reducer = (state, { payload, type }) => {
  switch (type) {
    case 'error':
      return {
        ...state,
        errorMessage: payload,
        hasError: true,
        showMessage: false,
      };
    case 'ok':
      return {
        ...state,
        errorMessage: '',
        hasError: false,
        showMessage: true,
      };
    case 'reset':
      return {
        ...INITIAL_STATE,
      };
  }
};
