import { includes, pathOr, pipe } from 'ramda';

const premiumPlans = ['Premium', 'Premium Plus'];

const comfortAndBetterPlans = [...premiumPlans, 'Comfort'];

const customerPlans = [...premiumPlans, 'Comfort', 'Comfort Mini', 'Mini'];

export const isCustomer = plan => includes(plan, customerPlans);

export const isComfortOrBetterCustomer = plan =>
  includes(plan, comfortAndBetterPlans);

export const isCustomerPremium = plan => includes(plan, premiumPlans);

export const getPremium = pipe(
  pathOr('', ['related', 'plan']),
  isCustomerPremium
);
