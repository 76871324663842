import { css } from '@emotion/react';
import { blue8, gray10 } from '@fire/_color';
import { fontSizeMedium, fontSizeSmall } from '@fire/_fonts';
import { media } from '@fire/_mediaQueries';

export const breadcrumbs = css`
  font-size: ${fontSizeMedium};
  margin-top: 16px;

  a {
    color: ${gray10};
    text-decoration: none;

    ::after {
      content: ' / ';
      text-decoration: none;
    }

    span {
      text-decoration: underline;
    }
  }
`;
export const breadcrumbsCompact = css`
  font-size: ${fontSizeSmall};

  a {
    color: ${gray10};
    text-decoration: none;

    :not(:last-child)::after {
      content: ', ';
      margin-right: 8px;
    }
  }

  ${media.lg} {
    font-size: ${fontSizeMedium};

    a {
      color: ${blue8};
      text-decoration: none;
    }
  }
`;
