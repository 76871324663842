import React from 'react';
import qs from 'qs';
import {
  clone,
  curry,
  findIndex,
  ifElse,
  isEmpty,
  isNil,
  keys,
  map,
  pathOr,
  pipe,
  prop,
  propEq,
  propOr,
} from 'ramda';

import { PAGE_SIZE } from '@fire/constants';
import { T } from '@fire/i18n/i18n-constants';
import { isEmptyOrNil } from '@fire/utils/generic';
import { isSearch } from '@utils/platform';

export const AD_POSITION = 4;
export const PREFIX = T?.SearchResult ?? '';
export const DESTINATION_PREFIX = T?.DestinationPage ?? '';

export const customerPlanParser = p => {
  if (typeof p !== 'string') return 0;
  const plans = [
    ['none', 'nein'],
    ['mini', 'comfort mini'],
    ['comfort'],
    ['premium'],
    ['premium plus'],
  ];
  const i = findIndex(x => x.includes(p), plans);
  if (i === -1) return 0;
  return i;
};

export const hasCustomerPlan = plan => customerPlanParser(plan) > 0;

export const hasPremiumPlan = plan => customerPlanParser(plan) > 2;

export const showReview = () => qs.parse(window.location.search).review;

export const getPage = () => {
  const parsed = qs.parse(window.location.search);
  return parsed.page > 0 ? parsed.page - 1 : parsed.page;
};

export const showCampsiteButton = curry(
  (link, plan) => link && plan !== 'None'
);

export const redirectToReview = ({ prn }) => {
  window.location.href = `/campingplatz-betwerten?prn=${prn}`;
};

export const getCarouselUrls = ({ images, endpoint }) =>
  images
    ? map(i => `${endpoint}${i.url}?w=500&fit=max&auto=compress,format`, images)
    : [];

/* eslint-disable react/prop-types */
const Temp = ({ children }) => <span>{children}</span>;
const ResultSummary = Temp;
const NoResultText = Temp;
const Trans = Temp;
export const getSummary = count => <ResultSummary count={count} />;

export const getNoResultMessage = ({ namespace, i18n }) => (
  <NoResultText>
    <Trans i18n={i18n} i18nKey={`${namespace}:no-result-text`}>
      Wir konnten zu deiner Suche leider nichts finden. Probiere doch was
      anderes.
    </Trans>
  </NoResultText>
);

export const getDataFromProsciutto = curry(
  (prosciuttoData, key, pc, fallback) =>
    ifElse(
      x => propOr(false, key, x) !== false,
      propOr(fallback, key),
      () => pathOr(fallback, ['initialState', key], pc)
    )(prosciuttoData)
);

export const getMinMax = ({ page, size }) => ({
  min: size * page - size,
  max: size * page,
});

const hasNoActiveFilters = pipe(prop('filters'), keys, propEq('length', 0));

export const interlinkSections = Object.freeze({
  interlinkingTopDestinations: 'Populäre Orte',
  interlinkingTopTopics: 'Populäre Themen',
  interlinkingTopTouristicRegions: 'Populäre Urlaubsregionen',
});

export const getAdvertisements = (context, where) => propOr([], where, context);

export const dropTiles = newList => {
  const [firstList, secondList] = newList;
  const firstListTiles = firstList?.tiles;
  const hasEnoughTiles =
    !isNil(firstListTiles) && firstListTiles.length > PAGE_SIZE + 1;

  const rearrangedTiles = hasEnoughTiles
    ? [
        {
          ...firstList,
          tiles: firstList.tiles.slice(0, PAGE_SIZE),
        },
        { ...(secondList ?? {}) },
      ]
    : newList;

  return rearrangedTiles;
};

export const shouldHaveAnAd = (list, ad) =>
  list?.length > AD_POSITION && !isEmpty(ad);

export const pushLastClicked = (tiles, lastClicked) => {
  const found = tiles?.find(({ prn }) => prn === lastClicked);

  return found ? [found, ...tiles.filter(i => i !== found)] : tiles;
};

/**
 *
 * Prepare and enhance the lists of Tiles by adding an ad
 * and a highlight campsite to each list and then removing a tile
 * from it if the list's longer than the page size.
 * Also moving the last clicked campsite to the first position
 * of the first list.
 *
 */
export const prepareTiles = ({ lastClickedPRN, lists }) => {
  if (isEmptyOrNil(lists)) {
    return [];
  }

  const listsWithAds = clone(lists);

  listsWithAds.forEach((list, index) => {
    if (index === 0) {
      list.tiles = pushLastClicked(list.tiles, lastClickedPRN);
    }
  });

  return listsWithAds;
};

export const getPaginatedIndex = curry(
  (pageSize, pageNumber, index) => index + pageSize * (pageNumber - 1)
);

export const getSummaryTitle = ({ pageContext, params, prn, t }) =>
  isSearch(prn) && params?.term
    ? t('Suche nach "{{term}}"', {
        term: propOr(propOr('', 'name', pageContext), 'term', params),
      })
    : pageContext?.h1 ?? '';

const hasViewport = viewport =>
  typeof viewport === 'string' && viewport.length > 0;

const isAllResultsPage = ({ params, prn }) =>
  isSearch(prn) && (propEq('term', '', params) || isNil(prop('term', params)));

const hasResults = count => count > 0;

export const hasNoResults = ({ count, params, prn, props, viewport }) =>
  isSearch(prn) &&
  !isAllResultsPage({ params, prn }) &&
  !hasResults(count) &&
  !hasViewport(viewport) &&
  hasNoActiveFilters(props);

export const getClickoutTrackData = curry(
  (pageContext, getIndex, tile, index) => ({
    tileProps: tile,
    metaDescription: pageContext?.metaDescription ?? '',
    pagePrn: pageContext?.prn ?? '',
    index: getIndex(index),
  })
);
