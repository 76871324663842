import { DEFAULT_TOTAL_ADULTS } from '@fire/constants';
import { queryToObject } from '@utils/url';

export const hasDatesChanged = ({ dateFrom, dateTo }) => {
  const query = new URLSearchParams(window?.location?.search);
  const dateFromQuery = query.get('date_from');
  const dateToQuery = query.get('date_to');

  if (dateFrom && dateTo) {
    return dateFrom !== dateFromQuery || dateTo !== dateToQuery;
  }

  return false;
};

export const hasSearchChanged = ({
  dateFrom,
  dateTo,
  kidsAges,
  totalAdults,
}) => {
  const query = new URLSearchParams(window?.location?.search);
  const hasUpdatedDates = hasDatesChanged({ dateFrom, dateTo });
  const childrenQuery = query.get('children');
  const kidsAgesQuery = childrenQuery ? childrenQuery.toString() : '';
  const totalAdultsQuery = Number(query.get('adults'));

  if (dateFrom && dateTo) {
    return (
      hasUpdatedDates ||
      kidsAgesQuery !== kidsAges?.toString() ||
      totalAdultsQuery !== totalAdults
    );
  }

  return false;
};

export const resetGuests = (
  setTotalAdults,
  setKidsAges,
  totalAdults = DEFAULT_TOTAL_ADULTS
) => {
  setTotalAdults(totalAdults);
  setKidsAges([]);
};

export const hasEdited = () => {
  const { edited } = queryToObject(window?.location?.search);
  return edited;
};
