import React, { useContext } from 'react';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';

import Link from '@components/Link';
import MaybeData from '@contexts/MaybeData';
import { getDataProtectionUrl } from '@utils/platform';

import * as ß from '../styles';

/* eslint-disable prettier/prettier */
/* prettier-ignore */
const PersonalInfoEmailDataPrivacy = ({t}) => {
const pageContext = useContext(MaybeData);
const dataProtectionUrl = getDataProtectionUrl(pageContext);
return (
<Trans i18nKey={'step-1-form.personal-info-email-data-privacy'} t={t}>
<p css={ß.infoText}> Deine E-Mail-Adresse wird dem Campingplatz nicht genannt und liegt PiNCAMP nur für den Zweck der Bestätigung deiner Identität vor.
Deine Bewertung wird optional unter dem von dir gewählten Namen oder Pseudonym, andernfalls anonym veröffentlicht, siehe Näheres zum Verfahren in unserer{' '}
<Link to={dataProtectionUrl} openInNewWindow>
Datenschutzerklärung
</Link>{'.'}
</p>
</Trans>
);
};

PersonalInfoEmailDataPrivacy.propTypes = {
  t: PropTypes.func.isRequired,
};

export default PersonalInfoEmailDataPrivacy;
