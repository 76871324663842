import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { withPrefix } from 'gatsby';
import { curry, filter, pipe } from 'ramda';

import Link from '@components/Link';
import MaybeData from '@contexts/MaybeData';
import { LOCALES } from '@fire/constants';
import useHasMounted from '@fire/hooks/useHasMounted';
import { T } from '@fire/i18n/i18n-constants';
import { isSwiss } from '@fire/utils/platform';
import { mapIndexed } from '@utils/generic';
import { isAbsoluteUrl } from '@utils/url';

import * as ß from './styles';

const prefix = T?.Footer ?? '';
const { DE, CH_DE, CH_IT, CH_FR } = LOCALES;

const mapIconsWithData = curry((data, icons) =>
  mapIndexed(({ href, alt, logo }, idx) => {
    const logoUrl = data[logo];
    return logoUrl ? (
      <Link
        to={href}
        key={alt || idx}
        isExternalUrl={isAbsoluteUrl(href)}
        openInNewWindow
      >
        <img alt={alt} src={logoUrl} />
      </Link>
    ) : (
      <React.Fragment key={idx} />
    );
  }, icons)
);

// useStaticQuery is magic, so we need to identity closure this pipe
const renderSocialIcons = (icons, localSocialIcons) =>
  pipe(mapIconsWithData(localSocialIcons), filter(Boolean))(icons);

const legalLinks = {
  [DE]: {
    imprint: '/impressum',
    privacy: '/datenschutz',
    termsOfUse: '/nutzungsbedingungen',
  },
  [CH_DE]: {
    imprint: '/impressum',
    privacy: '/datenschutz',
    termsOfUse: '/nutzungsbedingungen',
  },
  [CH_IT]: {
    imprint: '/impressum',
    privacy: '/protezione-dati',
    termsOfUse: '/condizioni-uso',
  },
  [CH_FR]: {
    imprint: '/impressum',
    privacy: '/politique-confidentialite',
    termsOfUse: '/conditions-utilisations',
  },
};

const socialIconsADAC = [
  {
    href: 'https://www.facebook.com/PiNCAMP.DE',
    alt: 'Facebook',
    logo: 'Facebook',
  },
  {
    href: 'https://twitter.com/PiNCAMP_DE',
    alt: 'Twitter',
    logo: 'Twitter',
  },
  {
    href: 'https://www.instagram.com/pincamp.de',
    alt: 'Instagram',
    logo: 'Instagram',
  },
  {
    href: 'https://www.pinterest.de/pincamp',
    alt: 'Pinterest',
    logo: 'Pinterest',
  },
];

const socialIconsTCS = [
  {
    href: 'https://www.facebook.com/CampingInsider',
    alt: 'Facebook',
    logo: 'Facebook',
  },
  {
    href: 'https://www.instagram.com/camping_insider/',
    alt: 'Instagram',
    logo: 'Instagram',
  },
];

const socialIcons = isSwissBool =>
  isSwissBool ? socialIconsTCS : socialIconsADAC;

const localSocialIcons = {
  Instagram: withPrefix('/icons/social/Instagram.svg'),
  Pinterest: withPrefix('/icons/social/Pinterest.svg'),
  Twitter: withPrefix('/icons/social/Twitter.svg'),
  Facebook: withPrefix('/icons/social/Facebook.svg'),
};

const invokeUserCentricsBanner = event => {
  event.preventDefault();
  window?.UC_UI?.showSecondLayer?.();
};

export const FooterLegalSocial = () => {
  const hasMounted = useHasMounted();
  const pageContext = useContext(MaybeData);

  const { t } = useTranslation(prefix);
  const language = pageContext?.language ?? DE;
  const localLegalLinks = legalLinks[language];

  return (
    <>
      <div css={ß.extraLinks} id="js-stop-cookie-banner">
        <ul css={ß.extraLinksList}>
          <li>
            <Link to={localLegalLinks.imprint}>{t('Impressum')}</Link>
          </li>
          <li>
            <Link to={localLegalLinks.privacy}>{t('Datenschutz')}</Link>
          </li>
          <li>
            <Link to={localLegalLinks.termsOfUse}>
              {t('Nutzungsbedingungen')}
            </Link>
          </li>
          {hasMounted && (
            <li>
              <a
                href="#"
                id="uc-corner-modal-show"
                onClick={invokeUserCentricsBanner}
              >
                {t('Cookies & Local Storage')}
              </a>
            </li>
          )}
        </ul>
        <div css={ß.socialLinks}>
          {renderSocialIcons(
            socialIcons(isSwiss(pageContext)),
            localSocialIcons
          )}
        </div>
      </div>
    </>
  );
};

export default FooterLegalSocial;
