import { graphql, useStaticQuery } from 'gatsby';

const useGetGatsbySiteUrl = (fallback = '') => {
  const data = useStaticQuery(
    graphql`
      query GatsbySiteUrl {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `
  );
  return data?.site?.siteMetadata?.siteUrl ?? fallback;
};

export default useGetGatsbySiteUrl;
