import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Loading from '@components/Loading';
import useReservationRequest from '@hooks/useReservationRequest';
import { useLocation } from '@reach/router';
import { isADACByTarget } from '@utils/platform';
import { queryToObject } from '@utils/url';

import { links, NAMESPACE } from '../constants';
import * as ß from './styles';

const Resolved = () => {
  const { search } = useLocation();
  const params = queryToObject(search);
  const { isLoading, isValid, resolveGuest } = useReservationRequest({
    requestId: params?.request_id,
    sessionId: params?.session_id,
  });
  const { t } = useTranslation(NAMESPACE);

  const isADAC = isADACByTarget();

  useEffect(() => {
    resolveGuest();
  }, []);

  return (
    <>
      {isLoading ? (
        <div css={ß.loading}>
          <Loading />
        </div>
      ) : isValid ? (
        <>
          <h1 css={ß.title}>{t('thank-you-reply')}</h1>
          <div css={ß.textBlock}>
            {isADAC && (
              <>
                <p>
                  <Trans i18nKey="magazine-promo" t={t}>
                    <a href={links.magazin}>Link to the Magazine</a>
                  </Trans>
                </p>
                <p>
                  <Trans i18nKey="app-promo" t={t}>
                    <a href={links.app}>Link to the App</a>
                  </Trans>
                </p>
              </>
            )}
            <p>{t('pincamp-team')}</p>
          </div>
        </>
      ) : (
        <h2 css={ß.subtitle}>{t('reservation-not-found')}</h2>
      )}
    </>
  );
};

export default Resolved;
