import { css } from '@emotion/react';
import { blue9, blue10, gray8, white } from '@fire/_color';
import { bold, fontFace } from '@fire/_fonts';
import { BREAKPOINTS, media } from '@fire/_mediaQueries';

export const header = css`
  margin: 24px 0;
`;
export const container = css`
  margin: 15px auto 24px;
  padding-left: 24px;
  padding-right: 24px;
  max-width: ${BREAKPOINTS.xl}px;
`;
export const html = css`
  margin-top: 15px;
  margin-bottom: 24px;
  font-size: 18px;
  strong {
    font-weight: ${bold};
  }

  h1 {
    margin-top: 32px;
    margin-bottom: 24px;
  }
`;

const flexCenter = css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 1; //special case
`;

export const aside = css`
  display: none;
  flex-direction: row;
  justify-content: space-between;
  margin: 40px 0;
  ${media.lg} {
    display: flex;
  }
  a,
  span {
    ${flexCenter};
    color: ${white};
    text-align: center;
    ${fontFace};
    font-weight: ${bold};
    font-size: 30px;
    width: 32px;
    height: 32px;
    align-self: center;
  }
  a {
    background-color: ${blue9};
    cursor: pointer;
    text-decoration: none;
    &:hover {
      background-color: ${blue10};
    }
  }
  span {
    background-color: ${gray8};
    cursor: not-allowed;
  }
`;

export const label = css`
  color: ${white};
  text-align: center;
  ${fontFace};
  font-weight: ${bold};
  font-size: 30px;
  width: 32px;
  height: 32px;
  align-self: center;

  margin-bottom: 10px;
`;

export const labelActive = css`
  background-color: ${blue9};
  ${flexCenter};
`;

export const labelInactive = css`
  background-color: ${gray8};
  cursor: not-allowed;
  ${flexCenter};
`;

export const groupWrapper = css`
  display: none;
  flex-direction: row;
  ${media.lg} {
    display: flex;
  }
`;

export const column = css`
  min-width: calc(100% / 3);
  font-size: 16px;

  margin: 6px 0;
`;

export const accordion = css`
  display: block;
  ${media.lg} {
    display: none;
  }
`;

export const breadcrumbs = css`
  padding: 8px 0;
`;
