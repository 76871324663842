import { css } from '@emotion/react';
import { black, charcoal, green, white } from '@fire/_color';
import { skeletonBackground, tileBoxShadow } from '@fire/_const';
import {
  bold,
  fontSizeBody,
  fontSizeMedium,
  fontSizeSmall,
} from '@fire/_fonts';
import { nonTouch } from '@fire/_mediaQueries';

export const tileAnchorsWrapper = css`
  position: relative;
`;

export const tile = homepage => css`
  display: block;
  text-decoration: none;
  max-width: ${homepage ? '100%' : '418px'};
  background: white;
`;

export const tileWrapper = css`
  flex-basis: 100%;
  position: relative;
  padding: 8px;
  overflow: hidden;
`;

export const contentTitle = css`
  font-size: ${fontSizeBody};
  color: ${charcoal};
  padding-right: 16px;
`;

export const tileIcons = css`
  margin-top: 20px;
  flex-wrap: nowrap;

  svg {
    max-width: 24px;
    max-height: 24px;
    height: 24px;
    width: 24px;
  }

  .Icon-caption {
    display: none;
  }
`;

export const featureListContainer = css`
  position: relative;
  margin-top: 20px;
`;

export const footer = css`
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
  padding-right: 16px;
`;

export const safetyWrapper = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  position: relative;
  max-width: 418px;

  ::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    ${tileBoxShadow}
  }

  ${nonTouch} {
    &:hover::after {
      opacity: 1;
    }
  }
`;

export const productTileFooter = css`
  label: productTileFooter;
  ${footer}
  align-items: center;

  a,
  span {
    color: ${charcoal};
    font-size: ${fontSizeMedium};
    font-weight: ${bold};
    text-decoration: underline;
  }
`;

export const tilePrice = css`
  font-size: ${fontSizeMedium};
  text-decoration: underline;
  color: ${charcoal};
`;

export const tileFooterLinkWrapper = css`
  position: absolute;
  bottom: 14px;
  right: 14px;
`;

export const imagesWrapper = css`
  position: relative;
`;

export const logo = css`
  label: logo;
  height: 37px;
  width: 37px;

  img {
    width: 100%;
  }
`;

export const productText = css`
  font-size: ${fontSizeMedium};
  margin-top: 6px;
`;

export const homePageContent = css`
  text-align: left;
  min-height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const skeletonStars = css`
  width: 100%;

  span {
    display: inline-block;
    width: 215px;
    height: 15px;

    ${skeletonBackground}
  }
`;

export const messageLabel = css`
  z-index: 10;
  position: absolute;
  background-color: rgba(255, 255, 255, 60%);
  backdrop-filter: blur(5px);
  color: ${black};
  border-radius: 13px;
  padding: 2px 8px;
  left: 8px;
  top: 8px;
  font-size: ${fontSizeSmall};
`;

export const greenLabel = css`
  color: ${white};
  background-color: ${green};
`;

export const safetyMargins = css`
  width: 100%;
  padding: 12px;
`;
