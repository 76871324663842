import { css } from '@emotion/react';
import { white } from '@fire/_color';
import { closeButton, zIndex } from '@fire/_const';
import { media } from '@fire/_mediaQueries';

export const modalWrapper = css`
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${zIndex.layerSix};
`;

export const innerModalWrapper = css`
  height: 100%;
  overflow-x: clip;
`;

export const modalFull = css`
  background: ${white};
  min-height: 100%;
  overflow: scroll;
  position: relative;
  width: 100%;
  z-index: ${zIndex.layerSix};
`;

export const modalMedium = css`
  ${modalFull};

  ${media.md} {
    margin: 114px auto;
    max-height: calc(100vh - 228px);
    min-height: auto;
    overflow: scroll;
    width: 745px;
  }
`;

export const modalBig = css`
  ${modalMedium};

  ${media.lg} {
    margin: 114px 10px 10px;
    width: calc(100% - 20px);
  }

  ${media.xl} {
    margin: 114px auto;
    width: 1080px;
  }
`;

export const close = css`
  ${closeButton};
  align-items: center;
  display: flex;
  justify-content: center;
  left: 100%;
  margin-bottom: -44px;
  position: sticky;
  width: 40px;
  z-index: 1001;
`;
