import { graphql, useStaticQuery } from 'gatsby';

export const LoyaltyQueryPlatinum = graphql`
  query LoyaltyQueryPlatinum {
    allLoyalties(filter: { category: { eq: "PLATINUM" } }) {
      ...LoyaltyQuery
    }
    allLoyaltiesRandom(filter: { category: { eq: "PLATINUM" } }) {
      ...LoyaltyRandomQuery
    }
  }
`;

const useLoyaltyPlatinum = props => {
  const random = props?.random;
  const data = useStaticQuery(LoyaltyQueryPlatinum);
  if (random) {
    return data?.allLoyaltiesRandom?.nodes?.[0] ?? {};
  }
  return data?.allLoyalties?.nodes ?? [];
};

export default useLoyaltyPlatinum;
