import axios from 'axios';

import { DATE_FORMAT_ISO8601 } from '@fire/constants';
import { formatDate } from '@utils/dateTime';
import { isEmptyOrNil } from '@utils/generic';
import { objectToQuery, parseURLSearchParams } from '@utils/url';

import { ACCOMMODATIONS_ENDPOINT_WITH_PARAMS } from './constants';

const parseInitialState = ({
  dateFrom,
  dateTo,
  kidsAges,
  totalAdults,
  ...other
}) => ({
  date_from: dateFrom && formatDate(dateFrom, DATE_FORMAT_ISO8601),
  date_to: dateTo && formatDate(dateTo, DATE_FORMAT_ISO8601),
  children: kidsAges || [],
  adults: totalAdults || 2,
  ...other,
});

export const fetchAccommodationList = async params => {
  const cachebreaker = `&cb=${Date.now()}`;
  const response = await axios.get(
    `${ACCOMMODATIONS_ENDPOINT_WITH_PARAMS}${params}&flexible_dates=true&unavailable_dates=true${cachebreaker}`
  );

  return response;
};

const relevantParams = {
  adults: 'adults',
  children: 'children',
  date_from: 'date_from',
  date_to: 'date_to',
  kids_ages: 'children',
  number_of_accommodations: 'number_of_accommodations',
  total_adults: 'adults',
  prn: 'prn',
};

export const pickRelevantParams = params => {
  const parsedParams = parseURLSearchParams(params);

  return Object.keys(relevantParams).reduce((list, param) => {
    if (!isEmptyOrNil(parsedParams[param])) {
      const key = relevantParams[param];
      list[key] = parsedParams[param];
    }

    return list;
  }, {});
};

export const transformBookingDataToParams = data =>
  objectToQuery(parseInitialState(data));
