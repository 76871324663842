import { trackObject } from '@utils/tracking';

export const partnerClickout = ({ prn, pagePath }) => {
  const currentPage = pagePath === '/' ? 'home' : 'profile';

  return trackObject({
    event: `${currentPage}_click-logo-ad`,
    category: currentPage,
    action: 'click logo ad',
    label: prn,
  });
};
