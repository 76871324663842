import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import MaybeData from '@contexts/MaybeData';

import ChecklistItem from './ChecklistItem';
import { PAYMENT_CHECKLIST_PREFIX } from './constants';
import * as ß from './styles';

export const PaymentChecklist = () => {
  const pageContext = useContext(MaybeData);
  const { t } = useTranslation(PAYMENT_CHECKLIST_PREFIX);

  const {
    payment_option_cash,
    payment_option_maestro,
    payment_option_mastercard,
    payment_option_visa,
  } = pageContext?.related?.adac_prices ?? {};

  return (
    <div css={[ß.checklistPayment, ß.wrapper]}>
      <h3>{t('Zahlungsmöglichkeiten')}</h3>
      <ul>
        {payment_option_cash && (
          <ChecklistItem>{t('Barzahlung')}</ChecklistItem>
        )}

        {payment_option_maestro && (
          <ChecklistItem>{t('Maestro')}</ChecklistItem>
        )}
        {payment_option_mastercard && (
          <ChecklistItem>{t('Mastercard')}</ChecklistItem>
        )}
        {payment_option_visa && <ChecklistItem>{t('Visa')}</ChecklistItem>}
      </ul>
      {!payment_option_cash && (
        <ul>
          <li>
            <ChecklistItem showIcon={false}>{t('no money')}</ChecklistItem>
          </li>
        </ul>
      )}
    </div>
  );
};

export default PaymentChecklist;
