const allCountries = {
  AL: {
    pathDE: 'albanien',
    pathITCH: 'albania',
    name: 'Albanien',
    pathFRCH: 'albanie',
  },
  AD: {
    pathDE: 'andorra',
    pathITCH: 'andorra',
    name: 'Andorra',
    pathFRCH: 'andorre',
  },
  BE: {
    pathDE: 'belgien',
    pathITCH: 'belgio',
    name: 'Belgien',
    pathFRCH: 'belgique',
  },
  BA: {
    pathDE: 'bosnien-und-herzegowina',
    pathITCH: 'bosnia-erzegovina',
    name: 'Bosnien und Herzegowina',
    pathFRCH: 'bosnie-herzegovine',
  },
  BG: {
    pathDE: 'bulgarien',
    pathITCH: 'bulgaria',
    name: 'Bulgarien',
    pathFRCH: 'bulgarie',
  },
  DK: {
    pathDE: 'daenemark',
    pathITCH: 'danimarca',
    name: 'Dänemark',
    pathFRCH: 'danemark',
  },
  DE: {
    pathDE: 'deutschland',
    pathITCH: 'germania',
    name: 'Deutschland',
    pathFRCH: 'allemagne',
  },
  EE: {
    pathDE: 'estland',
    pathITCH: 'estonia',
    name: 'Estland',
    pathFRCH: 'estonie',
  },
  FR: {
    pathDE: 'frankreich',
    pathITCH: 'francia',
    name: 'Frankreich',
    pathFRCH: 'france',
  },
  FI: {
    pathDE: 'finnland',
    pathITCH: 'finlandia',
    name: 'Finnland',
    pathFRCH: 'finlande',
  },
  GR: {
    pathDE: 'griechenland',
    pathITCH: 'grecia',
    name: 'Griechenland',
    pathFRCH: 'grece',
  },
  GB: {
    pathDE: 'grossbritannien',
    pathITCH: 'gran-bretagna',
    name: 'Großbritannien',
    pathFRCH: 'grande-bretagne',
  },
  IE: {
    pathDE: 'irland',
    pathITCH: 'irlanda',
    name: 'Irland',
    pathFRCH: 'irlande',
  },
  IT: {
    pathDE: 'italien',
    pathITCH: 'italia',
    name: 'Italien',
    pathFRCH: 'italie',
  },
  HR: {
    pathDE: 'kroatien',
    pathITCH: 'croazia',
    name: 'Kroatien',
    pathFRCH: 'croatie',
  },
  LV: {
    pathDE: 'lettland',
    pathITCH: 'lettonia',
    name: 'Lettland',
    pathFRCH: 'lettonie',
  },
  LI: {
    pathDE: 'liechtenstein',
    pathITCH: 'liechtenstein',
    name: 'Liechtenstein',
    pathFRCH: 'liechtenstein',
  },
  LT: {
    pathDE: 'litauen',
    pathITCH: 'lituania',
    name: 'Litauen',
    pathFRCH: 'lituanie',
  },
  LU: {
    pathDE: 'luxemburg',
    pathITCH: 'lussemburgo',
    name: 'Luxemburg',
    pathFRCH: 'luxembourg',
  },
  MT: {
    pathDE: 'malta',
    pathITCH: 'malta',
    name: 'Malta',
    pathFRCH: 'malte',
  },
  MK: {
    pathDE: 'mazedonien',
    pathITCH: 'macedonia-del-nord',
    name: 'Mazedonien',
    pathFRCH: 'macedoine-du-nord',
  },
  ME: {
    pathDE: 'montenegro',
    pathITCH: 'montenegro',
    name: 'Montenegro',
    pathFRCH: 'montenegro',
  },
  NL: {
    pathDE: 'niederlande',
    pathITCH: 'paesi-bassi',
    name: 'Niederlande',
    pathFRCH: 'pays-bas',
  },
  NO: {
    pathDE: 'norwegen',
    pathITCH: 'norvegia',
    name: 'Norwegen',
    pathFRCH: 'norvege',
  },
  AT: {
    pathDE: 'oesterreich',
    pathITCH: 'austria',
    name: 'Österreich',
    pathFRCH: 'autriche',
  },
  PL: {
    pathDE: 'polen',
    pathITCH: 'polonia',
    name: 'Polen',
    pathFRCH: 'pologne',
  },
  PT: {
    pathDE: 'portugal',
    pathITCH: 'portogallo',
    name: 'Portugal',
    pathFRCH: 'portugal',
  },
  RO: {
    pathDE: 'rumaenien',
    pathITCH: 'romania',
    name: 'Rumänien',
    pathFRCH: 'roumanie',
  },
  RU: {
    pathDE: 'russland',
    pathITCH: 'russia',
    name: 'Russland',
    pathFRCH: 'russie',
  },
  SM: {
    pathDE: 'san-marino',
    pathITCH: 'san-marino',
    name: 'San Marino',
    pathFRCH: 'saint-marin',
  },
  SE: {
    pathDE: 'schweden',
    pathITCH: 'svezia',
    name: 'Schweden',
    pathFRCH: 'suede',
  },
  CH: {
    pathDE: 'schweiz',
    pathITCH: 'svizzera',
    name: 'Schweiz',
    pathFRCH: 'suisse',
  },
  RS: {
    pathDE: 'serbien',
    pathITCH: 'serbia',
    name: 'Serbien',
    pathFRCH: 'serbie',
  },
  SK: {
    pathDE: 'slowakei',
    pathITCH: 'slovacchia',
    name: 'Slowakei',
    pathFRCH: 'slovaquie',
  },
  SI: {
    pathDE: 'slowenien',
    pathITCH: 'slovenia',
    name: 'Slowenien',
    pathFRCH: 'slovenie',
  },
  ES: {
    pathDE: 'spanien',
    pathITCH: 'spagna',
    name: 'Spanien',
    pathFRCH: 'espagne',
  },
  CZ: {
    pathDE: 'tschechien',
    pathITCH: 'repubblica-ceca',
    name: 'Tschechien',
    pathFRCH: 'republique-tcheque',
  },
  TR: {
    pathDE: 'tuerkei',
    pathITCH: 'turchia',
    name: 'Türkei',
    pathFRCH: 'turquie',
  },
  HU: {
    pathDE: 'ungarn',
    pathITCH: 'ungheria',
    name: 'Ungarn',
    pathFRCH: 'hongrie',
  },
};

export default allCountries;
