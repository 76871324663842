import React from 'react';
import PropTypes from 'prop-types';
import { withPrefix } from 'gatsby';

import Link from '@components/Link';

import * as ß from './styles';

const BrandWrapper = ({ isHyperLink, children }) =>
  isHyperLink ? (
    <Link to="/" title="PiNCAMP">
      {children}
    </Link>
  ) : (
    <div>{children}</div>
  );

BrandWrapper.propTypes = {
  children: PropTypes.node,
  isHyperLink: PropTypes.bool,
};

const logo = withPrefix('/icons/logo-pincamp.svg');

export const Brand = ({ isHyperLink }) =>
  logo && (
    <BrandWrapper isHyperLink={isHyperLink}>
      <img css={ß.brand} alt="PiNCAMP" src={logo} />
    </BrandWrapper>
  );

Brand.defaultProps = {
  isHyperLink: true,
};

Brand.propTypes = {
  children: PropTypes.node,
  isHyperLink: PropTypes.bool,
};

export default Brand;
