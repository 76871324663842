import { clone } from 'ramda';

import { isEmptyOrNil } from '@fire/utils/generic';

import { debugTrack } from './debugger';
import { cleanUpObject, cropArray, submit, trackObject } from './index';

export const processProductTile = obj => {
  if (isEmptyOrNil(obj)) {
    return {};
  }
  const clonedObj = clone(obj);
  const cleanResult = cleanUpObject({
    track: 'product',
    id: clonedObj?.prn,
    dimension5: clonedObj?.prn,
    name: clonedObj?.name,
    group: [clonedObj?.plan],
    brutto: clonedObj.adac_prices?.comparison_price,
    brand: clonedObj?.country_code,
    quantity: 1,
    variant: 'campsite',
    currencyCode: clonedObj?.adac_prices?.currency || 'EUR',
  });
  return cleanResult;
};

const prepareToTrackImpression = (arr, list) =>
  cropArray(clone(arr)).map((product, index) => ({
    type: 'productlist',
    position: index + 1,
    list,
    ...processProductTile(product),
  }));

const impression =
  (label = '', list = '') =>
  arr =>
  () => {
    const { group, groupEnd } = debugTrack();
    group(`tracking product tile impressions - ${label} - ${list}`);
    const objectsToTrack = prepareToTrackImpression(arr, list);
    objectsToTrack.forEach(obj => trackObject(obj));
    trackObject({
      track: 'productlist',
      listname: list,
    });
    trackObject({
      track: 'event',
      group: list,
      name: 'view product list',
      value: label,
      noninteraction: false,
    });
    submit();
    groupEnd(`tracking product tile impressions - ${label} - ${list}`);
  };

const click =
  (label = '', list = '') =>
  (obj, index = 0) =>
  () => {
    const { group, groupEnd } = debugTrack();
    group(`tracking product tile click - ${label} - ${list}`);
    trackObject({
      type: 'productlistclick',
      position: index + 1,
      list,
      ...processProductTile(obj),
    });
    trackObject({
      track: 'productlistclick',
      listname: list,
    });
    trackObject({
      track: 'event',
      group: list,
      name: 'click product list',
      value: label,
      noninteraction: false,
    });
    submit();
    groupEnd(`tracking product tile click - ${label} - ${list}`);
  };

const purchase =
  (label, list = '') =>
  // add overwrite things as 3rd param when needed and with fallback
  (obj, index = 0, options = {}) =>
  () => {
    const { group, groupEnd } = debugTrack();
    group(`tracking product tile purchase click - ${list}`);
    const processedTile = processProductTile(obj);
    const { affiliation = 'clickout' } = options;
    trackObject({
      type: 'order',
      position: index + 1,
      list,
      ...processedTile,
    });
    trackObject({
      track: 'order',
      affiliation,
      orderid: `${Date.now()}-${processedTile?.id}`,
    });
    trackObject({
      track: 'event',
      group: 'order',
      name: 'clickout',
      value: label,
      noninteraction: false,
    });
    submit();
    groupEnd(`tracking product tile purchase click - ${list}`);
  };

const singleItemView =
  (label = '', list = '') =>
  singleItem =>
  () => {
    const { group, groupEnd } = debugTrack();
    group(`tracking product view impressions - ${label} - ${list}`);
    trackObject({
      type: 'productview',
      ...processProductTile(singleItem),
    });
    trackObject({
      track: 'productview',
    });
    submit();
    groupEnd(`tracking product view impressions - ${label} - ${list}`);
  };

export const prepareToTrackProduct = (label = '', list = '') => ({
  productTileImpressions: impression(label, list),
  productTileClick: click(label, list),
  productTilePurchaseClick: purchase(label, list),
  singleProductView: singleItemView(label, list),
});
