import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';

import { T } from '@fire/i18n/i18n-constants';

const namespace = pathOr('', ['Tile'], T);

export const Rating = ({ rating }) => {
  const { t } = useTranslation(namespace);

  const getRatingText = () => {
    switch (true) {
      case rating > 4.8:
        return t('Außergewöhnlich');
      case rating > 4.5:
        return t('Hervorragend');
      case rating > 4.3:
        return t('Fabelhaft');
      case rating > 4:
        return t('Sehr gut');
      case rating > 3.4:
        return t('Gut');
      default:
        return;
    }
  };

  return <>{getRatingText()}</>;
};

Rating.propTypes = {
  rating: PropTypes.number,
};
