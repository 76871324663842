import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';

import TileCampsite from '@components/Tile/Campsite';
import TileHighlightContext from '@contexts/TileHighlight';
import { MaybeData } from '@fire/contexts';
import { useDimensions } from '@hooks/useDimensions';
import useTracking from '@hooks/useTracking';
import { checkIsMobile } from '@utils/device';

import SpecialAdsListPage from '../BannerAd/SpecialAdsListPage';
import GridTile from './GridTile';
import { getCategory, getLabel } from './tracking';

const ListTiles = ({
  bookingParams,
  dateFrom,
  dateTo,
  isLoading,
  tiles,
  adPosition,
  showLinkoutCTA,
}) => {
  const pageContext = useContext(MaybeData);
  const { setHighlightedTile } = useContext(TileHighlightContext);
  const { width } = useDimensions();
  const metaDescription = pageContext?.metaDescription;
  const trackingLabel = getLabel(metaDescription);
  const trackingList = getCategory(metaDescription);
  const { prepareToTrackProduct } = useTracking(trackingLabel, trackingList);
  const {
    prepareToTrackEvent: { clickEvent: tileClickEvent },
  } = useTracking('tile click');

  const isMobile = checkIsMobile(width);
  const AD_INDEX = 4;
  const getTileProps = (tile, index) => ({
    ...tile,
    bookingParams,
    clickOutObject: prepareToTrackProduct.productTileClick(tile, index),
    dateFrom,
    dateTo,
    hasUrlPrefix: true,
    imageLoading: 'lazy',
    isExternalUrl: false,
    isLoading,
    key: `id-${tile?.prn}`,
    openInNewWindow: !isMobile,
    // onBookClick can send 'category'
    onBookClick: () =>
      prepareToTrackProduct.productTilePurchaseClick(tile, index),
    onCtaClick: prepareToTrackProduct.productTileClick(tile, index),
    onMouseEnter: () => setHighlightedTile(tile.prn),
    onMouseLeave: () => setHighlightedTile(''),
    showLinkoutCTA,
    onClick: tileClickEvent(tile.prn),
  });

  return tiles.map((tile, index) => (
    <>
      {index === AD_INDEX && (
        <GridTile index={index} key="tile-ad">
          <SpecialAdsListPage position={adPosition} />
        </GridTile>
      )}
      <GridTile index={index} key={tile?.prn}>
        <TileCampsite {...getTileProps(tile, index)} />
      </GridTile>
    </>
  ));
};

ListTiles.propTypes = {
  ad: PropTypes.object,
  bookingParams: PropTypes.string,
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  getTrackData: PropTypes.func,
  isLoading: PropTypes.bool,
  tiles: PropTypes.array,
  adPosition: PropTypes.number,
  showLinkoutCTA: PropTypes.bool,
};

ListTiles.defaultProps = {
  showLinkoutCTA: true,
  isLoading: true,
  adPosition: 0,
};

export default memo(ListTiles);
