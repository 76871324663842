import { formatDate } from '@utils/dateTime';
import { trackObject } from '@utils/tracking';

export const trackClickFlexibleDate = (dateFrom, dateTo) =>
  trackObject({
    event: 'list_click-flexible-dates',
    category: 'profile',
    action: 'click flexible dates',
    label: `${formatDate(dateFrom)}-${formatDate(dateTo)}`,
  });
