import React, { useContext, useReducer, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { InputForwardedRef } from '@components/Input';
import Link from '@components/Link';
import { MaybeData } from '@fire/contexts';
import useTracking from '@hooks/useTracking';
import { getDataProtectionEmail, getDataProtectionUrl } from '@utils/platform';

import * as ß from './styles';
import SuccessMessage from './SuccessMessage';
import {
  handleInput,
  handleInvalid,
  INITIAL_STATE,
  PREFIX,
  reducer,
  registerForNewsletter,
} from './utils';

const NewsletterForm = ({ children, source }) => {
  const {
    prepareToTrackEvent: { clickEvent },
  } = useTracking(`${source} newsletter`);
  const pageContext = useContext(MaybeData);
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const inputRef = useRef();
  const { t } = useTranslation(PREFIX);

  const locale = pageContext.language;
  const dataProtectionEmail = getDataProtectionEmail(locale);
  const dataProtectionUrl = getDataProtectionUrl(locale);
  const invalidFieldMessage = t('invalid-field');

  const trackClickSignup = clickEvent('click newsletter signup');
  const trackSuccess = clickEvent('successful registration');

  const resetState = () => dispatch({ type: 'reset' });

  const handleSubmit = async event => {
    event.preventDefault();
    resetState();
    trackClickSignup();

    const email = inputRef?.current?.value?.trim();

    if (!email) {
      dispatch({ type: 'error' });

      return;
    }

    const response = await registerForNewsletter({ email, source });

    dispatch({
      payload: response?.code,
      type: response?.status,
    });
  };

  if (state?.showMessage) {
    trackSuccess();
    return <SuccessMessage />;
  }

  return (
    <div css={ß.newsletterForm}>
      {children}
      <form onSubmit={handleSubmit}>
        <InputForwardedRef
          id="newsletter-form"
          label={t('E-Mail-Adresse')}
          name="newsletter-form"
          onInput={handleInput(resetState)}
          onInvalid={handleInvalid(invalidFieldMessage)}
          pattern=".+@[a-z0-9.-]+\.[a-z]{2,}$"
          ref={inputRef}
          type="email"
        />
        {state?.hasError && (
          <div css={ß.errorMessage}>
            {t([state?.errorMessage ?? 'invalid-field', 'unknown-error'])}
          </div>
        )}
        <button type="submit">{t('Senden')}</button>
      </form>
      <Trans t={t} i18nKey="legal-text-b">
        <p>
          Der optionale Newsletter-Versand und die Verwendung deiner
          E-Mail-Adresse erfolgt entsprechend der{' '}
          <Link to={dataProtectionUrl} openInNewWindow>
            Datenschutzerklärung
          </Link>{' '}
          von PiNCAMP zur Bewerbung der dort beschriebenen Produkte und
          Dienstleistungen von PiNCAMP, Unternehmen der ADAC Gruppe und
          Werbepartnern. Deine Einwilligung in den Versand kannst du jederzeit
          via{' '}
          <a href={`mailto:${dataProtectionEmail}`}>datenschutz@pincamp.de</a>{' '}
          widerrufen.
        </p>
      </Trans>
    </div>
  );
};

export default NewsletterForm;

NewsletterForm.propTypes = {
  children: PropTypes.node,
  source: PropTypes.string,
};
