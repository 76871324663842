import { css } from '@emotion/react';
import { adGrey, white } from '@fire/_color';
import { zIndex } from '@fire/_const';

export const ad = css`
  background: ${adGrey};
  z-index: ${zIndex.layerTwo};
  padding: 2px 4px;
  position: absolute;
  top: 12px;
  left: 0;
  color: ${white};
  font-size: 12px;
`;
