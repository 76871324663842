import { css } from '@emotion/react';
import { white } from '@fire/_color';
import { media } from '@fire/_mediaQueries';

export const wrapper = css`
  position: relative;
  margin-top: 12px;
`;

export const rightArrow = css`
  button {
    right: 9px;
  }
`;

export const leftArrow = css`
  button {
    left: 9px;
  }
`;

const arrowPositions = {
  center: 'calc(50% - 20px)',
  top: '120px',
  drilldown: 'calc(50% - 2px)',
};

export const arrow = position => css`
  border: 0;
  left: -9px;
  top: ${arrowPositions[position]};
  position: absolute;
  z-index: 3;
  width: 40px;
  height: 40px;
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: ${white};
  border-radius: 100%;
  display: none;
  box-shadow: -2px 0 2px 0 rgba(0, 0, 0, 0.2);

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  img {
    transform: scale(1.3) rotate(90deg);
  }

  @media (hover: hover) {
    display: flex;
  }
`;

export const arrowNext = css`
  left: auto;
  right: 0;
  transform: rotate(180deg);
`;

export const scroller = (amount, minMax) => css`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(${amount}, ${minMax || '90%'});
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  ${media.xs} {
    grid-template-columns: repeat(${amount}, ${minMax || '40%'});
  }

  ${media.xl} {
    grid-template-columns: repeat(${amount}, ${minMax || '350px'});
  }

  > div {
    scroll-snap-align: start;
  }
`;

export const scrollerFlex = css`
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;

  > div {
    scroll-snap-align: start;
  }
`;

export const child = css`
  margin: auto;
`;

export const mobileCarousel = css`
  display: block;

  ${media.lg} {
    display: none;
  }
`;

export const desktopCarousel = css`
  display: none;

  ${media.lg} {
    display: block;
  }
`;
