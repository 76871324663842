import { css } from '@emotion/react';
import { gray8 } from '@fire/_color';
import { media } from '@fire/_mediaQueries';

export const loading = css`
  align-items: center;
  display: flex;
  height: 50vh;
  justify-content: center;
`;

export const title = css`
  font-size: 28px;
  margin-bottom: 16px;
`;

export const subtitle = css`
  font-size: 22px;
  margin-bottom: 16px;
`;

export const confirm = css`
  font-size: 20px;
  margin-top: 16px;
`;

export const buttons = css`
  display: flex;
  justify-content: space-between;
  margin: 12px 0;

  ${media.md} {
    width: 380px;
  }

  span {
    width: 100%;
  }

  a {
    text-transform: uppercase;
    width: 100%;

    :last-of-type {
      margin-left: 12px;
    }
  }
`;

export const details = css`
  margin: 32px 0;

  & > div {
    margin-bottom: 24px;
  }

  h4 {
    color: ${gray8};
    margin: 0 0 8px;
    text-transform: uppercase;
  }
`;

export const feedback = css`
  margin-bottom: 16px;
`;
