import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import * as ß from './styles';
import { PREFIX } from './utils';

const SuccessMessage = () => {
  const { t } = useTranslation(PREFIX);

  return (
    <Trans t={t} i18nKey="success-message">
      <p css={ß.newsletterP}>
        Toll, vielen Dank. Wir haben dir zur Bestätigung eine E-Mail geschickt.
        <br />
        Klicke auf den Button in der E-Mail, um deine Anmeldung abzuschließen.
      </p>
    </Trans>
  );
};

export default SuccessMessage;
