import React from 'react';
import PropTypes from 'prop-types';

import Link from '@components/Link';
import { IMGIX_URL } from '@fire/constants';

import 'lazysizes';
import * as ß from './styles';

const SquareTile = ({ image, title, url, onClickable }) => (
  <Link to={url} css={ß.squareImageWrapper} onClick={onClickable}>
    <img
      alt={title}
      css={ß.squareImage}
      data-src={`${IMGIX_URL}/${image.name}.jpg?handle=${image.handle}&min-w=350&min-h=350&max-w=500&max-h=500&auto=compress,format&fp-x=0.74&fp-y=0.5&fit=crop`}
      className="lazyload"
    />
    <h3 css={ß.squareHeading}>{title}</h3>
  </Link>
);

SquareTile.propTypes = {
  translationPrefix: PropTypes.string,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  onClickable: PropTypes.func,
  image: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    handle: PropTypes.string,
  }).isRequired,
};

export default SquareTile;
