import { gql } from '@apollo/client';

export const query = gql`
  query LandingPage($locale: Locale!, $where: LandingPageSearchFieldInput!) {
    landingPage(locale: $locale, where: $where) {
      smallAndCharmingCampsites {
        prn
        path
        name
        images(limit: 1) {
          filename
          path
          altText
          isMain
          position
        }
        rating {
          average
          count
        }
        classification {
          adac
          tcs
        }
        ctaLink
        plan
      }
    }
  }
`;
