import axios from 'axios';

import {
  ENDPOINT_CAMPSITE_ACCEPT,
  ENDPOINT_CAMPSITE_DETAILS,
  ENDPOINT_CAMPSITE_REFUSE,
  ENDPOINT_GUEST_ACCEPT,
  ENDPOINT_GUEST_RESOLVE,
} from './constants';

export const parseData = data => ({
  campsiteName: data?.campsite_name,
  campsiteUrl: data?.campsite_url,
  checkIn: data?.date_from,
  checkOut: data?.date_to,
  city: data?.guest_city,
  email: data?.guest_email,
  expiration: data?.expiry,
  guests: {
    adults: data?.adults,
    dogs: data?.dogs,
    kids: data?.children?.length,
    kidsAges: data?.children,
  },
  message: data?.guest_message,
  firstName: data?.guest_firstname,
  lastName: data?.guest_lastname,
  nights: data?.number_nights,
  type: data?.guest_request_type,
  vehicleSize: data?.guest_vehicle_size,
});

export const campsiteConfirmRequest = ({ requestId, sessionId }) =>
  axios
    .get(
      `${ENDPOINT_CAMPSITE_ACCEPT}?request_id=${requestId}&session_id=${sessionId}`
    )
    .then(({ data }) => ({
      data: parseData(data),
      isError: false,
    }))
    .catch(() => ({ isError: true }));

export const campsiteRefuseRequest = ({ requestId, sessionId }) =>
  axios
    .get(
      `${ENDPOINT_CAMPSITE_REFUSE}?request_id=${requestId}&session_id=${sessionId}`
    )
    .then(({ data }) => ({
      data: parseData(data),
      isError: false,
    }))
    .catch(() => ({ isError: true }));

export const fetchDetails = ({ requestId, sessionId }) =>
  axios
    .get(
      `${ENDPOINT_CAMPSITE_DETAILS}/?request_id=${requestId}&session_id=${sessionId}`
    )
    .then(({ data }) => ({
      data: parseData(data),
      isError: false,
    }))
    .catch(() => ({
      isError: true,
    }));

export const guestConfirmRequest = ({ requestId, sessionId }) =>
  axios
    .get(
      `${ENDPOINT_GUEST_ACCEPT}?request_id=${requestId}&session_id=${sessionId}`
    )
    .then(({ data }) => ({
      data: parseData(data),
      isError: false,
    }))
    .catch(() => ({ isError: true }));

export const guestResolveRequest = ({ requestId, sessionId }) =>
  axios
    .get(
      `${ENDPOINT_GUEST_RESOLVE}?request_id=${requestId}&session_id=${sessionId}`
    )
    .then(({ data }) => ({
      data: parseData(data),
      isError: false,
    }))
    .catch(() => ({ isError: true }));

export const getMethod = ({ isAccept, isGuest }) => {
  if (isGuest) {
    return isAccept ? guestConfirmRequest : guestResolveRequest;
  }

  return isAccept ? campsiteConfirmRequest : campsiteRefuseRequest;
};
