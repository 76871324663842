import React from 'react';
import { withPrefix } from 'gatsby';

import { GATSBY_ENTRYPOINT } from '@fire/constants';
import { noQuestionMarkObjectToQuery, objectToQuery } from '@utils/url';

import Placeholder from './Placeholder';
import * as ß from './styles';

export const cropConfig = {
  w: 360,
  auto: 'compress,format',
  crop: 'edges',
  fit: 'crop',
};
export const blurhash = {
  blur: 350,
  q: 20,
};

export const IMAGE_PLACEHOLDER = () =>
  `${GATSBY_ENTRYPOINT}/i/placeholder-image.jpg?handle=KXy7SucqTjGP8gSNZEPR&${objectToQuery(
    cropConfig
  )}`;

const FORBIDDEN_HOSTS = ['sharetribe'];

const shouldOptimize = url => {
  const { length: forbiddenHosts = 0 } = FORBIDDEN_HOSTS.filter(
    host => !url.includes(host)
  );

  return forbiddenHosts > 0;
};

const getSizes = images => index => {
  const maxWidth = 320;
  const maxHeight = 213;
  const { width, height } = images[index]?.crop || {
    width: maxWidth,
    height: maxHeight,
  };
  const ratio = Math.min(maxWidth / width, maxHeight / height);

  return { width: width * ratio, height: height * ratio };
};

const isFirst = index => index === 0;

const getDivider = url => (url.includes('?') ? '&' : '?');

const getDataSrc = (index, loading, imageSrc, disableQueryString = false) => {
  // "optimization" only happens to in house images, everything else we bypass
  const hasOptimization = shouldOptimize(imageSrc);

  const divider = hasOptimization ? getDivider(imageSrc) : '';
  const cropQueryString = hasOptimization
    ? noQuestionMarkObjectToQuery(cropConfig)
    : '';
  const cropWithBlurhash = hasOptimization
    ? noQuestionMarkObjectToQuery({ ...cropConfig, ...blurhash })
    : '';

  return {
    'data-src': `${imageSrc}${divider}${
      disableQueryString ? '' : cropQueryString
    }`,
    src: `${imageSrc}${divider}${disableQueryString ? '' : cropWithBlurhash}`,
  };
};

export const getImageOrPlaceholder = (
  hasPrefixImage,
  hasImages,
  index,
  loading,
  images,
  siteUrl,
  urlPrefix,
  name,
  disableQueryString = false
) => {
  if (hasImages) {
    const imgSrc = hasPrefixImage
      ? `${withPrefix(images[index])}`
      : `${urlPrefix}${images[index]}`;

    const dataSrc = getDataSrc(index, loading, imgSrc, disableQueryString);
    const getSize = getSizes(images);
    const loadingType = isFirst(index) ? loading : 'lazy';

    return (
      <img
        alt={name ?? 'Slideshow image'}
        key={images[index]}
        css={[ß.image(), ß.lazyImage]}
        className="lazyload"
        data-parent-fit="cover"
        {...getSize(index)}
        {...dataSrc}
        loading={loadingType} // force lazy if not first image
      />
    );
  }
  return <Placeholder siteUrl={siteUrl} loading={loading} />;
};
