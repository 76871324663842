import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withPrefix } from 'gatsby';

const data = {
  arrow: withPrefix('/icons/arrowDark.svg'),
  close: withPrefix('/icons/close.svg'),
  locationPin: withPrefix('/icons/location.svg'),
};

const Icon = ({ iconName }) => <img src={data[iconName]} alt={iconName} />;

Icon.propTypes = {
  iconName: PropTypes.string.isRequired,
};

const MemoizedIcon = memo(Icon);

export const Arrow = () => <MemoizedIcon iconName="arrow" />;
export const X = () => <MemoizedIcon iconName="close" />;
export const LocationPin = () => <MemoizedIcon iconName="locationPin" />;

export default Icon;
