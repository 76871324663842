import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import { pipe, prop, reject, toPairs } from 'ramda';

import InterLinking from '@components/InterLinking';
import { T } from '@fire/i18n/i18n-constants';
import { isEmptyOrNil, mapIndexed } from '@utils/generic';

import { interlinkSections } from './utils';

const prefix = T?.DestinationPage ?? '';

const Interlinks = memo(({ interlinking }) =>
  pipe(
    toPairs,
    mapIndexed((sectionArr, index) => {
      const section = prop(0, sectionArr);
      const group = prop(section, interlinking);

      const isTopicsHidden =
        section === 'interlinkingTopTopics' &&
        !interlinking.interlinkingTopRegions?.length;

      return isTopicsHidden ? (
        <Fragment key={index}></Fragment>
      ) : (
        <InterLinking
          key={index}
          group={group}
          title={interlinkSections[section]}
          prefix={prefix}
        />
      );
    }),
    reject(isEmptyOrNil)
  )(interlinkSections)
);

Interlinks.displayName = 'Interlinks';

Interlinks.propTypes = {
  interlinking: PropTypes.object,
};

export default Interlinks;
