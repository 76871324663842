import { graphql } from 'gatsby';

import useLoyaltyAll from './all';
import useLoyaltyGold from './gold';
import useLoyaltyPlatinum from './platinum';
import useLoyaltySilver from './silver';

export const LoyaltyQueryFragment = graphql`
  fragment LoyaltyQuery on LoyaltiesConnection {
    nodes {
      category
      logo
      name
      prn
      url
      rating
      userRating
      userRatingCount
      tcsRating
      region
      images {
        campsite
        img
        url
        altText
      }
    }
  }
`;

export const LoyaltyRandomQueryFragment = graphql`
  fragment LoyaltyRandomQuery on LoyaltiesRandomConnection {
    nodes {
      category
      logo
      name
      prn
      url
      rating
      userRating
      userRatingCount
      region
      images {
        campsite
        img
        url
        altText
      }
    }
  }
`;

export { useLoyaltyAll, useLoyaltyGold, useLoyaltyPlatinum, useLoyaltySilver };
export default useLoyaltyAll;
