import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { navigate } from 'gatsby';
import { ap, equals, pathOr, pipe, propOr } from 'ramda';

import Link from '@components/Link';
import FormDataContext from '@contexts/FormData';
import MaybeData from '@contexts/MaybeData';
import { T } from '@fire/i18n/i18n-constants';
import { box } from '@utils/generic';
import { localStorage } from '@utils/local-storage';
import { reviewPrefixLocized } from '@utils/platform';

import * as ß from './styles';
import { trackVerifyReview } from './tracking';

const prefix = pathOr('', ['ReviewPage'], T);

const Step3 = () => {
  const pageContext = useContext(MaybeData);
  const {
    state: { campsiteData, localStorageKey, validValue, duplicateEntry },
    dispatch,
  } = useContext(FormDataContext);
  const [trackingDone, setTrackingDone] = useState(false);
  const isDuplicate = equals('true', duplicateEntry);
  const isInvalid = equals('false', validValue);

  const [campsitePath, campsiteName, campsitePrn] = pipe(
    box,
    ap([propOr('/', 'path'), propOr('', 'name'), propOr('', 'prn')])
  )(campsiteData);

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    localStorage.removeItem(localStorageKey);
  }, [localStorageKey]);

  useEffect(() => {
    if (!trackingDone && !isInvalid && !isDuplicate) {
      trackVerifyReview({ pageContext, campsiteData });
      setTrackingDone(true);
    }
  }, [
    campsiteData,
    isDuplicate,
    isInvalid,
    pageContext,
    setTrackingDone,
    trackingDone,
  ]);

  const restartReviewCallback = e => {
    if (e) e.preventDefault();
    dispatch({ type: 'setStep', payload: 1 });
    navigate(`${reviewPrefixLocized(pageContext)}?prn=${campsitePrn}`);
  };
  const { t } = useTranslation(prefix);

  /* WARNING! Space, newline and tab sensitive translations ahead */
  /* Don't change anything except you're sure that the translaitons work! */
  /* eslint-disable prettier/prettier */
  /* prettier-ignore */

  if (isDuplicate) {
    return (
      <div css={ß.contentWidth}>
        <Trans t={t} i18nKey="message-already-rated">
          <><h4></h4>
            <p css={ß.step2paragraph}></p>
            <p css={ß.step2paragraph}></p>
            <div css={ß.step3urlContainer}>
              <Link to={`${reviewPrefixLocized(pageContext)}`} />
            </div>
          </>
        </Trans>
      </div>
    )
  }

  if (isInvalid) {
    return (
      <div css={ß.contentWidth}>
        <Trans
          t={t}
          i18nKey={'message-auth-error'}
          values={{ profile: campsiteName }}
        >
          <>
            <h2></h2>
            <p css={ß.step2paragraph}></p>
            <p css={ß.step2paragraph}></p>
            <div css={ß.step3urlContainer}>
              <Link to={campsitePath} />
            </div>
            <p css={ß.step2paragraph}></p>
            <div css={ß.step3urlContainer}>
              <Link
                to={`${reviewPrefixLocized(pageContext)}?prn=${campsitePrn}`}
                onClick={restartReviewCallback}
              />
            </div>
          </>
        </Trans>
      </div>
    );
  }

  return (
    <div css={ß.contentWidth}>
      <Trans t={t} i18nKey={'step-3-form.content'}>
        <h2></h2>
        <p css={ß.step2paragraph}></p>
        <p css={ß.step2paragraph}></p>
        <div css={ß.step3urlContainer}>
          <Link to={`${reviewPrefixLocized(pageContext)}`} />
        </div>
      </Trans>
    </div>
  );
};

export default Step3;
