import React from 'react';
import PropTypes from 'prop-types';

import * as ß from './styles';

const TextArea = ({
  value,
  placeholder,
  type,
  onChange,
  onFocus,
  refProp,
  pattern,
  id,
  hasError,
  ...rest
}) => (
  <div css={ß.wrapper}>
    <textarea
      id={id}
      css={[ß.input, hasError ? ß.error : undefined]}
      // Placeholder is hidden to achieve the floaty label style
      placeholder={placeholder}
      type={type}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      ref={refProp}
      pattern={pattern}
      {...rest}
    />
  </div>
);

TextArea.defaultProps = {
  type: 'text',
};

TextArea.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string,
  pattern: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  id: PropTypes.string.isRequired,
  refProp: PropTypes.func,
  hasError: PropTypes.bool,
};

export default TextArea;
