import { css } from '@emotion/react';
import { blue10 } from '@fire/_color';
import { bold, fontSizeSmall } from '@fire/_fonts';
import { media } from '@fire/_mediaQueries';

export const list = css`
  display: none;
  flex-direction: row;
  font-size: ${fontSizeSmall};
  justify-content: space-between;
  padding: 0;
  width: 100%;

  ${media.md} {
    display: flex;
  }
  ul li a:hover {
    color: ${blue10};
  }
`;

export const item = css`
  margin-bottom: 19px;
  width: 100%;
`;

export const listMobile = css`
  display: block;
  width: 100%;

  ${media.md} {
    display: none;
  }
`;

export const itemTitle = css`
  font-size: 20px;

  a {
    font-weight: ${bold};
    text-decoration: none;
  }

  ${media.md} {
    display: inline-block;
    margin-bottom: 8px;
  }
`;
