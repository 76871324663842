import { css } from '@emotion/react';
import { bold, fontSizeExtraLarge, lineHeightDefault } from '@fire/_fonts';
import { media } from '@fire/_mediaQueries';

export const title = css`
  display: inline;
  font-size: 26px;

  font-weight: ${bold};
  white-space: normal;
  line-height: ${lineHeightDefault};
  ${media.md} {
    font-size: 34px;
  }
`;

export const subTitle = css`
  ${title};

  display: block;

  ${media.md} {
    display: inline-block;
  }
`;

export const seoSubtitle = css`
  font-size: ${fontSizeExtraLarge};
  margin: 20px 0;
`;
