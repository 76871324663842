import React from 'react';
import PropTypes from 'prop-types';

import AdLabel from '@components/AdLabel';
import Link from '@components/Link';
import Slideshow from '@components/Slideshow';
import TileLoading from '@contexts/TileLoading';

import * as ß from '../styles';
import DestinationContent from './DestinationContent';

const TileDestination = props => {
  const {
    images,
    path,
    isAd,
    onClick,
    homepage,
    openInNewWindow,
    isExternalUrl,
    gatsbyFluid,
    hasUrlPrefix,
    item,
    logo,
    ...restProps
  } = props;
  const linkProps = { openInNewWindow, isExternalUrl, to: path };

  return (
    <TileLoading.Provider value={false}>
      <div css={ß.tileAnchorsWrapper}>
        <div css={ß.safetyWrapper}>
          <Link
            css={ß.tile(homepage)}
            onClick={onClick}
            {...linkProps}
            {...(restProps.onMouseEnter
              ? { onMouseEnter: restProps.onMouseEnter }
              : {})}
            {...(restProps.onMouseLeave
              ? { onMouseLeave: restProps.onMouseLeave }
              : {})}
          >
            <div css={ß.imagesWrapper}>
              {isAd && <AdLabel />}
              <Slideshow
                isAd={isAd}
                images={images}
                hasPrefixImage={homepage}
                gatsbyFluid={gatsbyFluid}
                hasUrlPrefix={hasUrlPrefix}
                name={item?.title}
                imageLoading="lazy"
              />
            </div>
            <div css={ß.tileWrapper}>
              <DestinationContent item={item} logo={logo} />
            </div>
          </Link>
        </div>
      </div>
    </TileLoading.Provider>
  );
};

TileDestination.propTypes = {
  images: PropTypes.array,
  path: PropTypes.string,
  isAd: PropTypes.bool,
  onClick: PropTypes.func,
  homepage: PropTypes.bool,
  openInNewWindow: PropTypes.bool,
  isExternalUrl: PropTypes.bool,
  gatsbyFluid: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  hasUrlPrefix: PropTypes.bool,
  item: PropTypes.object,
  logo: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

TileDestination.defaultProps = {
  path: '',
  isAd: false,
  onClick: () => {},
  homepage: false,
  openInNewWindow: false,
  isExternalUrl: false,
  hasUrlPrefix: false,
};

export default React.memo(TileDestination);
