import { createContext } from 'react';
import { always, cond, propEq, T } from 'ramda';

export const FormDataContext = createContext({});

FormDataContext.displayName = 'FormDataContext';

export const reducer = (state, action) =>
  cond([
    [propEq('type', 'setStep'), always({ ...state, step: action.payload })],
    [
      propEq('type', 'setFormData'),
      always({ ...state, formData: action.payload }),
    ],
    [T, always(state)],
  ])(action);

export default FormDataContext;
