import { withPrefix } from 'gatsby';

import { isEmptyOrNil } from '@fire/utils/generic';
import { localStorage } from '@fire/utils/local-storage';
import {
  getLanguageFromTarget,
  locizedSearchPathPrefix,
} from '@fire/utils/platform';

const STORAGE = 'FAVORITES';
const TARGET = process.env.TARGET ?? 'de/de';

/*
 * Storage format:
 * [{ prn: "prn:xpto:campinas" date: "new Date() from when saved" }]
 */

const hasStorage =
  typeof window !== 'undefined' && localStorage.checkLocalStorage();

const getAllFavs = () => {
  if (hasStorage) {
    const allFavs = localStorage.getItem(STORAGE);
    if (!isEmptyOrNil(allFavs)) {
      return JSON.parse(allFavs);
    }
  }
  return [];
};

const findInStorage = prn => () => {
  if (hasStorage) {
    const allFavs = getAllFavs();
    if (!isEmptyOrNil(allFavs)) {
      return allFavs.find(obj => obj.prn === prn);
    }
  }
  return false;
};

const addToStorage = prn => () => {
  if (hasStorage && !findInStorage(prn)()) {
    const allFavs = getAllFavs();
    const favToSave = JSON.stringify([...allFavs, { prn, date: new Date() }]);
    return localStorage.setItem(STORAGE, favToSave);
  }
  return;
};

const removeFromStorage = prn => () => {
  if (hasStorage && findInStorage(prn)()) {
    const allFavs = getAllFavs();
    const filteredFavs = allFavs.filter(obj => obj.prn !== prn);
    return localStorage.setItem(STORAGE, JSON.stringify(filteredFavs));
  }
  return;
};

const getLink = () => {
  const language = getLanguageFromTarget(TARGET);
  const searchPrefix = locizedSearchPathPrefix({ language });
  const allFavs = getAllFavs();
  const prnQuery = allFavs?.map(fav => `${fav.prn}`)?.join(',');
  return `${withPrefix(searchPrefix)}?prn=${prnQuery}`;
};

const hasFavs = () => {
  if (hasStorage) {
    const allFavs = getAllFavs();
    return allFavs.length > 0;
  }
  return false;
};

const useFavorites = prn => ({
  add: addToStorage(prn),
  remove: removeFromStorage(prn),
  existsInStorage: findInStorage(prn),
  hasStorage,
  getLink,
  hasFavs,
});

export default useFavorites;
