import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { merge, pathOr, propOr } from 'ramda';

import Checklist from '@components/Checklist';
import { Footer } from '@components/Footer';
import Header from '@components/HeaderContainer';
import LinkedResources from '@components/LinkedResources';
import SocialMedia, { HrefLang } from '@components/Meta';
import { Accordion, AccordionItem } from '@components/ReviewForm/Accordion';
import ContentAccordion from '@components/ReviewForm/ContentAccordion';
import SearchBar from '@components/SearchBar';
import SEO from '@components/seo';
import MaybeData from '@contexts/MaybeData';
import { SearchSuggestionsProvider } from '@contexts/SearchSuggestions';
import AvailabilityStateProvider from '@fire/contexts/AvailabilityState';
import { GlobalStyles } from '@fire/globalStyles';
import withI18next from '@fire/i18n/withI18next';
import { PageView } from '@utils/tracking';

import * as ß from './styles';

export const ReviewLandingPage = props => {
  const pageContext = merge(
    propOr({}, 'pageContext', props),
    pathOr({}, ['data', 'currentPage', 'context'], props)
  );

  const instructions = pathOr([], ['instructions'], pageContext);
  const prices = pathOr([], ['prices'], pageContext);
  return (
    <>
      <MaybeData.Provider value={pageContext}>
        <AvailabilityStateProvider hasHash={false}>
          <SearchSuggestionsProvider>
            <SEO pageContext={pageContext} />
            <HrefLang />
            <LinkedResources pageContext={pageContext} />
            <GlobalStyles />
            <PageView />
            <Helmet>
              <title>{pageContext.title}</title>
            </Helmet>
            <SocialMedia pageContext={pageContext} />
            <Header minimal hideReview hideCookieBanner />
            <div css={ß.container} id="page-container">
              <h1 css={ß.header}>{pageContext.title}</h1>
              <div css={ß.checkList}>
                <Checklist
                  name={pathOr('', ['instructionsHeadline'], pageContext)}
                  values={instructions}
                  ordered
                />
              </div>
              <div css={ß.searchBarWrapper}>
                <SearchBar />
              </div>

              <div css={ß.explanationWrapper}>
                <p>{pathOr('', ['explanation'], pageContext)}</p>
              </div>

              <div css={ß.checkListwrapper}>
                <Checklist
                  name={pathOr('', ['pricesTitle'], pageContext)}
                  values={prices}
                  cssMod={ß.checkListNoBoldTitle}
                />
              </div>

              <div css={ß.accordionWrapper}>
                <Accordion>
                  {pageContext?.conditionsHeadline &&
                    pageContext?.conditions && (
                      <AccordionItem
                        id={'conditions-headline'}
                        title={pathOr('', ['conditionsHeadline'], pageContext)}
                        body={
                          <ContentAccordion
                            markdown={pathOr('', ['conditions'], pageContext)}
                          />
                        }
                      />
                    )}

                  {pageContext.guidelinesHeadline && pageContext.guidelines && (
                    <AccordionItem
                      id={'guidelines'}
                      title={pathOr('', ['guidelinesHeadline'], pageContext)}
                      body={
                        <ContentAccordion
                          markdown={pathOr('', ['guidelines'], pageContext)}
                        />
                      }
                    />
                  )}
                </Accordion>
              </div>
            </div>
            <Footer pageContext={pageContext} />
          </SearchSuggestionsProvider>
        </AvailabilityStateProvider>
      </MaybeData.Provider>
    </>
  );
};

export const query = graphql`
  query ReviewLandingPageData($prn: String!) {
    currentPage: campfirePage(context: { prn: { eq: $prn } }) {
      path
      component
      context {
        pageType
        graphCMSLocale
        conditions
        conditionsHeadline
        explanation
        guidelines
        guidelinesHeadline
        instructions
        instructionsHeadline
        prices
        pricesTitle
        breadcrumbs
        entity
        metaDescription
        metaRobots
        name
        h1
        seoText
        siteUrl
        title
        partners {
          clickoutUrl
          id
          logo {
            altText
            alt_text
            url
            title
            campsite
            copyright
            fileName
            handle
            isMain
            name
            position
          }
          trackingId
        }
      }
    }
    locize: allLocale(
      filter: {
        namespace: {
          in: [
            "pt-map-country"
            "pt-hero-menu"
            "pt-footer"
            "pt-premium-partners-footer"
            "pt-signup-newsletter"
            "pt-language-selector"
            "pt-review-banner"
            "pt-cookie-banner"
            "pt-search-box"
            "pt-suggestion-list"
            "pt-review-landing-page"
            "pt-review-landing-page-search-box"
          ]
        }
      }
    ) {
      edges {
        node {
          data
          id
          language
          namespace
        }
      }
    }
  }
`;

export default withI18next()(ReviewLandingPage);
