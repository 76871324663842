import { css } from '@emotion/react';
import { blue9, white } from '@fire/_color';
import { bold } from '@fire/_fonts';
import { media } from '@fire/_mediaQueries';

export const reviewBanner = css`
  background-color: ${blue9};
  height: 57px;
  width: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  position: relative;

  ${media.lg} {
    display: flex;
  }
`;
export const text = css`
  display: flex;
  justify-content: center;
  align-items: center;

  h3 {
    color: ${white};
    font-size: 22px;
  }

  a {
    color: ${white};
    margin-left: 32px;
    text-decoration: underline;
    font-weight: ${bold};
    font-size: 18px;
  }
`;

export const clickableTopBanner = css`
  position: absolute;
  top: 20px;
  right: 14px;
  width: 18px;
  height: 18px;
  cursor: pointer;

  &:before,
  &:after {
    position: absolute;
    content: ' ';
    height: 18px;
    width: 3px;
    background-color: ${white};
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`;

export const hidden = css`
  display: none;
`;
