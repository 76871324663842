import React from 'react';
import PropTypes from 'prop-types';
import { withPrefix } from 'gatsby';
import { pathOr } from 'ramda';

import * as ß from '../styles';

const adacLogo = withPrefix('/icons/adac-logo.svg');

const DestinationContent = ({ item, logo }) => {
  const title = pathOr('', ['title'], item);
  const subtext = pathOr('', ['subtext'], item);
  const link = pathOr('', ['link'], item);

  return (
    <div css={ß.homePageContent}>
      <span>
        <h4 css={ß.contentTitle}>{title}</h4>
        <p css={ß.productText}>{subtext}</p>
      </span>
      <div css={ß.productTileFooter}>
        <div css={ß.logo}>
          <img alt="Partner" src={logo || adacLogo} />
        </div>
        <span>{link}</span>
      </div>
    </div>
  );
};

DestinationContent.propTypes = {
  item: PropTypes.object,
  logo: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

DestinationContent.defaultProps = {
  item: {},
};

export default React.memo(DestinationContent);
