import React from 'react';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';

import * as ß from './styles';

const ErrorArea = ({ i18key, mobileHeight, desktopHeight, t, showError }) => (
  <div css={ß.createErrorAreaStyle(mobileHeight, desktopHeight)}>
    {showError && (
      <Trans t={t} i18nKey={i18key}>
        <p css={ß.invalidFieldText}></p>
      </Trans>
    )}
  </div>
);

ErrorArea.propTypes = {
  i18key: PropTypes.string.isRequired,
  mobileHeight: PropTypes.number,
  desktopHeight: PropTypes.number,
  fallbackText: PropTypes.string,
  showError: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

ErrorArea.defaultProps = {
  mobileHeight: 60,
  desktopHeight: 48,
};

export default ErrorArea;
