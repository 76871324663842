import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { AvailabilityStateContext } from '@contexts/AvailabilityState';
import { MomentType } from '@types';

import AvailabilityBox from './component';
import * as track from './tracking';

export const ControlledAvailabilityBox = props => {
  const stateProps = useContext(AvailabilityStateContext);
  const [showGuestPicker, setShowGuestPicker] = useState(false);

  return (
    <AvailabilityBox
      {...props}
      {...stateProps}
      setShowGuestPicker={setShowGuestPicker}
      showGuestPicker={showGuestPicker}
      trackDateSearch={track.trackDateSearch}
    />
  );
};

ControlledAvailabilityBox.propTypes = {
  dateFrom: MomentType,
  dateTo: MomentType,
  isOnAvailabilityPage: PropTypes.bool,
  kidsAges: PropTypes.array,
  totalAdults: PropTypes.number,
};
