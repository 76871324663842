import React from 'react';
import PropTypes from 'prop-types';

import * as ß from './styles';

const PlaceholderList = ({ amount }) => {
  const fakeArray = [...Array.from({ length: amount }).keys()];

  return fakeArray.map((x, i) => <div css={ß.logo} key={i}></div>);
};

PlaceholderList.propTypes = {
  amount: PropTypes.number,
};

export default PlaceholderList;
