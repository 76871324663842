import { css } from '@emotion/react';
import { blue9, blue10, charcoal, white } from '@fire/_color';
import { buttonSmall, primaryButton } from '@fire/_const';
import { bold, fontSizeXSmall, lineHeightDefault, regular } from '@fire/_fonts';
import { media } from '@fire/_mediaQueries';

export const newsletter = css`
  width: 100%;
  min-height: 69px;

  h5 {
    margin-bottom: 8px;
  }

  ${media.md} {
    flex: 1;
  }
`;

export const newsletterForm = css`
  p a:hover {
    color: ${blue10};
  }
  form {
    position: relative;
    display: flex;
    flex-basis: 100%;
    margin-bottom: 10px;
    width: calc(100% - 40px);
    height: 50px;
    max-width: 330px;

    input {
      border-right: 0;
    }

    button {
      position: absolute;
      right: -35px;
      width: 91px;
      height: 52px;
      border-radius: 0 8px 8px 0;
      margin: 0;
      background-color: ${blue9};
      border: none;

      font-size: 16px;
      font-weight: ${bold};
      color: ${white};
      cursor: pointer;

      ${media.lg} {
        right: -80px;
      }
    }
  }

  p {
    font-size: ${fontSizeXSmall};
    line-height: ${lineHeightDefault};
    margin-bottom: 30px;
    text-align: left;
    font-weight: ${regular};
  }
`;

export const newsletterP = css`
  text-align: left;
  font-weight: ${regular};
  color: ${charcoal};
  font-size: 16px;

  margin-bottom: 12px;
`;

export const buttonSmallTCS = css`
  margin-top: 16px;
  margin-bottom: 32px;

  ${media.lg} {
    margin-bottom: 39px;
  }

  ${primaryButton}
  ${buttonSmall}
`;
