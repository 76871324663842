import React from 'react';
import PropTypes from 'prop-types';

import useCampsitesOfTheMonth from '@hooks/useCampsitesOfTheMonth';

import CampsiteOfTheMonthCarousel from './component';

const HOMEPAGE = 'hp';

const HomepageCOTMCarousel = props => {
  const campsites = useCampsitesOfTheMonth({ pageType: HOMEPAGE });
  return <CampsiteOfTheMonthCarousel {...props} campsites={campsites} />;
};

HomepageCOTMCarousel.defaultProps = {
  trackCategory: 'home',
};

HomepageCOTMCarousel.propTypes = {
  trackCategory: PropTypes.string,
};

export default HomepageCOTMCarousel;
