import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { filter as fltr, isEmpty, pluck, propEq } from 'ramda';

import RadioButton from '@components/RadioButton';
import { useFilters } from '@contexts/Filters';
import { T } from '@fire/i18n/i18n-constants';

import * as ß from './styles';

const namespace = T?.SearchResultFilter ?? '';

const RadioGroup = ({ items, label }) => {
  const [stateContext, dispatchContext] = useFilters();
  const { t } = useTranslation(namespace);

  const hasCheckedFilter = !isEmpty(fltr(item => stateContext[item.id], items));
  const handleChange = idFilter => () => {
    dispatchContext({
      type: 'removeFilters',
      payload: pluck('id', items),
    });
    dispatchContext({
      type: 'setFilters',
      payload: {
        [idFilter]: stateContext[idFilter] === 1 ? 0 : 1,
      },
    });
  };

  return (
    <div css={ß.filterCategory}>
      <>
        <h3 css={ß.categoryTitle}>{t(label)}</h3>
        <ul>
          {items.map(filter => {
            const { id = '', label: filterLabel = '', detail = '' } = filter;
            const filterId = id;
            const radioLabel = t(filterLabel);
            const radioDetail = t(detail);
            const checked =
              propEq(filterId, 1, stateContext) ||
              (!hasCheckedFilter && filterId.endsWith('-any'));

            return (
              <RadioButton
                checked={checked}
                detail={radioDetail}
                key={filterId}
                label={radioLabel}
                onChange={handleChange(filterId)}
                value={filterId}
              />
            );
          })}
        </ul>
      </>
    </div>
  );
};

RadioGroup.propTypes = {
  items: PropTypes.array,
  label: PropTypes.string,
};

RadioGroup.defaultProps = {
  items: [],
};

export default RadioGroup;
