import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';

import BlockHeader from '@components/BlockHeader';
import Carousel from '@components/Carousel';
import TileCampsite from '@components/Tile/Campsite';
import { isEmptyOrNil } from '@fire/utils/generic';
import useTracking from '@hooks/useTracking';

import * as ß from './styles';

const getTileProps = ({ campsite, index, trackClickout, trackClick }) => ({
  ...campsite,
  index,
  hasUrlPrefix: true,
  isAd: true,
  isExternalUrl: false,
  key: campsite?.prn || '',
  onBookClick: trackClickout(campsite, index),
  onClick: trackClick(campsite, index),
  onCtaClick: trackClickout(campsite, index),
  openInNewWindow: true,
  showLinkoutCTA: true,
});

const CampsiteOfTheMonthCarousel = ({
  campsites,
  subtitle,
  tileCount,
  title,
  trackCategory,
}) => {
  const { prepareToTrackProduct } = useTracking(
    'campsite of the month',
    trackCategory
  );
  const { ref, inView } = useInView();
  const { t } = useTranslation(); // no need for prefix as all text has it embed already

  const track = prepareToTrackProduct.productTileImpressions(campsites);

  useEffect(() => {
    if (!isEmptyOrNil(campsites) && inView) {
      track();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return campsites.length > 0 ? (
    <>
      <BlockHeader title={t(title)} description={t(subtitle)} />
      <div css={ß.wrapper} ref={ref}>
        <Carousel tileCount={tileCount}>
          {campsites.map((campsite, index) => (
            <div css={ß.tile} key={`carousel-tile-${index}`}>
              <TileCampsite
                {...getTileProps({
                  campsite,
                  list: trackCategory,
                  index,
                  trackClickout: prepareToTrackProduct.productTilePurchaseClick,
                  trackClick: prepareToTrackProduct.productTileClick,
                })}
              />
            </div>
          ))}
        </Carousel>
      </div>
    </>
  ) : (
    <></>
  );
};

CampsiteOfTheMonthCarousel.propTypes = {
  campsites: PropTypes.array,
  subtitle: PropTypes.string,
  tileCount: PropTypes.number.isRequired,
  title: PropTypes.string,
  trackCategory: PropTypes.string,
};

CampsiteOfTheMonthCarousel.defaultProps = {
  campsites: [],
  tileCount: 3,
};

const MemoCampsiteOfTheMonthCarousel = memo(CampsiteOfTheMonthCarousel);
export default MemoCampsiteOfTheMonthCarousel;

export { CampsiteOfTheMonthCarousel };
