import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import SmallTile from '@components/SmallTile';

import HeroMenuLink from '../HeroMenuLink';
import { responsiveTiles } from './constants';
import { breaker } from './styles';

export const HeroMenuTiles = ({ content, activeItem, parentTitle }) => {
  // remove first item from small tiles so it goes alone in the big tile,
  // if it's active child is 0 we don't do it, because we show a map there coolcoolcool 😎
  const slicedContent = activeItem === 0 ? content : content.slice(1);
  const [windowWidth, changeWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      changeWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return slicedContent.map((tile, key) =>
    //  only the first 6 tiles are displayed as a tile.
    // IF there is enough place in the window.
    responsiveTiles[key] < windowWidth && key < 6 ? (
      <SmallTile
        url={tile.url}
        parentTitle={parentTitle}
        key={key}
        id={key}
        {...tile}
        activeChild={activeItem}
      />
    ) : (
      <React.Fragment key={key}>
        <div css={breaker}></div>
        <HeroMenuLink tile={tile} order={3} parentTitle={parentTitle} />
      </React.Fragment>
    )
  );
};

HeroMenuTiles.propTypes = {
  activeItem: PropTypes.number.isRequired,
  image: PropTypes.shape({
    name: PropTypes.string.isRequired,
    handle: PropTypes.string.isRequired,
  }),
  parentTitle: PropTypes.string.isRequired,
};

export default HeroMenuTiles;
