import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Link from '@components/Link';
import Slideshow from '@components/Slideshow';
import TileContent from '@components/Tile/TileContent';
import TileLabel from '@components/Tile/TileLabel';
import { T } from '@fire/i18n/i18n-constants';
import useTracking from '@hooks/useTracking';

import * as ß from './styles';

const namespace = T?.Tile ?? '';

const SuggestionTile = ({
  name,
  images,
  rating,
  userRating,
  userRatingCount,
  tcsRating,
  region,
  url,
  position,
  category,
  prn,
}) => {
  const { prepareToTrackProduct } = useTracking('suggest');
  const { t } = useTranslation(namespace);
  const trackClick = prepareToTrackProduct.productTileClick(
    { name, prn, plan: category },
    position
  );
  const linkProps = {
    onClick: trackClick,
    openInNewWindow: true,
    isExternalUrl: false,
    to: url,
  };

  return (
    <div css={ß.tile}>
      <Link {...linkProps}>
        <div css={ß.tileHeader}>
          <TileLabel message={t('Anzeige')} />
          <Slideshow hasUrlPrefix images={images} isAd />
        </div>
      </Link>

      <TileContent
        name={name}
        rating={rating}
        tcsRating={tcsRating}
        userRating={userRating}
        userRatingCount={userRatingCount}
        region={region}
        namespace={namespace}
        linkProps={linkProps}
      />
    </div>
  );
};
SuggestionTile.propTypes = {
  images: PropTypes.array.isRequired,
  region: PropTypes.string.isRequired,
  rating: PropTypes.string.isRequired,
  userRating: PropTypes.string.isRequired,
  userRatingCount: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  tcsRating: PropTypes.number,
  prn: PropTypes.string.isRequired,
  category: PropTypes.string,
};

export default SuggestionTile;
