import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import Link from '@components/Link';
import MaybeData from '@contexts/MaybeData';

import * as ß from './styles';
import { getBreadcrumbs } from './utils';

export const Breadcrumbs = ({ isCompact }) => {
  const { breadcrumbs, pagePath } = useContext(MaybeData);
  const crumbs = isCompact
    ? getBreadcrumbs(breadcrumbs, pagePath)
    : breadcrumbs;

  const styles = isCompact ? ß.breadcrumbsCompact : ß.breadcrumbs;

  return (
    <div css={styles} id="breadcrumb">
      {crumbs?.map(([text, href]) =>
        href === pagePath ? (
          <span key={href}>{text}</span>
        ) : (
          <Link key={href} to={href}>
            <span>{text}</span>
          </Link>
        )
      )}
    </div>
  );
};

Breadcrumbs.propTypes = {
  isCompact: PropTypes.bool,
};

Breadcrumbs.defaultProps = {
  isCompact: false,
};

export default Breadcrumbs;
