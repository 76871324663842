import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import lazy from '@fire/hoc/lazy.js';

import Group from './Group';

const InterLinking = props => {
  const { title, prefix, group } = props;
  const { t } = useTranslation(prefix);

  return group?.length > 0 ? (
    <>
      {title && <h2>{t(title)}</h2>}
      <Group {...props} />
    </>
  ) : (
    <></>
  );
};

InterLinking.propTypes = {
  group: PropTypes.array,
  title: PropTypes.string,
  prefix: PropTypes.string,
  mobile: PropTypes.number,
  tablet: PropTypes.number,
  desktop: PropTypes.number,
};

InterLinking.defaultProps = {
  group: [],
  mobile: 2,
  tablet: 3,
  desktop: 6,
};

export default lazy(InterLinking);
