import React from 'react';
import { useTranslation } from 'react-i18next';

import { mapIndexed } from '@utils/generic';

import { INFRA_CHECKLIST_PREFIX } from './constants';
import * as ß from './styles';
import {
  filterTruthyAttributes,
  getAttribute,
  getSecondValue,
  getValue,
} from './utils';

const InfrastructureCheckListItem = ({ attributes, values }) => {
  const { t } = useTranslation(INFRA_CHECKLIST_PREFIX);
  const cspAttrValues = filterTruthyAttributes(values, attributes);

  return mapIndexed((value, index) => {
    const attrValue = getAttribute(attributes, value);
    const attrSecondValue = getAttribute(attributes, getSecondValue(value));

    return (
      <li key={index}>
        <div css={ß.item}>
          {t(getValue(value), {
            value: attrValue,
            secondValue: attrSecondValue || undefined,
          })}
        </div>
      </li>
    );
  })(cspAttrValues);
};

export default InfrastructureCheckListItem;
