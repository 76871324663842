import React from 'react';
import PropTypes from 'prop-types';
import { assocPath, dissocPath, isEmpty } from 'ramda';

import Link from '@components/Link';
import { makeURLSearchParams } from '@utils/prosciutto';

const PreviousPageLinkWrapper = ({
  itemCount,
  pageCount,
  pagePath,
  prosciuttoState,
  currentPage,
  children,
}) => {
  if (itemCount === 0 || pageCount < 2) {
    return <></>;
  } else if (currentPage === 1) {
    return <>{children}</>;
  }
  const nextPage = currentPage - 1;
  const nextProsciuttoState =
    nextPage === 1
      ? dissocPath(['params', 'page'], prosciuttoState)
      : assocPath(['params', 'page'], nextPage, prosciuttoState);
  const nextUrlParams = makeURLSearchParams(nextProsciuttoState);
  const nextPageHref = isEmpty(nextUrlParams)
    ? pagePath
    : `${pagePath}?${nextUrlParams}`;

  return <Link to={nextPageHref}>{children}</Link>;
};

PreviousPageLinkWrapper.propTypes = {
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  pagePath: PropTypes.string,
  prosciuttoState: PropTypes.object,
  currentPage: PropTypes.number,
  children: PropTypes.node,
};

export default PreviousPageLinkWrapper;
