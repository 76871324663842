import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { navigate, withPrefix } from 'gatsby';
import { curry, pathOr } from 'ramda';

import { LanguageSelector } from '@components/LanguageSelect/component';
import { isSwiss } from '@utils/platform';
import { trackObject } from '@utils/tracking';
import { goTo, goToNewWindow } from '@utils/url';

import MenuItem from './MenuItem';
import { arrowLeft, container, hidden, menu, menuHeader } from './styles.js';

const initialHeaderState = 'Menu';

const handleClickWithContent = curry(
  (
    content,
    resolvedIsSwiss,
    { node, show, header },
    { setLinks, setHeader }
  ) => {
    const handleClick = link => {
      if (link.children) {
        trackObject({
          label: link.title,
          action: `click navigation: ${link.title}`,
          category: 'navigation-portal',
          event: 'navigation-portal_click-navigation',
        });

        setLinks(link.children);
        setHeader(link.title);
        return;
      }
      trackObject({
        label: header,
        action: `click navigation: ${link.title}`,
        category: 'navigation-portal',
        event: 'navigation-portal_click-navigation-links',
      });
      const isLinkout = pathOr(false, ['linkout'], link);
      if (isLinkout) {
        return goToNewWindow(link.url);
      }
      if (resolvedIsSwiss && !isLinkout) {
        return navigate(link.url);
      }
      return goTo(link.url);
    };

    const resetMenu = () => {
      setLinks(content);
      setHeader('Menu');
    };

    const handleClickContainer = e => {
      if (node.current.contains(e.target)) {
        // inside click
        return;
      }
      // outside click
      trackObject({
        event: 'navigation-portal_click-close-navigation-dropdown',
        category: 'navigation-portal',
        action: 'click close navigation dropdown',
        label: 'mobile',
      });
      resetMenu();
      show(false);
    };

    return { handleClick, handleClickContainer, resetMenu };
  }
);

const arrow = withPrefix('/icons/menuArrow.svg');

export const MobileMenu = ({ heroMenuContent, isOpen, show, pageContext }) => {
  const node = useRef();
  const resolvedIsSwiss = isSwiss(pageContext);
  const initialState = [...heroMenuContent, ...LanguageSelector(pageContext)];

  const [links, setLinks] = useState(initialState);
  const [header, setHeader] = useState(initialHeaderState);

  const handleClicks = handleClickWithContent(initialState, resolvedIsSwiss);

  const { handleClick, handleClickContainer, resetMenu } = handleClicks(
    { node, show, header },
    { setLinks, setHeader }
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickContainer);
    }
    return () =>
      document.removeEventListener('mousedown', handleClickContainer);
  }, [handleClickContainer, isOpen]);

  return (
    <div css={isOpen ? container : hidden}>
      <div ref={node} css={menu}>
        <div onClick={resetMenu} css={menuHeader}>
          <img
            src={arrow}
            css={header !== initialHeaderState ? arrowLeft : hidden}
            alt="menu arrow"
          />
          {header}
        </div>
        {links.map(link => (
          <MenuItem
            key={link.title}
            title={link.title}
            arrow={link.children}
            onClick={link.action ? link.action : () => handleClick(link)}
          />
        ))}
      </div>
    </div>
  );
};

MobileMenu.propTypes = {
  isOpen: PropTypes.bool,
  show: PropTypes.func,
  pageContext: PropTypes.object,
  heroMenuContent: PropTypes.array,
};

MobileMenu.defaultProps = {
  heroMenuContent: [],
};

export default MobileMenu;
