import React from 'react';
import PropTypes from 'prop-types';

import Header, { HeaderBooking } from '@components/Header';
import PlatformSelector from '@components/PlatformSelector';
import TopBanner from '@components/TopBanner';
import useIsConsumerApp from '@hooks/useIsConsumerApp';

const HeaderContainer = ({
  hideSearchBar,
  isTopBannerVisible,
  minimal,
  hidePlatformSelector,
}) => {
  const isConsumerApp = useIsConsumerApp();

  return !isConsumerApp ? (
    <>
      <span id="welcome-popup" />
      {isTopBannerVisible && <TopBanner />}
      {minimal ? <HeaderBooking /> : <Header hideSearchBar={hideSearchBar} />}
      {!hidePlatformSelector && <PlatformSelector />}
    </>
  ) : (
    <></>
  );
};

HeaderContainer.propTypes = {
  hideSearchBar: PropTypes.bool,
  isTopBannerVisible: PropTypes.bool,
  minimal: PropTypes.bool,
  hidePlatformSelector: PropTypes.bool,
};

HeaderContainer.defaultProps = {
  hideSearchBar: false,
  isTopBannerVisible: false,
  minimal: false,
  hidePlatformSelector: false,
};

export default HeaderContainer;
