import axios from 'axios';
import F from 'fluture';
import { pipe } from 'ramda';
import sjcl from 'sjcl';

import { GATSBY_REVIEWS_API } from '@fire/constants';

export const verifyDuplicateReview = (
  prn,
  {
    email,
    content,
    headline,
    rating,
    termsAccepted,
    travelDateMonth,
    travelDateYear,
    nickname,
  }
) =>
  new F((bad, good) => {
    const emailDigest = pipe(
      sjcl.hash.sha256.hash,
      sjcl.codec.hex.fromBits
    )(email);
    axios
      .post(`${GATSBY_REVIEWS_API}/${prn}`, {
        email,
        content,
        headline,
        rating,
        termsAccepted,
        travelDateMonth,
        travelDateYear,
        username: email,
        nickname,
        email_digest: `sha256:${emailDigest}`,
      })
      .catch(bad)
      .then(good);
    return () => {};
  });

export const checkAuth = ({ email, prn, onAuth, reviewId }) => {
  if (window.auth0.WebAuth) {
    const redirectUrl = encodeURIComponent(
      `${window.location.href}&valid=true`
    );
    const failedRedirectUrl = encodeURIComponent(
      `${window.location.href}&valid=false`
    );
    const webAuth = new window.auth0.WebAuth({
      domain: 'adac-camping.eu.auth0.com',
      clientID: process.env.GATSBY_AUTH0_CLIENT_ID,
      redirectUri: `${GATSBY_REVIEWS_API}/callback?prn=${prn}&reviewId=${reviewId}&redirect=${redirectUrl}&failedRedirect=${failedRedirectUrl}`,
      responseType: 'code',
      scope: 'openid profile',
    });

    webAuth.passwordlessStart(
      {
        connection: 'email',
        send: 'link',
        email,
      },
      onAuth
    );
    webAuth.crossOriginVerification();
  }
};
