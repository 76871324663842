import axios from 'axios';

import { BREAKPOINTS } from '@fire/_mediaQueries';
import {
  deriveCenter,
  getPositions,
} from '@fire/components/DestinationMap/utils';
import { dateToISOString } from '@fire/utils/dateTime';
import { isEmptyOrNil } from '@fire/utils/generic';
import { centsToDecimal, formatPrice } from '@fire/utils/money';
import GeoViewport from '@mapbox/geo-viewport';
import { noQuestionMarkObjectToQuery } from '@utils/url';

import {
  CAMPERLAND_FEE,
  MICROCAMPING_PATH_PREFIX,
  MICROCAMPING_URL,
  REF,
} from './consts';

export const validateOrigin = (rawLatitude, rawLongitude) => {
  const latitude = Number.parseFloat(rawLatitude);
  const longitude = Number.parseFloat(rawLongitude);
  return (
    !isEmptyOrNil(latitude) &&
    !isEmptyOrNil(longitude) &&
    !Number.isNaN(latitude) &&
    !Number.isNaN(longitude) &&
    latitude !== 0 &&
    longitude !== 0
  );
};

export const consolidateViewport = (
  { width, height },
  prosciuttoParams,
  prosciuttoViewport = {}
) => {
  const isMobile = width <= BREAKPOINTS.md;
  const { latitude, longitude, zoom } = prosciuttoViewport;
  if (!isMobile && validateOrigin(latitude, longitude)) {
    return prosciuttoViewport;
  }
  const viewportParam = prosciuttoParams?.viewport?.split(',');
  const initialCenterZoom = GeoViewport.viewport(
    [
      Number.parseFloat(viewportParam?.[1]),
      Number.parseFloat(viewportParam?.[0]),
      Number.parseFloat(viewportParam?.[3]),
      Number.parseFloat(viewportParam?.[2]),
    ],
    [width, height]
  );
  return {
    ...prosciuttoViewport,
    width,
    height,
    zoom: initialCenterZoom?.zoom || zoom,
    latitude: initialCenterZoom?.center?.[0],
    longitude: initialCenterZoom?.center?.[1],
  };
};

export const getLatitudeLongitudeFromTiles = tiles => {
  const newPositions = getPositions(tiles);

  return deriveCenter(newPositions);
};

const getChildrenQuery = (children = []) =>
  children?.length > 0 ? { children: children.join(',') } : {};

export const getQueryString = ({
  dateFrom,
  dateTo,
  adults = 1,
  children = [],
}) => {
  const query = {
    date_from: dateToISOString(dateFrom),
    date_to: dateToISOString(dateTo),
    adults,
    ...getChildrenQuery(children),
  };

  return `&${noQuestionMarkObjectToQuery(query)}`;
};

export const getAPIQueryString = ({ dateFrom, dateTo, totalGuests = 2 }) => {
  const query = {
    start: dateToISOString(dateFrom),
    end: dateToISOString(dateTo),
    seats: totalGuests,
  };

  return `&${noQuestionMarkObjectToQuery(query)}`;
};

export const getPathPrefix = props => id =>
  `${MICROCAMPING_PATH_PREFIX}${id}?${REF}${getQueryString(props)}`;

export const getMicrocampingUrl = props => {
  const { dateFrom, dateTo, latitude, longitude } = props;

  if (dateFrom && dateTo) {
    return `${MICROCAMPING_URL}${getAPIQueryString(
      props
    )}&origin=${latitude},${longitude}`;
  }
};

export const parsePrice = price => {
  const priceInCents = price?.amount;
  const priceWithFee = centsToDecimal(priceInCents) * CAMPERLAND_FEE;
  const currency = price?.currency;

  return formatPrice(priceWithFee, currency);
};

export const getImagesFrom = (included, imagesRelated) =>
  imagesRelated.flatMap(({ id }) =>
    included
      .filter(image => image.id === id)
      .map(({ attributes: attr }) => attr?.variants?.tile ?? {})
      .filter(i => i)
  );

const getDescription = ({
  description = '',
  publicData: { extendedDescription = '' } = {},
}) => [description || '', extendedDescription || ''].filter(i => i).join(' ');

export const parseMicrocampingData = (response, pathPrefix) => {
  if (!isEmptyOrNil(response)) {
    const { data, included } = response;

    return data.map(item => {
      const { id, attributes, relationships } = item;

      const imagesRelated = relationships?.images?.data ?? [];

      const path = pathPrefix(id);
      const price = parsePrice(attributes?.price);
      const images = getImagesFrom(included, imagesRelated);
      const description = getDescription(attributes);

      return { ...attributes, path, price, images, id, description };
    });
  }

  return [];
};

export const fetchMicrocamping = async props => {
  const { dateFrom, dateTo } = props;
  if (dateFrom && dateTo) {
    try {
      const pathPrefix = getPathPrefix(props);
      const url = getMicrocampingUrl(props);
      const { data = [] } = await axios.get(url);

      return parseMicrocampingData(data, pathPrefix);
    } catch (error) {
      return error;
    }
  }

  return [];
};
