import React from 'react';
import PropTypes from 'prop-types';

import Scroller from './Scroller';

export const Carousel = ({ children, minMax, scrollType }) => (
  <Scroller minMax={minMax} scrollType={scrollType}>
    {children}
  </Scroller>
);

Carousel.defaultProps = {
  scrollType: 'full',
};

Carousel.propTypes = {
  children: PropTypes.array,
  minMax: PropTypes.string,
  scrollType: PropTypes.oneOf(['full', 'single']),
};

export default Carousel;
