export const ADACAttributesDefaultProps = {
  accept_dogs: false,
  has_beach: false,
  has_bread_roll_service: false,
  has_kids_activities: false,
  has_restaurant: false,
  has_shops: false,
  has_swimming_pool: false,
  has_wifi: false,
};

export default ADACAttributesDefaultProps;
