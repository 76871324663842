import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';

import FooterAbout, { FooterBookWithUs } from '@components/FooterAbout';
import FooterAboutNewsletter from '@components/FooterAboutNewsletter';
import FooterLegalSocial from '@components/FooterLegalSocial';
import FooterList from '@components/FooterList';
import FooterPremiumPartner from '@components/FooterPremiumPartner';
import footerDestinationsContent from '@content/footer_destinations.json';
import footerRecommendations from '@content/footer_recommendations.json';
import { T } from '@fire/i18n/i18n-constants';
import { DimensionsProvider } from '@hooks/useDimensions';
import useIsConsumerApp from '@hooks/useIsConsumerApp';

import AppDownload from './AppDownload';
import ReviewLink from './ReviewLink';
import * as ß from './styles';

const prefix = T?.Footer ?? '';

export const Footer = ({ pageContext, hasStickyCtaBoxBar }) => {
  const isConsumerApp = useIsConsumerApp();
  const { t } = useTranslation(prefix);

  const defaultUserRatingIfWeDoNotKnow = 5;
  const averageUserRating = pathOr(
    defaultUserRatingIfWeDoNotKnow,
    ['average_user_rating'],
    pageContext
  );

  return !isConsumerApp ? (
    <DimensionsProvider>
      <footer css={ß.footer}>
        <div id="cta-box-stopper" />
        <div css={ß.row}>
          <FooterPremiumPartner
            partners={pageContext.partners}
            locale={pageContext.language}
          />
        </div>
        <div css={ß.row}>
          <FooterList items={footerDestinationsContent} />
        </div>
        <div css={ß.row}>
          <FooterBookWithUs />
          <FooterList items={footerRecommendations} />
        </div>
        <div css={ß.row}>
          <FooterAbout />
          <AppDownload />
          <div css={ß.optionBox}>
            <FooterAboutNewsletter />
            <ReviewLink
              averageUserRating={averageUserRating}
              title={t('Campingplatz besucht?')}
              buttonText={t('Jetzt bewerten')}
              pageContext={pageContext}
            />
          </div>
        </div>
        <FooterLegalSocial
          pageContext={pageContext}
          hasStickyCtaBoxBar={hasStickyCtaBoxBar}
        />
      </footer>
    </DimensionsProvider>
  ) : (
    <></>
  );
};

Footer.propTypes = {
  hasStickyCtaBoxBar: PropTypes.bool,
  pageContext: PropTypes.object.isRequired,
};

Footer.defaultProps = {
  hasStickyCtaBoxBar: false,
};

export default Footer;
