import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withPrefix } from 'gatsby';

import MaybeData from '@contexts/MaybeData';

import { INFRA_CHECKLIST_PREFIX } from './constants';
import InfrastructureCheckListItem from './InfrastructureCheckListItem';
import * as ß from './styles';
import { hasChildren } from './utils';

const InfrastructureCheckList = ({ name, values }) => {
  const { related } = useContext(MaybeData);
  const { t } = useTranslation(INFRA_CHECKLIST_PREFIX);

  const icon = withPrefix(`/icons/infrastructure/${name}.svg`);
  const title = t(`title.${name}`);

  return (
    hasChildren(values, related) && (
      <div css={ß.checklist}>
        <h4 css={ß.checklistTitle}>
          <span>
            <img alt={title} height={20} src={icon} width={20} />
          </span>
          {title}
        </h4>
        <ul css={ß.list}>
          <InfrastructureCheckListItem attributes={related} values={values} />
        </ul>
      </div>
    )
  );
};

InfrastructureCheckList.propTypes = {
  name: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.func])
      ),
    ])
  ),
};

InfrastructureCheckList.defaultProps = {
  name: '',
  values: [],
};

export default InfrastructureCheckList;
