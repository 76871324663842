import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { MomentType } from '@types';

import { BackButton, BookingButton, NextButton } from './Buttons';
import * as ß from './styles';

const FilterBar = ({
  bookingUrl,
  dateFrom,
  dateTo,
  handleNext,
  handleReset,
  hideBookingModal,
  isGuestPicker,
  isResetDisabled,
  kidsAges,
  nights,
  prefix,
  totalAdults,
}) => {
  const { t } = useTranslation(prefix);

  return (
    <div css={ß.filterBar}>
      {isGuestPicker && (
        <BackButton
          handleReset={handleReset}
          isResetDisabled={isResetDisabled}
          prefix={prefix}
        />
      )}
      <div css={ß.buttonAndDays}>
        <p>{nights > 0 ? t('night', { count: nights }) : t('no-nights')}</p>
        {isGuestPicker ? (
          <NextButton handleNext={handleNext} prefix={prefix} />
        ) : (
          <BookingButton
            bookingUrl={bookingUrl}
            dateFrom={dateFrom}
            dateTo={dateTo}
            hideBookingModal={hideBookingModal}
            kidsAges={kidsAges}
            totalAdults={totalAdults}
          />
        )}
      </div>
    </div>
  );
};

FilterBar.propTypes = {
  bookingUrl: PropTypes.string,
  dateFrom: MomentType,
  dateTo: MomentType,
  handleNext: PropTypes.func.isRequired,
  handleReset: PropTypes.func,
  hideBookingModal: PropTypes.func,
  isGuestPicker: PropTypes.bool,
  isResetDisabled: PropTypes.bool,
  kidsAges: PropTypes.arrayOf(PropTypes.number),
  nights: PropTypes.number.isRequired,
  prefix: PropTypes.string.isRequired,
  totalAdults: PropTypes.number,
};

FilterBar.defaultProps = {
  handleNext: () => {},
  nights: 0,
};

export default FilterBar;
