import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { curry, range } from 'ramda';

import NumberInput from '@components/NumberInput';
import {
  DEFAULT_KID_AGE,
  DEFAULT_KIDS_AGES,
  DEFAULT_TOTAL_ADULTS,
  MAX_GUEST_AMOUNT,
  THE_OLDEST_MINOR,
} from '@fire/constants';
import { isEmptyOrNil, mapIndexed } from '@utils/generic';

import * as ß from '../styles';

export const generateAgeFields = curry((setAges, ages) =>
  mapIndexed(
    (age, id) => (
      <div key={`generated-select-${id}`} css={ß.input}>
        <select
          onChange={({ target }) => {
            const copy = [...ages];
            copy[id] = +target.options[target.selectedIndex].value;
            setAges(copy);
          }}
          value={ages[id]}
        >
          {range(0, THE_OLDEST_MINOR + 1).map(index => (
            <option key={`generated-field-option-${id}-${index}`} value={index}>
              {index}
            </option>
          ))}
        </select>
      </div>
    ),
    ages
  )
);

const GuestPickerForm = ({
  hasDefaultValues,
  kidsAges,
  prefix,
  setKidsAges,
  setTotalAdults,
  totalAdults,
}) => {
  const { t } = useTranslation(prefix);

  useEffect(() => {
    if (isEmptyOrNil(totalAdults)) {
      setTotalAdults(hasDefaultValues ? DEFAULT_TOTAL_ADULTS : 0);
    }
  }, []);

  const handleKidChange = value => {
    const updatedKidsAge =
      kidsAges.length > value
        ? [...kidsAges.slice(0, -1)]
        : [...kidsAges, DEFAULT_KID_AGE];

    setKidsAges(updatedKidsAge);
  };

  return (
    <>
      <div css={ß.pickerRow}>
        <p>{t('Erwachsene_plural')}</p>
        <NumberInput
          minValue={hasDefaultValues ? 1 : 0}
          maxValue={MAX_GUEST_AMOUNT}
          setValue={setTotalAdults}
          value={totalAdults}
        />
      </div>
      <div css={ß.pickerRow}>
        <p>{t('Kinder_plural')}</p>
        <NumberInput
          minValue={DEFAULT_KID_AGE}
          maxValue={MAX_GUEST_AMOUNT}
          setValue={handleKidChange}
          value={kidsAges.length}
        />
      </div>
      {kidsAges.length > 0 && (
        <p css={ß.kidsAgesTitle}>{t('Alter der Kinder bei Abreise')}:</p>
      )}
      <div css={ß.ageFields}>{generateAgeFields(setKidsAges, kidsAges)}</div>
    </>
  );
};

GuestPickerForm.defaultProps = {
  hasDefaultValues: true,
  kidsAges: DEFAULT_KIDS_AGES,
};

GuestPickerForm.propTypes = {
  hasDefaultValues: PropTypes.bool,
  kidsAges: PropTypes.array,
  prefix: PropTypes.string,
  setKidsAges: PropTypes.func,
  setTotalAdults: PropTypes.func,
  totalAdults: PropTypes.number,
};

export default GuestPickerForm;
