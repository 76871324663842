import React from 'react';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';

import NavigationItem from '@components/NavigationItem';

import { navigation } from './styles';

export const Navigation = ({
  heroMenuContent,
  changeActiveItem,
  activeItem,
}) => (
  <div css={navigation}>
    <ul>
      {heroMenuContent.map((e, index) => (
        <NavigationItem
          active={index === activeItem}
          key={index}
          id={index}
          {...e}
          changeActiveItem={changeActiveItem}
          linkout={pathOr(false, ['linkout'], e)}
        />
      ))}
    </ul>
  </div>
);

Navigation.propTypes = {
  activeItem: PropTypes.number.isRequired,
  changeActiveItem: PropTypes.func.isRequired,
  heroMenuContent: PropTypes.array,
};

Navigation.defaultProps = {
  heroMenuContent: {},
};

export default Navigation;
