import { css } from '@emotion/react';
import { white } from '@fire/_color';

export const link = css`
  text-decoration: none;
`;

export const wrapper = css`
  position: relative;
  width: 100%;
  max-width: 418px;
  max-height: 447px;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  background-size: cover;
  padding: 40px 16px 16px;
  overflow: hidden;

  img {
    filter: brightness(80%);
  }
`;

export const imageCover = css`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const imageWrapper = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const container = css`
  position: relative;
  height: 100%;
`;

export const content = css`
  display: flex;
  flex-direction: column;

  h2 {
    color: ${white};
    font-size: 24px;
    margin-bottom: 10px;
  }

  p {
    color: ${white};
    font-weight: bold;
    margin-bottom: 20px;
  }
`;

export const button = css`
  display: inline-flex;

  span {
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
    background: ${white};
    padding: 2px 6px;
    border-radius: 4px;
  }
`;

export const logo = css`
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 60px;

  img {
    width: 100%;
    filter: brightness(100%);
  }
`;
