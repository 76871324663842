import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { curry } from 'ramda';

export const useOutsideAlerterWithContext = curry((ctx, ref, callback) => {
  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      callback(event);
    }
  };

  useEffect(() => {
    ctx.addEventListener('mousedown', handleClickOutside);

    return () => {
      ctx.removeEventListener('mousedown', handleClickOutside);
    };
  });
});

export const OutsideAlerter = ({ callback, children }) => {
  const useOutsideAlerter = useOutsideAlerterWithContext(document);
  const wrapperRef = useRef();

  useOutsideAlerter(wrapperRef, callback);

  return <div ref={wrapperRef}>{children}</div>;
};

OutsideAlerter.propTypes = {
  callback: PropTypes.func,
  children: PropTypes.element.isRequired,
};

export default OutsideAlerter;
