import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { T } from '@fire/i18n/i18n-constants';

const PREFIX = T?.Tile;

import { isEmptyOrNil } from '@fire/utils/generic';
import useFavorites from '@hooks/useFavorites';
import useTracking from '@hooks/useTracking';

import * as ß from './styles';
import { getIcon } from './utils';

const FavoriteHeart = ({ prn }) => {
  const { add, remove, existsInStorage } = useFavorites(prn);
  const { t } = useTranslation(PREFIX);
  const {
    prepareToTrackEvent: { clickEvent },
  } = useTracking('favorite heart');

  const [isFavorited, setFavorite] = useState(false);

  const shouldShow = !isEmptyOrNil(prn);
  const altText = t('favorite-icon');

  const trackOptions = { prn };
  const trackOnAddFavorite = clickEvent(
    'click add favorite icon',
    trackOptions
  );
  const trackOnRemoveFavorite = clickEvent(
    'click remove favorite icon',
    trackOptions
  );

  const onClickHandle = () => {
    if (!isFavorited) {
      setFavorite(true);
      add();
      trackOnAddFavorite();
    } else {
      setFavorite(false);
      remove();
      trackOnRemoveFavorite();
    }
  };

  useEffect(() => {
    existsInStorage() && setFavorite(true);
  }, []);

  return shouldShow ? (
    <div css={ß.wrapper} onClick={onClickHandle}>
      <img src={getIcon(isFavorited)} alt={altText} width="23" height="23" />
    </div>
  ) : (
    false
  );
};

FavoriteHeart.propTypes = {
  prn: PropTypes.string.isRequired,
};

export default FavoriteHeart;
