import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { MomentType } from '@types';

const Nights = ({ dateEnd, dateStart, prefix }) => {
  const { t } = useTranslation(prefix);
  const nights = dateEnd && dateStart ? dateEnd.diff(dateStart, 'days') : 0;
  const hasZeroNights = nights === 0;
  const pickingEndDate = dateStart && !dateEnd;
  const pickingDateText =
    hasZeroNights && pickingEndDate ? t('pick-end-date') : t('no-nights');
  const infoText = hasZeroNights
    ? pickingDateText
    : t('night', { count: nights });

  return <span>{infoText}</span>;
};

Nights.propTypes = {
  dateEnd: MomentType,
  dateStart: MomentType,
  prefix: PropTypes.string,
};

Nights.defaultProps = {
  prefix: 'availability-box',
};

export default Nights;
