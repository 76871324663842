import { css } from '@emotion/react';
import { blue9, gray5, gray7, white } from '@fire/_color';
import { fontSizeLarge } from '@fire/_fonts';

export const checkbox = css`
  .container {
    display: block;
    position: relative;
    padding-left: 34px;
    margin: 8px 0px;
    cursor: pointer;
    user-select: none;

    &:hover {
      input:disabled ~ .checkmark {
        background-color: ${white};
        cursor: not-allowed;
      }

      input:not(:checked) ~ .checkmark {
        background-color: ${gray5};
        transition: background-color 0.25s ease;
      }
    }

    input {
      display: none;
    }
  }

  label {
    cursor: pointer;
    font-size: ${fontSizeLarge};
  }

  .checkmark {
    background-color: ${white};
    border-color: ${gray7};
    border-image: initial;
    border-style: solid;
    border-width: 2px;
    bottom: 0;
    height: 22px;
    left: 0;
    margin: auto;
    padding: 0;
    position: absolute;
    top: 0;
    transition: background-color 0.25s ease;
    width: 22px;

    &::after {
      border-color: transparent;
      border-image: initial;
      border-style: solid;
      border-width: 0px 2px 2px 0px;
      content: '';
      height: 11px;
      left: 5px;
      position: absolute;
      top: 1px;
      transform: rotate(45deg);
      transition: border-color 0.25s ease;
      width: 5px;
    }
  }

  input:checked ~ .checkmark {
    background-color: ${blue9};
    border-color: ${blue9};
    transition: background-color 0.25s ease;

    &::after {
      border-color: ${white};
      transition: border-color 0.25s ease;
    }
  }
`;

export const checkboxDescription = css`
  margin-left: 34px;
`;

export const small = css`
  .container {
    font-size: 16px;
    font-weight: bold;
  }
`;

export const disabled = css`
  color: ${gray7};
  cursor: not-allowed;
`;
