import { graphql, useStaticQuery } from 'gatsby';

export const LoyaltyQuerySilver = graphql`
  query LoyaltyQuerySilver {
    allLoyalties(filter: { category: { eq: "SILVER" } }) {
      ...LoyaltyQuery
    }
  }
`;

const useLoyaltySilver = () => {
  const data = useStaticQuery(LoyaltyQuerySilver);
  return data?.allLoyalties?.nodes ?? [];
};

export default useLoyaltySilver;
