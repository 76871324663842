import React, { useContext } from 'react';
import { graphql, useStaticQuery, withPrefix } from 'gatsby';

import BlockHeader from '@components/BlockHeader';
import Carousel from '@components/Carousel';
import TileDestination from '@components/Tile/Destination';
import MaybeData from '@contexts/MaybeData';
import { LOCALES } from '@fire/constants';
import { T } from '@fire/i18n/i18n-constants';

import productsAllLang from './fixture.js';
import * as ß from './styles';

const namespace = T?.HomePage ?? '';
const { DE } = LOCALES;

const ProductCarousel = () => {
  const pageContext = useContext(MaybeData);
  const language = pageContext?.language ?? DE;
  const products = productsAllLang[language];

  const data = useStaticQuery(graphql`
    query TCSLogoQuery {
      TCSLogo: file(relativePath: { eq: "tcs-logo.png" }) {
        publicURL
      }
      productImage1: file(
        relativePath: {
          eq: "homepage/adac-products/adac-campingfuehrer-2021-homepage-tile.png"
        }
      ) {
        childImageSharp {
          fluid(maxHeight: 400, srcSetBreakpoints: [570, 760, 990]) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      productImage2: file(
        relativePath: {
          eq: "homepage/adac-products/adac-produkte-campingreisefuehrer-app-cropped_420x280.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxHeight: 400, srcSetBreakpoints: [570, 760, 990]) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      productImage3: file(
        relativePath: {
          eq: "homepage/adac-products/campcard-homepage-tile.png"
        }
      ) {
        childImageSharp {
          fluid(maxHeight: 400, srcSetBreakpoints: [570, 760, 990]) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      productImage4: file(
        relativePath: { eq: "homepage/adac-products/Home-Produktbild-Womo.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 400, srcSetBreakpoints: [570, 760, 990]) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      productImage5: file(
        relativePath: { eq: "homepage/adac-products/Home-Produktbild-Wowa.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 400, srcSetBreakpoints: [570, 760, 990]) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      productImage6: file(
        relativePath: { eq: "homepage/adac-products/adac-autokredit.png" }
      ) {
        childImageSharp {
          fluid(maxHeight: 400, srcSetBreakpoints: [570, 760, 990]) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      productImage7: file(
        relativePath: { eq: "homepage/tcs-products/tcs-membership.png" }
      ) {
        childImageSharp {
          fluid(maxHeight: 400, srcSetBreakpoints: [570, 760, 990]) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      productImage8: file(
        relativePath: { eq: "homepage/tcs-products/tcs-camping-membership.png" }
      ) {
        childImageSharp {
          fluid(maxHeight: 400, srcSetBreakpoints: [570, 760, 990]) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      productImage9: file(
        relativePath: {
          eq: "homepage/tcs-products/tcs-eti-protection-letter.png"
        }
      ) {
        childImageSharp {
          fluid(maxHeight: 400, srcSetBreakpoints: [570, 760, 990]) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      productImage10: file(
        relativePath: { eq: "homepage/tcs-products/tcs-vehicle-insurance.png" }
      ) {
        childImageSharp {
          fluid(maxHeight: 400, srcSetBreakpoints: [570, 760, 990]) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      productImage11: file(
        relativePath: { eq: "homepage/tcs-products/tcs-member-mastercard.png" }
      ) {
        childImageSharp {
          fluid(maxHeight: 400, srcSetBreakpoints: [570, 760, 990]) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      productImage12: file(
        relativePath: { eq: "homepage/tcs-products/tcs-driver-training.png" }
      ) {
        childImageSharp {
          fluid(maxHeight: 400, srcSetBreakpoints: [570, 760, 990]) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      productImage13: file(
        relativePath: {
          eq: "homepage/tcs-products/tcs-tollbox-telepass-europe.png"
        }
      ) {
        childImageSharp {
          fluid(maxHeight: 400, srcSetBreakpoints: [570, 760, 990]) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      productImage14: file(
        relativePath: { eq: "homepage/tcs-products/tcs-traffic-protection.png" }
      ) {
        childImageSharp {
          fluid(maxHeight: 400, srcSetBreakpoints: [570, 760, 990]) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      productImage15: file(
        relativePath: { eq: "homepage/adac-products/yes-we-camp-product.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 400, srcSetBreakpoints: [570, 760, 990]) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      productImage16: file(
        relativePath: { eq: "homepage/adac-products/yes-we-camp-magazin.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 400, srcSetBreakpoints: [570, 760, 990]) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const tcsLogo = data?.TCSLogo?.publicURL ?? false;
  const logo = language !== DE && tcsLogo;

  return (
    <div css={ß.wrapper}>
      <BlockHeader
        description="adac-products>description"
        prefix={namespace}
        title="adac-products>title"
      />
      <Carousel>
        {products.map((product, index) => {
          const {
            isExternalUrl,
            hasPincampLogo,
            images = [],
            fluidImage = false,
            url = '',
          } = product;
          const openInNewWindow = isExternalUrl ?? false;
          const maybeFluidData =
            data?.[fluidImage]?.childImageSharp?.fluid ?? false;
          const logoImage = hasPincampLogo
            ? withPrefix('/icons/logo-pincamp.svg')
            : logo;

          return (
            <div key={index} css={ß.tile}>
              <TileDestination
                gatsbyFluid={maybeFluidData || false}
                homepage
                images={images}
                isExternalUrl={isExternalUrl ?? true}
                item={product}
                logo={logoImage}
                openInNewWindow={openInNewWindow}
                path={url}
              />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default ProductCarousel;
