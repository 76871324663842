import { map } from 'ramda';

const breakMin = z => `@media(min-width: ${z}px)`;
const breakMax = z => `@media(max-width: ${z}px)`;

export const nonTouch = `@media (hover: hover) and (pointer: fine)`;

export const BREAKPOINTS = {
  xxs: 370,
  xs: 570,
  md: 760,
  lg: 990,
  xl: 1200,
  xxl: 1450,
  xxxl: 1600,
};

export const media = map(breakMin, BREAKPOINTS);
export const mediaMax = map(breakMax, BREAKPOINTS);
export const maxStickyHeight = 700;
export const countriesCarouselBreak = 1120;
