import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withPrefix } from 'gatsby';

import MaybeData from '@contexts/MaybeData';
import SearchSuggestionsContext from '@contexts/SearchSuggestions';
import TileLoading from '@contexts/TileLoading';
import useKeyPress from '@hooks/useKeyPress';
import { isSearch, locizedSearchPathPrefix } from '@utils/platform';

import CampsiteNearby from './CampsiteNearby';
import { NAMESPACE } from './constants';
import PromotedSuggestions from './PromotedSuggestions';
import * as ß from './styles';
import SuggestionList from './SuggestionList';
import * as track from './tracking';
import { createIdOnItems, getLastItemId, getUserLocation } from './utils';

const SearchDropDown = ({ hideSuggestions, shouldNavigate }) => {
  const pageContext = useContext(MaybeData);
  const { suggestions, searchTerm, setSearchTerm, setSelectedSuggestion } =
    useContext(SearchSuggestionsContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const { t } = useTranslation(NAMESPACE);
  const [cursor, setCursor] = useState(0);
  const downPress = useKeyPress('ArrowDown');
  const upPress = useKeyPress('ArrowUp');
  const enterPress = useKeyPress('Enter');
  const escPress = useKeyPress('Escape');

  const searchPathPrefix = locizedSearchPathPrefix(pageContext);
  const isSearchPage = isSearch(pageContext?.prn ?? '');
  const nearbyDescription = t('Campingplätze in deiner Nähe');
  const nearbyHeadline = t('Umgebung');
  const suggestionsWithId = createIdOnItems(suggestions);
  const lastItemId = getLastItemId(suggestionsWithId);
  const isCursorOnLastItem = cursor === lastItemId;

  const handleNearbySelection = ({ suggestion }) => {
    hideSuggestions();
    setSelectedSuggestion(suggestion);
    setSearchTerm(suggestion.name);

    if (shouldNavigate) {
      window.location.href = withPrefix(suggestion?.slug);
    }
  };

  const handleSelection = useCallback(
    selectedItem => {
      const suggestion = selectedItem?.name
        ? { ...selectedItem, term: searchTerm }
        : {
            name: searchTerm,
            slug: `${searchPathPrefix}?term=${searchTerm}`,
            term: searchTerm,
            type: 'Search',
          };
      const tracking =
        suggestion?.type === 'Search' ? track.search : track.suggestion;

      setSearchTerm(suggestion.name);
      setSelectedSuggestion(suggestion);
      hideSuggestions(true);

      tracking(suggestion);

      if (shouldNavigate) {
        window.location.href = withPrefix(suggestion?.slug);
      }
    },
    [
      hideSuggestions,
      searchPathPrefix,
      searchTerm,
      setSearchTerm,
      setSelectedSuggestion,
      shouldNavigate,
    ]
  );

  const handleOnClickCampsiteNearby = () =>
    getUserLocation({
      description: nearbyDescription,
      handleNearbySelection,
      isSearchPage,
      searchPathPrefix,
      setIsActive,
      setIsLoading,
      shouldNavigate,
    });

  useEffect(() => {
    if (downPress) {
      setCursor(prev => (isCursorOnLastItem ? 0 : prev + 1));
    }
  }, [downPress, isCursorOnLastItem]);

  useEffect(() => {
    if (enterPress) {
      handleSelection(suggestionsWithId.find(({ id }) => id === cursor));
    }
  }, [cursor, enterPress, handleSelection, suggestionsWithId]);

  useEffect(() => {
    if (escPress) {
      hideSuggestions(true);
    }
  }, [escPress, hideSuggestions]);

  useEffect(() => {
    if (upPress) {
      setCursor(prev => (prev === 0 ? lastItemId : prev - 1));
    }
  }, [lastItemId, upPress]);

  return (
    <TileLoading.Provider value={false}>
      <div css={ß.searchBarDropDownWrapper}>
        {searchTerm ? (
          <SuggestionList
            cursor={cursor}
            handleSelection={handleSelection}
            searchPathPrefix={searchPathPrefix}
            setCursor={setCursor}
            shouldNavigate={shouldNavigate}
            suggestions={suggestionsWithId}
          />
        ) : (
          <>
            <CampsiteNearby
              description={nearbyDescription}
              handleOnClick={handleOnClickCampsiteNearby}
              headline={nearbyHeadline}
              isActive={isActive}
              isLoading={isLoading}
            />
            <PromotedSuggestions />
          </>
        )}
      </div>
    </TileLoading.Provider>
  );
};

SearchDropDown.propTypes = {
  hideSuggestions: PropTypes.func,
  shouldNavigate: PropTypes.bool,
};

SearchDropDown.defaultProps = {
  shouldNavigate: true,
};

export default memo(SearchDropDown);
