import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import MaybeData from '@contexts/MaybeData';
import { T } from '@fire/i18n/i18n-constants';
import { isSwiss } from '@utils/platform';

import PremiumPartnerItem from './PremiumPartnerItem';
import * as ß from './styles';

const prefix = T?.FooterPremiumPartner ?? '';

export const FooterPremiumPartner = () => {
  const pageContext = useContext(MaybeData);
  const { t } = useTranslation(prefix);

  const { partners = [] } = pageContext;
  const isSwitzerland = isSwiss(pageContext);
  const hasPartners = !isSwitzerland && partners && partners.length > 0;

  return hasPartners ? (
    <div css={ß.container}>
      <div css={ß.item}>
        <h5 css={ß.title}>{t('Premium Partner')}</h5>
      </div>
      <div css={ß.list}>
        {partners.map(({ id, ...props }) => (
          <PremiumPartnerItem id={id} key={id} {...props} />
        ))}
      </div>
    </div>
  ) : (
    false
  );
};

export default FooterPremiumPartner;
