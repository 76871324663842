import { css } from '@emotion/react';
import { media } from '@fire/_mediaQueries';

export const brand = css`
  width: 48px;
  max-width: 48px;
  height: 48px;
  max-height: 48px;

  ${media.lg} {
    width: 70px;
    max-width: 70px;
    height: 70px;
    max-height: 70px;
  }
`;
