import { bool, shape } from 'prop-types';

export const ADACAttributesType = shape({
  accept_dogs: bool,
  has_beach: bool,
  has_bread_roll_service: bool,
  has_kids_activities: bool,
  has_restaurant: bool,
  has_shops: bool,
  has_swimming_pool: bool,
  has_wifi: bool,
});

export default ADACAttributesType;
