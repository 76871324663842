import { css } from '@emotion/react';
import { media } from '@fire/_mediaQueries';

export const wrapper = css`
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  text-decoration: underline;
  img {
    margin-top: 1px;
    margin-left: 10px;
    margin-right: 5px;
    ${media.lg} {
      margin-top: 3px;
      margin-left: 1px;
    }
  }
  span {
    display: none;
    ${media.lg} {
      display: block;
    }
  }
`;

export const innerWrapper = css`
  position: relative;
`;
