import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useFilters } from '@contexts/Filters';
import useProsciutto from '@hooks/useProsciutto';

import * as ß from './styles';

const RemoveAllFiltersButton = memo(function RemoveAllFiltersButton({
  activeFilters,
  hasAnyActiveFilter,
  namespace,
}) {
  const [, dispatchContext] = useFilters();
  const [, prosciuttoDispatch] = useProsciutto();
  const { t } = useTranslation(namespace);

  const removeAllFilter = e => {
    e.preventDefault();
    dispatchContext({
      type: 'removeFilters',
      payload: activeFilters,
    });
    prosciuttoDispatch({
      type: 'setUrlParams',
      removeParams: activeFilters,
    });
  };

  return hasAnyActiveFilter ? (
    <a css={ß.clearFiltersLink} href="#" onClick={removeAllFilter}>
      {t('Alle Filter löschen')}
    </a>
  ) : (
    <></>
  );
});

RemoveAllFiltersButton.propTypes = {
  activeFilters: PropTypes.array,
  hasAnyActiveFilter: PropTypes.bool,
  namespace: PropTypes.string,
};

export default RemoveAllFiltersButton;
