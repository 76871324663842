import React, { Component } from 'react';
import { I18nextProvider } from 'react-i18next';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';

import { I18nProvider } from '@contexts/I18n';
import { getLanguageFromTarget } from '@utils/platform';

import setupI18next from './setupI18next';

const withI18nextComponent = Comp => {
  class I18n extends Component {
    constructor(props) {
      super(props);

      const { pageContext } = props;
      this.i18n = setupI18next(pageContext);
      this.activateLng();
    }

    activateLng = () => {
      const { data, pageContext } = this.props;
      const locize = data?.locize;
      const currentLanguage =
        pageContext?.language ?? getLanguageFromTarget(process.env.TARGET);

      if (!isEmpty(locize)) {
        locize?.edges.forEach(({ node }) => {
          const { language, namespace = 'messages', data: toParse } = node;
          const parsedData = JSON.parse(toParse);

          if (!this.i18n.hasResourceBundle(language, namespace)) {
            this.i18n.addResourceBundle(language, namespace, parsedData, true);
          }
        });
      }
      this.i18n.changeLanguage(currentLanguage);
    };

    componentDidUpdate(prevProps) {
      if (this.props.pageContext.language !== prevProps.pageContext.language) {
        this.activateLng();
      }
    }

    render() {
      return (
        <I18nextProvider i18n={this.i18n}>
          <I18nProvider {...this.props.pageContext}>
            <Comp {...this.props} language={this.props.pageContext.language} />
          </I18nProvider>
        </I18nextProvider>
      );
    }
  }
  I18n.propTypes = {
    pageContext: PropTypes.shape({
      language: PropTypes.string,
    }),
    data: PropTypes.shape({
      locize: PropTypes.shape({
        edges: PropTypes.array,
      }),
    }),
  };

  return I18n;
};

const withI18next = () => withI18nextComponent;

export default withI18next;
