import { graphql, useStaticQuery } from 'gatsby';

export const HomepageReviews = graphql`
  query HomepageReviews {
    allReviews {
      nodes {
        campsite {
          prn
          name
        }
        review {
          nickname
          rating
          headline
          comment
        }
        tile {
          country
          path
          images {
            url
          }
        }
      }
    }
  }
`;

const useReviews = () => {
  const data = useStaticQuery(HomepageReviews);
  return data?.allReviews?.nodes ?? [];
};

export default useReviews;
