import { AvailabilitiesDefaultProps } from '.';

export const DataDefaultProps = {
  data: {
    availabilities: AvailabilitiesDefaultProps,
    allReviews: {
      nodes: [
        {
          reviews: [],
        },
      ],
    },
    allImages: {
      nodes: [],
    },
    allLocale: {
      edges: [],
    },
  },
};

export default DataDefaultProps;
