import React from 'react';
import {
  Accordion as ReactAccordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { map, pathOr, pipe, propOr } from 'ramda';

import * as ß from '@components/Accordion/styles';
import Link from '@components/Link';
import { css } from '@emotion/react';
import { T } from '@fire/i18n/i18n-constants';

const namespace = pathOr('', ['Footer'], T);

export const renderFooterList = pipe(
  propOr([], 'items'),
  map(({ title, to, isExternalUrl }) => {
    const { t } = useTranslation(namespace);
    return (
      <li key={`${title}-${to}`}>
        <Link
          to={to}
          isExternalUrl={isExternalUrl}
          openInNewWindow={isExternalUrl}
        >
          {t(title)}
        </Link>
      </li>
    );
  })
);

const Accordion = ({ data = [] }) => {
  const { t } = useTranslation(namespace);
  return (
    <ReactAccordion allowMultipleExpanded allowZeroExpanded>
      {data &&
        data.map((list, index) => (
          <AccordionItem css={ß.item} key={`${list.title}-${index}`}>
            <AccordionItemHeading>
              <AccordionItemButton
                css={css`
                  ${ß.button}\n${ß.title}
                `}
              >
                {t(list.title)}
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel key={`${list.title}-item-panel-${index}`}>
              <ul css={ß.footerLinks}>{renderFooterList(list)}</ul>
            </AccordionItemPanel>
          </AccordionItem>
        ))}
    </ReactAccordion>
  );
};

Accordion.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node,
      items: PropTypes.arrayOf(Array).isRequired,
    }).isRequired
  ).isRequired,
};

export default Accordion;
