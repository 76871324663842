import { css } from '@emotion/react';
import { charcoal, gray5, white } from '@fire/_color';
import { bold, fontFace, fontSizeBody } from '@fire/_fonts';

export const travelDetails = css`
  align-items: center;
  display: flex;
  background-color: ${white};
  border-radius: 8px;
  padding: 12px 16px;
  min-height: 54px;

  button {
    ${fontFace};
    background: none;
    border: 0;
    color: ${charcoal};
    cursor: pointer;
    font-size: ${fontSizeBody};
    margin-left: 12px;
    text-decoration: underline;
    padding: 6px;
    border-radius: 4px;
    transition: all 0.25s ease;

    :hover {
      background-color: ${gray5};
    }
  }
`;

export const travelDates = css`
  cursor: pointer;
  text-decoration: underline;
  font-weight: ${bold};
`;
