import { css } from '@emotion/react';
import { gray5, gray8 } from '@fire/_color';
import { bold } from '@fire/_fonts';
import { media } from '@fire/_mediaQueries';

export const button = css`
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  p {
    max-width: 85%;
  }

  ::after {
    transform: rotate(45deg);
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    margin-left: 12px;
    border-bottom: 2px solid ${gray8};
    border-right: 2px solid ${gray8};
  }
`;

export const title = css`
  font-size: 20px;
  outline: none;
  font-weight: ${bold};
  a {
    font-weight: ${bold};
    text-decoration: none;
  }

  ${media.md} {
    display: flex;
  }
`;

export const item = css`
  padding: 14px 0px;
  border-bottom: 1px solid ${gray5};

  [aria-expanded='true'] {
    ${button}::after {
      transform: rotate(-135deg);
      margin-top: 10px;
    }
  }
`;

export const answer = css`
  padding-top: 16px;

  ${media.md} {
    padding-top: 6px;
  }
`;

export const headerTitle = css`
  font-size: 26px;

  margin: 16px 24px 24px 0;
`;
