import { css } from '@emotion/react';
import { white } from '@fire/_color';
import { skeletonBackground } from '@fire/_const';
import { bold } from '@fire/_fonts';
import { media } from '@fire/_mediaQueries';

const imageHeight = '140px';

export const slideshow = css`
  position: relative;
  width: 100%;
  padding-top: ${imageHeight};
`;

export const skeletonPlaceholder = css`
  ${slideshow}
  background: grey;
  ${skeletonBackground}
`;

export const slideshowImage = css`
  display: flex;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: ${imageHeight};
  width: 100%;
`;

export const placeholderText = css`
  font-weight: ${bold};
  color: ${white};
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    text-align: center;
    word-wrap: break-word; // IE11
    overflow-wrap: break-word;
    padding: 5px;
  }
`;

export const slideshowImageWrapper = css`
  position: absolute;
  top: 0px;
  height: 100%;
  width: 100%;
  display: flex;
`;

export const image = imageUrl => css`
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  ${imageUrl && `background-image: url(${imageUrl})`}
`;

export const lazyImage = css`
  padding-top: 0;
`;

export const slideshowButtons = css`
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  width: 100%;
  top: 45%;

  button {
    background: none;
    border: 0;

    &:focus {
      outline: none;
      opacity: 0.8;
    }

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }

  ${media.md} {
    top: 0;
    /* height: 112px; */
    height: 100%;
  }
`;

export const arrowLeft = css`
  transform: rotate(180deg);
`;

export const arrowRight = css`
  right: 0;
`;
