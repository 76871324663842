import React from 'react';
import PropTypes from 'prop-types';

import Link from '@components/Link';

import * as ß from './styles';

const SuggestionItem = ({
  cursor,
  handleSelection,
  highlight,
  id,
  setCursor,
  slug,
}) => {
  const isActive = cursor === id;

  const handleOnClick = event => {
    event.preventDefault();
    handleSelection();
  };

  return (
    <Link
      css={ß.suggestion(isActive)}
      key={slug}
      onClick={handleOnClick}
      onMouseEnter={() => setCursor(id)}
      to={slug}
    >
      <span dangerouslySetInnerHTML={{ __html: highlight }} />
    </Link>
  );
};

SuggestionItem.propTypes = {
  cursor: PropTypes.number,
  handleSelection: PropTypes.func,
  highlight: PropTypes.string,
  id: PropTypes.number,
  setCursor: PropTypes.func,
  slug: PropTypes.string,
};

export default SuggestionItem;
