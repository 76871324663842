import { ACCOMMODATIONS_ID } from '@components/AccommodationList/constants';
import {
  AVAILABILITY_INITIAL_STATE,
  AVAILABILITY_STORAGE_KEY,
  DEFAULT_TOTAL_ADULTS,
} from '@fire/constants';
import { pickRelevantParams } from '@hooks/useAvailabilitiesApi/utils';
import { formatDateISO8601, getValidDateRange } from '@utils/dateTime';
import { isEmptyOrNil } from '@utils/generic';
import {
  getUrlAndParams,
  objectToQuery,
  parseURLSearchParams,
  queryToObject,
} from '@utils/url';

export const kidsAgesParamsToArray = kidsAges => {
  if (isEmptyOrNil(kidsAges)) {
    return;
  }

  return kidsAges
    .toString()
    .split(',')
    .map(age => Math.abs(Number.parseInt(age)))
    .filter(age => !Number.isNaN(age));
};

export const getSafeAdultsValue = adults =>
  Math.abs(Number.parseInt(adults)) || DEFAULT_TOTAL_ADULTS;

// Generate availability link
export const getAvailabilityLink = ({
  dateFrom,
  dateTo,
  date_from,
  date_to,
  kidsAges = [],
  pagePath,
  hasHash,
  totalAdults,
}) => {
  const realDateFrom = date_from || dateFrom;
  const realDateTo = date_to || dateTo;

  const searchParams = new URLSearchParams(window?.location?.search);

  const { urlPath, paramsFromUrl } = getUrlAndParams(pagePath);

  if (realDateFrom && realDateTo) {
    searchParams.set('date_from', formatDateISO8601(realDateFrom));
    searchParams.set('date_to', formatDateISO8601(realDateTo));
    searchParams.set('adults', totalAdults);
    searchParams.set(
      'children',
      Array.isArray(kidsAges) ? kidsAges.toString() : ''
    );
  }

  searchParams.delete('page');
  searchParams.set('edited', true);

  const searchParamsObject = queryToObject(searchParams);
  Object.assign(searchParamsObject, paramsFromUrl);

  const query = objectToQuery(searchParamsObject);
  const hash = hasHash ? ACCOMMODATIONS_ID : '';
  const url = `${urlPath}${query}${hash}`;
  return url;
};

/**
 * Booking Data (Local Storage)
 */

// Get Booking Data from Local Storage as an object
export const getBookingData = () => {
  try {
    const storedData = JSON.parse(
      localStorage.getItem(AVAILABILITY_STORAGE_KEY)
    );
    const { date_from, dateFrom, date_to, dateTo } = storedData;

    const realDateFrom = date_from || dateFrom;
    const realDateTo = date_to || dateTo;

    return {
      ...storedData,
      dateFrom: realDateFrom,
      dateTo: realDateTo,
    };
  } catch {
    return;
  }
};

// Replace entire Booking Data with new object
export const saveBookingData = value => {
  if (isEmptyOrNil(value)) return;

  const { date_from, dateFrom, date_to, dateTo } = value;

  const realDateFrom = date_from || dateFrom;
  const realDateTo = date_to || dateTo;

  const saveObj = {
    ...value,
    dateFrom: undefined, // reset and remove fake one
    dateTo: undefined, // reset and remove fake one
    date_from: realDateFrom,
    date_to: realDateTo,
  };
  localStorage.setItem(AVAILABILITY_STORAGE_KEY, JSON.stringify(saveObj));
};

// Get initial state in this priority:
// 1. Query params
// 2. Local Storage
// 3. Default initial state
export const getInitialState = ({ hasDefaultValues = true, query }) => {
  const localStorageData = getBookingData() || {};
  const queryData = query ? parseURLSearchParams(query) : {};
  const relevantParams = pickRelevantParams(query);

  if (isEmptyOrNil(localStorageData) && isEmptyOrNil(relevantParams)) {
    return { ...AVAILABILITY_INITIAL_STATE(hasDefaultValues) };
  }

  const {
    date_from: dateFromLocalStorage,
    date_to: dateToLocalStorage,
    kidsAges: kidsAgesLocalStorage,
    totalAdults: totalAdultsLocalStorage,
  } = localStorageData;
  const {
    date_from: dateFromQuery,
    date_to: dateToQuery,
    children: kidsAgesQuery,
    adults: totalAdultsQuery,
  } = queryData;
  const dateFrom = dateFromQuery || dateFromLocalStorage;
  const dateTo = dateToQuery || dateToLocalStorage;
  const { endDate, startDate } = getValidDateRange(dateFrom, dateTo);
  const kidsAges = kidsAgesParamsToArray(kidsAgesQuery) || kidsAgesLocalStorage;
  const totalAdults = getSafeAdultsValue(
    totalAdultsQuery || totalAdultsLocalStorage
  );

  const response = {
    ...localStorageData,
    date_from: formatDateISO8601(startDate),
    date_to: formatDateISO8601(endDate),
    kidsAges,
    totalAdults,
  };

  saveBookingData({
    ...response,
  });

  return response;
};

// Update single entry of Booking Data
export const updateBookingData = (key, value) => {
  const data = getBookingData() ?? {};
  data[key] = value;

  localStorage.setItem(AVAILABILITY_STORAGE_KEY, JSON.stringify(data));
};

// Delete entire Booking Data
export const deleteBookingData = () =>
  localStorage.removeItem(AVAILABILITY_STORAGE_KEY);
