import React from 'react';
import PropTypes from 'prop-types';

import Link from '@components/Link';

import { SIZES, VARIANTS } from './consts';
import * as ß from './styles';
import Text from './Text';

const variantStyles = {
  danger: ß.danger,
  linkout: ß.linkout,
  primary: ß.primary,
  primaryOutlined: ß.primaryOutlined,
  success: ß.success,
};

const sizeStyles = {
  small: ß.small,
  large: ß.large,
};

const Button = ({
  children,
  disabled,
  isExternalUrl,
  isFullWidth,
  isTwoLines,
  marginBottom,
  onClick,
  openInNewWindow,
  rel,
  size,
  state,
  to,
  tracking,
  type,
  variant,
  isLoading,
}) => {
  const isLinkout = variant === 'linkout';
  const fullWidthStyle = isFullWidth && ß.fullWidth;
  const marginBottomStyle = marginBottom
    ? ß.marginBottom(marginBottom)
    : undefined;
  const disabledStyle =
    disabled || isLoading ? ß.disabled(isLinkout) : undefined;
  const twoLinesStyle = isTwoLines ? ß.twoLines : undefined;
  const loadingStyle = isLoading ? ß.loading : undefined;

  const styles = [
    variantStyles[variant],
    sizeStyles[size],
    fullWidthStyle,
    marginBottomStyle,
    disabledStyle,
    twoLinesStyle,
    loadingStyle,
  ];

  return to ? (
    <Link
      css={styles}
      isExternalUrl={isExternalUrl}
      openInNewWindow={openInNewWindow}
      to={to}
      onClick={onClick}
      rel={rel}
      role="button"
      tabIndex="0"
      {...tracking}
      state={state}
      disabled={disabled || isLoading}
    >
      <Text content={children} isLinkout={isLinkout} isLoading={isLoading} />
    </Link>
  ) : (
    <button
      type={type}
      onClick={onClick}
      css={styles}
      disabled={disabled || isLoading}
    >
      <Text content={children} isLinkout={isLinkout} isLoading={isLoading} />
    </button>
  );
};

Button.defaultProps = {
  isExternalUrl: false,
  isLoading: false,
  isTwoLines: false,
  isFullWidth: false,
  isLink: false,
  disabled: false,
  openInNewWindow: false,
  type: 'button',
  variant: 'primary',
};

Button.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  isExternalUrl: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  isLink: PropTypes.bool,
  isTwoLines: PropTypes.bool,
  marginBottom: PropTypes.number,
  onClick: PropTypes.func,
  openInNewWindow: PropTypes.bool,
  rel: PropTypes.string,
  size: PropTypes.oneOf(SIZES),
  state: PropTypes.object,
  to: PropTypes.string,
  tracking: PropTypes.object,
  type: PropTypes.string,
  variant: PropTypes.oneOf(VARIANTS),
  isLoading: PropTypes.bool,
};

export default Button;
