import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import MaybeData from '@contexts/MaybeData';
import useAvailabilityState from '@hooks/useAvailabilityState';
import { getAvailabilityLink, saveBookingData } from '@utils/availability';

export const AvailabilityStateContext = createContext();

const AvailabilityStateProvider = ({ children, hasHash }) => {
  const stateProps = useAvailabilityState();
  const [availabilityLink, setAvailabilityLink] = useState();
  const [isEditState, setIsEditState] = useState();

  const pageContext = useContext(MaybeData);

  const { dateFrom, dateTo, kidsAges, totalAdults } = stateProps;
  const pagePath = pageContext?.pagePath;

  useEffect(() => {
    setAvailabilityLink(
      getAvailabilityLink({
        dateFrom,
        dateTo,
        kidsAges,
        hasHash,
        pagePath,
        totalAdults,
      })
    );
  }, [dateFrom, dateTo, kidsAges, totalAdults, pagePath, hasHash]);

  return (
    <AvailabilityStateContext.Provider
      value={{
        ...stateProps,
        isEditState,
        setIsEditState,
        availabilityLink,
        saveBookingData,
        getAvailabilityLink,
      }}
    >
      {children}
    </AvailabilityStateContext.Provider>
  );
};

AvailabilityStateProvider.propTypes = {
  children: PropTypes.node,
  hasHash: PropTypes.bool,
};

AvailabilityStateProvider.defaultProps = {
  hasHash: true,
};

export default AvailabilityStateProvider;
