import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Link from '@components/Link';
import Slideshow from '@components/Slideshow';
import TileLoading from '@contexts/TileLoading';
import { T } from '@fire/i18n/i18n-constants';

import * as ß from '../styles';
import TileLabel from '../TileLabel';
import Content from './content';
import Footer from './footer';
import { clickOutEvent } from './tracking';

const PREFIX = T?.Tile;

const TileMicrocamping = ({
  id,
  index,
  images,
  title,
  description,
  price,
  path,
}) => {
  const { t } = useTranslation(PREFIX);

  const linkProps = {
    openInNewWindow: true,
    isExternalUrl: true,
    to: path,
  };

  return (
    <TileLoading.Provider value={false}>
      <div css={[ß.tileAnchorsWrapper, ß.safetyMargins]}>
        <div css={ß.safetyWrapper}>
          <div css={ß.tile(false)}>
            <Link
              {...linkProps}
              onClick={clickOutEvent({ id, price, title, index })}
            >
              <div css={ß.imagesWrapper}>
                <TileLabel isBookable message={t('Sofort buchbar')} />
                <Slideshow images={images} name={title} />
              </div>
            </Link>
            <div css={ß.tileWrapper}>
              <Content title={title} description={description} />
              <Footer
                path={path}
                price={price}
                onClick={clickOutEvent({ id, price, title, index })}
              />
            </div>
          </div>
        </div>
      </div>
    </TileLoading.Provider>
  );
};

TileMicrocamping.propTypes = {
  index: PropTypes.number,
  id: PropTypes.string,
  images: PropTypes.array,
  title: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.string,
  path: PropTypes.string,
};

TileMicrocamping.defaultProps = {
  path: '',
};

export default memo(TileMicrocamping);
