import React, { memo, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withPrefix } from 'gatsby';

import { ControlledAvailabilityBox } from '@components/AvailabilityBox/controlled';
import AvailabilityBoxHeader from '@components/Sidebar/Header';
import MaybeData from '@contexts/MaybeData';
import TileControlAvailabilityModal from '@contexts/TileControlAvailabilityModal';
import CloseIcon from '@fire/assets/close-tiny.svg';
import useModal from '@hooks/useModal';
import useTracking from '@hooks/useTracking';
import { MomentType } from '@types';

import Counter from './Counter';
import * as ß from './styles';
import {
  getAvailabilityBoxMobileProps,
  getDatepickerText,
  getGuestpickerText,
} from './utils';

const filterIcon = withPrefix('/icons/filter-icon.svg');

const FilterContainerMobile = memo(function FilterContainerMobile({
  dateFrom,
  dateTo,
  kidsAges,
  namespace,
  openMobileFilter,
  resetForm,
  totalAdults,
  count,
}) {
  const {
    prepareToTrackEvent: { clickEvent },
  } = useTracking('filter');
  const pageContext = useContext(MaybeData);
  const tileControlAvailabilityModal = useContext(TileControlAvailabilityModal);
  const setIsAvailabilityModalOpen = () => {
    tileControlAvailabilityModal?.setIsAvailabilityModalOpen?.();
  };

  const isAvailabilityModalOpen =
    tileControlAvailabilityModal?.isAvailabilityModalOpen;

  const {
    hide: hideBookingModal,
    show: showBookingModal,
    Modal: BookingModal,
  } = useModal();
  const { t } = useTranslation(namespace);

  const datepickerText = getDatepickerText({
    dateFrom,
    dateTo,
    fallbackText: t('Reisedaten'),
  });

  const guestpickerText = getGuestpickerText({
    dateFrom,
    dateTo,
    kidsAges,
    totalAdults,
    t,
  });

  const hasDates = dateFrom && dateTo;
  const availabilityBoxProps = {
    ...getAvailabilityBoxMobileProps(pageContext),
    dateFrom,
    dateTo,
    kidsAges,
    totalAdults,
  };

  const closeBookingModal = () => {
    setIsAvailabilityModalOpen(false);
    hideBookingModal();
  };

  const trackOpenForm = clickEvent('click open calendar');

  const trackShowBookingModal = () => {
    trackOpenForm();
    showBookingModal();
  };

  useEffect(() => {
    isAvailabilityModalOpen && showBookingModal();
  }, [isAvailabilityModalOpen, showBookingModal]);

  return (
    <>
      <div css={ß.filterContainerMobile}>
        <div css={ß.filterContainerButtonPanel}>
          <div css={ß.filterButton} onClick={trackShowBookingModal}>
            <span>{datepickerText}</span>
            <span css={ß.guestpickerText(hasDates)}>{guestpickerText}</span>
            {hasDates && (
              <div
                css={ß.resetFormButton}
                onClick={e => {
                  e.stopPropagation();
                  resetForm();
                }}
                role="button"
              >
                <CloseIcon />
              </div>
            )}
          </div>
          <button css={ß.filterButton} onClick={openMobileFilter}>
            <p css={ß.filterButtonText}>{t('Filter')}</p>
            <img css={ß.filterIcon} src={filterIcon} />
          </button>
        </div>
        <Counter count={count} />
      </div>

      <BookingModal>
        <>
          <AvailabilityBoxHeader isBookingBox isMobile />

          <ControlledAvailabilityBox
            {...availabilityBoxProps}
            focusOnLoad
            hideBookingModal={closeBookingModal}
          />
        </>
      </BookingModal>
    </>
  );
});

FilterContainerMobile.propTypes = {
  dateFrom: MomentType,
  dateTo: MomentType,
  hasActiveFilters: PropTypes.func,
  kidsAges: PropTypes.arrayOf(PropTypes.number),
  namespace: PropTypes.string,
  openMobileFilter: PropTypes.func,
  openMobileMap: PropTypes.func,
  resetForm: PropTypes.func,
  totalAdults: PropTypes.number,
  count: PropTypes.number.isRequired,
};

export default FilterContainerMobile;
