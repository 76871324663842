const localStorage = {
  checkCookies: () => {
    // sorry, it's ugly, go in peace my son
    // it's necessary because private navigation doesn't have some of them in some browsers
    // let this one pass 🙏
    if (typeof navigator === 'undefined') return false;
    return navigator?.cookieEnabled !== 'undefined';
  },
  checkLocalStorage: () => {
    if (
      typeof window !== 'undefined' ||
      typeof window?.localStorage !== 'undefined' ||
      typeof Storage !== 'undefined'
    ) {
      return true;
    }
    return false;
  },
  getItem: key => {
    if (localStorage.checkCookies()) {
      if (localStorage.checkLocalStorage()) {
        try {
          return window.localStorage.getItem(key);
        } catch {
          // Sentry.captureException(err);
          return false;
        }
      }
      return false;
    }
    return false;
  },
  setItem: (key, data) => {
    if (localStorage.checkCookies()) {
      if (localStorage.checkLocalStorage()) {
        try {
          return window.localStorage.setItem(key, data);
        } catch {
          // Sentry.captureException(err);
          return false;
        }
      }
      return false;
    }
    return false;
  },
  removeItem: key => {
    if (localStorage.checkCookies()) {
      if (localStorage.checkLocalStorage()) {
        try {
          return window.localStorage.removeItem(key);
        } catch {
          // Sentry.captureException(err);
          return false;
        }
      }
      return false;
    }
    return false;
  },
  clear: () => {
    if (localStorage.checkCookies()) {
      if (localStorage.checkLocalStorage()) {
        try {
          return window.localStorage.clear();
        } catch {
          // Sentry.captureException(err);
          return false;
        }
      }
      return false;
    }
    return false;
  },
};

export { localStorage };
