import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { addIndex, curry, drop, is, map, pipe, propOr } from 'ramda';

import { isEmptyOrNil } from '@fire/utils/generic';

const imap = addIndex(map);

const internalBreadcrumbs = ([name, id], index) => `{
	"@type": "ListItem",
	"position": ${index + 1},
	"item":
	{
		"@id": "${id}",
		"name": "${encodeURIComponent(name)}"
	}
}`;

const removeStart = drop(1);

const givePageAPath = curry((pagePath, bread) =>
  map(i => (is(String, i) ? [i, pagePath] : i), bread)
);

const addPrefixUrl = curry((siteUrl, bread) =>
  map(i => {
    const [name, pagePath] = i;
    return [name, `https://${siteUrl}${pagePath}`];
  })(bread)
);

export const MetaBreadcrumbs = ({ pageContext }) => {
  const pagePath = propOr('', 'pagePath', pageContext);
  const siteUrl = propOr('', 'siteUrl', pageContext);
  const givePageAPathWithPagePath = givePageAPath(pagePath);
  const addPrefixUrlWithSiteUrl = addPrefixUrl(siteUrl);
  const breadcrumbs = pipe(
    propOr([], 'breadcrumbs'),
    givePageAPathWithPagePath,
    removeStart,
    addPrefixUrlWithSiteUrl,
    imap(internalBreadcrumbs)
  )(pageContext);
  const id = `https://${siteUrl}${pagePath}`;
  return !isEmptyOrNil(breadcrumbs) ? (
    <Helmet>
      <script type="application/ld+json">{`
				{
					"@context": "https://schema.org",
					"@type": "BreadcrumbList",
					"@id": "${id}#breadcrumb",
					"itemListelement": [${breadcrumbs}]
				}
				`}</script>
    </Helmet>
  ) : (
    false
  );
};

MetaBreadcrumbs.propTypes = {
  pageContext: PropTypes.shape({
    breadcrumbs: PropTypes.array,
  }),
};

export default MetaBreadcrumbs;
