import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';

import AdLabel from '@components/AdLabel';
import Link from '@components/Link';
import { GATSBY_ENTRYPOINT } from '@fire/constants';
import { isEmptyOrNil } from '@fire/utils/generic';
import useTracking from '@hooks/useTracking';

import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import * as ß from './styles';

const cropConfig = 'auto=compress,format&crop=edges&fit=crop';

const buildImageUrl = (image, isBackground = false) => {
  const imageFileName = image?.fileName ?? false;
  const imageHandle = image?.handle ?? false;
  return `${GATSBY_ENTRYPOINT}/i/${encodeURIComponent(
    imageFileName
  )}?handle=${imageHandle}&${cropConfig}${isBackground && '&w=322&h=436'}`;
};

export const BannerAd = ({
  buttonText,
  description,
  headline,
  buttonLink,
  trackingId,
  logo,
  background,
  category,
}) => {
  const { prepareToTrackSpecialAds } = useTracking(
    'teaser special ads',
    category
  );
  const { ref, inView } = useInView();

  const onClick = prepareToTrackSpecialAds.specialAdsClick({ trackingId });

  const adsToTrack = prepareToTrackSpecialAds.specialAdsImpressions([
    { trackingId },
  ]);

  useEffect(() => {
    if (inView && trackingId) {
      adsToTrack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  const hasBanner = !isEmptyOrNil(background) && !isEmptyOrNil(logo);

  return hasBanner ? (
    <Link
      css={ß.link}
      openInNewWindow
      isExternalUrl
      to={buttonLink || '/'}
      onClick={onClick}
    >
      <div css={ß.wrapper} ref={ref}>
        <div css={ß.imageWrapper}>
          <img
            src={buildImageUrl(background, true)}
            className="lazyload"
            css={ß.imageCover}
            data-parent-fit="cover"
          />
        </div>
        <AdLabel />
        <div css={ß.container}>
          <div css={ß.content}>
            <h2>{headline}</h2>
            <p>{description}</p>
            <div css={ß.button}>
              <span>{buttonText}</span>
            </div>
          </div>
          <div css={ß.logo}>
            <picture>
              <img
                src={buildImageUrl(logo)}
                id={trackingId}
                className="lazyload"
              />
            </picture>
          </div>
        </div>
      </div>
    </Link>
  ) : (
    false
  );
};

BannerAd.propTypes = {
  buttonText: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
  trackingId: PropTypes.string.isRequired,
  logo: PropTypes.shape({
    altText: PropTypes.string,
    filename: PropTypes.string.isRequired,
    handle: PropTypes.string.isRequired,
    url: PropTypes.string,
  }),
  background: PropTypes.shape({
    altText: PropTypes.string,
    filename: PropTypes.string.isRequired,
    handle: PropTypes.string.isRequired,
    url: PropTypes.string,
  }),
  category: PropTypes.string.isRequired,
};

export default BannerAd;
