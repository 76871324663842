import { useEffect, useState } from 'react';

import { isEmptyOrNil } from '@utils/generic';

import { fetchDetails, getMethod } from './utils';

const useReservationRequest = ({ requestId, sessionId }) => {
  const [details, setDetails] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    const hasParams = !isEmptyOrNil(requestId) && !isEmptyOrNil(sessionId);

    setIsValid(hasParams);
  }, []);

  const submitRequest = async ({ isAccept = true, isGuest = false }) => {
    const hasParams = !isEmptyOrNil(requestId) && !isEmptyOrNil(sessionId);
    const method = getMethod({ isAccept, isGuest });

    if (!hasParams) {
      setIsValid(false);
      setIsLoading(false);
      return;
    }

    if (method) {
      setIsLoading(true);

      const { isError, data } = await method({ requestId, sessionId });

      setDetails(!isError && data);
      setIsValid(!isError);
      setIsLoading(false);
    }
  };

  const acceptCampsite = () =>
    submitRequest({ isAccept: true, isGuest: false });
  const refuseCampsite = () =>
    submitRequest({ isAccept: false, isGuest: false });
  const acceptGuest = () => submitRequest({ isAccept: true, isGuest: true });
  const resolveGuest = () => submitRequest({ isAccept: false, isGuest: true });

  const getDetails = async () => {
    const hasParams = !isEmptyOrNil(requestId) && !isEmptyOrNil(sessionId);

    if (!hasParams) {
      setIsValid(false);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    const { isError, data } = await fetchDetails({
      requestId,
      sessionId,
    });

    setDetails(!isError && data);
    setIsValid(!isError);
    setIsLoading(false);
  };

  return {
    acceptCampsite,
    acceptGuest,
    details,
    getDetails,
    isLoading,
    isValid,
    refuseCampsite,
    resolveGuest,
    setDetails,
    setIsLoading,
    setIsValid,
  };
};

export default useReservationRequest;
