import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';

import Brand from '@components/Brand';
import FavoriteHeartMenu from '@components/FavoriteHeartMenu';
import HeroMenu from '@components/HeroMenu';
import LanguageSelect from '@components/LanguageSelect';
import MobileMenu from '@components/MobileMenu';
import Navigation from '@components/Navigation';
import PlatformLogo from '@components/PlatformLogo';
import SearchBar from '@components/SearchBar';
import MaybeData from '@contexts/MaybeData';
import { LOCALES } from '@fire/constants';
import { useLocation } from '@reach/router';
import { trackObject } from '@utils/tracking';

import 'lazysizes';
import { heroMenuContentDE } from './content/navigationContent_DE';
import { heroMenuContentDECH } from './content/navigationContent_DECH';
import { heroMenuContentFRCH } from './content/navigationContent_FRCH';
import { heroMenuContentITCH } from './content/navigationContent_ITCH';
import * as ß from './styles';

const { DE, CH_DE, CH_IT, CH_FR } = LOCALES;

const heroMenuContentMap = {
  [DE]: heroMenuContentDE,
  [CH_DE]: heroMenuContentDECH,
  [CH_IT]: heroMenuContentITCH,
  [CH_FR]: heroMenuContentFRCH,
};

const getHeroMenuContentLocalized = language => heroMenuContentMap[language];

export const Header = ({ hideSearchBar }) => {
  const pageContext = useContext(MaybeData);
  const [activeItem, changeItem] = useState(-1);
  const [isOpen, show] = useState(false);

  const language = pathOr(DE, ['language'], pageContext);
  const heroMenuContent = getHeroMenuContentLocalized(language);

  const location = useLocation();
  const locationKey = location?.state?.key;

  const changeActiveItem = newItem =>
    changeItem(newItem === activeItem ? -1 : newItem);

  useEffect(() => {
    changeActiveItem(-1);
  }, [locationKey]);

  const handleBurgerClick = e => {
    e.preventDefault();
    e.stopPropagation();
    trackObject({
      event: 'navigation-portal_click-open-navigation-dropdown',
      category: 'navigation-portal',
      action: 'click open navigation dropdown',
      label: 'mobile',
    });
    show(true);
  };

  return (
    <>
      <LanguageSelect />
      <div css={ß.wrapper}>
        <div css={ß.wrapperLeft}>
          <Brand isHyperLink />
          <a href="#" title="menu" onClick={handleBurgerClick}>
            <span css={ß.burgerIcon} />
          </a>
          {!hideSearchBar && <SearchBar showIconOnlyOnMobile />}
          <div css={ß.hideOnDesktop}>
            <FavoriteHeartMenu />
          </div>
        </div>
        <div css={ß.wrapperRight}>
          <Navigation
            heroMenuContent={heroMenuContent}
            changeActiveItem={changeActiveItem}
            activeItem={activeItem}
          />
          <div css={ß.hideOnMobile}>
            <FavoriteHeartMenu tooltipSide="right" />
          </div>
          <PlatformLogo siteUrl={pageContext.siteUrl} language={language} />
        </div>
      </div>
      <HeroMenu
        heroMenuContent={heroMenuContent}
        activeItem={activeItem}
        changeActiveItem={changeActiveItem}
      />
      <MobileMenu
        heroMenuContent={heroMenuContent}
        isOpen={isOpen}
        show={show}
        pageContext={pageContext}
      />
    </>
  );
};

Header.propTypes = {
  hideSearchBar: PropTypes.bool,
};

Header.defaultProps = {
  hideSearchBar: false,
};

export default Header;
