import i18next from 'i18next';
import Pseudo from 'i18next-pseudo';

const withPseudo = process.env.GATSBY_WITH_PSEUDO === 'true';

function setupI18next({ fallbackLng, debug }) {
  i18next
    .use(
      new Pseudo({
        enabled: withPseudo, //convert to boolean
        languageToPseudo: 'dev',
      })
    )
    .init({
      compatibilityJSON: 'v3',
      keySeparator: false,
      postProcess: ['pseudo'],
      debug,
      defaultNS: 'messages',
      fallbackLng,
      interpolation: {
        escapeValue: false, // not needed for react!!
        skipOnVariables: false,
      },
      react: {
        useSuspense: false,
      },
    });

  return i18next;
}

export default setupI18next;
