// keep it in sync with plugins/faq-list-page/const.js
export const ALLOWED_LIST = [
  'country',
  'destination',
  'region',
  'place',
  'shape',
];

export const MIN_TILES = 5;
