import { css } from '@emotion/react';
import {
  blue3,
  blue9,
  blue10,
  gray8,
  green,
  greenDark,
  red9 as primaryRed,
  red10,
  white,
} from '@fire/_color';
import {
  bold,
  fontFace,
  fontSizeMedium,
  lineHeightDefault,
  regular,
} from '@fire/_fonts';
import { nonTouch } from '@fire/_mediaQueries';

const focus = css`
  &:focus:not(:focus-visible) {
    outline: 0;
  }

  &:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
  }
`;

export const button = css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  ${fontFace};
  font-weight: ${bold};
  width: auto;
  cursor: pointer;
  text-decoration: none;
  border-width: 2px;
  border-style: solid;
  border-image: initial;
  transition: all 0.32s ease;

  ${focus}
`;

const primaryInteraction = `
  background-color: ${blue10};
  border-color: ${blue10};
`;

export const primary = css`
  ${button};
  background-color: ${blue9};
  color: ${white};
  border-color: ${blue9};
  font-weight: ${bold};

  &:active {
    ${primaryInteraction}
  }

  ${nonTouch} {
    &:hover {
      ${primaryInteraction}
    }
  }
`;

const primaryOutlinedInteraction = `
  color: ${blue10};
  border-color: ${blue10};
`;

export const primaryOutlined = css`
  ${button};
  background-color: ${white};
  color: ${blue9};
  border-color: ${blue9};
  font-weight: ${bold};

  &:active {
    ${primaryOutlinedInteraction}
  }

  ${nonTouch} {
    &:hover {
      ${primaryOutlinedInteraction}
    }
  }
`;

const linkoutInteraction = `
  color: ${blue10};
  border-color: ${blue10};
`;

export const linkout = css`
  font-weight: ${bold};
  color: ${blue9};
  text-decoration: underline;
  border: none;
  background: none;
  padding: 0px;
  font-size: ${fontSizeMedium};
  cursor: pointer;
  position: relative;

  &:active {
    ${linkoutInteraction}
  }

  ${nonTouch} {
    &:hover {
      ${linkoutInteraction}

      span {
        transform: translateX(2px);

        :after {
          border-color: ${blue10};
        }
      }
    }
  }
`;

const successInteraction = `
  background-color: ${greenDark};
  border-color: ${greenDark};
`;

export const success = css`
  ${button};
  background-color: ${green};
  color: ${white};
  border-color: ${green};
  font-weight: ${bold};

  &:active {
    ${successInteraction}
  }

  ${nonTouch} {
    &:hover {
      ${successInteraction}
    }
  }
`;

const dangerInteraction = `
  background-color: ${red10};
  border-color: ${red10};
`;

export const danger = css`
  ${button};
  background-color: ${primaryRed};
  color: ${white};
  border-color: ${primaryRed};
  font-weight: ${bold};

  &:active {
    ${dangerInteraction}
  }

  ${nonTouch} {
    &:hover {
      ${dangerInteraction}
    }
  }
`;

export const arrow = css`
  transition: transform 0.2s ease;
  display: inline-block;
  height: 9px;
  label: arrow;
  margin-left: 4px;
  width: 7px;

  :after {
    position: absolute;
    right: 0;
    border-bottom: 1px solid ${blue9};
    border-right: 1px solid ${blue9};
    content: '';
    display: inline-block;
    height: 7px;
    width: 7px;
    transform: rotateZ(-45deg);
  }
`;

export const small = css`
  padding: 2px 6px;

  min-width: 104px;
  font-size: 14px;
  border-radius: 4px;
`;

export const large = css`
  padding: 10px 14px;

  font-size: 18px;
  border-radius: 8px;
`;

export const fullWidth = css`
  width: 100%;
`;

export const twoLines = css`
  white-space: pre-line;
  flex-direction: column;

  max-height: 40px;

  line-height: ${lineHeightDefault};

  padding-top: 1px;
  padding-bottom: 1px;

  span {
    font-weight: ${regular};

    b {
      font-weight: ${bold};
    }
  }
`;

export const marginBottom = margin => css`
  margin-bottom: ${margin}px;
`;

const notLinkoutDisabled = css`
  background-color: ${gray8};
  border-color: ${gray8};
  color: ${white};
`;

export const disabled = isLinkout => css`
  color: ${gray8};
  opacity: 0.8;
  cursor: not-allowed;
  pointer-events: none;

  /* Removing arrow on the linkout */
  [class*='arrow'] {
    display: none;
  }

  ${!isLinkout && notLinkoutDisabled}
`;

export const loading = css`
  background-color: ${blue3};
  border-color: ${blue3};
`;

export const buttonContent = isLoading => css`
  label: buttonContent;
  text-align: center;
  visibility: ${isLoading ? 'hidden' : 'initial'};
`;

export const loader = css`
  position: absolute;
`;
