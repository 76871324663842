import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { merge, pathOr, propOr } from 'ramda';

import { HomepageCOTMCarousel } from '@components/CampsiteOfTheMonthCarousel';
import Container from '@components/Container';
import CountriesCarousel from '@components/CountriesCarousel';
import { Footer } from '@components/Footer';
import Header from '@components/HeaderContainer';
import HeroImage from '@components/HeroImage';
import LinkedResources from '@components/LinkedResources';
import MagazineTeaser from '@components/MagazineTeaser';
import { HrefLang, SocialMedia } from '@components/Meta';
import PartnerLogos from '@components/PartnerLogos';
import RegionCarousel from '@components/RegionCarousel';
import HomePageReviews from '@components/Reviews/HomePageReviews';
import SEO from '@components/seo';
import AvailabilityStateProvider from '@contexts/AvailabilityState';
import { DatePickerProvider } from '@contexts/DatePicker';
import MaybeData from '@contexts/MaybeData';
import { SearchSuggestionsProvider } from '@contexts/SearchSuggestions';
import OrgLDJson from '@fire/components/Meta/OrgLDJson';
import MobileHomes from '@fire/components/MobileHomes';
import ProductCarousel from '@fire/components/ProductCarousel';
import SpecialAdsCarousel from '@fire/components/SpecialAdsCarousel';
import { GlobalStyles } from '@fire/globalStyles';
import withI18next from '@fire/i18n/withI18next';
import { isSwiss } from '@fire/utils/platform';
import { DimensionsProvider } from '@hooks/useDimensions';
import { PageView } from '@utils/tracking';

import * as ß from './styles';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }
  componentDidCatch(error, info) {
    console.error(info.componentStack);
    console.error(error);
  }
  render() {
    return process.env.NODE_ENV === 'development' && this.state.hasError ? (
      <strong>
        Something is wrong with this page, please see logs for more details
      </strong>
    ) : (
      this.props.children
    );
  }
}
ErrorBoundary.propTypes = { children: PropTypes.node };

const HomePage = props => {
  const pageContext = merge(
    propOr({}, 'pageContext', props),
    pathOr({}, ['data', 'currentPage', 'context'], props)
  );

  const buildTime = pathOr('', ['data', 'site', 'buildTime'], props);
  const isSwitzerland = isSwiss(pageContext);

  return (
    <MaybeData.Provider value={pageContext}>
      <DimensionsProvider>
        <AvailabilityStateProvider>
          <SearchSuggestionsProvider>
            <SEO pageContext={pageContext} />
            <OrgLDJson />
            <HrefLang />
            <LinkedResources pageContext={pageContext} />
            <GlobalStyles />
            <PageView />
            <Helmet>
              <title>{pageContext.title}</title>
              <meta name="buildtime" content={buildTime} />
              <meta name="apple-itunes-app" content="app-id=6444773343" />
            </Helmet>
            <SocialMedia pageContext={pageContext} />
            <ErrorBoundary>
              <Header hideSearchBar />
              <DatePickerProvider>
                <HeroImage />
              </DatePickerProvider>
              <Container fullWidth hasLargePadding>
                <CountriesCarousel
                  title="home-page-module>countries-title"
                  description="home-page-module>countries-subtitle"
                />
              </Container>
              <Container fullWidth hasLargePadding>
                <HomepageCOTMCarousel
                  title="pt-home-page:csp-of-the-month-title"
                  subtitle="pt-home-page:csp-of-the-month-subtitle"
                  cssModTitle={ß.campsiteOfTheMonthTitle}
                  cssModSubtitle={ß.campsiteOfTheMonthSubtitle}
                />
              </Container>
              <Container fullWidth hasLargePadding>
                <SpecialAdsCarousel />
              </Container>
              <Container hasLargePadding>
                <MobileHomes />
              </Container>
              <Container fullWidth hasLargePadding>
                <HomePageReviews />
              </Container>
              {isSwitzerland && (
                <Container fullWidth hasLargePadding>
                  <ProductCarousel />
                </Container>
              )}
              <Container fullWidth hasLargePadding>
                <div css={ß.partnerLogoWrapper}>
                  <PartnerLogos isCenter />
                </div>
              </Container>
              <Container fullWidth hasLargePadding>
                <RegionCarousel />
              </Container>
              <Container fullWidth hasLargePadding>
                <MagazineTeaser />
              </Container>
              <Footer pageContext={pageContext} />
            </ErrorBoundary>
          </SearchSuggestionsProvider>
        </AvailabilityStateProvider>
      </DimensionsProvider>
    </MaybeData.Provider>
  );
};

export const query = graphql`
  query HomePageLocale($prn: String!) {
    site {
      buildTime
    }
    currentPage: campfirePage(context: { prn: { eq: $prn } }) {
      path
      component
      context {
        pageType
        graphCMSLocale
        breadcrumbs
        body
        entity
        metaDescription
        metaRobots
        name
        h1
        seoText
        siteUrl
        title
        prn
        partners {
          clickoutUrl
          id
          logo {
            altText
            alt_text
            url
            title
            campsite
            copyright
            fileName
            handle
            isMain
            name
            position
          }
          trackingId
        }
      }
    }
    locize: allLocale(
      filter: {
        namespace: {
          in: [
            "pt-map-country"
            "pt-hero-menu"
            "pt-footer"
            "pt-premium-partners-footer"
            "pt-signup-newsletter"
            "pt-language-selector"
            "pt-review-banner"
            "pt-cookie-banner"
            "pt-home-page"
            "pt-search-box"
            "pt-suggestion-list"
            "pt-search-result-list-tile"
            "pt-search-result-filter"
            "availability-box"
            "pt-partner-ads"
            "pt-csp-review-list"
          ]
        }
      }
    ) {
      edges {
        node {
          data
          id
          language
          namespace
        }
      }
    }
  }
`;

export default withI18next()(HomePage);
