import { css } from '@emotion/react';
import { gray7, yellow9 } from '@fire/_color';
import { media } from '@fire/_mediaQueries';

const size = '15px';
const bigSize = '18px';

export const starRating = css`
  display: flex;
  flex-direction: row;
  align-self: center;
  align-items: center;
  justify-content: left;
`;

export const caption = css`
  font-size: 16px;

  ${media.md} {
    margin-left: 8px;
  }
`;

export const star = (color = yellow9, big) => css`
  display: flex;
  justify-content: flex-end;
  width: ${big ? bigSize : size};
  height: ${big ? bigSize : size};
  background: ${color};
  clip-path: polygon(
    50% 0%,
    61% 35%,
    98% 35%,
    68% 57%,
    79% 91%,
    50% 70%,
    21% 91%,
    32% 57%,
    2% 35%,
    39% 35%
  );
`;

export const half = big => css`
  ::after {
    content: '';
    background: ${gray7};
    height: ${big ? bigSize : size};
    width: 50%;
  }

  ~ .star {
    background: ${gray7};
  }
`;

export const rating = css`
  display: flex;
  flex-direction: row;
  align-self: center;
`;
