import { css } from '@emotion/react';
import { blue9, blue10, charcoal, white } from '@fire/_color';
import { dateInputPadding, zIndex } from '@fire/_const';
import {
  bold,
  fontFace,
  fontSizeMedium,
  lineHeightDefault,
} from '@fire/_fonts';
import { media } from '@fire/_mediaQueries';

export const dateRangePicker = css`
  // react-dates style
  .CalendarDay__selected_span {
    background: ${blue9};
    border-color: ${white};
    color: ${white};
  }

  .CalendarDay__selected {
    background: ${blue9};
    border-color: ${white};
    color: ${white};
  }

  .CalendarDay__selected:hover {
    background: ${blue9};
    border-color: ${white};
    color: ${white};
  }

  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    background: ${blue9};
    border-color: ${white};
    color: ${white};
  }

  .DateInput {
    width: 50%;

    input {
      ${fontFace}
      font-size: ${fontSizeMedium};
      font-weight: ${bold};
      color: ${charcoal};
    }
  }

  .DateInput_input {
    ${dateInputPadding}
  }

  .CalendarDay__selected_span:hover {
    background-color: ${blue10};
    border-color: ${blue10};
  }

  .CalendarMonthGrid {
    padding-top: 10px;

    ${media.md} {
      padding-top: 0;
    }
  }

  .CalendarMonth_caption {
    padding-bottom: 7px;
    padding-top: 22px;

    ${media.md} {
      padding: 20px 0 35px;
    }
  }

  .DayPickerNavigation__vertical {
    ${media.xs} {
      margin-top: 22px;
      position: relative;
    }
  }

  .CalendarDay__default {
    font-weight: ${bold};
    padding: 9px;
  }

  .DateInput_input__focused {
    border-bottom: 2px solid ${blue9};
  }

  .DateRangePicker_closeButton {
    position: fixed;
    z-index: ${zIndex.layerTop};
  }

  .DayPicker_weekHeader {
    background: #fff;
    border-bottom: 1px solid #dbdbdb;
    display: table-row;
    left: 0;
    margin-left: 0 !important; // I know, but it must be here
    position: fixed;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: ${zIndex.layerFour};
    line-height: ${lineHeightDefault};

    ${media.md} {
      background: transparent;
      border: 0;
      position: absolute;
      text-align: left;
      top: 60px;
    }
  }
`;

export const nav = css`
  height: 25px;
  position: absolute;
  top: 20px;
  width: 25px;
  width: 25px;

  &:hover {
    filter: brightness(0.5);
  }
`;

export const next = css`
  ${nav}
  position: absolute;
  right: 30px;
  transform: rotate(180deg);
`;

export const prev = css`
  ${nav}
  left: 28px;
`;
