import { array, arrayOf, shape, string } from 'prop-types';

import { AvailabilitiesType } from '.';

export const DataType = shape({
  data: shape({
    availabilities: AvailabilitiesType,
    allReviews: shape({
      nodes: arrayOf(
        shape({
          review: array,
        })
      ),
    }),
    allImage: shape({
      nodes: arrayOf(
        shape({
          name: string,
          base_url: string,
        })
      ),
    }),
    allLocale: shape({
      edges: arrayOf(
        shape({
          node: shape({
            data: string,
            id: string,
            language: string,
            namespace: string,
          }),
        })
      ),
    }),
  }),
});

export default DataType;
