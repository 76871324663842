import React, { useContext } from 'react';

import MaybeData from '@contexts/MaybeData';
import { isSwiss } from '@fire/utils/platform';

import ADACFooterAboutNewsletter from './ADACFooterAboutNewsletter';
import TCSFooterAboutNewsletter from './TCSFooterAboutNewsletter';

const FooterAboutNewsletter = () => {
  const pageContext = useContext(MaybeData);

  return isSwiss(pageContext) ? (
    <TCSFooterAboutNewsletter pageContext={pageContext} />
  ) : (
    <ADACFooterAboutNewsletter />
  );
};

export default FooterAboutNewsletter;
