import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withPrefix } from 'gatsby';

import Button from '@components/Button';
import { T } from '@fire/i18n/i18n-constants';
import useTracking from '@hooks/useTracking';
import { MomentType } from '@types';

import Nights from './Nights';
import * as ß from './styles';
import Tabs from './Tabs';
import { calculateLongestAvailableStay } from './utils';

const prefix = T?.AvailabilityBox ?? '';

const infoIcon = withPrefix('/icons/info-icon.svg');

const CalendarInfo = ({
  dateEnd,
  dateStart,
  headerTitle,
  isResetDisabled,
  resetDates,
  saveBookingData,
  setFocusedInput,
  tabs,
  unavailableDates,
}) => {
  const {
    prepareToTrackEvent: { clickEvent },
  } = useTracking('booking box');
  const { t } = useTranslation(prefix);
  const pickingEndDate = dateStart && !dateEnd;

  const trackOpenCalendar = clickEvent('click open calendar');

  const farthestEndDate = calculateLongestAvailableStay(
    dateStart,
    unavailableDates
  );

  const hasUnavailableDates =
    unavailableDates && pickingEndDate && farthestEndDate?.isAfter(dateStart);

  const handleClick = () => {
    setFocusedInput();

    saveBookingData?.();
  };

  useEffect(() => {
    trackOpenCalendar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div css={ß.calendarInfo}>
      <div css={ß.calendarHeader}>
        {headerTitle && <h5>{headerTitle}</h5>}
        {tabs && <Tabs resetDates={resetDates} tabs={tabs} />}
      </div>

      {hasUnavailableDates && (
        <div css={ß.dateInfo}>
          <img src={infoIcon} alt="Info icon" />
          <p>
            {t('maximum-stay', {
              startDate: dateStart.format('Do MMMM'),
              endDate: farthestEndDate.format('Do MMMM YYYY'),
            })}
          </p>
        </div>
      )}
      <div css={ß.wrapper}>
        <div css={ß.nights}>
          <Nights dateEnd={dateEnd} dateStart={dateStart} prefix={prefix} />
        </div>
        <div css={ß.actions}>
          <button
            css={ß.cancel}
            disabled={isResetDisabled}
            onClick={resetDates}
          >
            {t('Löschen')}
          </button>
          <div css={ß.applyButtonWrapper}>
            <Button
              isFullWidth
              onClick={handleClick}
              size="large"
              variant="primary"
            >
              {t('Anwenden')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

CalendarInfo.propTypes = {
  dateEnd: MomentType,
  dateStart: MomentType,
  headerTitle: PropTypes.string,
  isResetDisabled: PropTypes.bool,
  resetDates: PropTypes.func,
  saveBookingData: PropTypes.func,
  setFocusedInput: PropTypes.func,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      list: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  unavailableDates: PropTypes.array,
};

export default CalendarInfo;
