import React from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';

import GroupItem from './GroupItem';
import * as ß from './styles';

const Group = props => {
  const { group } = props;
  return group.length > 0 ? (
    <ul css={ß.group}>
      {group.map((item, index) => (
        <GroupItem
          {...props}
          key={`${propOr('', 'prn', item)}-${index}`}
          index={index}
          item={item}
        />
      ))}
    </ul>
  ) : (
    <></>
  );
};

Group.propTypes = {
  group: PropTypes.array,
};

Group.defaultProps = {
  group: [],
};

export default Group;
