import { css } from '@emotion/react';
import { carouselWrapper } from '@fire/_const';

export const tile = css`
  width: 100%;
  padding: 12px;
`;

export const wrapper = css`
  ${carouselWrapper}
`;

export const title = css`
  margin: 0 0 16px;
`;
