import React from 'react';
import PropTypes from 'prop-types';

import FormError from '@components/FormError';

import * as ß from './styles';

const Checkbox = ({
  checked,
  children,
  disabled,
  error,
  id,
  isTouched,
  label,
  name,
  onBlur,
  onChange,
  required,
}) => (
  <div css={ß.checkbox}>
    <label className="container">
      <span css={disabled && ß.disabled}>{label}</span>
      <input
        checked={checked}
        disabled={disabled}
        name={name}
        onBlur={onBlur}
        onChange={() => onChange(id, !checked)}
        required={required}
        type="checkbox"
      />
      <span className="checkmark"></span>
    </label>
    {children && <div css={ß.checkboxDescription}>{children}</div>}
    <FormError error={error} isTouched={isTouched} />
  </div>
);

// isTouched is formik based check
// to prevent errors on fields which
// have not been selected or unselected
// see more in FormError component
Checkbox.defaultProps = {
  isTouched: true,
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.object,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  id: PropTypes.string,
  isTouched: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  small: PropTypes.bool,
};

export default Checkbox;
