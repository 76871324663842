import { css } from '@emotion/react';
import { darkOverlay } from '@fire/_color';
import { media, mediaMax } from '@fire/_mediaQueries';
import { IMGIX_URL } from '@fire/constants';

export const container = css`
  display: flex;
  justify-content: center;
  width: 100%;
  ${mediaMax.lg} {
    padding: 12px;
  }
`;

export const wrapper = css`
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  ${media.lg} {
    padding: 12px;
  }
`;

export const heroTeaser = css`
  width: 50%;
  margin-right: 12px;
  display: flex;
  flex: 1;
  flex-direction: column;
  ${mediaMax.lg} {
    width: 100%;
    margin-right: 0;
    margin-bottom: 24px;
  }
  background-color: #f0f8fe;
`;

export const heroTeaserImage = image => css`
  flex: 1;
  min-height: 200px;
  width: 100%;
  background-image: url('${IMGIX_URL}/${image.name}.jpg?handle=${image.handle}&min-w=350&min-h=350&max-w=500&max-h=500&auto=compress,format&fp-x=0.74&fp-y=0.5&fit=crop');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${darkOverlay};
  background-blend-mode: overlay;
`;

export const heroTeaserContent = css`
  width: 100%;
  padding: 48px 32px;
  h3 {
    padding-bottom: 16px;
  }
  p {
    padding-bottom: 32px;
  }
`;

export const sideKick = css`
  width: 50%;

  ${mediaMax.lg} {
    width: 100%;
  }

  ${media.lg} {
    margin-left: 12px;
  }

  display: flex;
  flex-wrap: wrap;
  a {
    margin: 0;
    width: calc(50% - 12px);
    height: calc(50% - 12px);

    :nth-of-type(1) {
      margin-bottom: 24px;
    }
    :nth-of-type(2n + 1) {
      margin-right: 24px;
    }
    padding: 0;
    position: relative;

     {
      /* always square hackytty hack */
    }
    :after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
  }
`;
