const parseImages = images => images.map(img => ({ ...img, url: img?.path }));

export const parseToTile = campsites =>
  campsites.map(campsite => ({
    ...campsite,
    images: parseImages(campsite?.images),
    userRating: campsite?.rating?.average ?? 0,
    userRatingCount: campsite?.rating?.count ?? 0,
    tcs_attributes: {
      rating: campsite?.classification?.tcs,
    },
    rating: campsite?.classification?.adac,
  }));
