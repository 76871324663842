export const regions = {
  de: [
    {
      title: 'Camping am Gardasee',
      subtext:
        'Die Campingplätze am Gardasee locken mit ihrer herrlichen Lage an einem der schönsten Seen Europas.',
      imageKey: 'gardasee',
      link: 'Alle Campingplätze',
      url: '/campingurlaub/gardasee',
      trackLabel: 'gardasee',
    },
    {
      title: 'Camping in Istrien',
      subtext:
        'Von den Campingplätzen in Istrien direkt zum Meer, in die Altstadt oder Berge – Camping in Istrien ist so vielseitig wie die kroatische Halbinsel selbst.',
      imageKey: 'istrien',
      link: 'Alle Campingplätze',
      url: '/kroatien/gespanschaft-istrien',
      trackLabel: 'istrien',
    },
    {
      title: 'Camping am Lago Maggiore',
      subtext:
        'Camping am Lago Maggiore steht bei Campern für Entspannung und Ruhe auf traumhaften Campingplätzen in idyllischer Landschaft.',
      imageKey: 'lagoMaggiore',
      link: 'Alle Campingplätze',
      url: '/campingurlaub/lago-maggiore',
      trackLabel: 'lago-maggiore',
    },
    {
      title: 'Camping an der Ostsee',
      subtext:
        'Die Campingplätze an der Ostsee sind ein Paradies für alle Camper, die die Natur des Nordens in all ihrer Magie erleben wollen.',
      imageKey: 'ostsee',
      link: 'Alle Campingplätze',
      url: '/campingurlaub/ostsee',
      trackLabel: 'ostsee',
    },
    {
      title: 'Camping in Südfrankreich',
      subtext:
        'Camping in Südfrankreich heißt Entspannung an den Stränden der Côte d’Azur. Aktive wandern oder surfen nahe der Campingplätze in Südfrankreich.',
      imageKey: 'suedfrankreich',
      link: 'Alle Campingplätze',
      url: '/campingurlaub/suedfrankreich',
      trackLabel: 'suedfrankreich',
    },
    {
      title: 'Camping in der Toskana',
      subtext:
        'Bella Italia! Auf Campingplätzen in der Toskana kannst du zwischen Weingütern, Zypressenalleen und Olivenhainen übernachten.',
      imageKey: 'toskana',
      link: 'Alle Campingplätze',
      url: '/italien/toskana',
      trackLabel: 'toskana',
    },
    {
      title: 'Camping an der Côte d’Azur',
      subtext:
        'An der Côte d’Azur genießen Camper auf traumhaften Campingplätzen puren Glamour entlang der azurblauen Küste.',
      imageKey: 'coteDazur',
      link: 'Alle Campingplätze',
      url: '/campingurlaub/cote-d-azur',
      trackLabel: 'cote-dazur',
    },
    {
      title: 'Camping an Frankreichs Atlantikküste',
      subtext:
        'Die Campingplätze an der französischen Atlantikküste sind eingebettet in malerische Landschaften und nur einen Katzensprung von grandiosen Stränden entfernt.',
      imageKey: 'atlantikkuesteFrankreich',
      link: 'Alle Campingplätze',
      url: '/campingurlaub/atlantikkueste-frankreich',
      trackLabel: 'atlantikkueste-frankreich',
    },
    {
      title: 'Südtirol',
      subtext:
        'Südtirols Campingplätze bieten mediterranes Flair, kulinarische Köstlichkeiten und zahlreiche Aktivitäten inmitten einer atemberaubenden Bergkulisse.',
      imageKey: 'suedtirol',
      link: 'Alle Campingplätze',
      url: '/campingurlaub/suedtirol',
      trackLabel: 'suedtirol',
    },
    {
      title: 'Camping in Bayern ',
      subtext:
        'Ob ADAC Superplatz oder familiäres Idyll: Die Campingplätze in Bayern gehören zu den schönsten in Deutschland. ',
      imageKey: 'bayern',
      link: 'Alle Campingplätze',
      url: '/deutschland/bayern',
      trackLabel: 'bayern',
    },
  ],
  'de-CH': [
    {
      title: 'Lago Maggiore',
      subtext:
        'Camping am Lago Maggiore sorgt im Urlaub für Entspannung und Ruhe. Die wunderschöne idyllische Landschaft bietet zudem kulturelle Anreize.',
      imageKey: 'lagoMaggiore',
      link: 'Alle Campingplätze',
      url: '/campingferien/lago-maggiore',
      trackLabel: 'lago-maggiore',
    },
    {
      title: "Côte d'Azur",
      subtext:
        'Vor dem Panorama der Provenzalisch-Nizzardischen Voralpen lockt das azurblaue Wasser Sonnenanbeter und Aktivurlauber zum Camping an die Côte d’Azur.',
      imageKey: 'coteDazur',
      link: 'Alle Campingplätze',
      url: '/campingferien/cote-d-azur',
      trackLabel: 'cote-dazur',
    },
    {
      title: 'Toskana',
      subtext:
        'Beim Camping in der Toskana zwischen Weingütern, Zypressenalleen und Olivenhainen die Zelte aufschlagen oder auf den Campingplätzen in der Toskana übernachten.',
      imageKey: 'toskana',
      link: 'Alle Campingplätze',
      url: '/italien/toskana',
      trackLabel: 'toskana',
    },
    {
      title: 'Istrien',
      subtext:
        'Von den Campingplätzen in Istrien direkt zum Meer, in die Altstadt oder Berge – Camping in Istrien ist so vielseitig wie die kroatische Halbinsel selbst.',
      imageKey: 'istrien',
      link: 'Alle Campingplätze',
      url: '/kroatien/gespanschaft-istrien',
      trackLabel: 'istrien',
    },
    {
      title: 'Gardasee',
      subtext:
        'Wer sich für Camping am Gardasee entscheidet, kann in dem klaren Gewässer baden gehen, sich dem Wassersport widmen oder zum Wandern in die Berge aufbrechen.',
      imageKey: 'gardasee',
      link: 'Alle Campingplätze',
      url: '/campingferien/gardasee',
      trackLabel: 'gardasee',
    },
    {
      title: 'Ostsee',
      subtext:
        'Naturverbundene Urlauber finden beim Camping an der Ostsee ihr Paradies. Hochkarätige Events und vielseitige Aktivitäten sorgen für Unterhaltung.',
      imageKey: 'ostsee',
      link: 'Alle Campingplätze',
      url: '/campingferien/ostsee',
      trackLabel: 'ostsee',
    },
    {
      title: 'Südfrankreich',
      subtext:
        'Camping in Südfrankreich heißt Entspannung an den Stränden der Côte d’Azur. Aktive wandern oder surfen nahe der Campingplätze in Südfrankreich.',
      imageKey: 'suedfrankreich',
      link: 'Alle Campingplätze',
      url: '/campingferien/suedfrankreich',
      trackLabel: 'suedfrankreich',
    },
    {
      title: 'Atlantikküste Frankreich',
      subtext:
        'Beim Camping an der Atlantikküste in Frankreich erwarten Urlauber hervorragende Strände und pittoreske Landschaften.',
      imageKey: 'atlantikkuesteFrankreich',
      link: 'Alle Campingplätze',
      url: '/campingferien/atlantikkueste-frankreich',
      trackLabel: 'atlantikkueste-frankreich',
    },
    {
      title: 'Südtirol',
      subtext:
        'Camping in Südtirol bietet mediterranes Flair, kulinarische Köstlichkeiten und zahlreiche Aktivitäten inmitten einer atemberaubenden Bergkulisse.',
      imageKey: 'suedtirol',
      link: 'Alle Campingplätze',
      url: '/campingferien/suedtirol',
      trackLabel: 'suedtirol',
    },
    {
      title: 'Bayern',
      subtext:
        'Die schönste Umgebung inmitten der Alpen- und Seenkulisse auswählen und die Natur Bayerns beim Camping zwischen Flüssen, Tälern und Wiesen genießen.',
      imageKey: 'bayern',
      link: 'Alle Campingplätze',
      url: '/deutschland/bayern',
      trackLabel: 'bayern',
    },
  ],
  'it-CH': [
    {
      title: 'Lago Maggiore',
      subtext:
        'Il campeggio sul Lago Maggiore è garanzia di relax e pace in vacanza. Nel meraviglioso panorama idilliaco si trovano inoltre delle perle culturali.',
      imageKey: 'lagoMaggiore',
      link: 'Tutti i camping',
      url: '/vacanza-campeggio/lago-maggiore',
      trackLabel: 'lago-maggiore',
    },
    {
      title: 'Costa Azzurra',
      subtext:
        'Davanti al panorama delle Prealpi della Provenza e di Nizza, le acque azzurre accolgono turisti sportivi e appassionati di baneazione in campeggio in Costa Azzurra.',
      imageKey: 'coteDazur',
      link: 'Tutti i camping',
      url: '/vacanza-campeggio/costa-azzurra',
      trackLabel: 'cote-dazur',
    },
    {
      title: 'Toscana',
      subtext:
        'In campeggio in Toscana tra vigneti, viali di cipressi e oliveti, in tenda o nei camping della Toscana.',
      imageKey: 'toskana',
      link: 'Tutti i camping',
      url: '/italia/toscana',
      trackLabel: 'toskana',
    },
    {
      title: 'Istria',
      subtext:
        'Dai camping in Istria direttamente al mare, nei centri storici o in montagna: il campeggio in Istria è tanto variegato quanto la penisola croata stessa.',
      imageKey: 'istrien',
      link: 'Tutti i camping',
      url: '/croazia/istria',
      trackLabel: 'istrien',
    },
    {
      title: 'Lago di Garda',
      subtext:
        "Chi sceglie di andare in campeggio sul lago di Garda può nuotare nell'acqua limpida, praticare sport acquatici o fare escursioni a piedi sulle montagne circostanti.",
      imageKey: 'gardasee',
      link: 'Tutti i camping',
      url: '/vacanza-campeggio/lago-di-garda',
      trackLabel: 'gardasee',
    },
    {
      title: 'Mar Baltico',
      subtext:
        'Chi ama la natura trova un paradiso in campeggio sul mar Baltico. Eventi interessanti e tantissime attività rendono vivaci le giornate.',
      imageKey: 'ostsee',
      link: 'Tutti i camping',
      url: '/vacanza-campeggio/mar-baltico',
      trackLabel: 'ostsee',
    },
    {
      title: 'Sud della Francia',
      subtext:
        'Il campeggio nel sud delle Francia è sinonimo di relax sulle spiagge della Costa Azzurra. Gli sportivi fanno escursioni a piedi o surf vicino ai camping del sud della Francia.',
      imageKey: 'suedfrankreich',
      link: 'Tutti i camping',
      url: '/vacanza-campeggio/sud-della-francia',
      trackLabel: 'suedfrankreich',
    },
    {
      title: 'Costa atlantica della Francia',
      subtext:
        'In campeggio sulla costa atlantica della Francia i turisti trovano spiagge meravigliose e panorami pittoreschi.',
      imageKey: 'atlantikkuesteFrankreich',
      link: 'Tutti i camping',
      url: '/vacanza-campeggio/costa-atlantica-della-francia',
      trackLabel: 'atlantikkueste-frankreich',
    },
    {
      title: 'Alto Adige',
      subtext:
        'Il campeggio in Alto Adige offre il tipico fascino mediterraneo, delizie culinarie e la possibilità di praticare moltissime attività in un panorama montano mozzafiato.',
      imageKey: 'suedtirol',
      link: 'Tutti i camping',
      url: '/vacanza-campeggio/alto-adige',
      trackLabel: 'suedtirol',
    },
    {
      title: 'Baviera',
      subtext:
        'Scegliendo tra le zone più belle tra le Alpi e i laghi, si scopre la natura della Baviera in campeggio tra fiumi, valli e grandi prati.',
      imageKey: 'bayern',
      link: 'Tutti i camping',
      url: '/germania/baviera',
      trackLabel: 'bayern',
    },
  ],
  'fr-CH': [
    {
      title: 'Lac Majeur',
      subtext:
        'Un séjour en camping au lac Majeur est synonyme de détente et de tranquillité. En plus de cela, les panoramas grandioses de la région recèlent de véritables joyaux culturels.',
      imageKey: 'lagoMaggiore',
      link: 'Tous les campings',
      url: '/vacances-camping/lac-majeur',
      trackLabel: 'lago-maggiore',
    },
    {
      title: "Côte d'Azur",
      subtext:
        "Devant le panorama des Préalpes de Provence et de Nice, des eaux bleu azur attirent tous les amoureux du soleil et les voyageurs sportifs vers les campings de la Côte d'Azur.",
      imageKey: 'coteDazur',
      link: 'Tous les campings',
      url: '/vacances-camping/cote-d-azur',
      trackLabel: 'cote-dazur',
    },
    {
      title: 'Toscane',
      subtext:
        "En camping en Toscane, on monte la tente entre les vignes, les allées de cyprès et les oliveraies, mais on peut aussi passer la nuit dans l'un des campings de la région.",
      imageKey: 'toskana',
      link: 'Tous les campings',
      url: '/italie/toscane',
      trackLabel: 'toskana',
    },
    {
      title: 'Istrie',
      subtext:
        "Depuis les campings en Istrie, on peut tout aussi facilement se rendre à la mer, aller visiter un centre ville historique ou s'évader en montagne : le camping en Istrie est aussi diversifié que la péninsule croate elle-même.",
      imageKey: 'istrien',
      link: 'Tous les campings',
      url: '/croatie/comitat-istrie',
      trackLabel: 'istrien',
    },
    {
      title: 'Lac de Garde',
      subtext:
        "Si l'on opte pour un séjour en camping au lac de Garde, c'est aussi pour se baigner dans ses eaux cristallines, se consacrer aux sports aquatiques et partir en randonnée dans les montagnes.",
      imageKey: 'gardasee',
      link: 'Tous les campings',
      url: '/vacances-camping/lac-de-garde',
      trackLabel: 'gardasee',
    },
    {
      title: 'Mer Baltique',
      subtext:
        "Qui aime la nature se sentira comme au paradis lors d'un séjour en camping à la mer Baltique. Entre les nombreux événements et le menu étoffé d'activités à faire, le divertissement est garanti.",
      imageKey: 'ostsee',
      link: 'Tous les campings',
      url: '/vacances-camping/mer-baltique',
      trackLabel: 'ostsee',
    },
    {
      title: 'Sud de la France',
      subtext:
        "Le camping dans le sud de la France rime avec détente sur les plages de la Côte d'Azur. Et pour les amateurs de sport, les campings du sud de la France ne sont jamais bien loin des chemins de randonnée et des spots de surf.",
      imageKey: 'suedfrankreich',
      link: 'Tous les campings',
      url: '/vacances-camping/sud-de-la-france',
      trackLabel: 'suedfrankreich',
    },
    {
      title: 'Côte atlantique de France',
      subtext:
        'En séjour en camping sur la côte atlantique française, les vacanciers profitent de merveilleuses plages et de panoramas à couper le souffle.',
      imageKey: 'atlantikkuesteFrankreich',
      link: 'Tous les campings',
      url: '/vacances-camping/cote-atlantique',
      trackLabel: 'atlantikkueste-frankreich',
    },
    {
      title: 'Tyrol du Sud',
      subtext:
        'Partir en camping dans le Tyrol du Sud permet de découvrir son flair méditerranéen, ses merveilles culinaires ainsi que les innombrables activités de la région, et ce dans un panorama de montagne époustouflant.',
      imageKey: 'suedtirol',
      link: 'Tous les campings',
      url: '/vacances-camping/tyrol-du-sud',
      trackLabel: 'suedtirol',
    },
    {
      title: 'Bavière',
      subtext:
        "Avec une sélection des plus beaux endroits des Alpes et de la région des lacs, découvrez la belle nature qu'abrite la Bavière en campant entre ses rivières, ses vallées et ses immenses pâturages.",
      imageKey: 'bayern',
      link: 'Tous les campings',
      url: '/allemagne/baviere',
      trackLabel: 'bayern',
    },
  ],
};

export default regions;
