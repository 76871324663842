import { generatePurchaseId } from '@fire/components/Search/tracking';
import { isEmptyOrNil } from '@fire/utils/generic';
import { trackObject, trackObjectOnce } from '@utils/tracking';

export const trackOnView = (campsites = []) => {
  const track = {
    action: 'view product smallandcharming',
    category: 'list_smallandcharming',
    event: 'list_smallandcharming_view-product-list',
    label: 'smallandcharming carousel',
    impressions_list_smallandcharming: campsites,
  };
  return !isEmptyOrNil(campsites) && trackObjectOnce(track);
};

export const clickOutEvent =
  ({ prn, name, index }) =>
  () =>
    trackObject({
      event: 'list_smallandcharming_click-campsite-website',
      category: 'list_smallandcharming',
      action: 'click smallandcharming website',
      label: '(not set)',
      list: 'list_smallandcharming',
      purchase: {
        id: generatePurchaseId(prn),
        affiliation: 'click out',
        revenue: '1',
      },
      products: {
        variant: 'smallandcharming',
        position: index + 1,
        id: prn,
        name,
      },
    });
