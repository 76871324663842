import React, { useContext, useEffect, useMemo, useState } from 'react';
import { DateRangePicker } from 'react-dates';
import PropTypes from 'prop-types';

import { trackResetForm } from '@components/DatePicker/tracking';
import {
  getDatePickerId,
  getTabs,
  isDayBlocked,
} from '@components/DatePicker/utils';
import CampsitePageContext from '@contexts/CampsitePage';
import DatePickerContext from '@contexts/DatePicker';
import ArrowCircle from '@fire/assets/arrowCircle.svg';
import { isEmptyOrNil } from '@fire/utils/generic';
import { MomentType } from '@types';
import { deleteBookingData } from '@utils/availability';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import '$momentLocale';
import CalendarInfo from './CalendarInfo';
import * as ß from './styles';

export const InputDivider = () => <span></span>;

export const Empty = <></>;
export const Next = <ArrowCircle css={ß.next} />;
export const Prev = <ArrowCircle css={ß.prev} />;
export const NextMobile = (
  <span css={ß.nextMobile}>
    <ArrowCircle />
  </span>
);
export const PrevMobile = (
  <span css={ß.prevMobile}>
    <ArrowCircle />
  </span>
);

export const DatePicker = ({
  anchorDirection,
  dateFrom,
  dateTo,
  focusOnLoad,
  headerTitle,
  hideBookingModal,
  isAvailabilitySection,
  isDisabled,
  isMobile,
  openPicker,
  saveBookingData,
  setDateFrom,
  setDateTo,
  setOpenPicker,
  unavailableDates,
}) => {
  const cspContext = useContext(CampsitePageContext);
  const { closeDatePicker, isFocused, selectedTab } =
    useContext(DatePickerContext) ?? {};
  const [dateStart, changeDateStart] = useState(dateFrom);
  const [dateEnd, changeDateEnd] = useState(dateTo);
  const [focusedInput, setFocusedInput] = useState(
    isMobile && focusOnLoad ? 'startDate' : undefined
  );
  const [isResetDisabled, setIsResetDisabled] = useState(true);

  const { startDateId, endDateId } = getDatePickerId({
    isAvailabilitySection,
    isMobile,
  });

  const tabs = useMemo(
    () => getTabs(unavailableDates, cspContext?.availabilities?.list),
    [cspContext?.availabilities, unavailableDates]
  );

  const isPickingStartDate = focusedInput === 'startDate';
  const hasTabs = !unavailableDates && tabs.length > 1;
  const hasHeader = headerTitle || hasTabs;
  const selectedUnavailableDates = !isEmptyOrNil(unavailableDates)
    ? unavailableDates
    : tabs?.[selectedTab]?.list;

  const onDatesChange = ({ startDate, endDate }) => {
    changeDateStart(startDate);
    changeDateEnd(endDate);
    setDateFrom(startDate);
    setDateTo(endDate);
  };

  const onFocusChange = newFocusedInput => {
    closeDatePicker?.();
    setFocusedInput(newFocusedInput);
  };

  const resetDates = event => {
    event?.preventDefault?.();
    onDatesChange({ startDate: undefined, endDate: undefined });
    deleteBookingData();
    setFocusedInput('startDate');
    trackResetForm('calendar');
  };

  const renderCalendar = () => (
    <CalendarInfo
      dateEnd={dateEnd}
      dateStart={dateStart}
      headerTitle={headerTitle}
      isResetDisabled={isResetDisabled}
      resetDates={resetDates}
      saveBookingData={saveBookingData}
      setFocusedInput={setFocusedInput}
      tabs={hasTabs ? tabs : undefined}
      unavailableDates={selectedUnavailableDates}
    />
  );

  const close = (
    <span css={ß.close} onClick={hideBookingModal}>
      &times;
    </span>
  );

  useEffect(() => {
    document &&
      document
        .querySelector('.DayPicker_transitionContainer')
        ?.scrollIntoView?.();
  }, []);

  useEffect(() => {
    if (isFocused) {
      setFocusedInput('startDate');
    }
  }, [isFocused]);

  useEffect(() => {
    if (openPicker) {
      setFocusedInput('startDate');
    }
  }, [openPicker]);

  useEffect(() => {
    if (setOpenPicker) {
      setOpenPicker(!!focusedInput);
    }
  }, [focusedInput, setOpenPicker]);

  useEffect(() => {
    changeDateStart(dateFrom);
    changeDateEnd(dateTo);
  }, [dateFrom, dateTo]);

  useEffect(() => {
    setIsResetDisabled(!dateStart && !dateEnd);
  }, [dateEnd, dateStart]);

  return (
    <div className={hasHeader ? 'has-header' : ''} css={ß.dateRangePicker}>
      <DateRangePicker
        anchorDirection={anchorDirection}
        block
        calendarInfoPosition="bottom"
        customArrowIcon={<InputDivider />}
        customCloseIcon={close}
        disabled={isDisabled}
        disableScroll={false}
        displayFormat="DD.MM.YYYY"
        endDate={dateEnd}
        endDateId={endDateId}
        endDatePlaceholderText="–"
        firstDayOfWeek={1}
        focusedInput={focusedInput}
        hideKeyboardShortcutsPanel
        isDayBlocked={isDayBlocked({
          dateFrom,
          isPickingStartDate,
          selectedUnavailableDates,
        })}
        keepOpenOnDateSelect
        navNext={isMobile ? NextMobile : Next}
        navPrev={isMobile ? PrevMobile : Prev}
        numberOfMonths={isMobile ? 1 : 2}
        onDatesChange={onDatesChange}
        onFocusChange={onFocusChange}
        orientation={isMobile ? 'vertical' : 'horizontal'}
        readOnly // hide native keyboard on mobile
        renderCalendarInfo={renderCalendar}
        startDate={dateStart}
        startDateId={startDateId}
        startDatePlaceholderText="–"
        withFullScreenPortal={isMobile}
      />
    </div>
  );
};

DatePicker.propTypes = {
  anchorDirection: PropTypes.string,
  dateFrom: MomentType,
  dateTo: MomentType,
  focusOnLoad: PropTypes.bool,
  headerTitle: PropTypes.string,
  hideBookingModal: PropTypes.func,
  isAvailabilitySection: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isMobile: PropTypes.bool,
  openPicker: PropTypes.bool,
  saveBookingData: PropTypes.func,
  setDateFrom: PropTypes.func,
  setDateTo: PropTypes.func,
  setOpenPicker: PropTypes.func,
  unavailableDates: PropTypes.array,
};

DatePicker.defaultProps = {
  anchorDirection: 'right',
  focusOnLoad: false,
  isAvailabilitySection: false,
  isDisabled: false,
  isMobile: false,
  openPicker: false,
};

export default DatePicker;
