import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

const Image = ({ image, alt }) =>
  image ? (
    <Img
      {...image}
      key={image.src}
      alt={alt}
      imgStyle={{
        objectFit: 'cover',
        objectPosition: '50% 50%',
      }}
      style={{
        height: '100%',
      }}
      loading="eager"
    />
  ) : (
    <></>
  );

Image.defaultProps = {
  alt: 'Hero Image',
};

Image.propTypes = {
  image: PropTypes.object,
  alt: PropTypes.string,
};

export default Image;
