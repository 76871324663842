export const PAGETYPE_TO_LIST_MAP = {
  Campsite: 'csp',
  Destination: 'list',
  Topic: 'list',
  IndexPage: 'index page',
  Page: 'standard page',
};

export const PRN_TO_LIST_MAP = {
  'prn:page:home': 'home',
  'prn:page:search': 'search',
};

export const UNKNOW_LIST = 'unknown';
