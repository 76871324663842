import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { isEmpty, map, merge, pathOr, propOr, splitEvery } from 'ramda';

import Accordion from '@components/Accordion';
import { Breadcrumbs } from '@components/Breadcrumbs';
import { Footer } from '@components/Footer';
import Header from '@components/HeaderContainer';
import Layout from '@components/Layout';
import Link from '@components/Link';
import LinkedResources from '@components/LinkedResources';
import SocialMedia, { HrefLang, MetaBreadcrumbs } from '@components/Meta';
import Scroller from '@components/Scroller';
import SEO from '@components/seo';
import MaybeData from '@contexts/MaybeData';
import { SearchSuggestionsProvider } from '@contexts/SearchSuggestions';
import { css } from '@emotion/react';
import AvailabilityStateProvider from '@fire/contexts/AvailabilityState';
import withI18next from '@fire/i18n/withI18next';
import { PageContextType } from '@types';
import { HTMLText } from '@utils/HTMLText';
import { PageView } from '@utils/tracking';

import * as ß from './styles';

const getIndexListId = label => `index-list-label-${label}`;

const Labels = ({ indices }) =>
  indices.map(indice => {
    const { label, elements } = indice;
    if (isEmpty(elements)) {
      return <span key={`empty-label-${label}`}>{label}</span>;
    }
    return (
      <Scroller
        targetId={getIndexListId(label)}
        key={`label-${label}`}
        offset={90}
      >
        {label}
      </Scroller>
    );
  });

const getElements = map(element => (
  <Element
    key={`el-${element.link.text}-${element.link.url}`}
    element={element}
  />
));

const Title = ({ entry }) => {
  const { label, elements } = entry;
  const isActive = !isEmpty(elements) ? ß.labelActive : ß.labelInactive;
  return (
    <h3 key={`title-${label}`} css={css(ß.label, isActive)}>
      {label}
    </h3>
  );
};

Title.propTypes = {
  entry: PropTypes.shape({
    label: PropTypes.string,
    elements: PropTypes.array,
  }),
};

const List = ({ indices }) => {
  const indexes = splitEvery(9, indices);
  const group = indexes.map((item, index) => {
    const innerGroup = item.map(entry => (
      <div key={`i-${index}-${entry.label}`}>
        <Title entry={entry} />
        <ul id={getIndexListId(entry.label)}>{getElements(entry.elements)}</ul>
      </div>
    ));
    return (
      <div key={`${index}-innerGroup`} css={ß.column}>
        {innerGroup}
      </div>
    );
  });
  return <div css={ß.groupWrapper}>{group}</div>;
};

List.propTypes = {
  indices: PropTypes.array,
};

const Element = ({ element }) => {
  const { text, url } = element.link;
  return (
    <li>
      <Link to={url}>{text}</Link>
    </li>
  );
};

Element.propTypes = {
  element: PropTypes.shape({
    link: PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
    }),
  }),
};

const getAccordionData = indices =>
  indices
    .map(indice => {
      const title = <Title entry={indice} />;
      const items = !isEmpty(indice.elements)
        ? indice.elements.map(i => [i.link.text, i.link.url])
        : [];
      return {
        title,
        items,
      };
    })
    .filter(i => !isEmpty(i.items));

const IndexPage = props => {
  const pageContext = merge(
    propOr({}, 'pageContext', props),
    pathOr({}, ['data', 'currentPage', 'context'], props)
  );

  const teaser = pageContext?.teaser;

  return (
    <Layout>
      <MaybeData.Provider value={pageContext}>
        <AvailabilityStateProvider hasHash={false}>
          <SearchSuggestionsProvider>
            <PageView />
            <SEO pageContext={pageContext} />
            <HrefLang />
            <LinkedResources pageContext={pageContext} />
            <Helmet>
              <title>{pageContext.title}</title>
            </Helmet>
            <SocialMedia pageContext={pageContext} />
            <MetaBreadcrumbs pageContext={pageContext} />
            <Header />
            <div css={ß.container} id="page-container">
              <div css={ß.breadcrumbs}>
                <Breadcrumbs
                  crumbs={[['Start', '/'], `${pageContext.title}`]}
                />
              </div>
              <div css={ß.html}>
                <h1 css={ß.header}>{pageContext.h1}</h1>
                {teaser && <HTMLText source={teaser} />}
              </div>
              <aside css={ß.aside}>
                <Labels indices={pageContext.indices || []} />
              </aside>
              <div css={ß.accordion}>
                <Accordion
                  css={ß.accordion}
                  data={getAccordionData(pageContext.indices || [])}
                />
              </div>
              <List indices={pageContext.indices || []} />
            </div>
            <Footer pageContext={pageContext} />
          </SearchSuggestionsProvider>
        </AvailabilityStateProvider>
      </MaybeData.Provider>
    </Layout>
  );
};

export const query = graphql`
  query IndexPageData($prn: String!) {
    currentPage: campfirePage(context: { prn: { eq: $prn } }) {
      path
      component
      context {
        pageType
        graphCMSLocale
        breadcrumbs
        entity
        metaDescription
        metaRobots
        name
        h1
        seoText
        siteUrl
        title
        teaser
        indexType
        indices {
          elements {
            link {
              text
              url
            }
          }
          label
        }
        partners {
          clickoutUrl
          id
          logo {
            altText
            alt_text
            url
            title
            campsite
            copyright
            fileName
            handle
            isMain
            name
            position
          }
          trackingId
        }
      }
    }
    locize: allLocale(
      filter: {
        namespace: {
          in: [
            "pt-map-country"
            "pt-hero-menu"
            "pt-footer"
            "pt-premium-partners-footer"
            "pt-signup-newsletter"
            "pt-language-selector"
            "pt-review-banner"
            "pt-cookie-banner"
            "pt-search-box"
            "pt-suggestion-list"
            "pt-search-result-list-tile"
          ]
        }
      }
    ) {
      edges {
        node {
          data
          id
          language
          namespace
        }
      }
    }
  }
`;

IndexPage.propTypes = {
  pageContext: PageContextType,
  i18n: PropTypes.object,
};

IndexPage.defaultProps = {
  i18n: {
    t: key => key,
  },
};

export default withI18next()(IndexPage);
