import { css } from '@emotion/react';
import { fontFace } from '@fire/_fonts';

export const item = css`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const title = css`
  font-size: 20px;
  padding: 12px;
  margin-bottom: 8px;
`;

export const container = css`
  width: 100%;
`;

export const link = css`
  display: flex;
  justify-content: center;
  align-items: center;
  ${fontFace};
  height: 80px;
  overflow: hidden;
  position: relative;
`;

export const image = css`
  max-height: 80px;
`;

export const list = css`
  padding: 8px;
  display: grid;
  gap: 8px;
  grid-auto-flow: row;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
`;
