import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';

import MapPin from '@fire/assets/mapnew.svg';
import { T } from '@fire/i18n/i18n-constants';

import * as ß from './styles';

const namespace = pathOr('', ['SearchResult'], T);

const MobileMapToggle = ({ setModalMapOpen }) => {
  const { t } = useTranslation(namespace);

  return (
    <div css={ß.mapToggleWrapper}>
      <div css={ß.mapToggleButton} onClick={setModalMapOpen}>
        <MapPin css={ß.mapTogglePin} />
        <span>{t(`Karte`)}</span>
      </div>
    </div>
  );
};

MobileMapToggle.propTypes = {
  setModalMapOpen: PropTypes.func,
};

export default MobileMapToggle;
