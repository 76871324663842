import React from 'react';
import PropTypes from 'prop-types';

import * as ß from './styles';

const Tooltip = ({ tooltipTitle, tooltipText, side, children: v }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const tooltipDiv = (
    <div css={ß.tooltipWrapper}>
      <div css={side === 'left' ? ß.arrowLeft : ß.arrowRight}>
        <h4>{tooltipTitle}</h4>
        <p>{tooltipText}</p>
      </div>
    </div>
  );
  return (
    <div
      css={ß.tooltipContainer}
      tabIndex={0}
      onFocus={() => setIsOpen(true)}
      onBlur={() => setIsOpen(false)}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      {v}
      {isOpen && tooltipDiv}
    </div>
  );
};

Tooltip.propTypes = {
  tooltipTitle: PropTypes.string,
  tooltipText: PropTypes.string,
  side: PropTypes.string,
  children: PropTypes.node,
};

Tooltip.defaultProps = {
  tooltipText: '',
  tooltipTitle: '',
  side: 'left',
};
export default Tooltip;
