import { css } from '@emotion/react';

import {
  blue9,
  blue10,
  gray5,
  gray10,
  lightOverlay,
  shadow,
  skeletonGrey,
  white,
} from './_color';
import { bold, fontFace, fontSizeMedium } from './_fonts';
import { nonTouch } from './_mediaQueries';

export const borderRadiusLarge = `15px`;
export const borderRadius = `8px`;
export const borderRadiusSmall = '4px';

export const filterBarBoxShadow = css`
  box-shadow: 0 -5px 15px 0 ${lightOverlay};
`;

export const boxShadow = css`
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
`;

export const tileBoxShadow = css`
  box-shadow: 0 4px 8px 0 ${shadow};
`;

export const button = css`
  display: inline-block;
  text-align: center;
  ${fontFace};
  font-weight: ${bold};
  width: auto;
  cursor: pointer;
  text-decoration: none;
  border-width: 2px;
  border-style: solid;
  border-image: initial;

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

export const buttonSmall = css`
  padding: 2px 6px;

  width: auto;
  font-size: 14px;
  border-radius: 4px;
`;

export const buttonMedium = css`
  font-size: 16px;
  padding: 6px 16px;
`;

export const buttonDefault = css`
  font-size: 18px;

  padding: 10px 14px;
  border-radius: 8px;
`;

export const primaryButton = css`
  ${button};
  background-color: ${blue9};
  color: white;
  border-color: ${blue9};
  font-weight: ${bold};

  ${nonTouch} {
    &:hover {
      background-color: ${blue10};
      border-color: ${blue10};
    }
  }
`;

export const secondaryButton = css`
  ${button};
  ${buttonDefault};
  background-color: ${white};
  color: ${blue9};
  border-color: ${blue9};

  ${nonTouch} {
    &:hover {
      color: ${blue10};
      border-color: ${blue10};
    }
  }
`;

export const footerHeightMobile = 74;
export const footerHeightTablet = 105;

export const zIndexTop = 999;

export const carouselWrapper = css`
  width: 100%;
  position: relative;

  p {
    color: ${gray10};
  }
`;

export const hoverImage = css`
  &:hover {
    color: ${white};
    border-radius: ${borderRadiusSmall};
    background-color: ${blue9};
    background-blend-mode: overlay;
    background-image: none;
  }
`;

export const stickyCtaBoxHeight = 80;

export const zIndex = {
  layerNegative: -1,
  layerOne: 1,
  layerTwo: 10,
  layerThree: 20,
  layerFour: 30,
  layerFive: 40,
  layerSix: 9990,
  layerSeven: 9995,
  layerTop: 9999,
};

export const skeletonBackground = css`
  background: ${gray5};
  position: relative;
  overflow: hidden;

  &::after {
    will-change: transform;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    z-index: 1;
    animation: loading 1s infinite;
    transition: transform 300ms linear;
    transform: translateX(-100%);
    background: linear-gradient(
      90deg,
      transparent,
      ${skeletonGrey},
      transparent
    );

    @keyframes loading {
      100% {
        transform: translateX(100%);
      }
    }
  }
`;

export const closeButton = css`
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  background: ${blue9};
  color: ${white};
  font-size: 30px;
  text-align: center;

  &:hover {
    background: ${blue10};
  }
`;

// Date Picker
export const dateInputPadding = css`
  padding: 22px 0 5px 12px;
`;

export const dateInputLabel = css`
  position: absolute;
  width: 100%;
  z-index: 10;
  top: 4px;
  left: 12px;
  pointer-events: none;

  label {
    color: ${gray10};
    display: inline-block;
    font-size: ${fontSizeMedium};

    width: 50%;
    transform-origin: 0 0;
    transform: scale(0.85);
  }
`;
