import { instanceOf, number, oneOfType, string } from 'prop-types';

import { MomentType } from '@types/MomentType';

export const TimestampType = oneOfType([
  string,
  number,
  instanceOf(Date),
  MomentType,
]);

export default TimestampType;
