import { arrayOf, number, shape, string } from 'prop-types';

export const AvailabilitiesType = shape({
  categories: arrayOf(
    shape({
      category: string,
      count: number,
      accomodations: arrayOf(
        shape({
          id: string,
          position: number,
          name: string,
          min_guests: string,
          max_guests: string,
          description: string,
          images: arrayOf(
            shape({
              url: string,
            })
          ),
        })
      ),
    })
  ),
});

export default AvailabilitiesType;
