import { GATSBY_ENTRYPOINT } from '@fire/constants';
import { isEmptyOrNil } from '@utils/generic';

const cropConfig =
  'min-w=350&min-h=233&max-w=430&max-h=300&auto=compress,format&crop=edges&fit=crop';

export const IMAGE_PLACEHOLDER = () =>
  `${GATSBY_ENTRYPOINT}/i/placeholder-image.jpg?handle=KXy7SucqTjGP8gSNZEPR&${cropConfig}`;

const buildImageUrl = ({ image }) => {
  const imageFileName = image?.fileName ?? false;
  const imageHandle = image?.handle ?? false;
  return imageFileName && imageHandle
    ? `${GATSBY_ENTRYPOINT}/i/${encodeURIComponent(
        imageFileName
      )}?handle=${imageHandle}&${cropConfig}`
    : IMAGE_PLACEHOLDER();
};

export const getImage = item => {
  const image = item?.image ?? [];
  if (isEmptyOrNil(image)) {
    return IMAGE_PLACEHOLDER();
  }
  return buildImageUrl({ image });
};

export const hasEnoughTopRegions = regions => regions?.length > 0;

export const getInterlinkingData = interlinking => {
  if (hasEnoughTopRegions(interlinking?.interlinkingTopRegions)) {
    const interlinkingWithName = {
      interlinking: interlinking?.interlinkingTopRegions,
      name: 'Populäre Regionen',
    };
    return interlinkingWithName;
  }
  if (hasEnoughTopRegions(interlinking?.interlinkingTopTopics)) {
    const interlinkingWithName = {
      interlinking: interlinking?.interlinkingTopTopics,
      name: 'Populäre Themen',
    };
    return interlinkingWithName;
  }

  return false;
};
