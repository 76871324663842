import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { pathOr, pipe } from 'ramda';

import Accordion from '@components/Accordion';
import MaybeData from '@contexts/MaybeData';
import { LOCALES } from '@fire/constants';

import * as ß from './styles';
import { mapItems, renderCategories } from './utils';

const { DE } = LOCALES;

const FooterList = ({ items }) => {
  const pageContext = useContext(MaybeData);
  const language = pageContext?.language ?? DE;
  const list = pipe(pathOr([], [language]), mapItems)(items);

  return (
    <>
      <div css={ß.listMobile}>
        <Accordion data={list} />
      </div>
      <div css={ß.list}>{renderCategories(list)}</div>
    </>
  );
};

FooterList.propTypes = {
  items: PropTypes.object,
};

export default FooterList;
