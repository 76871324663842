import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Link from '@components/Link';
import MaybeData from '@contexts/MaybeData';
import { getTCSNewletterSignupUrl } from '@fire/utils/platform';
import useTracking from '@hooks/useTracking';

import * as ß from './styles';
import { PREFIX } from './utils';

const TCSFooterAboutNewsletter = () => {
  const {
    prepareToTrackEvent: { clickEvent },
  } = useTracking('footer newsletter');
  const pageContext = useContext(MaybeData);
  const { t } = useTranslation(PREFIX);

  const trackClickSubscribe = clickEvent('click newsletter subscribe');

  return (
    <div css={ß.newsletter}>
      <div>
        <h5>{t('signup-title-tcs')}</h5>
        <p>{t('signup-text-tcs')}</p>
        <Link
          to={getTCSNewletterSignupUrl(pageContext)}
          css={ß.buttonSmallTCS}
          openInNewWindow
          isExternalUrl
          onClick={trackClickSubscribe}
        >
          {t('subscribe-newsletter-tcs')}
        </Link>
      </div>
    </div>
  );
};

export default TCSFooterAboutNewsletter;
