import React from 'react';

import useCampsitesOfTheMonth from '@hooks/useCampsitesOfTheMonth';

import CampsiteOfTheMonthCarousel from './component';

const LISTPAGE = 'lp';

const ListpageCOTMCarousel = props => {
  const campsites = useCampsitesOfTheMonth({ pageType: LISTPAGE });
  return <CampsiteOfTheMonthCarousel {...props} campsites={campsites} />;
};

export default ListpageCOTMCarousel;
