import React, { createContext } from 'react';
import PropTypes from 'prop-types';

import { MAIN_LIST_MINIMUM } from './consts';

export const FlexibleDatesContext = createContext({});

FlexibleDatesContext.displayName = 'FlexibleDatesContext';

const dispatchFlexibleDates = dispatchPresunto => (dateFrom, dateTo) =>
  dispatchPresunto({
    type: 'setUrlParams',
    params: {
      date_from: dateFrom,
      date_to: dateTo,
    },
  });

export const FlexibleDatesProvider = ({
  children,
  prosciuttoState,
  dispatchProsciutto,
}) => {
  const {
    flexibleDates,
    lists,
    params: { date_from: originalDateFrom } = {},
  } = prosciuttoState ?? {};

  const mainList = lists?.[0];
  const hasMainList = mainList?.count <= MAIN_LIST_MINIMUM;
  const flexibleLength = flexibleDates?.length ?? 0;
  const hasFlexibleDates = flexibleLength > 0 && hasMainList;
  const dispatch = dispatchFlexibleDates(dispatchProsciutto);
  const value = { flexibleDates, hasFlexibleDates, dispatch, originalDateFrom };

  return (
    <FlexibleDatesContext.Provider value={value}>
      {children}
    </FlexibleDatesContext.Provider>
  );
};

FlexibleDatesProvider.propTypes = {
  children: PropTypes.node,
  prosciuttoState: PropTypes.shape({
    flexibleDates: PropTypes.array,
    lists: PropTypes.array,
    params: PropTypes.object,
  }),
  dispatchProsciutto: PropTypes.func,
};

export default FlexibleDatesContext;
