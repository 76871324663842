import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withPrefix } from 'gatsby';

import { NAMESPACE } from './constants';
import * as ß from './styles';

export const icons = {
  Destination: withPrefix('/icons/map-marker.svg'),
  Topic: withPrefix('/icons/sun.svg'),
  Campsite: withPrefix('/icons/camper-van.svg'),
};

const IconElement = ({ type }) =>
  icons[type] ? (
    <img css={ß.icon} alt={`${type}-icon`} src={icons[type]} />
  ) : (
    <></>
  );

IconElement.propTypes = {
  type: PropTypes.string,
};

export const SuggestionHeadline = ({ type }) => {
  const { t } = useTranslation(NAMESPACE);

  return (
    <>
      <hr />
      <div css={ß.headlineWrapper}>
        <IconElement type={type} />
        <p css={ß.headline}>{t(type)}</p>
      </div>
    </>
  );
};

SuggestionHeadline.propTypes = {
  searchTerm: PropTypes.string,
  suggestions: PropTypes.array,
  type: PropTypes.string,
};

export default SuggestionHeadline;
