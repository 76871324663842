import { css } from '@emotion/react';
import { blue9, white } from '@fire/_color';

export const tooltipContainer = css`
  align-items: center;
  display: flex;
  position: relative;

  &:focus {
    outline: none;
  }
`;

export const tooltipWrapper = css`
  label: tooltipWrapper;

  > div:last-of-type {
    display: block;
    width: 212px;
    color: ${white};
    background-color: ${blue9};
    position: absolute;
    padding: 16px;
    font-size: 16px;
    margin-top: 40px;
    margin-left: -12px;
    z-index: 12;
  }

  h4 {
    color: ${white};
    font-size: 20px;
  }

  p {
    margin-top: 15px;
    font-size: 16px;
  }
`;

const arrow = css`
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: ${blue9};
  top: -20px;
`;

export const arrowLeft = css`
  transform: translateX(-10%);
  &::before {
    ${arrow}
    left: 11px;
  }
`;

export const arrowRight = css`
  transform: translateX(-90%);
  &::before {
    ${arrow}
    right: 11px;
  }
`;
