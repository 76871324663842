import React from 'react';
import { graphql } from 'gatsby';

import Accepted from '@components/ReservationRequestConfirmation/guest/Accepted';
import withI18next from '@fire/i18n/withI18next';
import ReservationRequestPage from '@templates/ReservationRequest';

const ReservationRequestGuestAcceptedPage = () => (
  <ReservationRequestPage>
    <Accepted />
  </ReservationRequestPage>
);

export const query = graphql`
  query ReservationRequestGuestAcceptedPageData {
    locize: allLocale(
      filter: { namespace: { in: ["reservation-request-confirmation"] } }
    ) {
      edges {
        node {
          data
          id
          language
          namespace
        }
      }
    }
  }
`;

export default withI18next()(ReservationRequestGuestAcceptedPage);
