import React from 'react';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';

import * as ß from '../styles';

/* eslint-disable prettier/prettier */
/* prettier-ignore */
const PersonalInfoDescriptionEmail = ({t}) => (
<Trans i18nKey={'step-1-form.personal-info-description-email'} t={t}>
<p css={ß.infoText}>
Bitte gib deine E-Mail-Adresse an, damit wir dich authentifizieren können.
</p>
</Trans>
);

PersonalInfoDescriptionEmail.propTypes = {
  t: PropTypes.func.isRequired,
};

export default PersonalInfoDescriptionEmail;
