import { css } from '@emotion/react';
import { bold, fontSizeBody } from '@fire/_fonts';
import { media } from '@fire/_mediaQueries';

export const wrapper = css`
  padding: 5px 0;
  max-height: 90vh;
  overflow: auto;
`;

export const title = css`
  display: flex;
  padding: 11px 5px;

  p {
    font-weight: ${bold};
    font-size: ${fontSizeBody};
  }
`;

export const icon = css`
  width: 20px;
  height: 20px;
  margin: 2px 6px;
`;

export const tile = css`
  margin-bottom: 15px;
  padding: 0 30px;

  ${media.xs} {
    max-width: 50%;
    width: 100%;
    display: inline-block;
  }

  ${media.lg} {
    max-width: 100%;
    width: 100%;
    display: block;
  }
`;

export const tileHeader = css`
  position: relative;
  margin-bottom: 16px;
`;
