import { useContext } from 'react';
import moment from 'moment';

import { trackResetForm } from '@components/DatePicker/tracking';
import CampsitePageContext from '@contexts/CampsitePage';
import { ACCOMMODATION_PARAMS } from '@fire/constants';
import { deleteBookingData } from '@utils/availability';
import { isEmptyOrNil } from '@utils/generic';
import { parseURLSearchParams } from '@utils/url';

import { SHOW_MORE_THRESHOLD, TAB_OPTIONS } from './constants';

const relevantParams = {
  adults: 'adults',
  children: 'children',
  date_from: 'date_from',
  date_to: 'date_to',
  kids_ages: 'children',
  number_of_accommodations: 'number_of_accommodations',
  total_adults: 'adults',
};

export const pickRelevantParams = params => {
  const parsedParams = parseURLSearchParams(params);

  return Object.keys(relevantParams).reduce((list, param) => {
    if (!isEmptyOrNil(parsedParams[param])) {
      const key = relevantParams[param];
      list[key] = parsedParams[param];
    }

    return list;
  }, {});
};

export const resetDatesAndRemoveQuery = () => {
  const currentPath = window?.location?.pathname;
  trackResetForm('notification');
  deleteBookingData();
  window?.location?.assign?.(currentPath);
};

export const getSuggestion = pageContext => {
  const breadcrumbs = pageContext?.breadcrumbs ?? [];
  const index = breadcrumbs.length > 2 ? 2 : 1;
  const [suggestionText, suggestionLink] = breadcrumbs[index] ?? [];

  return breadcrumbs.length > 2 && { suggestionLink, suggestionText };
};

export const checkAvailabilities = ({ list = [] }) =>
  !isEmptyOrNil(list) &&
  list.some(({ accommodations }) => !isEmptyOrNil(accommodations));

export const checkSearchParams = string =>
  [...Array.from({ length: 4 })].every((_, index) =>
    string.includes(ACCOMMODATION_PARAMS[index])
  );

export const prepareAvailabilities = availabilities =>
  availabilities?.list
    ? [
        ...availabilities.list.map(({ accommodations, ...item }) => ({
          ...item,
          accommodations: accommodations.slice(0, SHOW_MORE_THRESHOLD),
        })),
      ]
    : [];

export const showMore =
  ({ availabilities, list, setList }) =>
  index => {
    const currentList = [...list];
    currentList[index] = { ...availabilities.list[index] };

    setList(currentList);
  };

const [, ...restTabOptions] = TAB_OPTIONS;

const getAllTotal = list =>
  list.length > 0
    ? [...list].reduce(
        (accumulator, currentValue) =>
          Number.parseInt(currentValue.count) + accumulator,
        0
      )
    : 0;
const getCatCount = list =>
  restTabOptions.map(option => {
    const item = [...list].find(i => i.category === option);
    return Number.parseInt(item?.count ?? 0);
  });

export const getCountArray = list => [getAllTotal(list), ...getCatCount(list)];

export const useFlexibleDates = () => {
  const campsitePageContext = useContext(CampsitePageContext);
  const availabilities = campsitePageContext?.availabilities || {};
  const flexibleDates = availabilities?.flexibleDates || [];
  const hasFlexibleDates = flexibleDates.length > 0;

  return {
    flexibleDates,
    hasFlexibleDates,
  };
};

export const daysApart = (dayA, dayB) => {
  const mDayA = moment(dayA);
  const mDayB = moment(dayB);
  return mDayA.diff(mDayB, 'days');
};

export const getDaysApartKey = (dayA, dayB) => {
  const days = daysApart(dayA, dayB);
  if (days > 0) {
    // X Day Later
    return 'deviation-days-later';
  }
  // all -X days:
  // X Day Earlier
  return 'deviation-days-earlier';
};
