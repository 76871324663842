import React, { createContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { BREAKPOINTS } from '@fire/_mediaQueries';
import { useDimensions } from '@hooks/useDimensions';

const INITIAL_STATE = {
  searchTerm: '',
  suggestions: [],
  selectedSuggestion: {},
};

export const SearchSuggestionsContext = createContext();

export const SearchSuggestionsProvider = ({ children }) => {
  const { width } = useDimensions();
  const inputRefMobile = useRef();
  const inputRefDesktop = useRef();
  const [inputRef, setInputRef] = useState(inputRefMobile);
  const [searchTerm, setSearchTerm] = useState(INITIAL_STATE.searchTerm);
  const [suggestions, setSuggestions] = useState(INITIAL_STATE.suggestions);
  const [selectedSuggestion, setSelectedSuggestion] = useState(
    INITIAL_STATE.selectedSuggestion
  );

  const blurInput = () => inputRef?.current?.blur?.();
  const focusInput = () => inputRef?.current?.focus?.();

  const reset = () => {
    setSearchTerm('');
    setSelectedSuggestion({});
    setSuggestions([]);
  };

  useEffect(() => {
    const isMobile = width < BREAKPOINTS.xs;

    setInputRef(isMobile ? inputRefMobile : inputRefDesktop);
  }, [width]);

  return (
    <SearchSuggestionsContext.Provider
      value={{
        blurInput,
        focusInput,
        inputRef,
        inputRefMobile,
        inputRefDesktop,
        reset,
        searchTerm,
        selectedSuggestion,
        setSearchTerm,
        setSelectedSuggestion,
        setSuggestions,
        suggestions,
      }}
    >
      {children}
    </SearchSuggestionsContext.Provider>
  );
};

SearchSuggestionsProvider.propTypes = {
  children: PropTypes.node,
};

export default SearchSuggestionsContext;
