import { css } from '@emotion/react';
import { darkOverlay, white } from '@fire/_color';
import { hoverImage } from '@fire/_const';
import { bold } from '@fire/_fonts';
import { IMGIX_URL } from '@fire/constants';

export const bigTile = image => css`
  background-image: url('${IMGIX_URL}/${image.name}.jpg?handle=${image.handle}&max-w=687&max-h=511&auto=compress,format&fp-x=0.74&fp-y=0.5&fit=crop');
  height: 437px;
  width: 453px;
  margin-top: 16px;
  padding: 3px;
  font-size: 20px;
  font-weight: ${bold};
  justify-self: stretch;
  order: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${darkOverlay};
  background-blend-mode: overlay;
  color: ${white};
  word-break: break-all;
  text-decoration: none;
  ${hoverImage}
`;

export const headline = css`
  font-size: 20px;
  font-weight: ${bold};
  margin-bottom: 16px;
  text-decoration: none;
`;
