import {
  always,
  anyPass,
  append,
  curry,
  equals,
  ifElse,
  pathOr,
  pipe,
  reduce,
  type,
} from 'ramda';

import { meterOrKilometer } from '@utils/generic';

export const getValue = x => (typeof x === 'string' ? x : x[0]);
export const getSecondValue = x => Array.isArray(x) && x.length > 0 && x[1];
const getGroupKeyFPYeah = curry((group, key) => {
  const value = pathOr(
    pathOr(false, [key], group),
    ['adac_attributes', key],
    group
  );

  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
});

const hasDistanceInTheKey = name => name?.includes?.('distance');

export const getAttribute = curry((group, key) => {
  const value = pipe(getValue, getGroupKeyFPYeah(group))(key);
  const keyIsArray = Array.isArray(key);
  const isDistance = keyIsArray
    ? key.some(hasDistanceInTheKey)
    : hasDistanceInTheKey(key);
  const isNumber = Number.isFinite(value);

  if (isDistance && isNumber) {
    return meterOrKilometer(value);
  }

  if (keyIsArray && Array.isArray(key?.[1])) {
    return key[1].some(i => getAttribute(group, i));
  }

  return value;
});

export const hasChildren = (values, group) => values.some(getAttribute(group));

const checkTruthyAttribute = anyPass([
  equals(true),
  equals('true'),
  attr => equals('Number', type(attr)) && attr !== 0,
  attr => /^[1-9]/g.test(attr),
]);

export const filterTruthyAttributes = (values, attributes) =>
  reduce(
    (acc, value) =>
      ifElse(
        checkTruthyAttribute,
        () => append(value, acc),
        always(acc)
      )(getAttribute(attributes, value)),
    []
  )(values);
