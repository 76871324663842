// Reds
export const red10 = '#C90000';
export const red9 = '#E42612';
export const red8 = '#FF381D';
export const red7 = '#FF8063';
export const red6 = '#FFA590';
export const red5 = '#FDEDE7';

// Blues
export const blue10 = '#18548A';
export const blue9 = '#1F74AC';
export const blue8 = '#2897D3';
export const blue7 = '#5ABFE9';
export const blue6 = '#85D1F0';
export const blue5 = '#E1F4FC';
export const blue4 = '#ebf2f8';
export const blue3 = '#92ADBE';

// Yellow
export const yellow10 = '#FFC413';
export const yellow9 = '#FFCC00';
export const yellow8 = '#FFD642';
export const yellow7 = '#FFE689';
export const yellow6 = '#FFEBA1';
export const yellow5 = '#FFF5D0';

// Greens
export const green = '#008662';
export const greenDark = '#02654a';

// Grays
export const gray10 = '#6f6f6f';
export const gray9 = '#7d7d7d';
export const gray8 = '#9a9a9a';
export const gray7 = '#c5c5c5';
export const gray6 = '#d4d4d4';
export const gray5 = '#f0f0f0';
export const gray4 = '#FBFBFB';

// Transparents
export const tertiaryTransparent = '#65b2e44f';
export const darkOverlay = 'rgba(0, 0, 0, 0.4)';
export const adGrey = 'rgba(0,0,0,0.3)';
export const shadow = 'rgba(0, 0, 0, 0.1)';
export const lightOverlay = 'rgba(0, 0, 0, 0.1)';
export const lightShadow = 'rgba(0, 0, 0, 0.05)';
export const skeletonGrey = 'rgba(255, 255, 255, 0.15)';
export const glass = 'rgba(255, 255, 255, 0.90)';
export const whiteHalfTransparent = 'rgba(255, 255, 255, 0.50)';

// Pincamp.ch specific
export const brandSwisscampPink = '#e0547e';

// General
export const white = '#fff';
export const black = '#000';
export const charcoal = '#484848';

// make safari happy
export const transparent = 'rgba(255, 255, 255, 0)';
