import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import {
  always,
  curry,
  dissoc,
  equals,
  mergeAll,
  pipe,
  reduce,
  when,
} from 'ramda';

export const FiltersStateContext = createContext();
export const FiltersDispatchContext = createContext();

FiltersStateContext.displayName = 'FiltersStateContext';
FiltersDispatchContext.displayName = 'FiltersDispatchContext';

const typeEq = (typeX, typeY) => always(equals(typeX, typeY));

const reducer = (state, { type, ...action }) =>
  pipe(
    when(typeEq('setFilters', type), s => mergeAll([s, action.payload])),
    when(typeEq('removeFilters', type), s =>
      reduce((acc, item) => dissoc(item, acc), s, action.payload)
    ),
    when(typeEq('clearFilters', type), always({}))
  )(state);

const FiltersProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {});

  return (
    <FiltersStateContext.Provider value={state}>
      <FiltersDispatchContext.Provider value={dispatch}>
        {children}
      </FiltersDispatchContext.Provider>
    </FiltersStateContext.Provider>
  );
};

FiltersProvider.propTypes = {
  children: PropTypes.node,
};

export const withFiltersContext = curry((C, props) => (
  <FiltersProvider>
    <C {...props} />
  </FiltersProvider>
));

export const useFilters = () => {
  const stateContext = useContext(FiltersStateContext);
  const dispatchContext = useContext(FiltersDispatchContext);

  if (equals(undefined, stateContext) || equals(undefined, dispatchContext)) {
    throw new Error('useFilters must be used within a FiltersProvider');
  }

  return [stateContext, dispatchContext];
};
