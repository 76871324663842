import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { truncateString } from '@fire/utils/string';

import * as ß from '../TileContent/styles';
import { contentHeight } from './styles';

const Content = ({ title, description }) => (
  <div css={[ß.tileContent, contentHeight]}>
    {title && <h5 css={ß.title}>{title}</h5>}
    {description && <p>{truncateString(description, 150)}</p>}
  </div>
);

Content.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default memo(Content);
