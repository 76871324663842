import React from 'react';
import PropTypes from 'prop-types';

import Loader from './Loader';
import * as ß from './styles';

const Text = ({ content, isLinkout, isLoading }) => (
  <>
    {isLoading && <Loader />}
    <span css={ß.buttonContent(isLoading)}>
      {content} {isLinkout && <span css={ß.arrow} />}
    </span>
  </>
);

Text.propTypes = {
  content: PropTypes.node,
  isLinkout: PropTypes.bool,
  isLoading: PropTypes.bool,
};

Text.defaultProps = {
  isLinkout: false,
  isLoading: false,
};

export default Text;
