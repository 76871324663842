import React from 'react';
import PropTypes from 'prop-types';
import { withPrefix } from 'gatsby';

export const Link = ({ isExternalUrl, openInNewWindow, to, ...props }) => {
  // currently not using Gatsby's Link component
  // because of the migration to NextCamp

  const newWindowProps = openInNewWindow
    ? {
        rel: 'noopener nofollow',
        target: '_blank',
      }
    : {};

  const linkProps = {
    ...props,
    ...newWindowProps,
    href: isExternalUrl ? to : withPrefix(to),
  };

  const anchor = <a {...linkProps}>{props.children}</a>;

  return anchor;
};

Link.defaultProps = {
  isExternalUrl: false,
  openInNewWindow: false,
};

Link.propTypes = {
  isExternalUrl: PropTypes.bool,
  openInNewWindow: PropTypes.bool,
  to: PropTypes.string.isRequired,
};

export default Link;
