export const heroMenuContentDECH = [
  {
    title: 'Ferienziele',
    url: '/campingferien',
    titles: {
      leftTitle: 'Klickbare Landkarte',
      upperRightTitle: 'Ferienziele',
      downRightTitle: 'Populäre Gegenden',
    },
    children: [
      {
        title: 'Schweiz',
        url: '/schweiz',
        image: {
          name: 'schweiz-alpenland',
          handle: 'IDJYCsrTfCWE8zFc9OKw',
        },
      },
      {
        title: 'Italien',
        url: '/italien',
        image: {
          name: 'italien-weingut-toskana-destination-italien',
          handle: 'ALmxRNZoSGWXQvIUMEwJ',
        },
      },
      {
        title: 'Frankreich',
        url: '/frankreich',
        image: {
          name: '',
          handle: 's02gTlEOT8i4m13mhIq2',
        },
      },
      {
        title: 'Kroatien',
        url: '/kroatien',
        image: {
          name: '',
          handle: 'Kl54itb5QLO8uhqIBCXz',
        },
      },
      {
        title: 'Spanien',
        url: '/spanien',
        image: {
          name: 'spanien-mittelmeer-bucht-mallorca-country',
          handle: 'pfpHE79LQSW0X1zHCDXH',
        },
      },
      {
        title: 'Deutschland',
        url: '/deutschland',
        image: {
          name: 'deutschland-stage.jpg',
          handle: 'j3j56n35TvK7yAI1E0yU',
        },
      },
      {
        title: 'Alle Länder',
        url: '/alle-laender',
        image: {
          name: '',
          handle: 'tBphsgeSQNazBt7j3j9o',
        },
      },
    ],
    links: [
      {
        title: 'Jura Drei-Seen-Land',
        url: '/campingferien/jura-drei-seen-land',
      },
      {
        title: 'Lago Maggiore',
        url: '/campingferien/lago-maggiore',
      },
      {
        title: 'Graubünden',
        url: '/campingferien/graubuenden',
      },
      {
        title: 'Berner Oberland',
        url: '/campingferien/berner-oberland',
      },
      {
        title: 'Südtirol',
        url: '/campingferien/suedtirol',
      },
      {
        title: 'Toskana',
        url: '/italien/toskana',
      },
      {
        title: "Côte d'Azur",
        url: '/campingferien/cote-d-azur',
      },
      {
        title: 'Ardèche',
        url: '/campingferien/ardeche',
      },
    ],
  },
  {
    title: 'Beste Campingplätze',
    url: '/campingthemen/5-sterne',
    titles: {
      leftTitle: 'Beste Campingplätze',
      upperRightTitle: '',
      downRightTitle: 'Beste Campingplätze in populäre Regionen',
    },
    children: [
      {
        title: 'Italien',
        url: '/campingthemen/5-sterne/italien',
        image: {
          name: '',
          handle: 'BUIczBzESXGepAluWfuv',
        },
      },
      {
        title: 'Frankreich',
        url: '/campingthemen/5-sterne/frankreich',
        image: {
          name: '',
          handle: 'TrgzuwdFRc6goGXcH2LS',
        },
      },
      {
        title: 'Kroatien',
        url: '/campingthemen/5-sterne/kroatien',
        image: {
          name: '',
          handle: 'qiw6kYvlR0aZQyg2jzL8',
        },
      },
      {
        title: 'Spanien',
        url: '/campingthemen/5-sterne/spanien',
        image: {
          name: 'spanien-mittelmeer-bucht-mallorca-country',
          handle: 'pfpHE79LQSW0X1zHCDXH',
        },
      },
      {
        title: 'Schweiz',
        url: '/campingthemen/4-sterne/schweiz',
        image: {
          name: 'schweiz-alpenland',
          handle: 'IDJYCsrTfCWE8zFc9OKw',
        },
      },
    ],
    links: [
      {
        title: 'Lago Maggiore',
        url: '/campingthemen/4-sterne/lago-maggiore',
      },
      {
        title: 'Südtirol',
        url: '/campingthemen/5-sterne/suedtirol',
      },
      {
        title: 'Adria',
        url: '/campingthemen/5-sterne/adria',
      },
      {
        title: 'Toskana',
        url: '/campingthemen/4-sterne/toskana',
      },
      {
        title: "Côte d'Azur",
        url: '/campingthemen/5-sterne/cote-d-azur',
      },
      {
        title: 'Nouvelle-Aquitaine',
        url: '/campingthemen/4-sterne/nouvelle-aquitaine',
      },
      {
        title: 'Beste Campingplätze am Meer',
        url: '/campingthemen/5-sterne/am-meer',
      },
      {
        title: 'Beste Campingplätze für Hundefreunde',
        url: '/campingthemen/5-sterne/mit-hund',
      },
      {
        title: 'Beste Campingplätze mit Schwimmbad',
        url: '/campingthemen/5-sterne/mit-pool',
      },
    ],
  },
  {
    title: 'Camping-Themen',
    url: '/campingthemen',
    titles: {
      leftTitle: 'Camping-Themen',
      upperRightTitle: '',
      downRightTitle: 'Wird oft gesucht: Camping …',
    },
    children: [
      {
        title: 'Camping mit Schwimmbad',
        url: '/campingthemen/mit-pool',
        image: {
          name: 'Swimmingpool.jpg',
          handle: '7vZO0msRRVCzhxRJoLW9',
        },
      },
      {
        title: 'Camping am Meer',
        url: '/campingthemen/am-meer',
        image: {
          name: '',
          handle: 'wwNDHth2SMC2Y1cTf74O',
        },
      },
      {
        title: 'Camping mit Hund',
        url: '/campingthemen/mit-hund',
        image: {
          name: '',
          handle: 'gj1nXuXjTAGhcfYOUnf3',
        },
      },
      {
        title: 'Camping mit Kindern',
        url: '/campingthemen/mit-kindern',
        image: {
          name: '',
          handle: 'PRabDvOTjq9NwBmHrDNt',
        },
      },
      {
        title: 'Glamping',
        url: '/campingthemen/glamping',
        image: {
          name: 'Glamping.jpg',
          handle: 'NVMvDXjlRmqSBCvjESFS',
        },
      },
      {
        title: 'Camping am See',
        url: '/campingthemen/am-see',
        image: {
          name: '',
          handle: 'ud7WjvQaT8yGSPKd3lkD',
        },
      },
      {
        title: 'Alle Camping-Themen A-Z',
        url: '/campingthemen',
        image: {
          name: '',
          handle: 'BUa4r7zJT8mM3TeLb99w',
        },
      },
    ],
    links: [
      {
        title: 'mit Kindern in der Schweiz',
        url: '/campingthemen/mit-kindern/schweiz',
      },
      {
        title: 'mit Kindern in Italien',
        url: '/campingthemen/mit-kindern/italien',
      },
      {
        title: 'am Meer in Italien',
        url: '/campingthemen/am-meer/italien',
      },
      {
        title: 'am See in der Schweiz',
        url: '/campingthemen/am-see/schweiz',
      },
      {
        title: 'mit Hund in Italien',
        url: '/campingthemen/mit-hund/italien',
      },
      {
        title: 'am Meer in Frankreich',
        url: '/campingthemen/am-meer/frankreich',
      },
    ],
  },
  {
    title: 'TCS Magazin',
    url: 'https://www.tcs.ch/de/camping-reisen/camping-insider/',
    linkout: true,
  },
  {
    title: 'TCS Produkte',
    url: 'https://www.tcs.ch/de/produkte/',
    linkout: true,
  },
];
