import {GATSBY_ENTRYPOINT} from '@fire/constants';

export const magazinePageContext = {
  de: {
    heroTeaser: {
      image: {
        handle: 'jzbjEuIS2epH1tGVJMcA',
        name: 'header_magazin.png',
      },
      headline: 'PiNCAMP Magazin',
      text:
        'Camping wird immer beliebter und diverser. Bleib auf dem Laufenden und verpasse keine Trends und Neuigkeiten.',
      button: {
        text: 'Zum PiNCAMP Magazin',
        url: `${GATSBY_ENTRYPOINT}/magazin`,
      },
    },
    sideKick: {
      squares: [
        {
          image: {
            handle: 'soPzIjsvTYaC76a1lWSO',
            name: 'routen_und_touren.png',
          },
          title: 'Routen & Touren',
          url: `${GATSBY_ENTRYPOINT}/magazin/inspiration/routen-touren`,
        },
        {
          image: {
            handle: '57GhZniR3iyMIfKLj9AP',
            name: 'messen.png',
          },
          title: 'Messen & News',
          url: `${GATSBY_ENTRYPOINT}/magazin/messen-und-events`,
        },
        {
          image: {
            handle: 'HCieya9qQg6otldsCnjE',
            name: 'ratgeber.png',
          },
          title: 'Ratgeber',
          url: `${GATSBY_ENTRYPOINT}/magazin/ratgeber`,
        },
        {
          image: {
            handle: 'FZXgI3NGSZCD9r18vCuL',
            name: 'inspiration.png',
          },
          title: 'Inspiration',
          url: `${GATSBY_ENTRYPOINT}/magazin/inspiration`,
        },
      ],
    },
  },
  'de-CH': {
    heroTeaser: {
      image: {
        handle: '8U9RordsRr6RP1Yv7VoH',
        name: 'CI_Camping-Insider-Header.png',
      },
      headline: 'Der Camping-Ratgeber für Einsteiger und Profis',
      text:
        'Im umfangreichen Camping-Ratgeber finden Sie Informationen und Antworten zu Fragen rund um das Campen und Reisen. Unsere Experten geben Tipps zur Ausrüstung, für die Reisevorbereitung und für Ihre Sicherheit, egal ob unterwegs oder auf dem Campingplatz.',
      button: {
        text: 'Besuche den Camping-Insider auf tcs.ch',
        url: 'https://www.tcs.ch/de/camping-reisen/camping-insider/ratgeber/',
      },
    },
    sideKick: {
      squares: [
        {
          image: {
            handle: 'TWQx5IiRaaAw8YIAEoYS',
            name: 'CI_Reisevorbereitung-2022.png',
          },
          title: 'Die richtige Reisevorbereitung',
          url:
            'https://www.tcs.ch/de/camping-reisen/camping-insider/ratgeber/?filterActive=1&filterType=small&cif_adviserCategory=529316529316%7C780951780951',
        },
        {
          image: {
            handle: 'scf5gFG1SG2fW37b9SAl',
            name: 'CI_Ausruestung-2022.png',
          },
          title: 'Die passende Ausrüstung',
          url:
            'https://www.tcs.ch/de/camping-reisen/camping-insider/ratgeber/?filterActive=1&filterType=small&cif_adviserCategory=529316529316%7C154727154727',
        },
        {
          image: {
            handle: 'jnIZknXKTn2y5IuBqyNK',
            name: 'CI_Sicherheit-2022.png',
          },
          title: 'Sicher unterwegs',
          url:
            'https://www.tcs.ch/de/camping-reisen/camping-insider/ratgeber/?filterActive=1&filterType=small&cif_adviserCategory=529316529316%7C805000805000',
        },
        {
          image: {
            handle: 'Qc55jr5uQ3y6A1kGE5Pf',
            name: 'CI_Community-2022.png',
          },
          title: 'Community',
          url:
            'https://www.tcs.ch/de/camping-reisen/camping-insider/ratgeber/?filterActive=1&filterType=small&cif_adviserCategory=529316529316%7C293541293541',
        },
      ],
    },
  },
  'it-CH': {
    heroTeaser: {
      image: {
        handle: '8U9RordsRr6RP1Yv7VoH',
        name: 'CI_Camping-Insider-Header.png',
      },
      headline: 'Consigli di campeggio per neofiti ed esperti',
      text:
        'I numerosi consigli di campeggio vi forniscono tutte le informazioni e le risposte alle domande riguardanti viaggi e campeggi. I nostri esperti vi danno dei suggerimenti sull’attrezzatura da portare, su cosa preparare per il vostro viaggio e la vostra sicurezza, indipendentemente che siate in transito o in camping.',
      button: {
        text: 'Vai a Camping-Insider su tcs.ch',
        url: 'https://www.tcs.ch/it/camping-viaggi/camping-insider/consigli/',
      },
    },
    sideKick: {
      squares: [
        {
          image: {
            handle: 'TWQx5IiRaaAw8YIAEoYS',
            name: 'CI_Reisevorbereitung-2022.png',
          },
          title: 'Come preparare il viaggio',
          url:
            'https://www.tcs.ch/it/camping-viaggi/camping-insider/consigli/?filterActive=1&filterType=small&cif_adviserCategory=529316529316%7C780951780951',
        },
        {
          image: {
            handle: 'scf5gFG1SG2fW37b9SAl',
            name: 'CI_Ausruestung-2022.png',
          },
          title: 'Attrezzatura',
          url:
            'https://www.tcs.ch/it/camping-viaggi/camping-insider/consigli/?filterActive=1&filterType=small&cif_adviserCategory=529316529316%7C154727154727',
        },
        {
          image: {
            handle: 'jnIZknXKTn2y5IuBqyNK',
            name: 'CI_Sicherheit-2022.png',
          },
          title: 'Sicurezza',
          url:
            'https://www.tcs.ch/it/camping-viaggi/camping-insider/consigli/?filterActive=1&filterType=small&cif_adviserCategory=529316529316%7C805000805000',
        },
        {
          image: {
            handle: 'Qc55jr5uQ3y6A1kGE5Pf',
            name: 'CI_Community-2022.png',
          },
          title: 'Community',
          url:
            'https://www.tcs.ch/it/camping-viaggi/camping-insider/consigli/?filterActive=1&filterType=small&cif_adviserCategory=529316529316%7C293541293541',
        },
      ],
    },
  },
  'fr-CH': {
    heroTeaser: {
      image: {
        handle: '8U9RordsRr6RP1Yv7VoH',
        name: 'CI_Camping-Insider-Header.png',
      },
      headline: 'Le guide du camping pour les novices et les professionnels',
      text:
        "Dans le vaste guide du camping, vous trouverez des informations ainsi que des réponses aux questions concernant le camping et les voyages. Nos experts vous prodiguent des conseils au sujet de l’équipement, des préparatifs de voyage et de votre sécurité, que ce soit en route ou au camping. ",
      button: {
        text: 'Découvrir Camping-Insider sur tcs.ch',
        url: 'https://www.tcs.ch/fr/camping-voyages/camping-insider/conseils/',
      },
    },
    sideKick: {
      squares: [
        {
          image: {
            handle: 'TWQx5IiRaaAw8YIAEoYS',
            name: 'CI_Reisevorbereitung-2022.png',
          },
          title: 'Préparer son voyage',
          url:
            'https://www.tcs.ch/fr/camping-voyages/camping-insider/conseils/?filterActive=1&filterType=small&cif_adviserCategory=529316529316%7C780951780951',
        },
        {
          image: {
            handle: 'scf5gFG1SG2fW37b9SAl',
            name: 'CI_Ausruestung-2022.png',
          },
          title: 'Équipement',
          url:
            'https://www.tcs.ch/fr/camping-voyages/camping-insider/conseils/?filterActive=1&filterType=small&cif_adviserCategory=529316529316%7C154727154727',
        },
        {
          image: {
            handle: 'jnIZknXKTn2y5IuBqyNK',
            name: 'CI_Sicherheit-2022.png',
          },
          title: 'Sécurité',
          url:
            'https://www.tcs.ch/fr/camping-voyages/camping-insider/conseils/?filterActive=1&filterType=small&cif_adviserCategory=529316529316%7C805000805000',
        },
        {
          image: {
            handle: 'Qc55jr5uQ3y6A1kGE5Pf',
            name: 'CI_Community-2022.png',
          },
          title: 'Community',
          url:
            'https://www.tcs.ch/fr/camping-voyages/camping-insider/conseils/?filterActive=1&filterType=small&cif_adviserCategory=529316529316%7C293541293541',
        },
      ],
    },
  },
};
