import React, { memo, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import useHasMounted from '@hooks/useHasMounted';

import { register, unregister } from './accountant';
import * as ß from './styles';

const TYPES = {
  full: ß.modalFull,
  big: ß.modalBig,
  medium: ß.modalMedium,
};

export const Modal = memo(function Modal({
  allowBackgroundScroll,
  children,
  closeModal,
  isModalVisible,
  tracking: { close },
  type,
}) {
  const hasMounted = useHasMounted();

  const ref = React.createRef();

  useEffect(() => {
    if (!hasMounted) return;

    isModalVisible ? register(ref, allowBackgroundScroll) : unregister(ref);

    return () => {
      unregister(ref);
    };
  }, [hasMounted, ref, isModalVisible, allowBackgroundScroll]);

  if (!hasMounted) return <></>;

  const domEl = document.getElementById('modal-root');

  if (!domEl) return <></>;

  const closeModalWithTracking = () => {
    closeModal();
    close();
  };

  const handleWrapperClick = ({ target }) =>
    target?.id === 'modalWrapper' && closeModalWithTracking();

  return ReactDOM.createPortal(
    <>
      {isModalVisible && (
        <div
          id="modalWrapper"
          css={ß.modalWrapper}
          onClick={handleWrapperClick}
        >
          <div css={TYPES[type]}>
            <span css={ß.close} onClick={closeModalWithTracking}>
              &times;
            </span>
            <div css={ß.innerModalWrapper}>{children}</div>
          </div>
        </div>
      )}
    </>,
    domEl
  );
});

Modal.propTypes = {
  allowBackgroundScroll: PropTypes.bool,
  children: PropTypes.node,
  closeModal: PropTypes.func,
  isModalVisible: PropTypes.bool,
  tracking: PropTypes.shape({
    close: PropTypes.func,
  }),
  type: PropTypes.oneOf(Object.keys(TYPES)),
};

Modal.defaultProps = {
  allowBackgroundScroll: false,
  isModalVisible: false,
  tracking: {
    close: x => x,
  },
  type: 'full',
};

export default Modal;
