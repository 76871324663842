import React from 'react';
import { useTranslation } from 'react-i18next';
import { pathOr } from 'ramda';

import { T } from '@fire/i18n/i18n-constants';

import * as ß from './styles';
import { IMAGE_PLACEHOLDER } from './utils';

const namespace = pathOr('', ['Tile'], T);

const Placeholder = () => {
  const { t } = useTranslation(namespace);

  return (
    <div css={ß.image(IMAGE_PLACEHOLDER())}>
      <div css={ß.placeholderText}>
        <p>{t(`Hier fehlen noch Bilder. Wir arbeiten dran`)}</p>
      </div>
    </div>
  );
};

export default Placeholder;
