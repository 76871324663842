import { css } from '@emotion/react';
import { blue10 } from '@fire/_color';
import { fontSizeSmall } from '@fire/_fonts';
import { media } from '@fire/_mediaQueries';

export const wrapperMobile = css`
  width: 100%;
  display: block;

  ${media.md} {
    display: none;
  }
`;

export const title = css`
  font-size: 20px;
  margin-bottom: 8px;
`;

export const about = css`
  display: none;

  ${media.md} {
    display: block;
    flex: 1;
  }
  ul li a:hover {
    color: ${blue10};
  }

  #booking-with-us-section {
    column-count: 2;
  }
`;

export const aboutFooter = css`
  font-size: ${fontSizeSmall};

  ${media.md} {
    display: flex;
    justify-content: space-between;
  }
`;
