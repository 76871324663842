import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { smoothScrollTo } from '@utils/scroll';

const doScroll = event => {
  event.preventDefault();
  const targetId = event.currentTarget.attributes.href.value.slice(1);
  const offset = event.currentTarget.attributes['data-offset'].value;
  const element = document.getElementById(targetId);
  const elementTop = element.getBoundingClientRect().top;
  smoothScrollTo(elementTop - offset);
};

export const Scroller = memo(function Scroller({ targetId, children, offset }) {
  return (
    <a href={`#${targetId}`} data-offset={offset} onClick={doScroll}>
      {children}
    </a>
  );
});

Scroller.propTypes = {
  targetId: PropTypes.string.isRequired,
  children: PropTypes.node,
  offset: PropTypes.number,
};

Scroller.defaultProps = {
  offset: undefined,
};

export default Scroller;
