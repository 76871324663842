import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { T } from '@fire/i18n/i18n-constants';

const PREFIX = T?.Tile;

import Tooltip from '@components/Tooltip';
import useFavorites from '@hooks/useFavorites';
import useTracking from '@hooks/useTracking';

import FavIcon from './FavIcon';
import * as ß from './styles';

const FavoriteHeartMenu = ({ tooltipSide }) => {
  const { getLink, hasFavs } = useFavorites();
  const { t } = useTranslation(PREFIX);
  const [showTooltip, setShowTooltip] = useState(false);
  const {
    prepareToTrackEvent: { clickEvent },
  } = useTracking('favorite heart menu');

  const altText = t('favorite-icon');
  const tooltipText = t('favorite-tooltip-text');

  const trackOnClickedMenu = clickEvent('click favorite heart menu');

  const onClickHandle = () => {
    if (hasFavs()) {
      trackOnClickedMenu();

      return (window.location.href = getLink());
    }
    return false;
  };

  const iconWithToolTip = showTooltip ? (
    <Tooltip
      tooltipTitle={altText}
      tooltipText={tooltipText}
      side={tooltipSide}
    >
      <FavIcon />
    </Tooltip>
  ) : (
    <FavIcon />
  );

  const onHoverHandle = () => {
    setShowTooltip(!hasFavs());
  };

  return (
    <div css={ß.wrapper} onClick={onClickHandle} onMouseEnter={onHoverHandle}>
      {iconWithToolTip}
    </div>
  );
};

FavoriteHeartMenu.propTypes = {
  tooltipSide: PropTypes.string,
};

FavoriteHeartMenu.defaultProps = {
  tooltipSide: 'left',
};

export default FavoriteHeartMenu;
