import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Button from '@components/Button';
import Link from '@components/Link';
import CheckIcon from '@fire/assets/check.svg';
import { MaybeData } from '@fire/contexts';
import { T } from '@fire/i18n/i18n-constants';

import * as ß from './styles';
import {
  getLocalizedMobileHomeButton,
  getLocalizedMobileHomeLinks,
} from './utils';

const PREFIX = T?.HomePage ?? '';

const MobileHomes = () => {
  const { language } = useContext(MaybeData);
  const { t } = useTranslation(PREFIX);

  const buttonLink = getLocalizedMobileHomeButton(language);
  const links = getLocalizedMobileHomeLinks(language);

  return (
    <div css={ß.mobileHomes}>
      <div css={ß.bigImage} />
      <div css={ß.smallImages}>
        <span />
        <span />
      </div>
      <div css={ß.content}>
        <div css={ß.title}>{t('mobilehome-title')}</div>
        <ul>
          <li>
            <CheckIcon /> {t('mobilehome-list-one')}
          </li>
          <li>
            <CheckIcon /> {t('mobilehome-list-two')}
          </li>
          <li>
            <CheckIcon /> {t('mobilehome-list-three')}
          </li>
        </ul>
        <div css={ß.buttonContainer}>
          <Button size="large" to={buttonLink} type="primary">
            {t('mobilehome-button')}
          </Button>
        </div>
        <p>
          <Trans i18nKey="mobilehome-description" t={t}>
            {links.map(link => (
              <Link key={link} to={link}>
                {link}
              </Link>
            ))}
          </Trans>
        </p>
      </div>
    </div>
  );
};

export default MobileHomes;
