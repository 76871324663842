import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import * as ß from './styles';

const MoreButton = ({ onClick, prefix, label }) => {
  const { t } = useTranslation(prefix);

  return (
    <a css={ß.readMore} onClick={onClick}>
      {t(label)}
    </a>
  );
};

MoreButton.defaultProps = {
  label: 'Mehr',
};

MoreButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  prefix: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default MoreButton;
