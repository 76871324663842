import { css } from '@emotion/react';
import { blue5, charcoal, gray8 } from '@fire/_color';
import { skeletonBackground } from '@fire/_const';
import {
  bold,
  fontSizeBody,
  fontSizeMedium,
  fontSizeSmall,
  fontSizeXSmall,
  regular,
} from '@fire/_fonts';

export const tileContent = css`
  margin-bottom: 16px;
`;

export const title = css`
  font-size: ${fontSizeBody};
  height: 22px;
  color: ${charcoal};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 16px;
`;

export const noDecoration = css`
  text-decoration: none;
`;

export const tileDestination = css`
  min-height: 23px;
  font-size: ${fontSizeMedium};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 16px;
  color: ${charcoal};
`;

export const starRatings = (isMapTile = false) => css`
  margin-bottom: 18px;
  white-space: nowrap;

  > div {
    height: 20px;
  }

  span {
    font-size: ${isMapTile ? fontSizeXSmall : fontSizeSmall};
    font-weight: ${regular};
    color: ${charcoal};
    padding-top: 2px;

    align-self: center;

    span {
      color: ${gray8};
    }
  }
`;

export const userRating = (rating, isMapTile = false) => css`
  font-size: ${isMapTile ? fontSizeXSmall : fontSizeSmall};
  font-weight: ${bold};
  padding-top: 4px;
  padding-right: 4px;
  span {
    padding-left: ${rating >= 3.4 ? '4px' : 0};
    font-weight: ${regular};
    color: ${charcoal};
  }
`;

export const noUserRating = css`
  font-weight: ${regular};
  color: ${charcoal};
`;

export const pill = css`
  font-size: ${fontSizeSmall};
`;

export const pillBackground = css`
  background-color: ${blue5};
  border-radius: 13px;
  display: flex;
  padding: 2px 6px;
  margin-right: 4px;
  margin-bottom: 4px;
`;

export const pillContainer = css`
  display: flex;
  margin-top: 8px;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: 30px;
  overflow: hidden;
`;

export const skeleton = css`
  width: 215px;
  min-height: 28px;

  ${skeletonBackground}
`;
