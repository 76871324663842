import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import {
  PREFIX,
  PRICE_WITHOUT_CENTS,
} from '@components/AccommodationList/constants';
import {
  checkAvailabilities,
  useFlexibleDates,
} from '@components/AccommodationList/utils';
import { everyTruthy } from '@utils/generic';
import { formatPrice } from '@utils/money';

import * as ß from './styles';

const PricePerNight = ({ accommodations, category }) => {
  const { t } = useTranslation(PREFIX);

  const rate = accommodations?.[0]?.rates?.[0];
  const price =
    rate && formatPrice(rate?.daily, rate?.currency, PRICE_WITHOUT_CENTS);

  return (
    <div css={ß.pricePerNight}>
      <p css={ß.priceMobile}>
        {t(`category-${category}`)} {t('price-per-night', { price })}
      </p>
      <p css={ß.priceDesktop}>
        <span>
          {t(`category-${category}`)} {t('price-per-night-from')}
        </span>
        <span css={ß.priceDesktopPrice}>{price}</span>
      </p>
    </div>
  );
};

const Message = ({ isEditState, availabilities }) => {
  const { t } = useTranslation(PREFIX);
  const { hasFlexibleDates } = useFlexibleDates();
  const hasAvailabilities = checkAvailabilities(availabilities || {});
  const hasDates = availabilities?.dateFrom && availabilities?.dateTo;
  const showPrices = hasDates && !isEditState && hasAvailabilities;

  const hasNothing = everyTruthy([
    hasDates,
    !hasAvailabilities,
    !hasFlexibleDates,
  ]);
  const hasFlexibleDatesNoAvailabilities = everyTruthy([
    hasDates,
    !hasAvailabilities,
    hasFlexibleDates,
  ]);

  return (
    <div css={ß.availabilityMessage}>
      {showPrices && (
        <div css={ß.prices}>
          {availabilities.list.map(({ accommodations, category }) => (
            <PricePerNight
              accommodations={accommodations}
              category={category}
              key={category}
            />
          ))}
        </div>
      )}
      {hasFlexibleDatesNoAvailabilities &&
        t('no-availabilities-only-flexible-dates')}
      {hasNothing && t('dates-not-available')}
      {!hasDates && <p>{t('dates-missing')}</p>}
    </div>
  );
};

Message.defaultProps = {
  isEditState: false,
};

Message.propTypes = {
  availabilities: PropTypes.shape({
    dateFrom: PropTypes.string,
    dateTo: PropTypes.string,
    list: PropTypes.array,
  }),
  isEditState: PropTypes.bool,
};

PricePerNight.propTypes = {
  accommodations: PropTypes.array,
  category: PropTypes.string,
};

export default Message;
