import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withPrefix } from 'gatsby';

import * as ß from './styles';

const arrowSlideshow = withPrefix('/icons/arrow-slideshow.svg');

const SlideshowButtons = ({ goLeft, goRight }) => (
  <div css={ß.slideshowButtons}>
    <button onClick={goLeft}>
      <img
        src={arrowSlideshow}
        css={ß.arrowLeft}
        width={15}
        height={22}
        alt="Previous item"
      />
    </button>
    <button css={ß.arrowRight} onClick={goRight}>
      <img src={arrowSlideshow} width={15} height={22} alt="Next item" />
    </button>
  </div>
);

SlideshowButtons.propTypes = {
  goLeft: PropTypes.func.isRequired,
  goRight: PropTypes.func.isRequired,
};

export default memo(SlideshowButtons);
