import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withPrefix } from 'gatsby';
import { debounce } from 'throttle-debounce';

import Modal from '@components/Modal';
import OutsideAlerter from '@components/OutsideAlerter/component';
import MaybeData from '@contexts/MaybeData';
import SearchSuggestionsContext from '@contexts/SearchSuggestions';
import { BREAKPOINTS } from '@fire/_mediaQueries';
import { useWindowWidth } from '@fire/hoc/viewportAware';
import useTracking from '@hooks/useTracking';
import { getLocaleData } from '@utils/platform';

import { NAMESPACE } from './constants';
import SearchBarElement from './SearchBarElement';
import SearchDropDown from './SearchDropDown';
import * as ß from './styles';
import { fetchSuggestions } from './utils';

const SEARCH_ICON = withPrefix('/icons/search.svg');

const SearchBar = ({
  isMobile,
  shouldNavigate,
  showIconOnlyOnMobile,
  trackLabel,
}) => {
  const {
    prepareToTrackEvent: { clickEvent },
  } = useTracking(trackLabel);
  const { blurInput, focusInput, inputRef, reset, setSuggestions } = useContext(
    SearchSuggestionsContext
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const pageContext = useContext(MaybeData);
  const width = useWindowWidth();
  const languageAndPlatform = getLocaleData(pageContext);
  const { t } = useTranslation(NAMESPACE);

  const trackSearchBarClicked = clickEvent('search bar clicked');

  const getSuggestions = async term => {
    if (!term) {
      setSuggestions({});
      return;
    }

    const trackSearchedTerm = clickEvent(`searched "${term}"`);
    const suggestions = await fetchSuggestions({
      term,
      ...languageAndPlatform,
    });

    trackSearchedTerm();
    setSuggestions(suggestions);
  };

  const debouncedGetSuggestions = debounce(200, getSuggestions);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = useCallback(debouncedGetSuggestions, []);

  const handleFocus = () => {
    trackSearchBarClicked();
    setIsDropdownOpen(true);

    if (width < BREAKPOINTS.lg) {
      setIsModalVisible(true);
    }
  };

  const handleClose = () => {
    setIsDropdownOpen(false);
    reset();
    focusInput();
  };

  const handleSearchBarClick = useCallback(() => {
    setIsModalVisible(width < BREAKPOINTS.lg);
  }, [setIsModalVisible, width]);

  const hideSuggestions = shouldBlur => {
    setIsDropdownOpen(false);
    setIsModalVisible(false);

    shouldBlur && blurInput();
  };

  const resetCallback = useCallback(
    ({ target }) => {
      const isOutsideInput = target?.id !== inputRef?.current?.id;
      isOutsideInput && setIsDropdownOpen(false);
    },
    [inputRef]
  );

  const searchBarElementProps = {
    handleChange,
    handleClose,
    handleFocus,
    isMobile,
  };

  const searchDropDownProps = {
    hideSuggestions,
    shouldNavigate,
  };

  // Cancel debounce on cleanup
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => debouncedGetSuggestions.cancel(), []);

  const showIcon = showIconOnlyOnMobile ? [ß.hideOnDesktop] : [ß.hideAll];
  const hideBar = showIconOnlyOnMobile ? [ß.hideOnMobile] : [];

  return isModalVisible ? (
    <Modal closeModal={hideSuggestions} isModalVisible={isModalVisible}>
      <div css={ß.modalBody}>
        <h5 css={ß.modalTitle}>{t('Suchen')}</h5>
        <div css={ß.searchbar(isDropdownOpen)}>
          <SearchBarElement {...searchBarElementProps} autofocus />
          {isDropdownOpen && (
            <OutsideAlerter callback={resetCallback}>
              <SearchDropDown {...searchDropDownProps} />
            </OutsideAlerter>
          )}
        </div>
      </div>
    </Modal>
  ) : (
    <>
      {showIconOnlyOnMobile && (
        <img
          alt={t('Suche')}
          css={[ß.searchIcon, ß.searchIconMobile, ...showIcon]}
          height={21}
          scale={1}
          src={SEARCH_ICON}
          width={21}
          onClick={handleSearchBarClick}
        />
      )}

      <div
        css={[ß.searchbar(isDropdownOpen), ...hideBar]}
        onClick={handleSearchBarClick}
      >
        <SearchBarElement {...searchBarElementProps} />
        {isDropdownOpen && (
          <OutsideAlerter callback={resetCallback}>
            <SearchDropDown {...searchDropDownProps} />
          </OutsideAlerter>
        )}
      </div>
    </>
  );
};

SearchBar.propTypes = {
  isMobile: PropTypes.bool,
  shouldNavigate: PropTypes.bool,
  showIconOnlyOnMobile: PropTypes.bool,
  trackLabel: PropTypes.string,
};

SearchBar.defaultProps = {
  isMobile: false,
  shouldNavigate: true,
  showIconOnlyOnMobile: false,
  trackLabel: 'search bar',
};

export default SearchBar;
