import React from 'react';
import PropTypes from 'prop-types';
import { map, pipe } from 'ramda';

import { asProp } from '@components/utils';

import { ChecklistItemString } from './ChecklistItem';
import * as ß from './styles';

export const Checklist = ({ cssMod, name, ordered, values }) => (
  <div css={[ß.checklist, cssMod]}>
    <h4 css={ß.checklistTitle}>{name}</h4>
    {ordered ? (
      <ol css={ß.orderedList}>
        {map(
          pipe(asProp('children'), ({ children: v }) => <li key={v}>{v}</li>),
          values
        )}
      </ol>
    ) : (
      <ul css={ß.checklistList}>{map(ChecklistItemString, values)}</ul>
    )}
  </div>
);

Checklist.propTypes = {
  cssMod: PropTypes.object,
  name: PropTypes.string,
  ordered: PropTypes.bool,
  values: PropTypes.arrayOf(PropTypes.string),
};

Checklist.defaultProps = {
  cssMod: {},
  name: '',
  ordered: false,
  values: [],
};

export default Checklist;
