/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 *
 * @namespace seo
 * @function SEO
 * @param {Object} props - data for graphql query
 * @returns {Object} jsx
 */
import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { withPrefix } from 'gatsby';
import {
  dropLastWhile,
  either,
  identity as I,
  ifElse,
  isEmpty,
  isNil,
  pathOr,
  pipe,
  slice,
} from 'ramda';

import { GATSBY_ENTRYPOINT } from '@fire/constants';
import { useHeroImage } from '@fire/hooks/useHeroImage';
import { useLoyaltyPlatinum } from '@fire/hooks/useLoyalty';
import { isADAC } from '@utils/platform';

import {
  USERCENTRICS_ID_CH,
  USERCENTRICS_ID_DE,
  USERCENTRICS_PRELOAD,
} from './constants';

const fontBold =
  '/fonts/pt-sans-v12-latin-ext_latin_cyrillic-ext_cyrillic-700.woff2';
const fontRegular =
  '/fonts/pt-sans-v12-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2';
const favicon = '/favicon/logo-pincamp-48x48.png';

const getUserCentricsId = pageContext =>
  isADAC(pageContext) ? USERCENTRICS_ID_DE : USERCENTRICS_ID_CH;

const getFavicon = {
  rel: 'icon',
  type: 'image/png',
  href: withPrefix(favicon),
};

const getPreloadedFonts = [fontBold, fontRegular].map(font => ({
  rel: 'preload',
  type: 'font/woff2',
  as: 'font',
  href: `${withPrefix(font)}`, // withPrefix adds an extra '/'
  crossorigin: '',
}));

const eitherEmptyOrNil = defaultPath =>
  ifElse(either(isNil, isEmpty), () => defaultPath, I);

export const getCanonicalInfo = pageContext => {
  const pagePath = pathOr('', ['pagePath'], pageContext);
  const language = pipe(pathOr('', ['language']), slice(0, 2))(pageContext);
  const url = isADAC(pageContext)
    ? `${GATSBY_ENTRYPOINT}${pagePath}`
    : `${GATSBY_ENTRYPOINT}/${language}${pagePath}`;
  const urlWithoutSlash = dropLastWhile(x => x === '/', url);
  return { rel: 'canonical', href: `${urlWithoutSlash}` };
};

function SEO({ lang, meta, pageContext, description }) {
  const mDescription = pathOr('', ['metaDescription'], pageContext);
  const language = pipe(pathOr(lang, ['language']), slice(0, 2))(pageContext);
  const eitherEmptyOrNilWithDefaultDescription = eitherEmptyOrNil(description);

  const metaDescription = eitherEmptyOrNilWithDefaultDescription(mDescription);

  const title = pathOr('', ['title'], pageContext);
  const titleProvider = isADAC(pageContext)
    ? 'PiNCAMP by ADAC'
    : 'PiNCAMP by TCS';
  const metaRobots = pathOr('', ['metaRobots'], pageContext);

  const partner = useLoyaltyPlatinum({ random: true });
  const image = useHeroImage(partner?.prn);

  const isHome = pageContext?.prn === 'prn:page:home';

  const preloadImages = isHome
    ? [
        {
          rel: 'preload',
          as: 'image',
          href: image?.childImageSharp?.fluid?.srcWebp,
          imagesrcset: image?.childImageSharp?.fluid?.srcSetWebp,
          imagesizes: image?.childImageSharp?.fluid?.sizes,
        },
      ]
    : [];
  const showConsentBanner = process.env.NODE_ENV !== 'development';

  return (
    <Helmet
      htmlAttributes={{
        lang: language,
      }}
      title={title}
      titleTemplate={`%s | ${titleProvider}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: 'robots',
          content: metaRobots,
        },
        {
          name: 'google-site-verification',
          content: 'yRUCPe6lVLBB4hGN5oA_A8pOFes_QKWnTPT7LNZePzM',
        },
        ...meta,
      ]}
      link={[
        getCanonicalInfo(pageContext),
        ...USERCENTRICS_PRELOAD(showConsentBanner),
        ...getPreloadedFonts,
        getFavicon,
        ...preloadImages,
      ]}
    >
      {showConsentBanner && (
        <script
          id="usercentrics-cmp"
          src="https://app.usercentrics.eu/browser-ui/latest/loader.js"
          data-settings-id={getUserCentricsId(pageContext)}
          async
        ></script>
      )}
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `de`,
  meta: [],
  description: ``,
  pageData: {},
  pageContext: {},
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  pageData: PropTypes.object,
  pageContext: PropTypes.object,
};

export default SEO;
