import { css } from '@emotion/react';
import { gray5 } from '@fire/_color';
import { media } from '@fire/_mediaQueries';

export const footer = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-top: 1px solid #dfe3e6;
  width: 100%;
`;

export const row = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 0;

  > div {
    flex: 1 1;
  }

  ${media.md} {
    flex-direction: row;
    padding: 32px 72px;
    border-top: 1px solid ${gray5};
  }
`;

export const optionBox = css`
  padding: 32px 12px;
  border-top: 1px solid ${gray5};

  ${media.xs} {
    padding: 32px 38px;
  }

  ${media.md} {
    padding: 0;
    border-top: 0;
  }
`;

export const ratingWrapper = css`
  display: flex;
  align-items: center;

  a {
    margin-left: 12px;
  }
`;

export const title = css`
  margin-bottom: 8px;
`;

export const footerOptions = css`
  display: flex;
  flex-direction: column;
`;

export const appIcons = css`
  display: flex;
  flex-direction: row;
`;
export const appContainer = css`
  ${optionBox}
  min-width: 300px;
`;

export const appImageApple = css`
  height: 39px;
  margin-top: 8px;
  margin-right: 20px;
`;

export const appImageGoogle = css`
  height: 56px;
`;
