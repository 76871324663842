import React, { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Link from '@components/Link';
import StarRating from '@components/StarRating';
import SkeletonStars from '@components/Tile/Campsite/SkeletonStars';
import { getClassificationProps } from '@components/utils';
import MaybeData from '@contexts/MaybeData';
import TileLoading from '@contexts/TileLoading';
import { ADACAttributesDefaultProps, ADACAttributesType } from '@types';
import { isADAC, isSwiss } from '@utils/platform';

import { Rating } from './ratings';
import * as ß from './styles';
import { getPillAttributes } from './utils';

const TileContent = ({
  adacAttributes,
  linkProps,
  name,
  namespace,
  onClick,
  rating,
  region,
  tcsRating,
  userRating,
  userRatingCount,
  isMapTile,
}) => {
  const pageContext = useContext(MaybeData);
  const isLoading = useContext(TileLoading);
  const { t } = useTranslation(namespace);
  const isAdac = isADAC(pageContext);
  const showTcsRating = isSwiss(pageContext) && tcsRating > 0;
  const pillAttributes = getPillAttributes(adacAttributes);

  const userRatingWithDecimal = Number.isInteger(userRating)
    ? `${userRating}.0`
    : userRating;

  const getClassificationPropsWithTranslation = getClassificationProps({
    rating,
    tcsRating,
    showTcsRating,
  });

  return (
    <div css={ß.tileContent}>
      <Link onClick={onClick} css={ß.noDecoration} {...linkProps}>
        <h5 css={ß.title}>{name}</h5>

        {isAdac && region && <p css={ß.tileDestination}>{region}</p>}
        {isLoading ? (
          <SkeletonStars />
        ) : (
          <div css={ß.starRatings(isMapTile)}>
            <StarRating {...getClassificationPropsWithTranslation(t)} />
            <div css={ß.userRating(userRating, isMapTile)}>
              {userRating === 0 ? (
                <p css={ß.noUserRating}>
                  {t('Es gibt noch keine Camper-Bewertung')}
                </p>
              ) : (
                <>
                  {userRatingWithDecimal} <Rating rating={Number(userRating)} />
                  <span>
                    {t('rating', { count: userRatingCount })}
                    <span>({userRatingCount})</span>
                  </span>
                </>
              )}
            </div>
          </div>
        )}
        <div css={ß.pillContainer}>
          {isLoading ? (
            <span css={ß.skeleton} />
          ) : (
            <>
              {pillAttributes.map(attribute => (
                <div key={attribute.position} css={ß.pillBackground}>
                  <span css={ß.pill}>{t(attribute.label)}</span>
                </div>
              ))}
            </>
          )}
        </div>
      </Link>
    </div>
  );
};

TileContent.propTypes = {
  adacAttributes: ADACAttributesType,
  isLoading: PropTypes.bool,
  linkProps: PropTypes.object,
  name: PropTypes.string,
  namespace: PropTypes.string,
  onClick: PropTypes.func,
  rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  region: PropTypes.string,
  tcsRating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  userRating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  userRatingCount: PropTypes.number,
  isMapTile: PropTypes.bool,
};

TileContent.defaultProps = {
  adacAttributes: ADACAttributesDefaultProps,
  namespace: 'Tile',
  userRatingCount: 0,
  isMapTile: false,
};

export default memo(TileContent);
