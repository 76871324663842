import { css } from '@emotion/react';
import { blue9, blue10, gray5, white } from '@fire/_color';
import { borderRadiusSmall } from '@fire/_const';
import { bold } from '@fire/_fonts';
import { media, mediaMax } from '@fire/_mediaQueries';

const tagCSS = css`
  background: ${blue9};
  border-radius: ${borderRadiusSmall};
  color: ${white};
  font-weight: ${bold};
  text-align: center;
  display: inline-block;
  text-transform: uppercase;
  height: auto;
  width: auto;
  min-width: auto;
  padding-bottom: 0;
  flex-basis: auto;
  flex-grow: 0;
  align-self: flex-start;
  justify-self: start;
  margin: 4px 16px 4px 0;
  overflow: hidden;

  ${media.md} {
    margin: 4px 16px 4px 0;
  }

  &:last-child {
    flex-grow: 0;
    margin: 4px 16px 4px 0;
  }

  a {
    position: relative;
    display: block;
    padding: 4px 8px;
    color: ${white};
    background-color: ${blue9};

    &:link,
    &:hover {
      color: ${white};
      font-weight: ${bold};
      text-decoration: none;
    }

    &:hover {
      background-color: ${blue10};
    }
  }

  .title {
    color: ${white};
    font-weight: ${bold};
    text-decoration: none;
    font-size: 12px;

    padding: 0;
  }
`;

/**
 * https://stackoverflow.com/questions/20456694/grid-of-responsive-squares
   Aspect ratio  |  padding-bottom  |  for 30% width
   ------------------------------------------------
   1:1        |  = width         |    30%
   1:2        |  width x 2       |    60%
   2:1        |  width x 0.5     |    15%
   4:3        |  width x 0.75    |    22.5%
   16:9       |  width x 0.5625  |    16.875%
 *
 * @example
 * @param props
 */

const withImageCSS = ({ image, placeholder, loaded }) => css`
  display: inline-flex;
  background-image: url(${loaded ? image : placeholder});
  background-position: center;
  background-size: cover;
  background-color: ${gray5};
  box-sizing: border-box;
  position: relative;
  min-width: calc(50% - 10px);
  flex-basis: calc(50% - 10px);
  padding-bottom: calc(50% - 10px);
  margin: 12px auto;

  ${mediaMax.xs} {
    &:nth-of-type(2n + 1) {
      margin-right: 10px;
      margin-left: 0;
      flex-grow: 0;
    }

    &:nth-of-type(2n) {
      margin-left: 10px;
      margin-right: 0;
      flex-grow: 0;
    }
  }

  ${media.md} {
    min-width: calc(33% - 20px);
    flex-basis: calc(33% - 20px);
    padding-bottom: calc(33% - 20px);

    &:nth-of-type(3n + 1) {
      margin-right: 20px;
      margin-left: 0;
      flex-grow: 0;
    }

    &:nth-of-type(3n) {
      margin-left: 20px;
      margin-right: 0;
      flex-grow: 0;
    }
  }

  ${media.lg} {
    min-width: calc(33% - 20px);
    flex-basis: calc(33% - 20px);
    padding-bottom: calc(33% - 20px);

    &:nth-of-type(3n + 1) {
      margin-right: 20px;
      margin-left: 0;
      flex-grow: 0;
    }

    &:nth-of-type(3n) {
      margin-left: 20px;
      margin-right: 0;
      flex-grow: 0;
    }
  }

  &:nth-of-type(1):last-child,
  &:nth-of-type(2):last-child,
  &:nth-of-type(4):last-child,
  &:nth-of-type(5):last-child {
    flex-grow: 1;
    margin: 12px auto;
  }

  a {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.25);
    color: ${white};

    &:hover,
    &:link:hover {
      background-color: ${blue9};
      color: ${white};
    }
  }

  .title {
    font-size: 16px;

    font-weight: ${bold};
    white-space: normal;
    padding: 0 8px;

    ${media.md} {
      padding: 0 24px;
      font-size: 22px;
    }
  }
`;

// the `mediaMax` breakpoints render blue tags for every item over the max
export const groupItem = ({
  mobile,
  tablet,
  desktop,
  image,
  loaded,
  placeholder,
}) => css`
  ${withImageCSS({ image, loaded, placeholder })}

  ${mediaMax.xs} {
    &:nth-of-type(n + ${mobile + 1}) {
      ${tagCSS}
    }
  }

  ${mediaMax.md} {
    &:nth-of-type(n + ${tablet + 1}) {
      ${tagCSS}
    }
  }

  ${media.lg} {
    &:nth-of-type(n + ${desktop + 1}) {
      ${tagCSS}
    }
  }
`;

export const group = css`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 16px 0 72px 0;
  padding: 0;

  a {
    color: ${white};
    text-decoration: none;

    &:hover,
    &:link:hover {
      color: ${white};
    }
  }

  .title {
    text-align: center;
    width: 100%;
    word-break: break-word;
  }
`;
