import { withPrefix } from 'gatsby';
import {
  always,
  ap,
  curry,
  head,
  ifElse,
  map,
  pipe,
  pluck,
  propOr,
  reduce,
  sum,
  when,
} from 'ramda';

import { box, merge } from '@utils/generic';

export const compareToAll = curry((direction, points, point) =>
  reduce(direction, point, points)
);

export const longitudeMax = compareToAll((a, b) => (a.lng > b.lng ? a : b));
export const longitudeMin = compareToAll((a, b) => (a.lng < b.lng ? a : b));
export const latitudeMax = compareToAll((a, b) => (a.lat > b.lat ? a : b));
export const latitudeMin = compareToAll((a, b) => (a.lat < b.lat ? a : b));

export const over = curry((b, a) => a / b);
export const centerProp = curry((pp, points) =>
  pipe(pluck(pp), sum, Math.round, over(points.length - 1))(points)
);

export const centerLng = centerProp('lng');
export const centerLat = centerProp('lat');

export const edges = points =>
  pipe(
    head,
    x => [x],
    ap(
      map(
        fn => fn(points),
        [longitudeMin, longitudeMax, latitudeMin, latitudeMax]
      )
    ),
    ([xmin, xmax, ymin, ymax]) => ({ xmin, xmax, ymin, ymax })
  )(points);

const isString = x => typeof x === 'string';
const orNegativeOne = propOr(-1);

export const getPositions = ifElse(
  x => x === null,
  always([]),
  map(x =>
    pipe(
      box,
      ap([orNegativeOne('latitude'), orNegativeOne('longitude')]),
      map(when(isString, parseFloat)),
      ([lat, lng]) =>
        merge(x, {
          lat,
          lng,
        })
    )(x)
  )
);

export const deriveCenter = positions =>
  reduce(
    (center, position) => ({
      lat: center.lat + position.lat / positions.length,
      lng: center.lng + position.lng / positions.length,
    }),
    { lat: 0, lng: 0 }
  )(positions);

export const deriveBounds = positions =>
  reduce(
    (bounds, position) => ({
      sw: {
        lat: position.lat < bounds.sw.lat ? position.lat : bounds.sw.lat,
        lng: position.lng < bounds.sw.lng ? position.lng : bounds.sw.lng,
      },
      ne: {
        lat: position.lat > bounds.ne.lat ? position.lat : bounds.ne.lat,
        lng: position.lng > bounds.ne.lng ? position.lng : bounds.ne.lng,
      },
    }),
    { sw: { lat: 90, lng: 180 }, ne: { lat: -90, lng: -180 } }
  )(positions);

export const pathWithPrefix = x => ({ ...x, path: withPrefix(x.path) });

const validatePosition = (position, max) =>
  position && !Number.isNaN(position) && Math.abs(position) <= max;

export const validateLongitude = longitude => validatePosition(longitude, 180);

export const validateLatitude = latitude => validatePosition(latitude, 85);

const degToRad = value => value * (Math.PI / 180);
const radToDeg = value => (180 * value) / Math.PI;

export const getBoundingBox = ({ lat, lon }, radius = 25) => {
  if (radius < 0) return;
  // coordinate limits
  const MIN_LAT = degToRad(-90);
  const MAX_LAT = degToRad(90);
  const MIN_LON = degToRad(-180);
  const MAX_LON = degToRad(180);
  // Earth's radius (km)
  const R = 6378.1;
  // angular distance in radians on a great circle
  const radDist = radius / R;
  // center point coordinates (rad)
  const radLat = degToRad(lat);
  const radLon = degToRad(lon);
  // minimum and maximum latitudes and longitudes for given distance
  let minLatWithin = radLat - radDist;
  let maxLatWithin = radLat + radDist;
  let minLonWithin = 0;
  let maxLonWithin = 0;
  // define deltaLon to help determine min and max longitudes
  const deltaLon = Math.asin(Math.sin(radDist) / Math.cos(radLat));
  if (minLatWithin > MIN_LAT && maxLatWithin < MAX_LAT) {
    minLonWithin =
      minLonWithin < MIN_LON ? minLonWithin + 2 * Math.PI : radLon - deltaLon;
    maxLonWithin =
      maxLonWithin > MAX_LON ? maxLonWithin - 2 * Math.PI : radLon + deltaLon;
  }
  // a pole is within the given distance
  else {
    minLatWithin = Math.max(minLatWithin, MIN_LAT);
    maxLatWithin = Math.min(maxLatWithin, MAX_LAT);
    minLonWithin = MIN_LON;
    maxLonWithin = MAX_LON;
  }
  return [
    radToDeg(minLatWithin),
    radToDeg(minLonWithin),
    radToDeg(maxLatWithin),
    radToDeg(maxLonWithin),
  ];
};
