import { css } from '@emotion/react';
import { fontFace } from '@fire/_fonts';

export const readMore = css`
  margin-left: 4px;
  ${fontFace};
  color: rgb(72, 72, 72);
  font-weight: 700;
  cursor: pointer;
  text-decoration: underline;
`;
