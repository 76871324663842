import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import smoothscroll from 'smoothscroll-polyfill';

import CarouselArrow from '@components/Carousel/CarouselArrow';

import { LIMIT_ARROW, MIN_WIDTH } from './constants';
import * as ß from './styles';

const Scroller = ({ children, minMax, isFlex, scrollType }) => {
  const scrollerRef = useRef();
  const listAmount = children.length;
  const showArrows = listAmount > LIMIT_ARROW;
  const [isAtEnd, setIsAtEnd] = useState(false);
  const [isAtStart, setIsAtStart] = useState(true);

  const handleArrowClick = direction => {
    const element = scrollerRef.current;

    const scrollAmountInPixels =
      scrollType === 'full' ? element.clientWidth - MIN_WIDTH : MIN_WIDTH;
    smoothscroll.polyfill();

    const toTheRight = direction === 'right';

    const checkIfAtEnd = ({ offsetWidth, scrollLeft, scrollWidth }) =>
      toTheRight
        ? offsetWidth + scrollLeft >= scrollWidth - scrollAmountInPixels
        : false;

    element.scrollBy({
      left: toTheRight ? scrollAmountInPixels : -scrollAmountInPixels,
      behavior: 'smooth',
    });

    setIsAtEnd(checkIfAtEnd(element));
    setIsAtStart(
      toTheRight ? false : element.scrollLeft <= scrollAmountInPixels
    );
  };

  return (
    <div css={ß.wrapper}>
      {!isAtEnd && showArrows && (
        <div css={ß.rightArrow}>
          <CarouselArrow
            direction="next"
            position="drilldown"
            onClick={() => handleArrowClick('right')}
          />
        </div>
      )}

      {!isAtStart && showArrows && (
        <div css={ß.leftArrow}>
          <CarouselArrow position="drilldown" onClick={handleArrowClick} />
        </div>
      )}
      <div
        ref={scrollerRef}
        css={isFlex ? ß.scrollerFlex : ß.scroller(children.length, minMax)}
      >
        {children}
      </div>
    </div>
  );
};

Scroller.defaultProps = {
  isFlex: false,
};

Scroller.propTypes = {
  children: PropTypes.array,
  minMax: PropTypes.string,
  isFlex: PropTypes.bool,
  scrollType: PropTypes.oneOf(['full', 'single']),
};

export default Scroller;
