import { useContext } from 'react';

import { MaybeData } from '@fire/contexts';
import { submit, trackObject } from '@tracking/utils';
import { prepareToTrackEvent } from '@tracking/utils/event';
import { prepareToTrackProduct } from '@tracking/utils/product';
import { prepareToTrackSpecialAds } from '@tracking/utils/specialAds';
import { isEmptyOrNil } from '@utils/generic';

import { getList } from './utils';

const useTracking = (label, overrideList) => {
  const { pageType, prn } = useContext(MaybeData);

  const list = !isEmptyOrNil(overrideList)
    ? overrideList
    : getList(prn, pageType);

  return {
    trackObject,
    submit,
    prepareToTrackProduct: prepareToTrackProduct(label, list),
    prepareToTrackSpecialAds: prepareToTrackSpecialAds(label, list),
    prepareToTrackEvent: prepareToTrackEvent(label, list),
  };
};

export default useTracking;
