import PropTypes from 'prop-types';

export const DetailsType = {
  checkIn: PropTypes.string,
  checkOut: PropTypes.string,
  city: PropTypes.string,
  email: PropTypes.string,
  firstName: PropTypes.string,
  guests: PropTypes.shape({
    adults: PropTypes.number,
    dogs: PropTypes.number,
    kids: PropTypes.number,
    kidsAges: PropTypes.arrayOf(PropTypes.number),
  }),
  lastName: PropTypes.string,
  message: PropTypes.string,
  nights: PropTypes.number,
  type: PropTypes.string,
  vehicleSize: PropTypes.string,
};
