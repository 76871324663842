import { useEffect, useState } from 'react';

const useIsConsumerApp = () => {
  const [isConsumerApp, setIsConsumerApp] = useState(false);
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const consumerParam = queryParams.get('consumer');
    setIsConsumerApp(consumerParam === 'app');
  }, []);

  return isConsumerApp;
};

export default useIsConsumerApp;
