import React from 'react';
import { useTranslation } from 'react-i18next';

import { T } from '@fire/i18n/i18n-constants';

import * as ß from './styles';

const namespace = T?.Tile ?? '';

const AdLabel = () => {
  const { t } = useTranslation(namespace);

  return <div css={ß.ad}>{t('Anzeige')}</div>;
};

export default AdLabel;
