import { isEmptyOrNil } from '@utils/generic';

import {
  PAGETYPE_TO_LIST_MAP,
  PRN_TO_LIST_MAP,
  UNKNOW_LIST,
} from './constants';

export const getList = (prn, pageType) => {
  if (!isEmptyOrNil(prn) && PRN_TO_LIST_MAP?.[prn]) {
    return PRN_TO_LIST_MAP[prn];
  }

  if (!isEmptyOrNil(pageType) && PAGETYPE_TO_LIST_MAP?.[pageType]) {
    return PAGETYPE_TO_LIST_MAP[pageType];
  }

  return UNKNOW_LIST;
};
