import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import FavoriteHeart from '@components/FavoriteHeart';
import Link from '@components/Link';
import StarRating from '@components/StarRating';
import Truncate from '@components/Truncate';

import * as ß from './styles';
import { trackClickReview } from './tracking';
import { getImageURL, prefix } from './utils';

const Review = ({
  campsite: { prn, name },
  review: { nickname, rating, comment, headline },
  tile: { country, images, path },
}) => {
  const { t } = useTranslation(prefix);
  const image = getImageURL(images);

  const handleClick = () => trackClickReview(prn);

  return (
    <div css={ß.wrapper}>
      <div css={ß.wrapperImage}>
        <FavoriteHeart prn={prn} />
        <Link to={path} onClick={handleClick}>
          <img alt={name} css={ß.image} data-src={image} className="lazyload" />
        </Link>
      </div>
      <div>
        <Link to={path} onClick={handleClick}>
          <p css={ß.title}>{name}</p>
        </Link>
        <p>{country}</p>
        <div css={ß.stars}>
          <StarRating rating={rating} big />
          <p>
            {<span dangerouslySetInnerHTML={{ __html: nickname }} /> ||
              t('Anonymous')}
          </p>
        </div>
        {headline && <h4 css={ß.headline}>{headline}</h4>}
        <p css={ß.content}>
          <Truncate words={comment} prefix={prefix} />
        </p>
      </div>
    </div>
  );
};

Review.propTypes = {
  campsite: PropTypes.shape({
    name: PropTypes.string.isRequired,
    prn: PropTypes.string.isRequired,
  }).isRequired,
  tile: PropTypes.shape({
    country: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
      })
    ).isRequired,
    path: PropTypes.string.isRequired,
  }).isRequired,
  review: PropTypes.shape({
    comment: PropTypes.string,
    headline: PropTypes.string,
    nickname: PropTypes.string,
    rating: PropTypes.string.isRequired,
  }),
};

export default Review;
