import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Button from '@components/Button';
import { DEFAULT_TOTAL_ADULTS } from '@fire/constants';

import * as ß from '../styles';

const hasDefaultValues = ({ kidsAges, totalAdults }) =>
  kidsAges?.length === 0 && totalAdults === DEFAULT_TOTAL_ADULTS;

const GuestPickerButtons = ({
  kidsAges,
  onClick,
  prefix,
  resetGuests,
  totalAdults,
}) => {
  const { t } = useTranslation(prefix);

  return (
    <>
      <button
        css={ß.resetButton}
        disabled={hasDefaultValues({ kidsAges, totalAdults })}
        onClick={resetGuests}
        type="button"
      >
        {t('Löschen')}
      </button>
      <Button isFullWidth onClick={onClick} size="large" variant="primary">
        {t('Anwenden')}
      </Button>
    </>
  );
};

GuestPickerButtons.propTypes = {
  kidsAges: PropTypes.array,
  onClick: PropTypes.func.isRequired,
  totalAdults: PropTypes.number,
  resetGuests: PropTypes.func,
  prefix: PropTypes.string,
};

export default GuestPickerButtons;
