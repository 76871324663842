export const SLASH = ' / ';

export const PLATFORMS = Object.freeze({
  DE: 'pincamp.de',
  CH: 'pincamp.ch',
});

export const GERMAN_LOCALES = Object.freeze({
  DE: 'de',
  CH_DE: 'de-CH',
});

export const LOCALES = Object.freeze({
  ...GERMAN_LOCALES,
  CH_FR: 'fr-CH',
  CH_IT: 'it-CH',
});

export const imageCropUrlParam =
  '?crop=entropy&w=400&fit=crop&auto=compress,format&ar=3:2';

export const AVAILABILITIES_API =
  process.env.GATSBY_AVAILABILITIES_API ||
  'https://www.pincamp.de/api/availabilities/v2/accommodations';

export const TILES_API =
  process.env.GATSBY_TILES_API || 'https://www.pincamp.de/v1/tiles';

export const TILES_API_WITH_AVAILABILITIES =
  process.env.GATSBY_TILES_API_WITH_AVAILABILITIES ||
  'https://www.pincamp.de/v2/tiles';

export const LIST_TILES_API =
  process.env.GATSBY_LIST_TILES_API || 'https://pincamp.de/v2/list-tiles';

export const FLEXIBLE_DATES_LIST_API =
  process.env.GATSBY_FLEXIBLE_DATES_LIST_API ||
  'https://www.pincamp.de/api/search/campsites/availabilities/v1/flexible-dates-availability';

export const ADS_API =
  process.env.GATSBY_ADS_API || 'https://www.pincamp.de/api/tiles/v1/ads';

export const SUGGESTIONS_API =
  process.env.GATSBY_SUGGESTIONS_API ||
  'https://www.pincamp.de/api/search/suggest';

export const PAGE_SIZE = 17;

export const MAPBOX_API_URL = 'https://api.mapbox.com';

export const COTM_API =
  process.env.GATSBY_COTM_API ||
  'https://www.pincamp.de/v1/campsites-of-the-month';

export const DATE_FORMAT_ISO8601 = 'YYYY-MM-DD';

export const AVAILABILITY_STORAGE_KEY = 'BOOKING_DATA';
export const DEFAULT_TOTAL_ADULTS = 2;
export const DEFAULT_KID_AGE = 0;
export const THE_OLDEST_MINOR = 17;
export const MAX_GUEST_AMOUNT = 10;
export const DEFAULT_KIDS_AGES = [];

export const AVAILABILITY_INITIAL_STATE = hasDefaultValues => ({
  date_from: undefined,
  date_to: undefined,
  kidsAges: DEFAULT_KIDS_AGES,
  totalAdults: hasDefaultValues ? DEFAULT_TOTAL_ADULTS : undefined,
});

export const GATSBY_NEWSLETTER_SIGNUP =
  process.env.GATSBY_NEWSLETTER_SIGNUP ||
  'https://www.pincamp.de/api/newsletters/signup';

export const ACCOMMODATION_PARAMS = Object.freeze([
  'date_from',
  'date_to',
  'children',
  'adults',
]);

const getPlatformInfo = () => {
  let language = 'de';
  const pathSuffix =
    process.env.S3_BUCKET === 'campfire-pages-dev' ? 'dev' : 'www';
  let pathPrefix = '';
  let domainExt = 'de';

  switch (process.env.TARGET) {
    case `ch/itch`:
      pathPrefix = `it`;
      domainExt = `ch`;
      language = pathPrefix;
      break;
    case `ch/frch`:
      pathPrefix = `fr`;
      domainExt = `ch`;
      language = pathPrefix;
      break;
    case `ch/dech`:
      pathPrefix = `de`;
      domainExt = `ch`;
      break;
  }
  return {
    language,
    pathSuffix,
    pathPrefix,
    domainExt,
  };
};

const fallBackUrl = () => {
  const platformInfo = getPlatformInfo();
  const { domainExt, pathSuffix } = platformInfo;
  return `https://${pathSuffix}.pincamp.${domainExt}`;
};

export const GATSBY_ENTRYPOINT = process.env.GATSBY_ENTRYPOINT || fallBackUrl();

export const IMGIX_URL = `${GATSBY_ENTRYPOINT}/i`;

export const GATSBY_IP_TO_LOCATION_SERVICE =
  process.env.GATSBY_IP_TO_LOCATION_SERVICE ||
  'https://ip-to-location.production.aws.pincamp.io/';

export const GATSBY_REVIEWS_API =
  process.env.GATSBY_REVIEWS_API || 'https://www.pincamp.de/api/reviews';

export const RESERVATION_REQUEST_API =
  process.env.GATSBY_RESERVATION_REQUEST_API ||
  'https://de.production.aws.pincamp.io/api/reservation-request/';

export const ADAC_APPS_PAGE_LINK =
  'https://www.pincamp.de/unternehmen/produkte/adac-camping-stellplatzfuehrer-app/';

export const ADAC_CAMPING_BOOK_LINK =
  'https://www.pincamp.de/produkte/adac-camping-und-stellplatzfuehrer';
