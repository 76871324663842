import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { mergeAll, pathOr, propOr } from 'ramda';

import { Footer } from '@components/Footer';
import HeaderContainer from '@components/HeaderContainer';
import Search from '@components/Search';
import MaybeData from '@contexts/MaybeData';
import { SearchSuggestionsProvider } from '@contexts/SearchSuggestions';
import AvailabilityStateProvider from '@fire/contexts/AvailabilityState';
import { GlobalStyles } from '@fire/globalStyles';
import withI18next from '@fire/i18n/withI18next';
import { ProsciuttoProvider } from '@hooks/useProsciutto';
import { PageView } from '@utils/tracking';

import SmartMetaAndSEO from './SmartMetaAndSEO';
import { parsePageViewData } from './tracking';
import { getContent, getIntro } from './utils';

const DestinationPage = props => {
  const namespace = propOr('dunno', 'namespace', props);

  const pageContext = mergeAll([
    propOr({}, 'pageContext', props),
    pathOr({}, ['data', 'currentPage', 'context'], props),
    { location: propOr({}, 'location', props) },
    { faqListPage: pathOr({}, ['data', 'faqListPage'], props) },
  ]);

  const i18n = propOr({}, 'i18n', props);

  const {
    interlinkingTopTopics = [],
    interlinkingTopRegions = [],
    interlinkingTopDestinations = [],
    interlinkingTopTouristicRegions = [],
    prn,
  } = pageContext;

  const destinationIntro = getIntro(pageContext);
  const destinationContent = getContent(pageContext);

  return (
    <>
      <ProsciuttoProvider pageContext={pageContext}>
        <MaybeData.Provider value={pageContext}>
          <AvailabilityStateProvider hasHash={false}>
            <SearchSuggestionsProvider>
              <SmartMetaAndSEO
                pageContext={pageContext}
                namespace={namespace}
              />
              <PageView trackingFunction={parsePageViewData} />
              <GlobalStyles />
              <HeaderContainer />
              <Search
                pageContext={pageContext}
                prn={prn}
                namespace={namespace}
                interlinking={{
                  interlinkingTopTopics,
                  interlinkingTopRegions,
                  interlinkingTopDestinations,
                  interlinkingTopTouristicRegions,
                }}
                intro={destinationIntro}
                content={destinationContent}
                i18n={i18n}
              />
              <span id="sticky-map-stopper" />
              <Footer pageContext={pageContext} />
            </SearchSuggestionsProvider>
          </AvailabilityStateProvider>
        </MaybeData.Provider>
      </ProsciuttoProvider>
    </>
  );
};

DestinationPage.propTypes = {
  namespace: PropTypes.string,
  pageContext: PropTypes.shape({
    h1: PropTypes.string,
    latitude: PropTypes.string,
    longitude: PropTypes.string,
    metaDescription: PropTypes.string,
    metaRobots: PropTypes.string,
    name: PropTypes.string,
    parent: PropTypes.string,
    path: PropTypes.string,
    prn: PropTypes.string,
    query: PropTypes.string,
    siteUrl: PropTypes.string,
    title: PropTypes.string,
    breadcrumbs: PropTypes.array,
    interlinking: PropTypes.shape({
      interlinkingTopDestinations: PropTypes.array,
      interlinkingTopRegions: PropTypes.array,
      interlinkingTopTopics: PropTypes.array,
      interlinkingTopTouristicRegions: PropTypes.array,
    }),
    content: PropTypes.shape({
      activities: PropTypes.string,
      camping: PropTypes.string,
      campingTypes: PropTypes.string,
      events: PropTypes.string,
      gastronomy: PropTypes.string,
      journey: PropTypes.string,
      targetGroups: PropTypes.string,
      topDestinations: PropTypes.string,
      topPointsOfInterest: PropTypes.string,
      topTips: PropTypes.string,
      weather: PropTypes.string,
      intro: PropTypes.string,
    }),
  }),
  i18n: PropTypes.object,
};

DestinationPage.defaultProps = {
  pageContext: {},
  i18n: {
    t: key => key,
  },
  namespace: 'pt-destination-page',
};

export const query = graphql`
  query DestinationPageData($prn: String!) {
    currentPage: campfirePage(context: { prn: { eq: $prn } }) {
      path
      component
      context {
        countryCode
        pageType
        graphCMSLocale
        breadcrumbs
        entity
        geoType
        bboxNorth
        bboxSouth
        bboxEast
        bboxWest
        latitude
        longitude
        gastronomy
        topPointsOfInterest
        weather
        targetGroups
        searchQuery
        teaser
        intro
        image {
          url
          altText
          seoFilename
        }
        camping
        journey
        topDestinations
        topTips
        activities
        events
        initialState {
          total_pages
          total_records
          tiles {
            prn
            region
            adac_attributes {
              acceptDogs
              adac_rating
              amount_pitches_total
              amount_rental_accommodations
              has_beach
              has_bread_roll_opportunity
              has_kids_activities
              has_restaurant
              has_shops
              has_swimming_pool
              has_wifi
            }
            avgUserRating
            ctaLink
            latitude
            longitude
            name
            path
            plan
            rating
            userRating
            tcs_attributes {
              rating
            }
            comparison_price {
              amount
              currency
              prefix
            }
            contactData {
              city
              country
              countryCode
              email
              phone
              postcode
              region
              street
              website
            }
            images {
              altText
              campsite
              img
              isMain
              position
              url
              crop {
                height
                width
                x
                y
              }
            }
          }
        }
        interlinkingTopDestinations {
          image {
            altText
            copyright
            fileName
            handle
            url
          }
          path
          prn
          name
        }
        interlinkingTopRegions {
          image {
            altText
            copyright
            fileName
            handle
            url
          }
          path
          prn
          name
        }
        interlinkingTopTopics {
          image {
            altText
            copyright
            fileName
            handle
            url
          }
          path
          prn
          name
        }
        interlinkingTopTouristicRegions {
          image {
            altText
            copyright
            fileName
            handle
            url
          }
          path
          prn
          name
        }
        metaDescription
        metaRobots
        name
        h1
        seoText
        title
        touristicNote
        partners {
          clickoutUrl
          id
          logo {
            altText
            alt_text
            url
            title
            campsite
            copyright
            fileName
            handle
            isMain
            name
            position
          }
          trackingId
        }
      }
    }
    faqListPage(prn: { eq: $prn }) {
      prn
      questions {
        order
        question {
          phrase
          resources
        }
        answer {
          phrase
          resources
        }
      }
    }
    locize: allLocale(
      filter: {
        namespace: {
          in: [
            "pt-destination-page"
            "pt-map-country"
            "pt-hero-menu"
            "pt-footer"
            "pt-home-page"
            "pt-premium-partners-footer"
            "pt-signup-newsletter"
            "pt-language-selector"
            "pt-review-banner"
            "pt-cookie-banner"
            "pt-csp-map"
            "pt-search-box"
            "pt-suggestion-list"
            "pt-search-result"
            "pt-search-result-list-tile"
            "pt-search-result-filter"
            "pt-search-result-summary"
            "availability-box"
            "faq-list-page"
            "accommodations"
            "flexible-dates"
          ]
        }
      }
    ) {
      edges {
        node {
          data
          id
          language
          namespace
        }
      }
    }
  }
`;

export default withI18next()(DestinationPage);
