import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { PREFIX } from './consts';

const AvailableCampingPlace = ({ count }) => {
  const { t } = useTranslation(PREFIX);
  return count ? (
    <p>
      <strong>{t('available-camping-place', { count })}</strong>
    </p>
  ) : (
    <></>
  );
};

AvailableCampingPlace.propTypes = {
  count: PropTypes.number,
};

export default AvailableCampingPlace;
