import { useContext, useState } from 'react';

import MaybeData from '@contexts/MaybeData';
import { LOCALES } from '@fire/constants';

/*
                               | |
  __ ___   _____   ___ __ _  __| | ___
 / _` \ \ / / _ \ / __/ _` |/ _` |/ _ \
| (_| |\ V / (_) | (_| (_| | (_| | (_) |
 \__,_| \_/ \___/ \___\__,_|\__,_|\___/

Use avocado to get content from a json file in the correct language
and assign to a component's state
*/

export const useAvocado = avocado => {
  const pageContext = useContext(MaybeData);
  const language = pageContext?.language || LOCALES.DE;
  const [content] = useState(avocado?.[language] || {});

  return content;
};

export default useAvocado;
