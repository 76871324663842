import React from 'react';
import PropTypes from 'prop-types';
import { identity } from 'ramda';

import * as ß from './styles';

// Note, using onClick from label to check the box fixes
// a common double-click bug on checkbox input fields
const Checkbox = ({ textElement, fieldName, isChecked, onChange }) => (
  <label css={ß.checkboxArea}>
    <input
      onClick={onChange}
      onChange={identity}
      type="checkbox"
      name={fieldName}
      checked={isChecked}
      css={ß.checkbox}
    />
    <span css={isChecked ? ß.checkmarkChecked : ß.checkmark} />
    {textElement}
  </label>
);

Checkbox.propTypes = {
  onChange: PropTypes.func,
  fieldName: PropTypes.string,
  isChecked: PropTypes.bool,
  textElement: PropTypes.node,
};

// The only reson this is here
// is because handleChange is undefined
// on initial render
Checkbox.defaultProps = {
  onChange: () => {},
};

export default Checkbox;
