const openModals = new WeakSet();
let openModalsCount = 0;

export function register(modal, allowBackgroundScroll) {
  if (openModals.has(modal)) return;

  openModals.add(modal);
  openModalsCount++;

  if (!allowBackgroundScroll) {
    document.body.classList.add('has-modal');
  }
}

export function unregister(modal) {
  if (!openModals.has(modal)) return;

  openModals.delete(modal);
  openModalsCount--;

  if (openModalsCount < 1) {
    document.body.classList.remove('has-modal');
  }
}
