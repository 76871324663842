import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withPrefix } from 'gatsby';

import Input from '@components/Input';
import { DEFAULT_KIDS_AGES } from '@fire/constants';

import { PREFIX } from './constants';
import * as ß from './styles';

const GUEST_ICON = withPrefix('icons/booking-box/guests.svg');

const GuestForm = ({
  kidsAges,
  setShowGuestPicker,
  totalAdults,
  moreCss,
  id,
}) => {
  const { t } = useTranslation(PREFIX);

  const handleOnClick = () => {
    setShowGuestPicker(true);
  };

  return (
    <div
      css={[ß.row, ß.guestPicker, moreCss, ß.formRow, ß.withIcon]}
      className="guestForm"
      onClick={handleOnClick}
    >
      <img src={GUEST_ICON} alt="Guests" width={24} height={24} />
      <Input
        label={t('Guests')}
        id={id}
        readOnly
        value={`${t('Erwachsene', { count: totalAdults })} • ${t('Kinder', {
          count: kidsAges?.length,
        })}`}
      />
    </div>
  );
};

GuestForm.defaultProps = {
  kidsAges: DEFAULT_KIDS_AGES,
  id: 'guest-input',
};

GuestForm.propTypes = {
  kidsAges: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  setShowGuestPicker: PropTypes.func,
  totalAdults: PropTypes.number,
  moreCss: PropTypes.object,
  id: PropTypes.string,
};

export default GuestForm;
