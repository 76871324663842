import { css } from '@emotion/react';
import { darkOverlay, white } from '@fire/_color';
import { boxShadow } from '@fire/_const';
import { bold, fontSizeMedium } from '@fire/_fonts';
import { media, nonTouch } from '@fire/_mediaQueries';

const borderRadius = '4px';

export const wrapper = css`
  position: relative;
  margin-top: 12px;
  padding-left: 24px;

  ${media.md} {
    padding-left: 32px;
    padding-right: 32px;
  }
`;

export const title = css`
  font-size: 20px;
  margin-bottom: 12px;
`;

export const destination = css`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: ${borderRadius};

  p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${white};
    text-decoration: none;

    font-weight: ${bold};
    text-align: center;
    font-size: ${fontSizeMedium};
    z-index: 1;
    pointer-events: none;

    line-height: 22px; // exception to the rule
  }
`;

export const image = css`
  border-radius: ${borderRadius};
  width: 115px;
  height: 110px;
  display: block;
  object-fit: cover;
`;

export const scroller = css`
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 8px 0;

  &::-webkit-scrollbar {
    display: none;
  }

  > div {
    scroll-snap-align: start;
    margin-right: 16px;
    max-width: 115px;
  }
`;

export const rightArrow = amount => css`
  button {
    right: 9px;
  }
  ${media.xl} {
    ${amount < 6 && 'display: none;'}
  }
`;

export const leftArrow = css`
  button {
    left: 9px;
  }
`;

export const overlay = css`
  position: absolute;
  border-radius: ${borderRadius};
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${darkOverlay};

  &:after {
    ${boxShadow}
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: ${borderRadius};
    opacity: 0;
    transition: opacity 200ms;
  }

  ${nonTouch} {
    &:hover {
      &:after {
        opacity: 1;
      }
    }
  }
`;
