import React from 'react';
import PropTypes from 'prop-types';
import { withPrefix } from 'gatsby';

import { arrowDown, hidden, menuItem } from './styles.js';

const arrowIcon = withPrefix('/icons/menuArrow.svg');

export const MenuItem = ({ arrow, title, onClick }) => {
  const isArrow = arrow.length > 0;
  return (
    <div css={menuItem} onClick={onClick}>
      {title}{' '}
      <img
        src={arrowIcon}
        css={isArrow ? arrowDown : hidden}
        alt="Menu arrow"
      />
    </div>
  );
};

MenuItem.propTypes = {
  arrow: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClick: PropTypes.func,
};

MenuItem.defaultProps = {
  arrow: [],
};

export default MenuItem;
