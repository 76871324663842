import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import HomePageBox from '@components/AvailabilityBox/HomePageBox';
import HeroImageContent from '@components/HeroImageContent';
import { BREAKPOINTS } from '@fire/_mediaQueries';
import { useDimensions } from '@fire/hooks/useDimensions';
import { T } from '@fire/i18n/i18n-constants';
import { useHeroImage } from '@hooks/useHeroImage';
import { useLoyaltyPlatinum } from '@hooks/useLoyalty';

import Image from './Image';
import * as ß from './styles';

const HeroImage = () => {
  const prefix = T?.HomePage ?? '';
  const { t } = useTranslation(prefix);
  const partner = useLoyaltyPlatinum({ random: true });
  const image = useHeroImage(partner?.prn);
  const { width } = useDimensions();

  const isMobile = width <= BREAKPOINTS.md;

  return (
    <>
      <div css={ß.heroImage} data-testid="heroImage">
        <div css={ß.slideshow}>
          <div css={ß.imageContainer}>
            <Image
              alt={t('Der beste Platz für Camper')}
              image={image?.childImageSharp}
            />
          </div>
        </div>
        <div css={ß.content}>
          <div css={ß.heroImageContentWrapper}>
            <HeroImageContent
              rating={partner?.rating}
              name={partner?.name}
              region={partner?.region}
              url={partner?.url}
              prn={partner?.prn}
            />
          </div>
          <div css={ß.wrapper}>
            <HomePageBox isMobile={isMobile} />
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(HeroImage);
