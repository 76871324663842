import React from 'react';
import PropTypes from 'prop-types';

import { HTMLText } from '@utils/HTMLText';

import * as ß from './styles';

const ContentAccordion = ({ markdown }) => (
  <div css={ß.infoText}>
    <HTMLText source={markdown} />
  </div>
);

ContentAccordion.propTypes = {
  markdown: PropTypes.string,
};

export default ContentAccordion;
