import React from 'react';

import * as ß from '../styles';

const SkeletonStars = () => (
  <div css={ß.skeletonStars}>
    <span />
    <span />
  </div>
);

export default SkeletonStars;
