import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import MobileHomeIcon from '@fire/assets/mobile-home.svg';
import { MaybeData } from '@fire/contexts';
import { getMobileHomesUrl } from '@fire/utils/platform';

import Link from '../Link';
import { NAMESPACE } from './constants';
import * as ß from './styles';

const MobileHomesPromo = () => {
  const pageContext = useContext(MaybeData);
  const { t } = useTranslation(NAMESPACE);

  const link = getMobileHomesUrl(pageContext);

  return (
    <>
      <div css={ß.headlineWrapper}>
        <div css={ß.headlineLeft}>
          <MobileHomeIcon alt="mobile-home-icon" css={ß.icon} />
          <p css={ß.headline}>{t('mobilehome-title')}</p>
        </div>
      </div>
      <span css={ß.headlineTitle(true)}>
        <Link to={link}>{t('mobilehome-description')}</Link>
      </span>
    </>
  );
};

export default MobileHomesPromo;
