import { take } from 'ramda';

import { GATSBY_ENTRYPOINT } from '@fire/constants';
import { T } from '@fire/i18n/i18n-constants';
import { shuffleArray } from '@utils/generic';
import { getPlaceholderImage } from '@utils/image';

export const getImageURL = images => {
  const maxWidth = 500;
  const imgUrl = images?.[0]?.url;

  return imgUrl
    ? `${GATSBY_ENTRYPOINT}${imgUrl}?max-w=${maxWidth}&max-h=${maxWidth}&auto=compress,format&fp-x=0.74&fp-y=0.5&fit=crop`
    : getPlaceholderImage(maxWidth);
};

export const prefix = T?.HomePage ?? '';
export const takeThreeRandomReviews = x => take(3, shuffleArray(x));
