import { css } from '@emotion/react';
import { charcoal, gray5, gray7, gray8, shadow, white } from '@fire/_color';
import { borderRadius, zIndex } from '@fire/_const';
import {
  bold,
  fontFace,
  fontSizeBody,
  fontSizeMedium,
  regular,
} from '@fire/_fonts';
import { media } from '@fire/_mediaQueries';

export const halfBorderRadius = css`
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;

export const searchbar = active => css`
  background: ${white};
  border: 1px solid ${gray7};
  border-radius: ${borderRadius};
  ${active && halfBorderRadius}
  color: ${charcoal};
  display: flex;
  flex-direction: column;
  margin: 0;
  outline-width: 0;
  position: relative;
  width: 100%;

  ${media.lg} {
    margin-left: 12px;
    max-width: 400px;
  }
`;

export const searchInputWrapper = css`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
`;

export const searchInput = css`
  ${fontFace};
  width: 100%;
  height: 50px;
  border: none;
  font-size: ${fontSizeMedium};
  font-weight: ${bold};
  border-radius: ${borderRadius};
  color: ${charcoal};

  &::placeholder {
    font-weight: ${regular};
  }

  &:focus {
    outline: none;
  }
`;

export const searchIcon = css`
  margin-left: 11px;
  margin-right: 5px;
  cursor: pointer;
`;

export const closeIcon = css`
  cursor: pointer;
  margin: 0 11px;
`;

export const suggestion = isActive => css`
  background-color: ${isActive ? gray5 : 'transparent'};
  cursor: pointer;
  display: block;
  padding: 6px 0px 6px 38px;
  text-decoration: none;
  transition: background-color 0.2s ease;

  em {
    font-weight: bold;
  }
`;

export const searchTermLink = css`
  border-top: 1px solid ${gray5};
  font-size: 18px;
  font-weight: bold;
`;

export const suggestionList = css`
  hr {
    margin: 0px;
    background-color: ${gray5};
    height: 1px;
    border: 0px;
  }

  hr:nth-of-type(1) {
    height: 0px;
  }
`;

export const modalBody = css`
  padding: 15px 10px;
`;

export const modalTitle = css`
  text-align: center;
  font-size: 26px;

  font-weight: ${bold};
  margin-bottom: 15px;
`;

export const suggestionsWrapper = css`
  z-index: 12;
`;

export const searchBarDropDownWrapper = css`
  background-color: ${white};
  border: 1px solid ${gray7};
  border-bottom-left-radius: ${borderRadius};
  border-bottom-right-radius: ${borderRadius};
  box-shadow: 0 3px 4px ${shadow};
  display: block;
  font-size: 16px;
  left: -1px;
  position: absolute;
  text-decoration: none;
  top: 50px;
  width: calc(100% + 2px);
  z-index: ${zIndex.layerSix};
`;

const isActiveHover = css`
  &:hover {
    background-color: ${gray5};
  }
`;

export const headlineTitle = isActive => css`
  border-bottom: 1px solid ${gray5};
  cursor: pointer;
  display: inline-block;
  padding: 6px 0;
  padding-left: 38px;
  width: 100%;
  color: ${isActive ? charcoal : gray8};
  cursor: ${isActive ? 'pointer' : 'unset'};

  ${isActive && isActiveHover};

  a {
    text-decoration: none;
  }
`;

export const headlineWrapper = css`
  display: flex;
  flex-direction: row;
  padding: 11px 5px;
`;

export const headlineLeft = css`
  display: flex;
`;

export const headline = css`
  font-size: ${fontSizeBody};
  font-weight: ${bold};
`;

export const icon = css`
  height: 20px;
  margin: 2px 6px 2px 2px;
  width: 25px;
`;

export const searchIconMobile = css`
  margin-left: 14px;
  margin-right: 0;
`;

export const hideOnDesktop = css`
  ${media.lg} {
    display: none;
  }
`;

export const hideOnMobile = css`
  display: none;

  ${media.lg} {
    display: block;
  }
`;

export const hideAll = css`
  display: none;
`;
