import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { fork } from 'fluture';
import { useFormik } from 'formik';
import queryString from 'query-string';
import {
  always,
  append,
  assoc,
  complement,
  cond,
  curry,
  equals,
  pathOr,
  pipe,
  pluck,
  propOr,
  range,
  reduce,
  T as RTrue,
  values as RValues,
} from 'ramda';

import Button from '@components/Button';
import Input from '@components/Input';
import TextArea from '@components/TextArea';
import FormDataContext from '@contexts/FormData';
import { T } from '@fire/i18n/i18n-constants';
import { localStorage } from '@utils/local-storage';

import { Accordion, AccordionItem } from './Accordion';
import { checkAuth, verifyDuplicateReview } from './auth';
import Checkbox from './Checkbox';
import ContentAccordion from './ContentAccordion';
import ErrorArea from './ErrorArea';
import CheckboxDataPrivacyAcceptTerms from './locize/CheckboxDataPrivacyAcceptTerms';
import PersonalInfoDescriptionEmail from './locize/PersonalInfoDescriptionEmail';
import PersonalInfoEmailDataPrivacy from './locize/PersonalInfoEmailDataPrivacy';
import RateInput from './RateInput';
import Select from './Select';
import * as ß from './styles';

const prefix = pathOr('', ['ReviewPage'], T);

const isEmailValid = email => /^[\w%+.-]+@[\d.a-z-]+\.[a-z]{2,4}$/i.test(email);

const conditionsId = 'teilnahmebedingungen';

const monthSelections = t => [
  {
    value: 'Januar',
    label: t('step-1-form.option-travel-date-month-1'),
  },
  {
    value: 'Februar',
    label: t('step-1-form.option-travel-date-month-2'),
  },
  {
    value: 'März',
    label: t('step-1-form.option-travel-date-month-3'),
  },
  {
    value: 'April',
    label: t('step-1-form.option-travel-date-month-4'),
  },
  {
    value: 'Mai',
    label: t('step-1-form.option-travel-date-month-5'),
  },
  {
    value: 'Juni',
    label: t('step-1-form.option-travel-date-month-6'),
  },
  {
    value: 'Juli',
    label: t('step-1-form.option-travel-date-month-7'),
  },
  {
    value: 'August',
    label: t('step-1-form.option-travel-date-month-8'),
  },
  {
    value: 'September',
    label: t('step-1-form.option-travel-date-month-9'),
  },
  {
    value: 'Oktober',
    label: t('step-1-form.option-travel-date-month-10'),
  },
  {
    value: 'November',
    label: t('step-1-form.option-travel-date-month-11'),
  },
  {
    value: 'Dezember',
    label: t('step-1-form.option-travel-date-month-12'),
  },
];

const yearSelections = () =>
  reduce(
    (acc, val) =>
      append(
        {
          value: new Date().getFullYear() - val,
          label: (new Date().getFullYear() - val).toString(),
        },
        acc
      ),
    [],
    range(0, 5)
  );

// the elements should match the layout linearity
const requiredFields = [
  { formId: 'headline', elemId: 'form-headline' },
  { formId: 'content', elemId: 'form-content' },
  { formId: 'travelDate', elemId: 'form-travel-date' },
  { formId: 'rating', elemId: 'form-rating' },
  { formId: 'termsAccepted', elemId: 'form-terms-accepted' },
  { formId: 'email', elemId: 'form-email' },
];

const redirectToInvalidPage = () => {
  location.search = pipe(
    queryString.parse,
    assoc('valid', 'false'),
    assoc('duplicate', 'true'),
    kv => queryString.stringify(kv, { encode: false })
  )(location.search);
};

const openLottoAccordion = e => {
  e.preventDefault();
  const htmlElement = document.getElementById(`${conditionsId}-button`);
  if (htmlElement && htmlElement.firstChild) {
    try {
      if (htmlElement.firstChild.getAttribute('aria-expanded') !== 'true') {
        htmlElement.firstChild.click();
      }
    } catch {}
  }
};

const Step1 = () => {
  const {
    state: {
      formData,
      campsiteData,
      localStorageKey,
      titleDropdownConditions,
      dropdownConditions,
      titleDropdownRaffle,
      dropdownRaffle,
    },
    dispatch,
  } = useContext(FormDataContext);
  const { t } = useTranslation(prefix);
  const [errors, setErrors] = useState({});

  const { values, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: formData,
    onSubmit: submittedValues => {
      localStorage.setItem(localStorageKey, JSON.stringify(submittedValues));
      dispatch({ type: 'setFormData', payload: submittedValues });
      const maybeErrors = reduce(
        (acc, fieldKey) =>
          cond([
            [
              always(equals(fieldKey, 'email')),
              assoc(fieldKey, !isEmailValid(submittedValues.email)),
            ],
            [
              always(equals(fieldKey, 'termsAccepted')),
              assoc(fieldKey, !propOr(false, 0, submittedValues.termsAccepted)),
            ],
            [
              always(equals(fieldKey, 'rating')),
              assoc(fieldKey, propOr('0', fieldKey, submittedValues) === '0'),
            ],
            [
              always(equals(fieldKey, 'travelDate')),
              assoc(
                fieldKey,
                propOr('', 'travelDateYear', submittedValues).length === 0 ||
                  propOr('', 'travelDateMonth', submittedValues).length === 0
              ),
            ],
            [
              RTrue,
              assoc(
                fieldKey,
                propOr('', fieldKey, submittedValues).length === 0
              ),
            ],
          ])(acc),
        {},
        pluck('formId')(requiredFields)
      );
      const someError = RValues(maybeErrors).some(b => b);
      if (someError) {
        setErrors(maybeErrors);
      } else {
        const onUnique = response => {
          const reviewId = pathOr('', ['data', 'id'], response);
          if (reviewId.length > 0) {
            const onAuth = err => {
              if (err) {
                // TODO: REDIRECT TO ERROR PAGE
                console.error(err);
              } else {
                return dispatch({ type: 'setStep', payload: 2 });
              }
            };
            checkAuth({
              email: submittedValues.email,
              prn: campsiteData.prn,
              onAuth,
              reviewId,
            });
          }
        };
        // change from array to single value
        fork(
          error =>
            pathOr('', ['response', 'data', 'status'], error) === 'duplicate'
              ? redirectToInvalidPage()
              : console.error(error),
          onUnique,
          verifyDuplicateReview(
            campsiteData.prn,
            assoc('termsAccepted', true, submittedValues)
          )
        );
      }
    },
  });

  return (
    <>
      <Helmet>
        <script
          src="https://cdn.auth0.com/js/auth0/9.13.2/auth0.min.js"
          async="true"
        />
      </Helmet>
      <form css={ß.formsContainer} onSubmit={handleSubmit}>
        <h2 css={ß.label} id={'form-headline'}>
          {t('step-1-form.title-headline')}
        </h2>
        <TextArea
          css={ß.headlineInput}
          type="text"
          name="headline"
          onChange={handleChange}
          value={values.headline}
          placeholder={t('step-1-form.text-input-headline')}
        />
        <ErrorArea
          i18key={'step-1-form.error-headline'}
          t={t}
          showError={propOr(false, 'headline', errors)}
        />
        <h2 css={ß.label} id={'form-content'}>
          {t('step-1-form.title-content')}
        </h2>
        <TextArea
          css={ß.contentInput}
          type="text"
          name="content"
          onChange={handleChange}
          value={values.content}
          placeholder={t('step-1-form.text-input-content')}
        />
        <ErrorArea
          i18key={'step-1-form.error-content'}
          t={t}
          showError={propOr(false, 'content', errors)}
        />
        <h2 css={ß.label} id={'form-travel-date'}>
          {t('step-1-form.title-travel-date-month')}
        </h2>
        <div css={ß.selectorsContainer}>
          <Select
            name="travelDateMonth"
            label={t('step-1-form.option-travel-date-month-0')}
            options={monthSelections(t)}
            value={values.travelDateMonth}
            onChange={handleChange}
          />
          <Select
            name="travelDateYear"
            label={t('step-1-form.title-travel-year')}
            options={yearSelections()}
            value={values.travelDateYear}
            onChange={handleChange}
          />
        </div>
        <ErrorArea
          i18key={'step-1-form.error-travel'}
          t={t}
          showError={propOr(false, 'travelDate', errors)}
          mobileHeight={32}
          desktopHeight={48}
        />
        <h2 css={ß.label} id={'form-rating'}>
          {t('step-1-form.title-rate')}
        </h2>
        <RateInput
          onChange={handleChange}
          labels={[
            t('step-1-form.option-rate-1'),
            t('step-1-form.option-rate-2'),
            t('step-1-form.option-rate-3'),
            t('step-1-form.option-rate-4'),
            t('step-1-form.option-rate-5'),
          ]}
          selectedValue={values.rating}
        />
        <ErrorArea
          i18key={'step-1-form.error-rate'}
          t={t}
          showError={propOr(false, 'rating', errors)}
          mobileHeight={32}
          desktopHeight={48}
        />
        <h2 css={ß.label}>{t('step-1-form.personal-info-title-name')}</h2>
        <h2 css={ß.labelSmall} id={'form-nickname'}>
          {t('step-1-form.personal-info-label-name')}
        </h2>
        <Input
          id="name"
          label={t('step-1-form.personal-info-placeholder-name')}
          onChange={handleChange}
          value={values.nickname}
          type="text"
          name="nickname"
        />
        <h2 css={ß.label} id={'form-email'}>
          {t('step-1-form.personal-info-title-email')}
        </h2>
        <PersonalInfoDescriptionEmail t={t} />
        <Input
          id="email"
          label={t('step-1-form.personal-info-placeholder-email')}
          onChange={handleChange}
          value={values.email}
          type="text"
          name="email"
        />
        <ErrorArea
          i18key={'step-1-form.personal-info-error-email'}
          t={t}
          showError={propOr(false, 'email', errors)}
          mobileHeight={32}
          desktopHeight={32}
        />
        <PersonalInfoEmailDataPrivacy t={t} />
        <h2 style={{ marginTop: 48 }} css={ß.label} id={'form-terms-accepted'}>
          {t('step-1-form.title-data-privacy')}
        </h2>
        <Checkbox
          isChecked={pathOr(false, ['termsAccepted', 0], values)}
          value="1"
          onChange={curry(e => {
            setFieldValue('termsAccepted', complement(values.termsAccepted));
            e.preventDefault();
          })}
          textElement={
            <CheckboxDataPrivacyAcceptTerms
              t={t}
              openLottoAccordion={openLottoAccordion}
            />
          }
        />
        <ErrorArea
          i18key={'step-1-form.error-data-privacy-accept-terms'}
          t={t}
          showError={propOr(false, 'termsAccepted', errors)}
          mobileHeight={48}
          desktopHeight={48}
        />
        <div css={ß.submitButtonContainer}>
          <Button isFullWidth variant="primary" size="large" type="submit">
            {t('step-1-form.submit')}
          </Button>
        </div>
      </form>

      <Accordion>
        {titleDropdownConditions && dropdownConditions && (
          <AccordionItem
            id={conditionsId}
            title={titleDropdownConditions}
            body={<ContentAccordion markdown={dropdownConditions} />}
          />
        )}

        {titleDropdownRaffle && dropdownRaffle && (
          <AccordionItem
            id={'lotto-accordion'}
            title={titleDropdownRaffle}
            css={ß.accordionContainer}
            body={<ContentAccordion markdown={dropdownRaffle} />}
          />
        )}
      </Accordion>
    </>
  );
};

export default Step1;
