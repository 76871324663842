import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Sticky from 'react-sticky-el';
import PropTypes from 'prop-types';

import { Breadcrumbs } from '@components/Breadcrumbs';
import { ListpageCOTMCarousel } from '@components/CampsiteOfTheMonthCarousel';
import DestinationFilters from '@components/DestinationFilters';
import {
  trackFullscreenOff,
  trackFullscreenOn,
} from '@components/DestinationMap/tracking';
import DrilldownList from '@components/DrilldownList';
import { getInterlinkingData } from '@components/DrilldownList/utils';
import FAQListPage from '@components/FAQListPage';
import InfoMessage from '@components/InfoMessage';
import Loading from '@components/Loading';
import MobileMapToggle from '@components/MobileMapToggle';
import Modal from '@components/Modal';
import Pagination from '@components/Pagination';
import ResultSummary from '@components/ResultSummary';
import { DatePickerProvider } from '@contexts/DatePicker';
import InfoMessageContext, { InfoMessageProvider } from '@contexts/InfoMessage';
import LastClickedContext, { LastClickedProvider } from '@contexts/LastClicked';
import { TileControlAvailabilityModalProvider } from '@contexts/TileControlAvailabilityModal';
import { TileHighlightProvider } from '@contexts/TileHighlight';
import { BREAKPOINTS } from '@fire/_mediaQueries';
import { DimensionsProvider, useDimensions } from '@hooks/useDimensions';
import useProsciutto from '@hooks/useProsciutto';
import { PageContextType } from '@types';
import { isEmptyOrNil } from '@utils/generic';
import { isSearch } from '@utils/platform';
import { objectToQuery } from '@utils/url';

import CarouselMicrocamping from './CarouselMicrocamping';
import CarouselSmallAndCharming from './CarouselSmallAndCharming';
import FlexibleDates from './FlexibleDates';
import { MAIN_LIST_MINIMUM } from './FlexibleDates/consts';
import { FlexibleDatesProvider } from './FlexibleDates/context';
import Interlinks from './Interlinks';
import ListPageMap from './ListPageMap';
import ListTiles from './ListTiles';
import NoResultMessage from './NoResultMessage';
import NoSearchResults from './NoSearchResults';
import * as ß from './styles';
import { trackAmountOfBookableCampsites } from './tracking';
import useHasActiveFilters from './useHasActiveFilters';
import {
  getClickoutTrackData,
  getPaginatedIndex,
  getSummaryTitle,
  hasNoResults,
  PREFIX,
  prepareTiles,
} from './utils';

const Search = props => {
  const {
    title,
    prn,
    pageContext,
    interlinking = {},
    intro,
    content,
    pageSize,
  } = props;

  const { lastClickedPRN } = useContext(LastClickedContext);
  const { showMessage, infoMessage } = useContext(InfoMessageContext);
  const { width } = useDimensions();
  const [prosciuttoState, dispatchProsciutto] = useProsciutto();

  const [bookingParams, setBookingParams] = useState('');
  const [mobileMode, setMobileMode] = useState(true);
  const [modalMapOpen, setModalMapOpen] = useState(false);
  const [lists, setLists] = useState(prosciuttoState?.lists ?? []);

  const { t } = useTranslation(PREFIX);

  const hasActiveFilters = useHasActiveFilters(prosciuttoState);
  const [mainList, fallbackList] = lists;
  const hasFallbackList = !isEmptyOrNil(fallbackList?.tiles);
  const { isLoading, params = {} } = prosciuttoState;
  const breadcrumbs = pageContext?.breadcrumbs ?? false;
  const count = prosciuttoState?.count ?? 0;

  const {
    date_from: dateFrom,
    date_to: dateTo,
    adults = 2,
    children = '',
    viewport,
  } = params;

  const showNoResults = hasNoResults({ count, params, prn, props, viewport });
  const summaryTitle = getSummaryTitle({ pageContext, params, prn, t });

  const calculatedInterlinking = getInterlinkingData(interlinking);

  const getPaginatedIndexWithSizeAndPage = getPaginatedIndex(
    pageSize,
    prosciuttoState?.params?.page ?? 1
  );

  const getClickoutTrackDataWithContext = getClickoutTrackData(
    pageContext,
    getPaginatedIndexWithSizeAndPage
  );

  const totalGuests = adults + children?.length;

  useEffect(() => {
    setBookingParams(
      dateFrom
        ? objectToQuery({
            date_from: dateFrom,
            date_to: dateTo,
            children,
            adults,
            edited: true, // has to be true, !true === show calendar
          })
        : ''
    );
  }, [dateFrom, dateTo, children, adults]);

  useEffect(() => {
    setMobileMode(width < BREAKPOINTS.lg);
  }, [width]);

  useEffect(() => {
    if (dateFrom && dateTo && !isLoading) {
      const hasMainList = mainList?.count <= MAIN_LIST_MINIMUM;
      const { flexibleDates } = prosciuttoState;
      const hasFlexibleDates = flexibleDates?.length > 0 && hasMainList;
      const tiles = lists?.[0]?.tiles;
      tiles &&
        trackAmountOfBookableCampsites({
          tiles,
          params,
          hasFlexibleDates,
        });
    }
  }, [dateFrom, dateTo, lists]);

  useEffect(() => {
    setLists(
      prepareTiles({
        lastClickedPRN,
        lists: prosciuttoState?.lists,
      })
    );
  }, [lastClickedPRN, prosciuttoState?.lists]);

  const results = showNoResults ? (
    <div css={ß.noResultsContainer}>
      <div css={ß.noResultsWrapper}>
        <NoSearchResults title={summaryTitle} count={count} />
      </div>
    </div>
  ) : (
    <div className="search-wrapper" css={ß.searchWrapper}>
      <article className="content" css={ß.searchContainer}>
        <div css={ß.searchPadding}>
          {breadcrumbs && (
            <div className="breadcrumbs-section">
              <Breadcrumbs crumbs={breadcrumbs} />
            </div>
          )}
          <div css={ß.summary}>
            <ResultSummary
              count={count}
              showOnlyCampsiteCount={hasActiveFilters && !isSearch(prn)}
            />
          </div>
        </div>

        {calculatedInterlinking && (
          <DrilldownList
            name={calculatedInterlinking.name}
            list={calculatedInterlinking.interlinking}
          />
        )}
        <DestinationFilters
          setMapOpen={setModalMapOpen}
          tracking={{
            openMapModal: trackFullscreenOn,
          }}
        />
        <div id="search-inner-wrapper" css={ß.searchPadding}>
          <InfoMessage showMessage={showMessage} {...infoMessage} />
          {isLoading && isSearch(prn) && (
            <div css={ß.saveTheirAss}>
              <Loading />
            </div>
          )}
          {mainList?.tiles?.length > 0 && (
            <div className="tiles" css={ß.tiles}>
              <ListTiles
                showLinkoutCTA={false}
                bookingParams={bookingParams}
                dateFrom={dateFrom}
                dateTo={dateTo}
                getTrackData={getClickoutTrackDataWithContext}
                isLoading={isLoading}
                tiles={mainList.tiles}
                adPosition={0}
              />
            </div>
          )}
          <FlexibleDates />
          {!hasFallbackList && (
            <div css={ß.pagination}>
              <Pagination pageSize={pageSize} />
            </div>
          )}
          <NoResultMessage lists={lists} />
          <CarouselMicrocamping
            dateFrom={dateFrom}
            dateTo={dateTo}
            totalGuests={totalGuests}
          />
          <CarouselSmallAndCharming />
          {hasFallbackList && (
            <div className="tiles" css={[ß.tiles, ß.secondList]}>
              <h2 css={ß.secondListHeader}>{t('not-bookable-yet')}</h2>
              <ListTiles
                bookingParams={bookingParams}
                dateFrom={dateFrom}
                dateTo={dateTo}
                getTrackData={getClickoutTrackDataWithContext}
                isLoading={isLoading}
                tiles={fallbackList.tiles}
                adPosition={1}
              />
            </div>
          )}
          <div css={ß.campsiteOfTheMonthWrapper}>
            <ListpageCOTMCarousel
              cssModTitle={ß.campsiteOfTheMonthTitle}
              tileCount={width > BREAKPOINTS.xxl ? 3 : 2}
              title={`${PREFIX}:csp-of-the-month-title`}
              trackCategory={isSearch(prn) ? 'search list' : 'list'}
            />
          </div>
          <Interlinks interlinking={interlinking} />
          <section css={ß.content}>
            {intro}
            {content}
            <FAQListPage />
          </section>
        </div>
        <MobileMapToggle setModalMapOpen={setModalMapOpen} />
      </article>
      {modalMapOpen ? (
        <div css={ß.modalWrapper}>
          <Modal
            closeModal={setModalMapOpen}
            isModalVisible={modalMapOpen}
            tracking={{
              close: trackFullscreenOff,
            }}
          >
            <ListPageMap
              prn={prn}
              mobileMode={mobileMode}
              dateFrom={dateFrom}
              dateTo={dateTo}
              getTrackData={getClickoutTrackDataWithContext}
            />
          </Modal>
        </div>
      ) : (
        !mobileMode && (
          <Sticky
            mode="top"
            hideOnBoundaryHit={false}
            boundaryElement=".search-wrapper"
            className="sticky-parent"
          >
            <ListPageMap
              prn={prn}
              mobileMode={mobileMode}
              dateFrom={dateFrom}
              dateTo={dateTo}
              getTrackData={getClickoutTrackDataWithContext}
            />
          </Sticky>
        )
      )}
    </div>
  );

  return (
    <>
      <FlexibleDatesProvider
        prosciuttoState={prosciuttoState}
        dispatchProsciutto={dispatchProsciutto}
      >
        {title && (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
        <section className="search-result">{results}</section>
      </FlexibleDatesProvider>
    </>
  );
};

Search.propTypes = {
  children: PropTypes.node,
  content: PropTypes.node,
  count: PropTypes.number,
  filters: PropTypes.object,
  i18n: PropTypes.object,
  interlinking: PropTypes.object,
  intro: PropTypes.node,
  minAdacRating: PropTypes.number,
  minUserRating: PropTypes.number,
  namespace: PropTypes.string,
  page: PropTypes.object,
  pageContext: PageContextType,
  pageSize: PropTypes.number,
  prn: PropTypes.string,
  state: PropTypes.object,
  term: PropTypes.number,
  title: PropTypes.string,
};

Search.defaultProps = {
  filters: {},
  pageSize: 17,
};

const SearchWithProviders = props => (
  <TileHighlightProvider>
    <TileControlAvailabilityModalProvider>
      <InfoMessageProvider>
        <LastClickedProvider>
          <DimensionsProvider>
            <DatePickerProvider>
              <Search {...props} />
            </DatePickerProvider>
          </DimensionsProvider>
        </LastClickedProvider>
      </InfoMessageProvider>
    </TileControlAvailabilityModalProvider>
  </TileHighlightProvider>
);
export default SearchWithProviders;
