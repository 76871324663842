import React, { useContext } from 'react';

import { Link } from '@components/Link';
import MaybeData from '@contexts/MaybeData';
import useTracking from '@hooks/useTracking';
import { isADAC } from '@utils/platform';

import {
  APP_DOWNLOAD_APPSTORE,
  APP_DOWNLOAD_PLAYSTORE,
  APPSTORE_ICON,
  PLAYSTORE_ICON,
} from './const';
import * as ß from './styles';

const AppDownload = () => {
  const {
    prepareToTrackEvent: { clickEvent },
  } = useTracking('footer adac app');
  const pageContext = useContext(MaybeData);
  const hasDownloadApp = isADAC(pageContext);

  return hasDownloadApp ? (
    <div css={ß.appContainer}>
      <h5>ADAC Camping / Stellplatz App</h5>
      <p>Inklusive digitaler ADAC Campcard</p>
      <div css={ß.appIcons}>
        <Link
          to={APP_DOWNLOAD_APPSTORE}
          openInNewWindow
          isExternalUrl
          onClick={clickEvent('iOS')}
        >
          <img alt="App Store" src={APPSTORE_ICON} css={ß.appImageApple} />
        </Link>
        <Link
          to={APP_DOWNLOAD_PLAYSTORE}
          openInNewWindow
          isExternalUrl
          onClick={clickEvent('android')}
        >
          <img
            alt="Google Play Store"
            src={PLAYSTORE_ICON}
            css={ß.appImageGoogle}
          />
        </Link>
      </div>
    </div>
  ) : (
    false
  );
};

export default AppDownload;
