import React from 'react';
import PropTypes from 'prop-types';

import * as ß from './styles';

const RadioButton = ({ value, label, checked, onChange, flexRow, detail }) => (
  <div css={ß.radioButtonBox} onClick={() => onChange(value)}>
    <div css={ß.radioWrapper}>
      <input
        type="radio"
        value={value}
        checked={checked}
        css={ß.radio}
        readOnly
      />
      <div css={ß.customRadio(checked)} />
      <label css={ß.overlayLabel(checked)} />
    </div>
    <div css={ß.labelAndDetails}>
      {label && (
        <label css={flexRow ? [ß.label, ß.flexRow] : ß.label}>{label}</label>
      )}
      {detail && <small css={ß.detail}>{detail}</small>}
    </div>
  </div>
);

RadioButton.propTypes = {
  checked: PropTypes.bool,
  detail: PropTypes.string,
  flexRow: PropTypes.bool,
  label: PropTypes.node,
  onChange: PropTypes.func,
  value: PropTypes.any.isRequired,
};

RadioButton.defaultProps = {
  checked: false,
  onChange: () => {},
  flexRow: false,
};

export default RadioButton;
