import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';

import { useQuery } from '@apollo/client';
import Carousel from '@components/Carousel';
import TileCampsite from '@components/Tile/Campsite';
import { MaybeData } from '@fire/contexts';
import { getLocaleData } from '@fire/utils/platform';

import { MIN_CAMPSITES, PREFIX } from './consts';
import { query } from './query';
import * as ß from './styles';
import { clickOutEvent, trackOnView } from './tracking';
import { parseToTile } from './utils';

const CarouselSmallAndCharming = () => {
  const pageContext = useContext(MaybeData);
  const { t } = useTranslation(PREFIX);
  const { ref, inView } = useInView();

  const { platform = 'de', language = 'de' } = getLocaleData(pageContext);

  const { data, loading, error } = useQuery(query, {
    variables: {
      locale: `${language}_${platform}`,
      where: { field: 'path', value: pageContext?.pagePath },
    },
  });

  const rawCampsites = data?.landingPage?.smallAndCharmingCampsites ?? [];
  const hasCampsites = rawCampsites.length >= MIN_CAMPSITES;
  const campsites = parseToTile(rawCampsites);

  useEffect(() => {
    inView && hasCampsites && trackOnView(campsites);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasCampsites, inView, trackOnView]);

  // hide carousel on error
  if (error) return false;

  return hasCampsites ? (
    <>
      <h2>{t('small-and-charming-campsites')}</h2>
      <div css={ß.centerHack} ref={ref}>
        <Carousel tileCount={campsites?.length || 0}>
          {campsites.map((campsite, index) => (
            <div key={`small-and-charming-${index}`} css={ß.safetyMargins}>
              <TileCampsite
                {...campsite}
                images={campsite?.images}
                isLoading={loading}
                isExternalUrl={false}
                hasUrlPrefix
                isFallbackList
                onClick={clickOutEvent({ ...campsite, index })}
              />
            </div>
          ))}
        </Carousel>
      </div>
    </>
  ) : (
    false
  );
};
export default CarouselSmallAndCharming;
