import { css } from '@emotion/react';
import { blue9, charcoal, gray5 } from '@fire/_color';
import { bold, fontSizeSmall, fontSizeXSmall } from '@fire/_fonts';

export const tileFooterLink = css`
  border-top: 1px solid ${gray5};
  padding-top: 12px;

  a {
    font-weight: ${bold};
    color: ${blue9};
    text-decoration: underline;
  }
`;

export const prices = css`
  border-top: 1px solid ${gray5};
  display: flex;
`;

export const priceRow = (isMapTile = false) => css`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 12px;
  ${isMapTile && `p { font-size: ${fontSizeXSmall};}`}
`;

export const info = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${charcoal};
  font-size: ${fontSizeSmall};

  b {
    font-weight: ${bold};
  }
`;

export const accommodationList = css`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
`;

export const listItem = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
