import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const LastClickedContext = createContext({});

LastClickedContext.displayName = 'LastClickedContext';

export const LastClickedProvider = ({ children }) => {
  const [lastClickedPRN, setLastClickedPRN] = useState('');

  return (
    <LastClickedContext.Provider
      value={{
        setLastClickedPRN,
        lastClickedPRN,
      }}
    >
      {children}
    </LastClickedContext.Provider>
  );
};

LastClickedProvider.propTypes = {
  children: PropTypes.node,
};

export default LastClickedContext;
