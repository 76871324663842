import React, { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { propEq } from 'ramda';

import Checkbox from '@components/Checkbox';
import { useFilters } from '@contexts/Filters';
import MaybeData from '@contexts/MaybeData';
import { satisfiesPlatformRestrictionIfSet } from '@utils/platform';

import * as ß from './styles';

const CheckboxList = ({ list, namespace }) => {
  const pageContext = useContext(MaybeData);
  const [stateContext, dispatchContext] = useFilters();
  const { t } = useTranslation(namespace);

  return (
    <ul>
      {list
        .filter(satisfiesPlatformRestrictionIfSet(pageContext))
        .map(({ id, label }) => {
          const checkboxLabel = t(label);
          const checked = propEq(id, 1, stateContext);
          const handleChange = () =>
            dispatchContext({
              type: 'setFilters',
              payload: { [id]: checked ? 0 : 1 },
            });

          return (
            <li css={ß.checkBoxItem} key={id}>
              <Checkbox
                checked={checked}
                id={id}
                label={checkboxLabel}
                onChange={handleChange}
              />
            </li>
          );
        })}
    </ul>
  );
};

CheckboxList.propTypes = {
  list: PropTypes.array,
  namespace: PropTypes.string,
};

export default memo(CheckboxList);
