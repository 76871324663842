import { useCallback, useContext, useEffect, useState } from 'react';
import queryString from 'query-string';
import { omit } from 'ramda';

import { checkAvailabilities } from '@components/AccommodationList/utils';
import MaybeData from '@contexts/MaybeData';
import { ACCOMMODATION_PARAMS } from '@fire/constants';
import { useLocation } from '@reach/router';
import { getBookingData, getInitialState } from '@utils/availability';
import { isEmptyOrNil } from '@utils/generic';
import { getLocaleData } from '@utils/platform';
import { objectToQuery } from '@utils/url';

import {
  fetchAccommodationList,
  pickRelevantParams,
  transformBookingDataToParams,
} from './utils';

const inferRateBookingModel = rate => ({
  ...rate,
  booking_type: rate?.booking_type ?? 'ibe',
}); // default to ibe for now, possible to remove once it's live from BE

const mapCategoriesWithBookingModel = list => {
  const accommodations = list?.accommodations ?? [];

  return {
    ...list,
    accommodations: accommodations.map(accommodation => {
      const rates = accommodation?.rates ?? [];
      return { ...accommodation, rates: rates.map(inferRateBookingModel) };
    }),
  };
};

const buildResponse = params => {
  const searchParams = {
    ...pickRelevantParams(params.query),
    language: params.language,
    platform: params.platform,
    prns: params.prn,
  };

  const query = objectToQuery(searchParams);
  const kidsAges = !isEmptyOrNil(searchParams?.children)
    ? searchParams.children.toString().split(',').map(Number)
    : [];

  const response = {
    dateFrom: searchParams?.date_from,
    dateTo: searchParams?.date_to,
    kidsAges,
    list: [],
    totalAdults: searchParams?.adults,
    totalGuests: kidsAges.length + searchParams?.adults,
  };

  return { response, query };
};

const _fetch =
  ({ setData, setIsError, setIsLoading, setHasList } = {}) =>
  async params => {
    if (!params?.query || !params?.prn) return;

    const { response, query } = buildResponse(params);

    setIsError(false);
    setIsLoading(true);

    try {
      const request = await fetchAccommodationList(query);
      const categories = request?.data?.results?.[0]?.categories || [];
      response.list = categories.map(mapCategoriesWithBookingModel);
      response.partner = request?.data?.results?.[0]?.partner;
      response.flexibleDates = request?.data?.flexible_dates_results || [];
      if (setHasList) {
        const hasList =
          !checkAvailabilities({ list: response?.list }) &&
          isEmptyOrNil(response?.flexibleDates);

        setHasList?.(hasList);
      } else {
        setData(response);
      }
    } catch {
      setHasList?.(false) || setData(response);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

const useAvailabilitiesApi = (prn = '') => {
  const pageContext = useContext(MaybeData);
  const location = useLocation();
  const [data, setData] = useState();
  const [hasList, setHasList] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const setDataFromContext = useCallback(() => {
    setData({
      list: pageContext?.availabilities?.categories,
      partner: pageContext?.availabilities?.partner,
    });
  }, [pageContext?.availabilities]);

  const fetch = (campsitePrn, query, preload = true) => {
    const bookingData = getBookingData();
    const initialState = getInitialState({ query });
    if (!initialState?.date_from || !initialState?.date_to) {
      // No request without dates.
      setDataFromContext();
      return;
    }

    const params = {
      ...getLocaleData(pageContext),
      prn: campsitePrn,
      query: transformBookingDataToParams({
        ...initialState,
        ...omit(ACCOMMODATION_PARAMS, queryString.parse(query)),
      }),
    };

    if (preload) {
      _fetch({ setData, setIsError, setIsLoading, setHasList })(params);
    } else {
      window?.history?.pushState?.(bookingData, '', params.query);

      _fetch({ setData, setIsError, setIsLoading })(params);
    }
  };

  useEffect(() => {
    const searchParams = location?.search;

    if (prn) fetch(prn, searchParams, false);
  }, [location?.search]);

  return { data, hasList, isLoading, isError, fetch };
};

export default useAvailabilitiesApi;
