import { css } from '@emotion/react';
import {
  bold,
  fontSizeBody,
  fontSizeLarge,
  fontSizeMedium,
} from '@fire/_fonts';
import { media } from '@fire/_mediaQueries';

export const reviews = css`
  label: home-page-reviews;
  ${media.md} {
    margin-top: 50px;
  }
`;

const gap = '30px';

export const wrapperImage = css`
  position: relative;
`;

export const wrapper = css`
  padding: 10px;

  ${media.md} {
    display: grid;
    gap: ${gap};
    grid-template-columns: 30% calc(70% - ${gap});
  }
`;

export const image = css`
  height: 150px;
  width: 100%;
  margin-bottom: 10px;
  object-fit: cover;

  ${media.md} {
    height: 200px;
  }
`;

export const title = css`
  font-weight: ${bold};
  font-size: ${fontSizeBody};
  text-decoration: underline;
`;

export const headline = css`
  font-weight: ${bold};
  font-size: ${fontSizeLarge};
`;

export const content = css`
  font-size: ${fontSizeMedium};
`;

export const stars = css`
  margin: 5px 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    margin-left: 3px;
  }
`;
