import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { HomepageCOTMCarousel } from '@components/CampsiteOfTheMonthCarousel';
import Container from '@components/Container';
import CountriesCarousel from '@components/CountriesCarousel';
import RegionCarousel from '@components/RegionCarousel';
import ResultSummary from '@components/ResultSummary';

import * as ß from './styles';

const NoSearchResults = ({ namespace, count }) => {
  const { t } = useTranslation(namespace);

  return (
    <div>
      <Container fullWidth>
        <div css={ß.noResultText}>
          <ResultSummary count={count} />
          {t(
            'Hoppla! Für deine Suchanfrage konnten wir leider keine Ergebnisse finden. Schau dir unsere Vorschläge an oder starte eine neue Suche mit einem alternativen Suchbegriff.'
          )}
        </div>
      </Container>
      <Container fullWidth hasLargePadding>
        <CountriesCarousel
          title="home-page-module>countries-title"
          description="home-page-module>countries-subtitle"
        />
      </Container>
      <Container fullWidth hasLargePadding>
        <HomepageCOTMCarousel
          title="pt-home-page:csp-of-the-month-title"
          subtitle="pt-home-page:csp-of-the-month-subtitle"
          trackCategory="search list"
        />
      </Container>
      <Container fullWidth hasLargePadding>
        <RegionCarousel />
      </Container>
    </div>
  );
};

NoSearchResults.defaultProps = {
  namespace: 'pt-search-result',
};

NoSearchResults.propTypes = {
  namespace: PropTypes.string,
  title: PropTypes.string,
  count: PropTypes.number,
};

export default NoSearchResults;
