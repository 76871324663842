import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { pathOr } from 'ramda';

import MaybeData from '@contexts/MaybeData';
import { COTM_API, LOCALES } from '@fire/constants';
import { shuffleArray } from '@utils/generic';
import { isSwiss } from '@utils/platform';
import { objectToQuery } from '@utils/url';

const { DE } = LOCALES;

const parseLanguage = str => str.split('-')[0] || DE;

const useCampsitesOfTheMonth = ({ pageType }) => {
  const [campsites, setCampsites] = useState([]);

  const pageContext = useContext(MaybeData);
  const platform = isSwiss(pageContext) ? 'ch' : 'de';
  const language = pathOr(DE, ['language'], pageContext);

  useEffect(() => {
    const getAndSetCampsitesOfTheMonth = async () => {
      const query = objectToQuery({
        language: parseLanguage(language),
        platform,
        page_type: pageType,
      });
      try {
        const response = await axios.get(`${COTM_API}/${query}`);
        const tiles = response?.data?.tiles ?? [];
        setCampsites(shuffleArray(tiles));
      } catch {}
    };
    getAndSetCampsitesOfTheMonth();
  }, [language, platform, pageType]);

  return campsites;
};

useCampsitesOfTheMonth.propTypes = {
  pageType: PropTypes.string.isRequired,
};

export default useCampsitesOfTheMonth;
