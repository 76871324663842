import { trackObject } from '@utils/tracking';

export const trackResetForm = label =>
  trackObject({
    event: 'delete-date',
    action: 'delete date',
    label: `${label}`,
  });

export const trackOpenForm = label =>
  trackObject({
    event: 'click-open-calendar',
    action: 'click open calendar',
    label: `${label}`,
  });
