import React, { memo } from 'react';
import PropTypes from 'prop-types';

const ListPageMap = memo(() => <p>NO MAP</p>);

ListPageMap.displayName = 'ListPageMap';

ListPageMap.propTypes = {
  prn: PropTypes.string,
  mobileMode: PropTypes.bool,
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  getTrackData: PropTypes.func,
};

export default ListPageMap;
