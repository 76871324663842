import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import smoothscroll from 'smoothscroll-polyfill';

import CarouselArrow from '@components/Carousel/CarouselArrow';
import Link from '@components/Link';
import lazy from '@fire/hoc/lazy.js';
import { T } from '@fire/i18n/i18n-constants';

import * as ß from './styles';
import { trackArrowRightClick, trackClick } from './tracking';
import { getImage } from './utils';

const prefix = T?.DestinationPage ?? '';

const DrilldownList = ({ list, name }) => {
  const { t } = useTranslation(prefix);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const [isAtStart, setIsAtStart] = useState(true);
  const listAmount = list?.length;
  const showArrows = listAmount >= 5;
  const scrollerRef = useRef();

  const handleArrowClick = direction => {
    const scrollAmount = 150;
    const element = scrollerRef.current;
    smoothscroll.polyfill();

    const checkIfAtEnd = scrollCurrent =>
      element.clientWidth + scrollAmount > scrollCurrent;

    const toTheRight = direction === 'right';
    const scrollCurrent = toTheRight
      ? element.scrollWidth - element.scrollLeft
      : element.scrollWidth + element.scrollLeft;

    element.scrollBy({
      behavior: 'smooth',
      left: toTheRight ? scrollAmount : -scrollAmount,
    });

    toTheRight && trackArrowRightClick();

    setIsAtEnd(checkIfAtEnd(scrollCurrent));
    setIsAtStart(toTheRight ? false : element.scrollLeft <= scrollAmount);
  };

  return (
    <div css={ß.wrapper}>
      <h2 css={ß.title}>{t(name)}</h2>
      {!isAtEnd && showArrows && (
        <div css={ß.rightArrow(listAmount)}>
          <CarouselArrow
            direction="next"
            position="drilldown"
            onClick={() => handleArrowClick('right')}
          />
        </div>
      )}
      {!isAtStart && showArrows && (
        <div css={ß.leftArrow}>
          <CarouselArrow position="drilldown" onClick={handleArrowClick} />
        </div>
      )}
      <div ref={scrollerRef} css={ß.scroller}>
        {list?.map(destination => (
          <div key={destination.name} css={ß.destination}>
            <Link
              to={destination?.path}
              rel="noopener noreferrer"
              target="_blank"
              title={destination?.name}
              onClick={() => trackClick(destination?.prn)}
            >
              <img css={ß.image} src={getImage(destination)} />

              <p>{destination.name}</p>
              <div css={ß.overlay} />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

DrilldownList.propTypes = {
  name: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.object,
      path: PropTypes.string,
      prn: PropTypes.string,
      name: PropTypes.string,
    })
  ),
};

export default lazy(DrilldownList);
