import { css } from '@emotion/react';
import { blue9, charcoal, gray5, white } from '@fire/_color';
import { media } from '@fire/_mediaQueries';

export const wrapper = css`
  display: none;
  border-bottom: 1px solid ${gray5};
  flex-direction: row;
  align-content: center;
  justify-content: flex-end;
  padding: 3px 24px;
  span {
    font-size: 14px;
    color: ${charcoal};
    margin-right: 4px;
    align-self: center;
  }
  ${media.lg} {
    display: flex;
  }
`;

export const select = css`
  position: relative;
  margin-top: -2px;

  span {
    font-size: 14px;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
    align-self: center;
  }
`;

export const options = css`
  padding: 16px 24px;
  background: ${white};
  position: absolute;
  right: 0%;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.16);
  z-index: 99999; // it has to be above everything else
  &.hidden {
    display: none;
  }
  &.visible {
    display: block;
  }
`;

export const option = css`
  font-size: 16px;
  padding: 8px 0;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: ${blue9};
  }
`;
