import React from 'react';
import PropTypes from 'prop-types';

import * as ß from './styles';
import SuggestionHeadline from './SuggestionHeadline';
import SuggestionItem from './SuggestionItem';

const SuggestionList = ({
  cursor,
  handleSelection,
  setCursor,
  suggestions,
}) => (
  <div css={ß.suggestionList}>
    {suggestions.map(({ isTitle, ...item }) =>
      isTitle ? (
        <SuggestionHeadline key={item.name} type={item.name} />
      ) : (
        <SuggestionItem
          cursor={cursor}
          handleSelection={() => handleSelection(item)}
          highlight={item.highlight}
          id={item.id}
          key={item.slug}
          name={item.name}
          setCursor={setCursor}
          slug={item.slug}
        />
      )
    )}
  </div>
);

SuggestionList.propTypes = {
  cursor: PropTypes.number,
  handleSelection: PropTypes.func,
  setCursor: PropTypes.func,
  suggestions: PropTypes.array,
};

export default SuggestionList;
