import { curry, isNil, pathOr } from 'ramda';

import currencies from './currencySymbols';
import { replaceDot } from './string.js';
import { isTestingWithJest } from './testing';

let exchangeRatesFile = {};

try {
  exchangeRatesFile = isTestingWithJest()
    ? global.exchangeRatesMock
    : require('../../exchange-rates.json');
} catch {
  console.error('No ExchangeRateFile found');
}

export const getCurrency = pathOr('-', ['related', 'adac_prices', 'currency']);

export const getCurrencyCode = x => pathOr(x, ['currencyCodes', x], currencies);

export const roundCurrency = num =>
  (Math.round(num * 10 ** 2) / 10 ** 2).toFixed(2);

export const exchangeCurrencyToCHF = (exchangeRates, price, currency) =>
  isNil(price) || isNil(currency)
    ? `-`
    : roundCurrency(
        Number.parseFloat(price) /
          pathOr(1, ['rates', `${currency}`], exchangeRates)
      );

export const convertToCHFInternal = curry(
  (exchangeRates, swiss, localCurrency, price) =>
    replaceDot(
      swiss
        ? exchangeCurrencyToCHF(
            exchangeRates,
            price,
            getCurrencyCode(localCurrency)
          )
        : price
    )
);

export const convertToCHF = convertToCHFInternal(exchangeRatesFile);

export const formatPrice = (price, currency, opts = {}, locale = 'de-DE') => {
  const formatted = Number(price).toLocaleString(locale, {
    currency,
    style: 'currency',
    ...opts,
  });

  return typeof formatted === 'string' ? formatted : `${price} ${currency}`;
};

export const getLocalCurrency = (currency, isSwitzerland) =>
  isSwitzerland ? 'CHF*' : currency;

export const centsToDecimal = price => {
  const value = Number.parseFloat(price);
  return value ? value / 100 : 0;
};
