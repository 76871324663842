import React from 'react';
import { graphql, useStaticQuery, withPrefix } from 'gatsby';
import { curry, pathOr } from 'ramda';

import { GATSBY_ENTRYPOINT } from '@fire/constants';
import { campsiteImageUrl } from '@utils/image';

import { PageContextType } from '../../types';
import { getCanonicalInfo } from '../seo';
import Facebook from './Facebook';
import Twitter from './Twitter';

const getImageOrPlaceholder = curry((defaultImage, pageContext) => {
  const imageName = pageContext?.images?.[0]?.name;
  const destinationImage = pageContext?.image?.url;

  if (destinationImage) return destinationImage;

  const image = imageName
    ? campsiteImageUrl(pageContext?.entity, imageName)
    : defaultImage;

  return withPrefix(image);
});

export const SocialMedia = ({ pageContext }) => {
  const { href } = getCanonicalInfo(pageContext);
  const { defaultImageFacebook, defaultImageTwitter } = useStaticQuery(graphql`
    query fallbackImages {
      defaultImageFacebook: file(
        relativePath: { eq: "default-image-facebook.jpeg" }
      ) {
        publicURL
      }
      defaultImageTwitter: file(
        relativePath: { eq: "default-image-twitter.jpeg" }
      ) {
        publicURL
      }
    }
  `);

  const title = pathOr('', ['title'], pageContext);
  const url = href || GATSBY_ENTRYPOINT;
  const description = pathOr(
    pathOr('', ['description'], pageContext),
    ['metaDescription'],
    pageContext
  );

  const getImageWithFacebookPlaceholder = getImageOrPlaceholder(
    defaultImageFacebook.publicURL
  );
  const getImageWithTwitterPlaceholder = getImageOrPlaceholder(
    defaultImageTwitter.publicURL
  );
  const facebookImage = getImageWithFacebookPlaceholder(pageContext);
  const twitterImage = getImageWithTwitterPlaceholder(pageContext);

  return (
    <>
      <Facebook
        {...{ title, description, url, image: facebookImage, pageContext }}
      />
      <Twitter {...{ title, description, image: twitterImage, pageContext }} />
    </>
  );
};

SocialMedia.propTypes = {
  pageContext: PageContextType,
};

export default SocialMedia;
