import { css } from '@emotion/react';
import { blue9, blue10 } from '@fire/_color';
import { bold, fontFace } from '@fire/_fonts';
import { media } from '@fire/_mediaQueries';

export const content = css`
  display: flex;
  padding-bottom: 50px;
  ${media.md} {
    padding-bottom: 0;
  }

  > div {
    flex: 1 0 0;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  > div:first-of-type {
    display: none;
    ${media.xl} {
      display: flex;
    }

    img {
      max-width: 100%;
      display: block;
    }
  }

  ul {
    text-align: center;
    margin: 0 15px 40px;
  }

  li + li {
    margin-top: 16px;
  }

  ${media.md} {
    li + li {
      margin-top: 32px;
    }
  }
`;

export const logo = css`
  width: 80px;
  margin: 77px 0 32px;
`;

export const buttonList = css`
  display: flex;
  flex-direction: column;
  ${media.md} {
    flex-direction: row;
  }

  a {
    padding: 10px 32px;
    margin-bottom: 24px;
    text-align: center;

    color: ${blue9};
    ${fontFace};
    font-size: 18px;
    font-weight: ${bold};

    text-decoration: none;

    border-radius: 8px;
    border: 2px solid ${blue9};

    :hover {
      color: ${blue10};
      border-color: ${blue10};
    }

    ${media.md} {
      margin: 0 8px 110px;
    }

    img {
      width: 30px;
      vertical-align: middle;
      margin-right: 16px;
    }
  }
`;
