import React from 'react';
import { useTranslation } from 'react-i18next';

import NewsletterForm from '@components/NewsletterForm';

import * as ß from './styles';
import { PREFIX } from './utils';

const ADACFooterAboutNewsletter = () => {
  const { t } = useTranslation(PREFIX);

  return (
    <div css={ß.newsletter}>
      <div>
        <h5>
          {t(
            'signup-text',
            'Du möchtest keine News verpassen? Melde dich jetzt an:'
          )}
        </h5>
        <NewsletterForm source="footer" />
      </div>
    </div>
  );
};

export default ADACFooterAboutNewsletter;
