import { clone } from 'ramda';

import { isEmptyOrNil } from '@fire/utils/generic';

import { debugTrack } from './debugger';
import { cropArray, submit, trackObject } from './index';

export const processspecialAds = (obj, label) => {
  if (isEmptyOrNil(obj)) {
    return {};
  }
  return {
    id: `${obj?.trackingId}_${label}`,
    name: obj?.trackingId,
  };
};

const prepareToTrackImpression = (arr, label) =>
  cropArray(clone(arr)).map((product, index) => ({
    position: index + 1,
    ...processspecialAds(product, label),
  }));

const impressions =
  (label = '', list = '') =>
  arr =>
  () => {
    const { group, groupEnd } = debugTrack();
    group(`tracking special ads impressions - ${label} - ${list}`);
    const objectsToTrack = prepareToTrackImpression(arr, label);
    objectsToTrack.forEach(obj => {
      trackObject({
        track: 'promotionimpression',
        creative: label,
        ...obj,
      });
      submit();
    });
    groupEnd(`tracking special ads impressions - ${label} - ${list}`);
  };

const click =
  (label = '', list = '') =>
  (obj, index = 0) =>
  () => {
    const { group, groupEnd } = debugTrack();
    group(`tracking special ads click - ${label} - ${list}`);
    trackObject({
      creative: label,
      track: 'promotionclick',
      position: index + 1,
      ...processspecialAds(obj, label),
    });
    submit();
    groupEnd(`tracking special ads click - ${label} - ${list}`);
  };

export const prepareToTrackSpecialAds = (label = '', list = '') => ({
  specialAdsImpressions: impressions(label, list),
  specialAdsClick: click(label, list),
});
