import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Button from '@fire/components/Button';
import { T } from '@fire/i18n/i18n-constants';

import * as ß from '../TileFooter/styles';

const PREFIX = T?.Tile;

const Footer = ({ price, path, onClick }) => {
  const { t } = useTranslation(PREFIX);

  const linkProps = {
    openInNewWindow: true,
    isExternalUrl: true,
    to: path,
    onClick,
  };

  return (
    <div css={ß.prices}>
      <div css={ß.priceRow(false)}>
        <div css={ß.accommodationList}>
          <div css={ß.listItem}>
            <div css={ß.info}>
              <p>
                <b>{t('microcamping-parking-space')}</b>
              </p>
              <p>{t('microcamping-bookable-offer')}</p>
            </div>
            <Button isTwoLines size="small" variant="primary" {...linkProps}>
              <span>
                <b>{t('Ab', { price })}</b>
              </span>
              <span>{t('microcamping-per-night')}</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {
  onClick: PropTypes.func,
  price: PropTypes.string,
  path: PropTypes.string,
};

Footer.defaultProps = {
  path: '',
  onClick: () => {},
};

export default memo(Footer);
