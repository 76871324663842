import { GATSBY_ENTRYPOINT } from '@fire/constants';

const products = {
  de: [
    {
      title: 'ADAC Camping- & Stellplatzführer',
      subtext:
        'Bis zu 300 Einzelinformationen pro Platz ermöglichen einen umfassenden Überblick über deren Ausstattung und Angebot.',
      fluidImage: 'productImage1',
      link: 'Zu den Produkten',
      url: `${GATSBY_ENTRYPOINT}/unternehmen/produkte/adac-camping-und-stellplatzfuehrer/`,
      trackLabel: 'adac-camping-und-stellplatzfuehrer',
    },
    {
      title: 'ADAC Camping-App',
      subtext:
        'Was kann die ADAC Camping- und Stellplatzführer-App 2019 so alles? Alle Vorteile und Funktionen auf einen Blick.',
      fluidImage: 'productImage2',
      link: 'Zu den Produkten',
      url: `${GATSBY_ENTRYPOINT}/unternehmen/produkte/adac-camping-stellplatzfuehrer-app/`,
      trackLabel: 'adac-camping-app',
    },
    {
      title: 'ADAC Campcard',
      subtext:
        'Die Rabattkarte ADAC Campcard bietet zahlreiche Angebote speziell für Camper.',
      fluidImage: 'productImage3',
      link: 'Mehr erfahren',
      url: `${GATSBY_ENTRYPOINT}/unternehmen/produkte/adac-campcard/`,
      trackLabel: 'adac-campcard-rabattkarten',
    },
    {
      title: 'ADAC Wohnmobilvermietung',
      subtext:
        'Mit einem gemieteten Wohnmobil aus der großen Flotte des ADAC steht deinem Campingurlaub nichts mehr im Weg.',
      fluidImage: 'productImage4',
      link: 'Zu den Produkten',
      url: `${GATSBY_ENTRYPOINT}/unternehmen/produkte/adac-wohnmobil-vermietung/`,
      trackLabel: 'adac-wlc',
    },
    {
      title: 'ADAC Wohnwagenvermietung',
      subtext:
        'Wohnwagen beim ADAC mieten: Professioneller Service, riesige Auswahl & über 50 Abholstationen in Deutschland.',
      fluidImage: 'productImage5',
      link: 'Zu den Produkten',
      url: `${GATSBY_ENTRYPOINT}/unternehmen/produkte/adac-wohnwagen-vermietung/`,
      trackLabel: 'adac-wlc',
    },
    {
      title: 'ADAC Autokredit',
      subtext:
        'Wohnmobil oder Wohnwagen mit der Wohnmobil Sonderkondition des ADAC Autokredits zum Top-Zins von 2,79 % finanzieren.',
      fluidImage: 'productImage6',
      link: 'Mehr erfahren',
      url: `${GATSBY_ENTRYPOINT}/unternehmen/produkte/adac-autokredit/`,
      trackLabel: 'adac-autokredit',
    },
    {
      title: 'Yes we Camp!',
      subtext:
        'Mit den Campingbüchern von „Yes we Camp!“ wird der Familienurlaub zum garantierten Erfolg.',
      fluidImage: 'productImage15',
      link: 'Mehr erfahren',
      url: `${GATSBY_ENTRYPOINT}/unternehmen/produkte/yes-we-camp/`,
      trackLabel: 'adac-yes-we-camp',
    },
  ],
  'de-CH': [
    {
      title: 'TCS Mitgliedschaft',
      subtext:
        'Mit der Pannenhilfe Nr. 1 in der Schweiz gelangen Sie immer sicher ans Ziel. Wenn Sie liegen bleiben, sind wir rund um die Uhr da. Ganz gleich, ob Sie zu Fuss, mit dem Velo, dem Auto oder mit ÖV unterwegs sind.',
      fluidImage: 'productImage7',
      link: 'Auf tcs.ch weiterlesen',
      url: 'https://www.tcs.ch/de/produkte/pannenhilfe-reiseschutz/pannenhilfe-unfall/pannenhilfe-autos-motorraeder.php',
      trackLabel: 'tcs-membership',
      isExternalUrl: true,
    },
    {
      title: 'TCS Camping Mitgliedschaft',
      subtext:
        'Profitieren Sie von attraktiven Rabatten. Vergünstigungen auf TCS Campingplätzen sowie ausgewählten Partnerplätzen in Europa. Sorgenfrei fahren mit Motorhomes mit der Pannen- und Unfallhilfe für Motorhomes bis 7.5 t und Wohnanhänger bis 3.5 t.',
      fluidImage: 'productImage8',
      link: 'Auf tcs.ch weiterlesen',
      url: 'https://www.tcs.ch/de/mitgliedschaft-vorteile/mitgliedschaften/camping-mitgliedschaft.php',
      trackLabel: 'tcs-camping-membership',
      isExternalUrl: true,
    },
    {
      title: 'TCS ETI Schutzbrief',
      subtext:
        'Mit dem umfassenden ETI Reiseschutz helfen wir Ihnen schnell und zuverlässig weiter – was auch immer geschieht. Ein ganzes Jahr lang, auf jeder Reise. Und das sogar bei Ferien in der Schweiz.',
      fluidImage: 'productImage9',
      link: 'Auf tcs.ch weiterlesen',
      url: 'https://www.tcs.ch/de/produkte/pannenhilfe-reiseschutz/reiseschutz/',
      trackLabel: 'tcs-eti-protection-letter',
      isExternalUrl: true,
    },
    {
      title: 'Fahrzeug-Versicherung für Camper',
      subtext:
        'Die TCS Wohnmobil-Versicherung für Reisemobile bis 7.5t, Camper sowie Wohnwagen und Wohnanhänger aller Art. TCS Camping-Mitglieder profitieren von einer vergünstigten Prämie.',
      fluidImage: 'productImage10',
      link: 'Auf tcs.ch weiterlesen',
      url: 'https://www.tcs.ch/de/produkte/versicherungen-kreditkarten/fahrzeug-versicherungen/wohnmobil-versicherung-wohnwagen-camper.php',
      trackLabel: 'tcs-vehicle-insurance',
      isExternalUrl: true,
    },
    {
      title: 'TCS Member Mastercard®',
      subtext:
        'Vollwertige und weltweit akzeptierte Gratis-Kreditkarte, die Ihnen attraktive Sparvorteile bietet: Bei BP und Tamoil tanken Sie zum Beispiel immer 4-5 Rappen günstiger pro Liter.',
      fluidImage: 'productImage11',
      link: 'Auf tcs.ch weiterlesen',
      url: 'https://www.tcs.ch/de/produkte/versicherungen-kreditkarten/kreditkarten/member-mastercard.php',
      trackLabel: 'tcs-member-mastercard',
      isExternalUrl: true,
    },
    {
      title: 'Fahrtraining mit Wohnwagen',
      subtext:
        'In diesem Kurs lernen Sie, wie Sie Ihr Anhänger-Gespann sicher und gefahrenlos von A nach B bewegen. Umsichtiges Manövrieren und Parkieren gehört ebenso zu diesem Kurs, wie der Umgang mit Beladung.',
      fluidImage: 'productImage12',
      link: 'Auf tcs.ch weiterlesen',
      url: 'https://www.tcs.ch/de/kurse-fahrzeugchecks/kurse-fahrtrainings/anhaenger-wohnwagen/anhaenger-basis.php',
      trackLabel: 'tcs-driver-training',
      isExternalUrl: true,
    },
    {
      title: 'Mautbox Telepass Europa',
      subtext:
        'Nicht nur in Italien - auch in Frankreich, Spanien oder Portugal garantiert der Telepass eine stressfreie Fahrt.',
      fluidImage: 'productImage13',
      link: 'Auf tcs.ch weiterlesen',
      url: 'https://www.tcs.ch/de/camping-reisen/reiseinformationen/wissenswertes/fahrkosten-gebuehren/telepass.php',
      trackLabel: 'tcs-tollbox-telepass',
      isExternalUrl: true,
    },
    {
      title: 'TCS Verkehrsrechtschutz',
      subtext:
        'Als Verkehrsteilnehmer können Sie schnell in Streitigkeiten verwickelt werden – zum Beispiel bei einem Unfall mit unklarer Schuldfrage, bei einem Konflikt mit Ihrem Garagisten oder beim Autokauf. Mit dem TCS Verkehrsrechtsschutz sind sie auf der sicheren Seite.',
      fluidImage: 'productImage14',
      link: 'Auf tcs.ch weiterlesen',
      url: 'https://www.tcs.ch/de/produkte/versicherungen-kreditkarten/rechtsschutz/verkehrsrechtsschutz/',
      trackLabel: 'tcs-traffic-protection',
      isExternalUrl: true,
    },
  ],
  'fr-CH': [
    {
      title: 'TCS Sociétariat',
      subtext:
        'Avec l’assistance dépannage numéro un en Suisse, vous arrivez toujours à destination. Si vous êtes bloqué, nous vous venons en aide, et ce, 24 heures sur 24, que vous vous déplaciez à pied, à vélo, en voiture ou en transports publics.',
      fluidImage: 'productImage7',
      link: 'En savoir plus sur tcs.ch',
      url: 'https://www.tcs.ch/fr/produits/depannage-protection-voyage/depannage-accident/assistance-depannage-auto-moto.php',
      trackLabel: 'tcs-membership',
      isExternalUrl: true,
    },
    {
      title: 'TCS Sociétariat Camping',
      subtext:
        'Profitez de réductions attrayantes. Réductions sur les campings TCS ainsi que des campings partenaires choisis en Europe. Voyagez sans stress avec l’assistance en cas de panne ou d’accident pour les camping-cars jusqu’à 7,5 t et les caravanes jusqu’à 3,5 t.',
      fluidImage: 'productImage8',
      link: 'En savoir plus sur tcs.ch',
      url: 'https://www.tcs.ch/fr/societariat-avantages/societariats/societariat-camping.php',
      trackLabel: 'tcs-camping-membership',
      isExternalUrl: true,
    },
    {
      title: 'TCS Livret ETI',
      subtext:
        'Avec la protection voyages complète ETI, nous vous venons en aide avec rapidité et efficacité, quoi qu’il puisse se passer. Pendant toute une année, à chaque voyage. Et ce, même lors de vos vacances en Suisse.',
      fluidImage: 'productImage9',
      link: 'En savoir plus sur tcs.ch',
      url: 'https://www.tcs.ch/fr/produits/depannage-protection-voyage/protection-voyage/',
      trackLabel: 'tcs-eti-protection-letter',
      isExternalUrl: true,
    },
    {
      title: 'Assurance véhicules pour camping-car',
      subtext:
        'L’assurance camping-car du TCS pour les fourgons aménagés jusqu’à 7,5 t, pour les camping-cars et pour les caravanes de toutes sortes. Les membres TCS Camping bénéficient d’une réduction sur la prime.',
      fluidImage: 'productImage10',
      link: 'En savoir plus sur tcs.ch',
      url: 'https://www.tcs.ch/fr/produits/assurances-cartes-credit/assurances-vehicules/camping-car-mobile-home-caravane.php',
      trackLabel: 'tcs-vehicle-insurance',
      isExternalUrl: true,
    },
    {
      title: 'TCS Member Mastercard®',
      subtext:
        'Gratuite et acceptée dans le monde entier, cette carte de crédit à part entière vous permet de faire des économies attrayantes: par exemple, chez BP et Tamoil, faire le plein vous coûtera toujours 4 ou 5 centimes de moins par litre.',
      fluidImage: 'productImage11',
      link: 'En savoir plus sur tcs.ch',
      url: 'https://www.tcs.ch/fr/produits/assurances-cartes-credit/cartes-de-credit/member-mastercard.php',
      trackLabel: 'tcs-member-mastercard',
      isExternalUrl: true,
    },
    {
      title: 'Cours de conduite avec une caravane',
      subtext:
        'Durant ce cours, vous apprenez comment déplacer votre attelage mobile en toute sécurité et sans danger du point A au point B. Manœuvrer et se parquer sont au programme, tout comme le maniement de la charge.',
      fluidImage: 'productImage12',
      link: 'En savoir plus sur tcs.ch',
      url: 'https://www.tcs.ch/fr/cours-controles-techniques/cours-et-stages-de-conduite/caravane-remorque/remorque-basic.php',
      trackLabel: 'tcs-driver-training',
      isExternalUrl: true,
    },
    {
      title: 'Système Telepass Europe',
      subtext:
        'Roulez sans stress grâce au Telepass, utilisable non seulement en Italie, mais aussi en France, en Espagne ou au Portugal.',
      fluidImage: 'productImage13',
      link: 'En savoir plus sur tcs.ch',
      url: 'https://www.tcs.ch/fr/camping-voyages/informations-touristiques/bon-a-savoir/peages-frais/telepass.php',
      trackLabel: 'tcs-tollbox-telepass',
      isExternalUrl: true,
    },
    {
      title: 'TCS Protection juridique circulation',
      subtext:
        'En tant qu’usager de la route, vous pouvez vite être impliqué dans un litige, par exemple en cas d’accident pour lequel la question de la responsabilité n’est pas claire, de conflit avec votre garagiste ou d’achat d’une voiture. En optant pour la TCS Protection juridique circulation, vous faites le choix de la sécurité.',
      fluidImage: 'productImage14',
      link: 'En savoir plus sur tcs.ch',
      url: 'https://www.tcs.ch/fr/produits/assurances-cartes-credit/protection-juridique/circulation/',
      trackLabel: 'tcs-traffic-protection',
      isExternalUrl: true,
    },
  ],
  'it-CH': [
    {
      title: 'TCS Societariato',
      subtext:
        'Con il Soccorso stradale nr. 1 in Svizzera raggiungete la destinazione sempre in sicurezza. In caso di problemi in viaggio, siamo a vostra disposizione 24 ore su 24 per aiutarvi. Che siate a piedi, in bici, in auto o con i mezzi pubblici.',
      fluidImage: 'productImage7',
      link: 'Continuare a leggere su tcs.ch',
      url: 'https://www.tcs.ch/it/prodotti/soccorso-stradale-protezione-viaggi/soccorso-stradale-incidente/soccorso-stradale-auto-moto.php',
      trackLabel: 'tcs-membership',
      isExternalUrl: true,
    },
    {
      title: 'TCS Societariato Camping',
      subtext:
        'Beneficiate di interessanti sconti. Agevolazioni nei campeggi TCS e in campeggi selezionati dei partner in Europa. Viaggiare senza preoccupazioni con il soccorso stradale in caso di panne o incidenti per camper fino a 7,5 t e rimorchi abitativi fino a 3,5 t.',
      fluidImage: 'productImage8',
      link: 'Continuare a leggere su tcs.ch',
      url: 'https://www.tcs.ch/it/societariato-vantaggi/societariati/societariato-camping.php',
      trackLabel: 'tcs-camping-membership',
      isExternalUrl: true,
    },
    {
      title: 'TCS Libretto ETI',
      subtext:
        'Con la protezione viaggi completa vi assistiamo rapidamente e in modo affidabile, qualunque cosa accada. Per un anno intero, in ogni viaggio. E persino durante le vacanze in Svizzera.',
      fluidImage: 'productImage9',
      link: 'Continuare a leggere su tcs.ch',
      url: 'https://www.tcs.ch/it/prodotti/soccorso-stradale-protezione-viaggi/protezione-viaggi/',
      trackLabel: 'tcs-eti-protection-letter',
      isExternalUrl: true,
    },
    {
      title: 'Assicurazione veicoli per camper',
      subtext:
        'L’assicurazione TCS per autocaravan fino a 7,5 t, camper, roulotte e veicoli abitabili di qualsiasi tipo. I soci TCS Camping beneficiano di un premio ridotto.',
      fluidImage: 'productImage10',
      link: 'Continuare a leggere su tcs.ch',
      url: 'https://www.tcs.ch/it/prodotti/assicurazioni-carte-credito/assicurazioni-veicoli/roulotte-camper.php',
      trackLabel: 'tcs-vehicle-insurance',
      isExternalUrl: true,
    },
    {
      title: 'TCS Member Mastercard®',
      subtext:
        'Una vera e propria carta di credito gratuita accettata in tutto il mondo e con allettanti vantaggi in termini di risparmio. Ad esempio, da BP e Tamoil risparmiate sempre 4-5 centesimi al litro.',
      fluidImage: 'productImage11',
      link: 'Continuare a leggere su tcs.ch',
      url: 'https://www.tcs.ch/it/prodotti/assicurazioni-carte-credito/carte-credito/',
      trackLabel: 'tcs-member-mastercard',
      isExternalUrl: true,
    },
    {
      title: 'Corso di guida con la roulotte',
      subtext:
        'Questo corso permette di imparare a guidare con un rimorchio in modo sicuro e senza pericoli lungo tutto il percorso. Non solo il corso insegna a manovrare in modo prudente e posteggiare in sicurezza, ma anche a gestire il carico e lo scarico.',
      fluidImage: 'productImage12',
      link: 'Continuare a leggere su tcs.ch',
      url: 'https://www.tcs.ch/it/corsi-controlli-tecnici/corsi/roulotte-e-rimorchi/rimorchio-corso-base.php',
      trackLabel: 'tcs-driver-training',
      isExternalUrl: true,
    },
    {
      title: 'Dispositivo Telepass Europa',
      subtext:
        'Guidare senza stress grazie al Telepass, non solo in Italia ma anche in Francia, Spagna o Portogallo.',
      fluidImage: 'productImage13',
      link: 'Continuare a leggere su tcs.ch',
      url: 'https://www.tcs.ch/it/camping-viaggi/informazioni-turistiche/utile-da-sapere/costi-di-viaggio-e-pedaggi/telepass.php',
      trackLabel: 'tcs-tollbox-telepass',
      isExternalUrl: true,
    },
    {
      title: 'TCS Protezione giuridica circolazione',
      subtext:
        'Come utente della strada è facile venire coinvolti in una controversia, basti pensare a un incidente in cui non si sa a chi attribuire la colpa, a un contrasto con il garagista o all’acquisto di un’auto. Con la TCS Protezione giuridica circolazione siete tutelati.',
      fluidImage: 'productImage14',
      link: 'Continuare a leggere su tcs.ch',
      url: 'https://www.tcs.ch/it/prodotti/assicurazioni-carte-credito/protezione-giuridica/circolazione/',
      trackLabel: 'tcs-traffic-protection',
      isExternalUrl: true,
    },
  ],
};

export default products;
