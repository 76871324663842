const pillAttributes = adacAttributes => [
  {
    name: 'hasBeach',
    label: 'Strandnähe',
    attribute: adacAttributes?.has_beach,
    position: 1,
  },
  {
    name: 'hasSwimmingPool',
    label: 'Schwimmbad',
    attribute: adacAttributes?.has_swimming_pool,
    position: 2,
  },
  {
    name: 'acceptDogs',
    label: 'Hunde erlaubt',
    attribute: adacAttributes?.accept_dogs,
    position: 3,
  },

  {
    name: 'hasKidsActivities',
    label: 'Kinderfreundlich',
    attribute: adacAttributes?.has_kids_activities,
    position: 4,
  },

  {
    name: 'hasRestaurant',
    label: 'Restaurant',
    attribute: adacAttributes?.has_restaurant,
    position: 5,
  },

  {
    name: 'hasBreadRollOpportunity',
    label: 'Brötchenservice',
    attribute: adacAttributes?.has_bread_roll_opportunity,
    position: 6,
  },

  {
    name: 'hasShops',
    label: 'Shopping',
    attribute: adacAttributes?.has_shops,
    position: 7,
  },

  {
    name: 'hasWifi',
    label: 'Internet',
    attribute: adacAttributes?.has_wifi,
    position: 8,
  },
];

const filterAttributes = attr => attr.filter(item => item.attribute);
export const getPillAttributes = adacAttributes => {
  const attributes = pillAttributes(adacAttributes);
  const filtered = filterAttributes(attributes);
  return filtered.length > 3 ? filtered.slice(0, 3) : filtered;
};
