import { css } from '@emotion/react';
import { darkOverlay, white } from '@fire/_color';
import { hoverImage } from '@fire/_const';
import { bold } from '@fire/_fonts';
import { mediaMax } from '@fire/_mediaQueries';
import { IMGIX_URL } from '@fire/constants';

export const smallImage = (image, fixed) => css`
  background-image: url('${IMGIX_URL}/${image.name}.jpg?handle=${image.handle}&min-w=350&min-h=350&max-w=500&max-h=500&auto=compress,format&fp-x=0.74&fp-y=0.5&fit=crop');
  font-size: 20px;
  font-weight: ${bold};
  margin: 0 20px 12px 0;
  height: ${fixed ? '165px' : '100%'};
  width: ${fixed ? '165px' : '100%'};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${darkOverlay};
  background-blend-mode: overlay;
  color: ${white};
  word-break: break-word;
  padding: 2px;
  ${hoverImage}
  text-decoration: none;
  order: 1;

  &:hover {
    color: white;
  }
  ${mediaMax.xl} {
    :after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
  }
`;
