/* eslint-disable test-selectors/button */
import React from 'react';
import PropTypes from 'prop-types';

import * as ß from './styles';

export const NumberInput = ({ value, setValue, minValue, maxValue }) => {
  const updateValue = type =>
    type === 'add' ? setValue(value + 1) : setValue(value - 1);

  return (
    <div css={ß.numberPicker}>
      <button type="button" disabled={value === minValue} onClick={updateValue}>
        <div css={ß.minus}>
          <div></div>
        </div>
      </button>
      <p css={ß.value}>{value}</p>
      <button
        disabled={value === maxValue}
        type="button"
        onClick={() => updateValue('add')}
      >
        <div css={ß.plus}>
          <div />
          <div />
        </div>
      </button>
    </div>
  );
};

NumberInput.propTypes = {
  value: PropTypes.number,
  setValue: PropTypes.func,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
};

NumberInput.defaultProps = {
  minValue: 1,
};

export default NumberInput;
