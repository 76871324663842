import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import MaybeData from '../MaybeData';
import { getSearchFallbackPath } from './utils';

export const CampsitePageContext = createContext({});

export const CampsitePageProvider = ({ children, availabilities }) => {
  const { related: { latitude, longitude } = {} } = useContext(MaybeData);

  const fallbackSearchPath = getSearchFallbackPath(
    latitude,
    longitude,
    availabilities
  );

  return (
    <CampsitePageContext.Provider
      value={{ availabilities, fallbackSearchPath, getSearchFallbackPath }}
    >
      {children}
    </CampsitePageContext.Provider>
  );
};

CampsitePageProvider.propTypes = {
  availabilities: PropTypes.shape({
    dateFrom: PropTypes.string,
    dateTo: PropTypes.string,
    kidsAges: PropTypes.arrayOf(PropTypes.number),
    list: PropTypes.array,
    totalAdults: PropTypes.number,
    totalGuests: PropTypes.number,
  }),
  children: PropTypes.node,
};

export default CampsitePageContext;
