// WARNING: ONLY USE FOR TESTING (BIG IMPORTS AHEAD)
import chance from 'chance';
import countries from 'countries-list';
import { pipe } from 'ramda';

import pkg from '@root/package.json';

import ipsum from './ipsum.fixture';

export const C = chance(pkg.name, pkg.version);
const ipsumList = ipsum.split('. ');

const CR = chance(0, 1);
const toInt = x => Number.parseInt(x, 10);

export const round = z => Math.round(CR.random() * z);

export const line = () => {
  let l = ipsumList[Math.round(C.random() * ipsumList.length)];
  while (!l) l = ipsumList[Math.round(C.random() * ipsumList.length)];
  return l;
};

export const country = () => {
  const xx = Object.values(countries.countries);
  let x = xx[Math.round(C.random() * xx.length)];
  while (!x) x = xx[Math.round(C.random() * xx.length)];
  return x;
};

export const cash = pipe(round, toInt);

export const cashWithEuro = pipe(cash, z => z + ' EUR');

// https://stackoverflow.com/a/52231746/3714556
export const isTestingWithJest = () => process.env.JEST_WORKER_ID !== undefined;
