import {
  array,
  bool,
  node,
  number,
  oneOfType,
  shape,
  string,
} from 'prop-types';

export const PageContextType = shape({
  average_user_rating: oneOfType([string, number]),
  related: shape({
    marketing_data: shape({
      youtube: string,
    }),
    plan: string,
    country_code: string,
    latitude: number,
    longitude: number,
    accept_dogs: bool,
    has_beach: bool,
    has_bread_roll_service: bool,
    has_kids_activities: bool,
    has_restaurant: bool,
    has_shops: bool,
    has_swimming_pool: bool,
    has_wifi: bool,
    adac_prices: shape({
      comparison_price: oneOfType([string, number]),
      currency: string,
    }),
    adac_attributes: shape({
      adac_rating: oneOfType([string, number]),
      amount_cee_connections: number,
      amount_fresh_and_waste_water_connections: number,
      amount_gas_connections: number,
      amount_other_rentals: oneOfType([string, number]),
      amount_pitches_lotted: number,
      amount_pitches_permanent: number,
      amount_pitches_total: number,
      amount_rental_bungalows: number,
      amount_rental_caravans: number,
      amount_rental_huts: number,
      amount_rental_mobile_homes: number,
      amount_rental_tents: number,
      amount_schuko_connections: oneOfType([string, number]),
      amount_tv_connections: number,
      amount_wired_internet_connections: number,
      distance_camping_shop_nearby: node,
      distance_health_treatments_nearby: node,
      distance_indoor_pool_nearby: node,
      distance_outdoor_pool_nearby: node, // #714
      distance_snack_bar_nearby: node,
      distance_supermarket_nearby: node,
      for_boating: bool,
      for_canoeing: bool,
      for_fishing: bool,
      for_health_treatments: bool,
      for_horse_riding: bool,
      for_nudism: bool,
      for_spa_treatments: bool,
      for_winter_escape: bool,
      for_winter_sports: bool,
      has_adventure_playground: bool,
      has_animation: bool,
      has_animation_for_kids: bool,
      has_beach_volleyball: bool,
      has_beauty_treatments: bool,
      has_bike_rental: bool,
      has_boat_crane: bool,
      has_boat_rental: bool,
      has_boat_slipway: bool,
      has_camping_shop: bool,
      has_cooking_facilities_under_roof: bool,
      has_cross_country_skiing_trail: bool,
      has_disco: bool,
      has_diving_courses: bool,
      has_ebike_rental: bool,
      has_exercise_room: bool,
      has_gas_canister_supply: bool,
      has_golf: bool,
      has_guided_horse_riding: bool,
      has_heated_outdoor_pool: bool,
      has_horse_riding_courses: bool,
      has_hot_water_dishes_sink: bool,
      has_indoor_playground: bool,
      has_indoor_tennis: bool,
      has_internet_corner: bool,
      has_minigolf: bool,
      has_outdoor_playground: bool,
      has_outdoor_pool: bool,
      has_pitches_outside: bool,
      has_pony_riding: bool,
      has_recreation_room: bool,
      has_sailing_courses: bool,
      has_ski_drying_room: bool,
      has_sport_animation: bool,
      has_sport_field: bool,
      has_steam_room: bool,
      has_surfing_courses: bool,
      has_water_playground: bool,
      has_water_slide: bool,
      has_whirlpool: bool,
      has_wifi_reception_restaurant: bool,
      nearest_public_transport: oneOfType([string, number]),
      on_wheels: bool,
      short_code: string,
      size_campsite: number,
      with_dogs: bool,
      with_kids_under_six: bool,
      with_kids_under_twelve: bool,
      with_tent: bool,
    }),
  }),
  content: oneOfType([
    array,
    shape({
      h1: string,
      campsiteCharacter: string,
      inspectorTip: string,
    }),
  ]),
});

export default PageContextType;
