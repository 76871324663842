import { css } from '@emotion/react';
import { blue5, blue9 } from '@fire/_color';
import { bold } from '@fire/_fonts';
import { media, mediaMax } from '@fire/_mediaQueries';

export const container = css`
  background: ${blue5};
  padding: 21px;
  display: flex;
  align-items: center;

  ${mediaMax.xs} {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }

  svg {
    min-width: 30px;
    min-height: 30px;
    fill: ${blue9};
    margin-right: 21px;
    ${mediaMax.xs} {
      margin-right: 12px;
    }
  }
`;

export const homepage = css`
  font-weight: ${bold};
  white-space: nowrap;
`;

export const messageContainer = css`
  margin-left: 6px;
  margin-right: 12px;
  display: flex;
  flex-direction: row;
  ${mediaMax.xs} {
    flex-direction: column;
  }
`;

export const message = css`
  ${media.xs} {
    margin-right: 8px;
  }
`;

export const wrapper = css`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${mediaMax.xs} {
    align-items: flex-start;
  }
`;
