import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { curry, isNil, propEq, propOr } from 'ramda';

import RadioButton from '@components/RadioButton';
import Rating, { COLOR_TABLE } from '@components/StarRating';
import { useFilters } from '@contexts/Filters';
import { T } from '@fire/i18n/i18n-constants';

import * as ß from './styles';

const namespace = T?.SearchResultFilter ?? '';

const getLabelRender = curry((starColor, extraLabels, t, value) =>
  value === 0 ? (
    <span>{t('Alle anzeigen')}</span>
  ) : (
    <>
      <span css={ß.ratingLabelPrefix}>{t(value === 5 ? 'Nur' : 'Ab')}</span>
      <Rating rating={value} color={starColor} />
      {extraLabels
        .filter(({ stars }) => stars === value)
        .map((item, key) => (
          <span css={ß.ratingExtraLabel} key={key}>
            {item.picture && <img src={item.picture} />}
            {item.label}
          </span>
        ))}
    </>
  )
);

const RatingRadioGroup = ({ filter, starColor, extraLabels }) => {
  const { t } = useTranslation(namespace);
  const [stateContext, dispatchContext] = useFilters();

  const label = propOr('', 'label', filter);

  const handleChange = value => () => {
    dispatchContext({ type: 'setFilters', payload: { [filter.id]: value } });
  };

  const hasCheckedFilter = !isNil(stateContext[filter.id]);

  const renderLabel = getLabelRender(starColor, extraLabels, t);

  return (
    <div css={ß.filterCategory}>
      <h3 css={ß.categoryTitle}>{t(label)}</h3>
      {filter.items.map(rating => {
        const ratingId = propOr('', 'id', rating);
        const value = propOr('', 'value', rating);
        const checked =
          propEq(filter.id, value, stateContext) ||
          (!hasCheckedFilter && ratingId.endsWith('-gteq-0'));
        return (
          <RadioButton
            checked={checked}
            flexRow
            id={ratingId}
            key={ratingId}
            label={renderLabel(value)}
            onChange={handleChange(value)}
            value={value}
          />
        );
      })}
    </div>
  );
};

RatingRadioGroup.propTypes = {
  activeValue: PropTypes.number,
  extraLabels: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      picture: PropTypes.string,
      stars: PropTypes.number,
    })
  ),
  filter: PropTypes.shape({
    id: PropTypes.string,
    items: PropTypes.array,
    label: PropTypes.string,
  }).isRequired,
  starColor: PropTypes.oneOf(Object.keys(COLOR_TABLE)),
};

RatingRadioGroup.defaultProps = {
  activeValue: 0,
  extraLabels: [],
  filter: {},
  starColor: 'RED',
};

export default memo(RatingRadioGroup);
