import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { curry, range } from 'ramda';

import NumberInput from '@components/NumberInput';
import {
  DEFAULT_KID_AGE,
  MAX_GUEST_AMOUNT,
  THE_OLDEST_MINOR,
} from '@fire/constants';
import { T } from '@fire/i18n/i18n-constants';
import { mapIndexed } from '@utils/generic';

import * as ß from './styles';

const NAMESPACE = T?.AvailabilityBox ?? '';

export const generateAgeFields = curry((setAges, ages) =>
  mapIndexed(
    (age, id) => (
      <div key={`generated-select-${id}`} css={ß.input}>
        <select
          css={ß.fakeInput}
          onChange={evt => {
            const copy = [...ages];
            copy[id] = +evt.target.options[evt.target.selectedIndex].value;
            setAges(copy);
          }}
          value={ages[id]}
        >
          {range(0, THE_OLDEST_MINOR + 1).map(index => (
            <option key={`generated-field-option-${id}-${index}`} value={index}>
              {index}
            </option>
          ))}
        </select>
      </div>
    ),
    ages
  )
);

const GuestPicker = ({
  kidsAges,
  setKidsAges,
  setTotalAdults,
  totalAdults,
}) => {
  const { t } = useTranslation(NAMESPACE);
  const containerRef = useRef();

  const handleKidChange = value => {
    const updatedKidsAge =
      kidsAges.length > value
        ? [...kidsAges.slice(0, -1)]
        : [...kidsAges, DEFAULT_KID_AGE];

    setKidsAges(updatedKidsAge);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleOutsideClick = useCallback(
    event =>
      containerRef.current &&
      !containerRef.current.contains(event.target) && [containerRef]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [handleOutsideClick]);

  return (
    <div css={ß.guestContainer} ref={containerRef}>
      <div css={ß.pickerRow}>
        <p>{t('Erwachsene_plural')}</p>
        <NumberInput
          maxValue={MAX_GUEST_AMOUNT}
          setValue={setTotalAdults}
          value={totalAdults}
        />
      </div>
      <div css={ß.pickerRow}>
        <p>{t('Kinder_plural')}</p>
        <NumberInput
          minValue={DEFAULT_KID_AGE}
          maxValue={MAX_GUEST_AMOUNT}
          setValue={handleKidChange}
          value={kidsAges.length}
        />
      </div>
      {kidsAges.length > 0 && (
        <p css={ß.kidsAgesTitle}>{t('Alter der Kinder bei Abreise')}:</p>
      )}
      <div css={ß.ageFields}>{generateAgeFields(setKidsAges, kidsAges)}</div>
    </div>
  );
};

GuestPicker.propTypes = {
  kidsAges: PropTypes.array,
  resetGuests: PropTypes.func,
  setKidsAges: PropTypes.func,
  setTotalAdults: PropTypes.func,
  totalAdults: PropTypes.number,
};

export default GuestPicker;
