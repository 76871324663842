import {
  always,
  assoc,
  cond,
  either,
  equals,
  find,
  has,
  ifElse,
  includes,
  pipe,
  prop,
  propOr,
  reduce,
  slice,
  T,
  test,
} from 'ramda';

import { LOCALES, PLATFORMS } from '@fire/constants';

const { CH_IT, CH_FR, CH_DE, DE } = LOCALES;

// Prepare platform regex
const platformMatchers = reduce(
  (matchers, platform) =>
    assoc(platform, new RegExp(platform.replace(/\./, '\\.'), 'g'), matchers),
  {},
  Object.values(PLATFORMS)
);

export const getPlatform = either(
  pipe(propOr('', 'siteUrl'), url =>
    find(platform => test(platformMatchers[platform])(url))(
      Object.values(PLATFORMS)
    )
  ),
  always(PLATFORMS.DE)
);

export const getPlatformShort = pipe(
  getPlatform,
  slice(-2, Number.POSITIVE_INFINITY)
);

export const isPlatform = platform => pipe(getPlatform, equals(platform));
export const isADAC = isPlatform(PLATFORMS.DE);
export const isSwiss = isPlatform(PLATFORMS.CH);
export const isSearch = prn => prn === 'prn:page:search';

export const satisfiesPlatformRestrictionIfSet = pageContext =>
  ifElse(
    has('platforms'),
    pipe(prop('platforms'), includes(getPlatform(pageContext))),
    T
  );

/**
 * Convert language as it appears in pageContext into
2 character string language identifier
 *
 * shortenLanguageName('it-CH')
 *
 * @example
 * // returns 'it'
 * @param {string} language - from pageContext
 * @returns {string} short version
 */
export const shortenLanguageName = language =>
  propOr('de', language, {
    'it-CH': 'it',
    'fr-CH': 'fr',
  });

export const reviewPrefixLocized = pipe(
  propOr('de', 'language'),
  cond([
    [equals(CH_FR), always('/donner-un-avis')],
    [equals(CH_IT), always('/valuta-campeggio')],
    [T, always('/campingplatz-bewerten')],
  ])
);

export const getDataProtectionUrl = pipe(
  propOr('de', 'language'),
  cond([
    [equals(CH_FR), always('/politique-confidentialite')],
    [equals(CH_IT), always('/protezione-dati')],
    [equals(CH_DE), always('/datenschutz')],
    [T, always('/datenschutz')],
  ])
);

export const getTermOfUseUrl = pipe(
  propOr('de', 'language'),
  cond([
    [equals(CH_FR), always('/conditions-utilisations')],
    [equals(CH_IT), always('/condizioni-uso')],
    [equals(CH_DE), always('/nutzungsbedingungen')],
    [T, always('/nutzungsbedingungen')],
  ])
);

export const getTCSNewletterSignupUrl = pipe(
  propOr('de', 'language'),
  lang =>
    ({
      [CH_FR]:
        'https://www.tcs.ch/fr/camping-voyages/camping/offres-actuelles/camping-insider.php',
      [CH_IT]:
        'https://www.tcs.ch/it/camping-viaggi/camping/proposte-attuali/camping-insider.php',
      [CH_DE]:
        'https://www.tcs.ch/de/camping-reisen/camping/aktuelle-angebote/camping-insider.php',
    }[lang])
);

export const getReviewLandingPageUrl = pipe(
  propOr('de', 'language'),
  cond([
    [equals(CH_FR), always('/evaluer-campings')],
    [equals(CH_IT), always('/valuta-camping')],
    [equals(CH_DE), always('/campingplaetze-bewerten')],
    [T, always('/campingplaetze-bewerten')],
  ])
);

export const getMobileHomesUrl = pipe(
  propOr('de', 'language'),
  cond([
    [equals(CH_FR), always('/mobil-homes')],
    [equals(CH_IT), always('/case-mobili')],
    [equals(CH_DE), always('/mobilheime')],
    [T, always('/mobilheime')],
  ])
);

export const getDataProtectionEmail = pipe(
  propOr('de', 'language'),
  ifElse(
    equals(DE),
    always('datenschutz@pincamp.de'),
    always('privacy@pincamp.ch')
  )
);

export const getLocalStorageDisclaimerUrl = pipe(
  propOr('de', 'language'),
  cond([
    [
      equals(CH_FR),
      always('/politique-confidentialite/cookies-stockage-local'),
    ],
    [equals(CH_IT), always('/protezione-dati/cookie-archiviazione-locale')],
    [T, always('/datenschutz/cookiesundlocalstorage')],
  ])
);

export const getLocaleData = pageContext =>
  ({
    DE: { platform: 'de', language: 'de' },
    DE_CH: { platform: 'ch', language: 'de' },
    IT_CH: { platform: 'ch', language: 'it' },
    FR_CH: { platform: 'ch', language: 'fr' },
  }[pageContext?.graphCMSLocale]);

export const getPlatformAndLanguage = pageContext => {
  const platform = getPlatformShort(pageContext);
  const language = isSwiss(pageContext)
    ? shortenLanguageName(propOr('de', 'language', pageContext))
    : 'de';
  return `platform=${platform}&language=${language}`;
};

export const getPlatformAndOptionalLanguage = pageContext => {
  const platform = getPlatformShort(pageContext);
  const languageParam = isSwiss(pageContext)
    ? `&language=${shortenLanguageName(propOr('de', 'language', pageContext))}`
    : '';
  return `platform=${platform}${languageParam}`;
};

export const locizedSearchPathPrefix = pageContext =>
  cond([
    [equals('fr-CH'), always('/search')],
    [equals('it-CH'), always('/search')],
    [T, always('/search')],
  ])(propOr('de', 'language', pageContext));

export const getLanguageFromTarget = target =>
  ({
    'de/de': 'de',
    'ch/dech': 'de-CH',
    'ch/frch': 'fr-CH',
    'ch/itch': 'it-CH',
  }[target] || 'de');

export const getPlatformByTarget = target => {
  const safeTarget =
    target && typeof target === 'string' ? target : process.env.TARGET;
  const [country] = safeTarget.split('/');

  return PLATFORMS[country.toUpperCase()] ?? PLATFORMS.DE;
};

export const isADACByTarget = target =>
  getPlatformByTarget(target) === PLATFORMS.DE;

export const isTCSByTarget = target =>
  getPlatformByTarget(target) === PLATFORMS.CH;
