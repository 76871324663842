import { isDebug } from '../consts';

export const debugTrack = () => {
  if (isDebug) {
    const { group, groupEnd } = console;
    return {
      group,
      groupEnd,
    };
  }

  return {
    group: () => {},
    groupEnd: () => {},
  };
};
