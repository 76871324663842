import { css } from '@emotion/react';
import { gray10, red8 } from '@fire/_color';
import { bold } from '@fire/_fonts';
import { media } from '@fire/_mediaQueries';

export const header = css`
  margin: 24px 0;
`;

export const html = css`
  margin-top: 15px;
  margin-bottom: 24px;
  font-size: 18px;
  iframe,
  img {
    max-width: 100%;
  }

  p {
    margin-bottom: 24px;
  }

  strong {
    font-weight: ${bold};
  }

  h1 {
    margin-top: 32px;
    margin-bottom: 24px;
  }

  h2 {
    margin-top: 32px;
    margin-bottom: 16px;
  }

  h3 {
    margin-top: 24px;
    margin-bottom: 12px;
  }

  h4 {
    margin-top: 16px;
    margin-bottom: 8px;
  }

  h5 {
    margin-top: 16px;
    margin-bottom: 8px;
  }

  ul {
    display: block;
    list-style-type: disc;
    list-style-position: outside;
    padding-left: 36px;
    li > * {
      display: inline;
    }
  }

  ol {
    list-style: decimal;
    padding-left: 36px;
    li > * {
      display: inline;
    }
  }

  em {
    font-style: italic;
  }

  img {
    margin: 50px 0 20px 0;
    width: auto;
    height: auto;
  }

  img + em {
    color: ${gray10};
    font-size: 16px;

    margin-bottom: 100px;
    display: inline-block;
    font-style: normal;
  }

  blockquote p {
    font-size: 30px;
    font-weight: ${bold};
    color: ${red8};

    text-align: center;
    padding: 0 20px;
  }
  blockquote {
    margin: 80px 0 48px 0;
  }

  blockquote::before {
    content: '“';
    opacity: 0.21;
    font-size: 200px;
    color: ${red8};
    height: 30px;
    position: relative;
    display: inline-block;
  }

  blockquote::after {
    content: '“';
    opacity: 0.21;
    font-size: 200px;
    color: ${red8};
    height: 30px;
    position: relative;
    left: 100%;
    transform: translateX(-100%) scaleX(-1);
    display: inline-block;
  }
`;

export const campsiteOfTheMonthTitle = css`
  margin: 0 0 16px;
  text-align: center;
`;

export const campsiteOfTheMonthSubtitle = css`
  color: ${gray10};
  margin-bottom: 35px;
  text-align: center;
`;

export const partnerLogoWrapper = css`
  div h2 {
    font-size: 26px;

    ${media.md} {
      font-size: 34px;
    }
`;
