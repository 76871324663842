import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { oneLine, stripIndents } from 'common-tags';
import { graphql, useStaticQuery } from 'gatsby';
import {
  __,
  curry,
  equals,
  isEmpty,
  map,
  nth,
  once,
  pathOr,
  pipe,
  prop,
  split,
  toLower,
} from 'ramda';

import MaybeData from '@contexts/MaybeData';
import {
  getJentisConfig,
  getJentisScriptName,
  JENTIS_PRELOAD,
} from '@fire/components/seo/constants';

const stringOr = curry((fallback, str) =>
  typeof str === 'string' ? str : fallback
);

const safelyGetPartSeparatedBy = curry((fallback, position, separator) =>
  pipe(stringOr(fallback), toLower, split(separator), nth(position))
);

export const getLanguageForGoogle = locale =>
  locale === 'DE' ? 'de' : safelyGetPartSeparatedBy('', 0, '_')(locale);

export const getCountryForGoogle = locale =>
  locale === 'DE' ? 'de' : safelyGetPartSeparatedBy('', 1, '_')(locale);

export const PAGE_CONTEXT_LENSES = Object.freeze({
  shortcode: ['related', 'adac_attributes', 'short_code'],
  prn: ['entity'],
  pagePrn: ['prn'],
  parentPrn: ['parentPrn'],
  name: ['related', 'name'],
  campsiteCountry: ['related', 'country_code'],
  category: ['related', 'plan'],
  price: ['related', 'adac_prices', 'comparison_price'],
  currency: ['related', 'adac_prices', 'currency'],
  locale: ['graphCMSLocale'],
  reviews: ['allReviews', 'nodes', 0, 'reviews'],
  averageUserReview: ['average_user_rating'],
  adacRating: ['related', 'adac_attributes', 'adac_rating'],
  images: ['images'],
  tilePrice: ['adac_prices', 'comparison_price'],
  tileCurrency: ['adac_prices', 'currency'],
  tileCountryCode: ['country_code'],
  tilePlan: ['plan'],
  tileName: ['name'],
  tilePrn: ['prn'],
  campsiteName: ['campsiteName'],
  pageType: ['pageType'],
  geoType: ['geoType'],
  interlinkingTopRegions: ['interlinkingTopRegions'],
  interlinkingTopTopics: ['interlinkingTopTopics'],
});

export const FORM_DATA_LENSES = Object.freeze({
  from: ['reservationDates', 'from'],
  to: ['reservationDates', 'to'],
  adults: ['familyMembers', 'adults'],
  kids: ['familyMembers', 'kids'],
  dogs: ['familyMembers', 'dogs'],
  reservationOptions: ['reservationOptions'],
});

export const safeParseObjectByPaths = curry((defaultValue, paths, object) =>
  map(pathOr(defaultValue, __, object))(paths)
);

export const parseObjectByPathsWithDefault =
  safeParseObjectByPaths('(not set)');
export const safeDataFromPageContext =
  parseObjectByPathsWithDefault(PAGE_CONTEXT_LENSES);
export const safeDataFromForm = parseObjectByPathsWithDefault(FORM_DATA_LENSES);

export const makeClickoutURL = (prn, platformAndLanguageParam) =>
  prn ? `https://go.pincamp.com/${prn}?${platformAndLanguageParam}` : false;

export const trackObject = object => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(object);
  return 0;
};

export const trackObjectOnce = once(trackObject);

const GTM_IFRAME = stripIndents(
  oneLine
)`<iframe src="https://www.googletagmanager.com/ns.html?id=${process.env.GATSBY_GTM}" type="application/javascript" height="0" width="0" style="display: none; visibility: hidden"></iframe>`;

const GTM_SCRIPT = stripIndents(
  oneLine
)`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl+'';f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer', '${process.env.GATSBY_GTM}');`;

const JENTIS_SCRIPT = branchSlug => stripIndents(
  oneLine
)`(function (sCDN,sCDNProject,sCDNWorkspace,sCDNVers) {
  if(
      window.localStorage !== null &&
      typeof window.localStorage === "object" &&
      typeof window.localStorage.getItem === "function" &&
      window.sessionStorage !== null &&
      typeof window.sessionStorage === "object" &&
      typeof window.sessionStorage.getItem === "function" )
  {
      sCDNVers = window.sessionStorage.getItem('jts_preview_version') || window.localStorage.getItem('jts_preview_version') || sCDNVers;
  }
  window.jentis = window.jentis || {};
  window.jentis.config = window.jentis.config || {};
  window.jentis.config.frontend = window.jentis.config.frontend || {};
  window.jentis.config.frontend.cdnhost = sCDN+"/get/"+sCDNWorkspace+"/web/"+sCDNVers+"/";
  window.jentis.config.frontend.vers = sCDNVers;
  window.jentis.config.frontend.env = sCDNWorkspace;
  window.jentis.config.frontend.project = sCDNProject;
  window._jts = window._jts || [];
  var f   = document.getElementsByTagName("script")[0];
  var j = document.createElement("script");
  j.async = true;
  j.src   = window.jentis.config.frontend.cdnhost+"${getJentisScriptName}";
  f.parentNode.insertBefore(j, f)
})(${getJentisConfig(branchSlug)});`;

const defaultPageViewData = pageContext => {
  const { pagePrn, locale } = safeDataFromPageContext(pageContext);
  return {
    event: 'pageview',
    page: {
      id: pagePrn,
      language: getLanguageForGoogle(locale),
      country: getCountryForGoogle(locale),
    },
  };
};

export const parseDefaultPageViewData = (pageContext, data) => {
  const pageData = defaultPageViewData(pageContext, data);
  return !isEmpty(pageData) && pageData;
};

export const PageView = ({ trackingFunction }) => {
  const pageContext = useContext(MaybeData);
  const currentPage = prop('pagePath', pageContext);
  const [lastPage, setLastPage] = useState();

  const data = useStaticQuery(graphql`
    query TrackingPageView {
      site {
        siteMetadata {
          siteUrl
          branchSlug
        }
      }
    }
  `);

  const branchSlug = data?.site?.siteMetadata?.branchSlug ?? '';

  useEffect(() => {
    if (!equals(lastPage, currentPage)) {
      const parse = trackingFunction || parseDefaultPageViewData;
      const trackingData = parse(pageContext);
      setLastPage(currentPage);
      // Prevent dataLayer persisting caused by GatsbyLink
      const dataLayer =
        window?.google_tag_manager?.[process.env.GATSBY_GTM]?.dataLayer;

      dataLayer && typeof dataLayer.reset === 'function' && dataLayer.reset();
      trackObject(trackingData);
    }
  }, [currentPage, lastPage, pageContext, trackingFunction]);

  return (
    <Helmet>
      <link rel="preload" type="script" href={JENTIS_PRELOAD(branchSlug)} />
      <script type="text/plain" data-usercentrics="Jentis" id="Jentis">
        {JENTIS_SCRIPT(branchSlug)}
      </script>
      <script type="text/plain" data-usercentrics="Google Tag Manager">
        {GTM_SCRIPT}
      </script>
      <noscript>{GTM_IFRAME}</noscript>
    </Helmet>
  );
};

PageView.propTypes = {
  trackingFunction: PropTypes.func,
};
