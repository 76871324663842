import React from 'react';
import PropTypes from 'prop-types';

import HeroMenuContent from './HeroMenuContent';

export const HeroMenu = props =>
  props.activeItem >= 0 && <HeroMenuContent {...props} />;

HeroMenu.propTypes = {
  activeItem: PropTypes.number.isRequired,
};

export default HeroMenu;
