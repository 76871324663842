import { css } from '@emotion/react';

export { loading, subtitle, title } from '../styles';

export const textBlock = css`
  p {
    margin-bottom: 16px;

    &:last-of-type {
      margin-top: 32px;
    }
  }
`;
