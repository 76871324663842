import { css } from '@emotion/react';
import { blue9, charcoal, gray7, gray8 } from '@fire/_color';
import { fontSizeLarge, fontSizeMedium } from '@fire/_fonts';

export const radioButtonBox = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 29px;
  margin-bottom: 3px;
`;

export const radioWrapper = css`
  height: 18px;
  margin-top: 4px;
  position: relative;
  width: 18px;
`;

const radioSize = 24;

export const radio = css`
  visibility: hidden;
  width: ${radioSize}px;
  height: ${radioSize}px;
  margin: 0;
  padding: 0;
`;

export const customRadio = checked => css`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: ${radioSize}px;
  width: ${radioSize}px;
  border: 2px solid ${checked ? blue9 : gray7};
  border-radius: 100%;
`;

export const overlayLabel = checked => css`
  position: absolute;
  cursor: pointer;
  top: 5px;
  left: 5px;
  height: 14px;
  width: 14px;
  background: ${checked ? blue9 : 'transparent'};
  border-radius: 50%;
`;

export const label = css`
  position: relative;
  margin-left: 15px;
  top: 3px;
  font-size: ${fontSizeLarge};

  color: ${charcoal};
`;

export const flexRow = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const labelAndDetails = css`
  display: flex;
  flex-direction: column;
`;

export const detail = css`
  color: ${gray8};
  font-size: ${fontSizeMedium};
  margin-left: 18px;
`;
