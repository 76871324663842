import React from 'react';
import { map } from 'ramda';

import * as ß from './styles';
import TitleWithUrl from './TitleWithUrl';

const renderListItems = ([title, url], id) => (
  <li key={id}>
    <TitleWithUrl title={title} url={url} />
  </li>
);

export const renderCategories = categories =>
  categories.map(({ title, items }, id) => (
    <div css={ß.item} key={id}>
      <strong css={ß.itemTitle}>{title}</strong>
      <ul>{items.map(renderListItems)}</ul>
    </div>
  ));

export const mapItems = map(({ title, links, url }) => ({
  title: <TitleWithUrl title={title} url={url} />,
  url,
  items: links.map(link => [link.title, link.url]),
}));
