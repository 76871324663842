import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';

import * as ß from './styles';

export const GridTile = props => {
  const { children, index, ...tileProps } = props;
  return (
    <div className="grid__tile" css={ß.gridTile} key={index}>
      {cloneElement(children, { ...tileProps })}
    </div>
  );
};

GridTile.propTypes = {
  index: PropTypes.number.isRequired,
  children: PropTypes.node,
};

export default GridTile;
