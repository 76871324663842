import axios from 'axios';
import { navigate } from 'gatsby';

import { getBoundingBox } from '@components/DestinationMap/utils';
import { SUGGESTIONS_API } from '@fire/constants';
import { isEmptyOrNil } from '@utils/generic';

export const goToSearchPageWithCoords =
  ({
    description,
    handleNearbySelection,
    shouldNavigate,
    isSearchPage,
    searchPathPrefix,
    setIsLoading,
  }) =>
  position => {
    const [minLat, minLon, maxLat, maxLon] = getBoundingBox({
      lat: position.coords.latitude,
      lon: position.coords.longitude,
    });
    const locationUrl = `${searchPathPrefix}?viewport=${minLat},${minLon},${maxLat},${maxLon}`;
    const options = isSearchPage ? { state: { needRecentering: true } } : {};

    setIsLoading(false);
    shouldNavigate && navigate(locationUrl, options);

    if (!shouldNavigate) {
      const suggestion = {
        name: description,
        slug: locationUrl,
        type: 'Location',
      };

      handleNearbySelection({ suggestion });
    }
  };

export const getUserLocation = ({
  description,
  handleNearbySelection,
  isSearchPage,
  searchPathPrefix,
  setIsActive,
  setIsLoading,
  shouldNavigate,
}) => {
  setIsLoading(true);
  navigator?.geolocation?.getCurrentPosition(
    goToSearchPageWithCoords({
      description,
      handleNearbySelection,
      shouldNavigate,
      isSearchPage,
      searchPathPrefix,
      setIsLoading,
    }),
    error => {
      console.error(error);
      setIsLoading(false);
      setIsActive(error.code !== 1);
    }
  );
};

const newStructure = ({ hits, type }) => [
  {
    isTitle: true,
    name: type,
  },
  ...hits?.map(suggestion => ({ ...suggestion, type })),
];

export const createIdOnItems = items => {
  let counter = 0;

  if (isEmptyOrNil(items)) {
    return [];
  }

  return items?.flatMap(newStructure).map(item => {
    if (item?.isTitle) {
      return item;
    }

    const newItem = {
      ...item,
      id: counter,
    };

    counter++;

    return newItem;
  });
};

export const fetchSuggestions = async ({ language, platform, term }) => {
  const query = `?language=${language}&platform=${platform}&term=${term}`;
  const url = `${SUGGESTIONS_API}${query}`;
  const result = await axios.get(url);

  return result?.data ?? {};
};

export const getLastItemId = results => results?.[results?.length - 1]?.id;
