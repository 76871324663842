import { constructN, dissoc, invoker, keys, omit, pipe } from 'ramda';

import { GATSBY_ENTRYPOINT } from '@fire/constants';

import { isEmptyOrNil } from './generic';

export const getBaseUrl = (lang, subLang) => {
  const lng = lang.toLowerCase();
  const suffix = lng === 'de' ? '' : `/${subLang.toLowerCase()}`;
  const siteUrl =
    lng !== 'de'
      ? GATSBY_ENTRYPOINT.replace('.de', `.${lng}`)
      : GATSBY_ENTRYPOINT;

  return `${siteUrl}${suffix}`;
};

export const goTo = url => (window.location.href = url);

export const goToNewWindow = url =>
  window.open(url, '_blank', 'noopener,noreferrer');

export const isAbsoluteUrl = url => /^https?:\/\//i.test(url);

export const objectToQuery = params => {
  const query = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (key && value) {
      query.set(key, value);
    }
  });
  return `?${query.toString()}`;
};

const removeQuestionMark = str => str.replace('?', '');

export const noQuestionMarkObjectToQuery = params =>
  removeQuestionMark(objectToQuery(params));

export const queryToObject = query =>
  Object.fromEntries(
    [...new URLSearchParams(query)].map(([key, value]) => {
      try {
        return [key, JSON.parse(value)];
      } catch {
        return [key, value];
      }
    })
  );

export const makeURLSearchParams = prosciuttoState =>
  pipe(
    omit(keys(prosciuttoState.contextParams || {})),
    dissoc('prn'),
    constructN(1, URLSearchParams),
    invoker(0, 'toString')
  )(prosciuttoState.params);

export const parseURLSearchParams = searchParams =>
  [...new URLSearchParams(searchParams).entries()].reduce((acc, param) => {
    const [key] = param;
    let [, value] = param;

    try {
      value = JSON.parse(value);
    } catch {}

    acc[key] = key in acc ? `${acc[key]},${value}` : value;

    return acc;
  }, {});

export const getUrlAndParams = pathname => {
  if (!isEmptyOrNil(pathname)) {
    const [urlPath, urlParams] = pathname?.split('?');
    const paramsFromUrl = queryToObject(urlParams);

    return { urlPath, paramsFromUrl };
  }

  return {};
};
