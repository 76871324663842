import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import { getUrl, imgWithAttrs, poweredBy } from './utils';

export const Facebook = ({ title, description, image, url, pageContext }) => (
  <Helmet>
    <meta property="og:url" content={getUrl(url)} />
    <meta property="og:type" content="website" />
    <meta property="og:title" content={poweredBy(title, pageContext)} />
    <meta property="og:site_name" content={poweredBy('', pageContext)} />
    <meta property="og:description" content={description} />
    <meta property="og:fb:admins" content="180846436061917" />
    <meta
      property="og:image"
      content={imgWithAttrs({ width: 1200, height: 630 }, image)}
    />
  </Helmet>
);

Facebook.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string,
  url: PropTypes.string,
  pageContext: PropTypes.object,
};

Facebook.defaultProps = {
  title: '',
  description: '',
  pageContext: {},
};

export default Facebook;
