import { generatePurchaseId } from '@fire/components/Search/tracking';
import { trackObject } from '@utils/tracking';

export const clickOutEvent =
  ({ id, price, title, index }) =>
  () =>
    trackObject({
      event: 'list_microcamping_click-campsite-website',
      category: 'list_microcamping',
      action: 'click microcamping website',
      label: '(not set)',
      list: 'list_microcamping',
      purchase: {
        id: generatePurchaseId(id),
        affiliation: 'click out',
        revenue: '1',
      },
      products: {
        variant: 'microcamping',
        position: index + 1,
        id,
        price,
        name: title,
      },
    });
