import { safeDataFromPageContext, trackObject } from '@utils/tracking';

export const trackClickUpdateAvailabilityList = pageContext => {
  const { prn, campsiteName, price, currency, country, category } =
    safeDataFromPageContext(pageContext);
  trackObject({
    event: 'availability-list_click-update',
    category: 'availability list',
    action: 'click update',
    label: prn,
    products_detail: [
      {
        name: campsiteName,
        id: prn,
        price,
        currency,
        country,
        category,
        variant: 'campsite',
      },
    ],
  });
};

export const trackDateSearch = ({
  event,
  category,
  dateFrom,
  dateTo,
  totalAdults,
  kidsAges,
}) =>
  trackObject({
    event,
    category,
    action: 'send date search',
    label: '(not set)',
    'travel type': {
      date_from: dateFrom,
      date_to: dateTo,
      travellers: `${totalAdults};${kidsAges.length}`,
      'age of kids': kidsAges.join(';'),
    },
  });
