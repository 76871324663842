export const PageContextDefaultProps = {
  average_user_rating: '',
  related: {
    marketing_data: {
      youtube: '',
    },
    latitude: 0,
    longitude: 0,
    accept_dogs: false,
    has_beach: false,
    has_bread_roll_service: false,
    has_kids_activities: false,
    has_restaurant: false,
    has_shops: false,
    has_swimming_pool: false,
    has_wifi: false,
    plan: '',
    country_code: '',
    adac_prices: {
      comparison_price: 0,
      currency: '',
    },
    /* eslint-disable unicorn/no-null */
    adac_attributes: {
      adac_rating: '',
      amount_cee_connections: null,
      amount_fresh_and_waste_water_connections: null,
      amount_gas_connections: null,
      amount_other_rentals: null,
      amount_pitches_lotted: null,
      amount_pitches_permanent: null,
      amount_pitches_total: null,
      amount_rental_bungalows: null,
      amount_rental_caravans: null,
      amount_rental_huts: null,
      amount_rental_mobile_homes: null,
      amount_rental_tents: null,
      amount_schuko_connections: null,
      amount_tv_connections: null,
      amount_wired_internet_connections: null,
      distance_camping_shop_nearby: null,
      distance_health_treatments_nearby: null,
      distance_indoor_pool_nearby: null,
      distance_outdoor_pool_nearby: null,
      distance_snack_bar_nearby: null,
      distance_supermarket_nearby: null,
      for_boating: false,
      for_canoeing: false,
      for_fishing: false,
      for_health_treatments: false,
      for_horse_riding: false,
      for_nudism: false,
      for_spa_treatments: false,
      for_winter_escape: false,
      for_winter_sports: false,
      has_adventure_playground: false,
      has_animation: false,
      has_animation_for_kids: false,
      has_beach_volleyball: false,
      has_beauty_treatments: false,
      has_bike_rental: false,
      has_boat_crane: false,
      has_boat_rental: false,
      has_boat_slipway: false,
      has_camping_shop: false,
      has_cooking_facilities_under_roof: false,
      has_cross_country_skiing_trail: false,
      has_disco: false,
      has_diving_courses: false,
      has_ebike_rental: false,
      has_exercise_room: false,
      has_gas_canister_supply: false,
      has_golf: false,
      has_guided_horse_riding: false,
      has_heated_outdoor_pool: false,
      has_horse_riding_courses: false,
      has_hot_water_dishes_sink: false,
      has_indoor_playground: false,
      has_indoor_tennis: false,
      has_internet_corner: false,
      has_minigolf: false,
      has_outdoor_playground: false,
      has_outdoor_pool: false,
      has_pitches_outside: false,
      has_pony_riding: false,
      has_recreation_room: false,
      has_sailing_courses: false,
      has_ski_drying_room: false,
      has_sport_animation: false,
      has_sport_field: false,
      has_steam_room: false,
      has_surfing_courses: false,
      has_water_playground: false,
      has_water_slide: false,
      has_whirlpool: false,
      has_wifi_reception_restaurant: false,
      nearest_public_transport: null,
      on_wheels: false,
      short_code: '',
      size_campsite: null,
      with_dogs: false,
      with_kids_under_six: false,
      with_kids_under_twelve: false,
      with_tent: false,
    },
  },
  content: {
    h1: '',
    campsiteCharacter: '',
    inspectorTip: '',
  },
};

export default PageContextDefaultProps;
