import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { T } from '@fire/i18n/i18n-constants';

import CheckboxGroup from './CheckboxGroup';
import { filterGroups } from './filters.json';
import RadioGroup from './RadioGroup';
import * as ß from './styles';

const namespace = T?.SearchResultFilter ?? '';

const GeneralFilters = () => {
  const { t } = useTranslation(namespace);

  return (
    <>
      <h2 css={ß.filterTitle}>{t('Filter')}</h2>
      <div css={ß.gridWrap}>
        {filterGroups.map(group => {
          const { id, items, label, type } = group;

          if (type === 'checkbox-group') {
            return (
              <CheckboxGroup
                label={label ?? ''}
                items={items ?? []}
                key={`filter-checkbox-group-${id}`}
              />
            );
          }

          if (type === 'radio-group') {
            return (
              <RadioGroup
                label={label ?? ''}
                items={items ?? []}
                key={`filter-radio-group-${id}`}
              />
            );
          }
          return <Fragment key={id}></Fragment>;
        })}
      </div>
    </>
  );
};

export default GeneralFilters;
