import { withPrefix } from 'gatsby';

export const LOADING_SPINNER = withPrefix('/icons/camper-loading-white.gif');

export const VARIANTS = [
  'danger',
  'linkout',
  'primary',
  'primaryOutlined',
  'success',
];

export const SIZES = ['small', 'large'];
