import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { curry, pathOr } from 'ramda';

import Link from '@components/Link';
import { T } from '@fire/i18n/i18n-constants';
import { slugify } from '@utils/string';
import { trackObject } from '@utils/tracking';

import { smallImage } from './styles';

export const initOnClick = curry((track, { parentTitle, title }) => () => {
  track({
    event: 'navigation-portal_click-navigation',
    category: 'navigation-portal',
    action: `click navigation: ${title} - as image`,
    label: parentTitle,
  });
});

const onClickable = initOnClick(trackObject);

const SmallTile = ({
  image,
  title,
  url,
  parentTitle,
  fixed,
  isExternalUrl,
  openInNewWindow,
}) => {
  const prefix = pathOr('', ['HeroMenu'], T);
  const { t } = useTranslation();
  return (
    <Link
      openInNewWindow={openInNewWindow}
      isExternalUrl={isExternalUrl}
      to={url}
      css={smallImage(image, fixed)}
      onClick={onClickable({ parentTitle, title })}
    >
      {t(`${prefix}:tile.${slugify(title)}`, title)}
    </Link>
  );
};

SmallTile.propTypes = {
  openInNewWindow: PropTypes.bool,
  isExternalUrl: PropTypes.bool,
  fixed: PropTypes.bool,
  parentTitle: PropTypes.string,
  image: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    handle: PropTypes.string,
  }).isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

SmallTile.defaultProps = {
  openInNewWindow: false,
  isExternalUrl: false,
  fixed: true,
  parentTitle: '',
};

export default SmallTile;
