import { clone } from 'ramda';

import { BREAKPOINTS } from '@fire/_mediaQueries';
import { isEmptyOrNil } from '@fire/utils/generic';

import { isDebug } from '../consts';

export const cleanUpObject = obj => {
  const clonedObj = clone(obj);
  Object.keys(clonedObj).forEach(key => {
    if (clonedObj[key] === undefined) {
      delete clonedObj[key];
    }
  });
  return clonedObj;
};

export const cropArray = data => {
  // crop the data based on screen size
  // send 1 on mobile and 3 on other screens
  const cloneData = clone(data);
  const limit = window.innerWidth >= BREAKPOINTS.md ? 3 : 1;
  return cloneData.slice(0, limit);
};

const tracker = thing => {
  if (isDebug) {
    const { log } = console;
    log(thing);
    return;
  }

  if (typeof window === 'undefined') return;

  window._jts = window?._jts || [];
  return window?._jts?.push(thing);
};

export const submit = () => tracker({ track: 'submit' });

export const trackObject = obj => {
  if (isEmptyOrNil(obj)) return;
  return tracker(obj);
};

export { processProductTile } from './product';
