import React, { useContext } from 'react';
import { omit } from 'ramda';

import MaybeData from '@contexts/MaybeData';
import { higherOrderName } from '@utils/hoc';

export const withData = Component => {
  const BigDataEmbiggenedComponent = props => {
    const pageContext = useContext(MaybeData);
    return <Component {...props} pageContext={pageContext} />;
  };
  return higherOrderName('withData', Component, BigDataEmbiggenedComponent);
};

export const provideData = C =>
  higherOrderName(C, props => (
    <MaybeData.Provider value={props.pageContext}>
      <C {...omit(['pageContext'], props)} />
    </MaybeData.Provider>
  ));

export default withData;
