import React from 'react';
import { withPrefix } from 'gatsby';

const loadingSpinner = withPrefix('/icons/camper-loading.gif');

const Loading = () => (
  <img src={loadingSpinner} width={50} height={50} alt="loading spinner" />
);

export default Loading;
