import React, { useCallback, useEffect, useState } from 'react';

import useEventListener from '@hooks/useEventListener';
import { higherOrderName } from '@utils/hoc';

export const useWindowWidth = () => {
  const [width, setWidth] = useState(0);
  // Event handler utilizing useCallback
  // so that reference never changes.
  const handler = useCallback(() => {
    // Update coordinates
    setWidth(window.innerWidth);
  }, [setWidth]);
  useEventListener('resize', handler);
  // run handler une single time
  useEffect(() => handler(), [handler]);
  return width;
};

export const viewportAware = Component => {
  const X = props => <Component {...props} width={useWindowWidth()} />;
  return higherOrderName('viewportAware', Component, X);
};

export default viewportAware;
