import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Button from '@components/Button';
import DatePicker from '@components/DatePicker';
import SearchBar from '@components/SearchBar';
import { AvailabilityStateContext } from '@contexts/AvailabilityState';
import SearchSuggestionContext from '@contexts/SearchSuggestions';
import { DEFAULT_KIDS_AGES, DEFAULT_TOTAL_ADULTS } from '@fire/constants';
import { MaybeData } from '@fire/contexts';
import { locizedSearchPathPrefix } from '@fire/utils/platform';
import useTracking from '@hooks/useTracking';
import { everyTruthy, isEmptyOrNil } from '@utils/generic';

import { HOMEPAGE_PREFIX, PREFIX as AVAILABILITY_PREFIX } from '../constants';
import GuestForm from '../GuestForm';
import GuestPicker from '../GuestPicker';
import { resetGuests } from '../utils';
import * as ß from './styles';

const HomePageBox = ({ isMobile }) => {
  const {
    prepareToTrackEvent: { clickEvent },
  } = useTracking('booking box');
  const pageContext = useContext(MaybeData);
  const stateProps = useContext(AvailabilityStateContext);
  const { t } = useTranslation([HOMEPAGE_PREFIX, AVAILABILITY_PREFIX]);
  const [showGuestPicker, setShowGuestPicker] = useState(false);
  const { focusInput, selectedSuggestion } =
    useContext(SearchSuggestionContext) ?? {};

  const trackSearchButtonClicked = clickEvent('search button clicked');
  const {
    dateFrom,
    dateTo,
    kidsAges,
    setKidsAges,
    setTotalAdults,
    totalAdults,
    getAvailabilityLink,
    saveBookingData,
  } = stateProps;

  const hasDates = dateFrom && dateTo;
  const hasNoSearchTerm = isEmptyOrNil(selectedSuggestion);

  const searchPathPrefix = locizedSearchPathPrefix(pageContext);

  const shouldGoToSearch = everyTruthy([
    hasDates,
    !isEmptyOrNil(selectedSuggestion?.search_query),
  ]);

  const builtSlug = shouldGoToSearch
    ? `${searchPathPrefix}${selectedSuggestion?.search_query}`
    : selectedSuggestion?.slug;

  const availabilityLink = hasNoSearchTerm
    ? '#'
    : getAvailabilityLink({
        dateFrom,
        dateTo,
        kidsAges: hasDates ? kidsAges : undefined,
        pagePath: builtSlug,
        totalAdults: hasDates ? totalAdults : undefined,
      });

  const guestFormId = `guest-input${isMobile ? '-mobile' : ''}`;
  const handleSubmit = event => {
    if (hasNoSearchTerm) {
      event?.preventDefault?.();

      focusInput();
      return;
    }

    trackSearchButtonClicked();
  };

  const resetGuestPicker = () => {
    resetGuests(setTotalAdults, setKidsAges);
    saveBookingData({
      totalAdults: DEFAULT_TOTAL_ADULTS,
      kidsAges: DEFAULT_KIDS_AGES,
    });
  };

  return (
    <div css={ß.wrapper}>
      <h1>{t('Entdecke & buche deine nächsten Campingplatz')}</h1>
      <h2>{t('homepage box description')}</h2>
      <div css={ß.searchBar}>
        <SearchBar
          isMobile={isMobile}
          shouldNavigate={false}
          trackLabel="booking box"
        />
      </div>
      <div css={ß.labelsWrapper}>
        <div css={ß.labels}>
          <label>{t(`${AVAILABILITY_PREFIX}:Anreise`)}</label>
          <label>{t(`${AVAILABILITY_PREFIX}:Abreise`)}</label>
        </div>
      </div>
      <DatePicker {...stateProps} isMobile={isMobile} />
      <div css={ß.guestForm}>
        <GuestForm
          {...stateProps}
          id={guestFormId}
          setShowGuestPicker={setShowGuestPicker}
        />
      </div>
      {showGuestPicker && (
        <div css={ß.guestPicker}>
          <GuestPicker
            {...stateProps}
            mobileModal={isMobile}
            prefix={AVAILABILITY_PREFIX}
            resetGuests={resetGuestPicker}
            setShowGuestPicker={setShowGuestPicker}
          />
        </div>
      )}
      <Button
        isFullWidth
        onClick={handleSubmit}
        size="large"
        to={availabilityLink}
        variant="primary"
      >
        {t('Suchen')}
      </Button>
    </div>
  );
};

HomePageBox.propTypes = {
  isMobile: PropTypes.bool,
};

HomePageBox.defaultProps = {
  isMobile: false,
};

export default HomePageBox;
