import React from 'react';
import PropTypes from 'prop-types';

import BlockHeader from '@components/BlockHeader';
import Carousel from '@components/Carousel';
import SquareTile from '@components/SquareTile';
import { countriesCarouselBreak } from '@fire/_mediaQueries';
import { T } from '@fire/i18n/i18n-constants';
import useAvocado from '@hooks/useAvocado';
import { useDimensions } from '@hooks/useDimensions';

import avocado from './avocado.json';
import * as ß from './styles';

const prefix = T?.HomePage ?? '';

const CountriesCarousel = ({ data, title, description, onClickTile }) => {
  const { width } = useDimensions();
  const tileCount = width >= countriesCarouselBreak ? 4 : 3;
  const countries = useAvocado(data ?? avocado);

  return countries.length > 0 ? (
    <div css={ß.superWrapper} data-testid="countryCarousel">
      <BlockHeader prefix={prefix} title={title} description={description} />

      <div css={ß.carouselWrapper}>
        <Carousel
          tileCount={tileCount}
          minMax={'minmax(250px, 1fr)'}
          arrowPosition="center"
        >
          {countries.map((country, index) => {
            const { url, image, name } = country;
            return (
              <div key={`${name}-${index}`}>
                <SquareTile
                  url={url}
                  image={image}
                  title={name}
                  onClickable={() => onClickTile(country)}
                />
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  ) : (
    <></>
  );
};

CountriesCarousel.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClickTile: PropTypes.func,
};

CountriesCarousel.defaultProps = {
  data: avocado,
  onClickTile: () => {},
};

export default CountriesCarousel;
