import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { take } from 'ramda';

import Icon from '@fire/assets/bulbRed.svg';
import { T } from '@fire/i18n/i18n-constants';
import { useLoyaltyGold, useLoyaltyPlatinum } from '@hooks/useLoyalty';
import { shuffleArray } from '@utils/generic';

import MobileHomesPromo from '../MobileHomesPromo';
import * as ß from './styles';
import SuggestionTile from './SuggestionTile';

const prefix = T?.SearchBar ?? '';

const PromotedSuggestions = () => {
  const { t } = useTranslation(prefix);
  const [campsites, setCampsites] = useState([]);
  const goldCampsites = useLoyaltyGold();
  const platinumCampsites = useLoyaltyPlatinum();
  const campsiteAmount = 4;

  useEffect(() => {
    setCampsites(
      take(
        campsiteAmount,
        shuffleArray([...goldCampsites, ...platinumCampsites])
      )
    );
  }, []);

  return (
    <div css={ß.wrapper}>
      <MobileHomesPromo />
      <div css={ß.title}>
        <Icon alt="star-icon" css={ß.icon} />
        <p>{t('Ausgewählte Campingplätze')}</p>
      </div>

      {campsites.map((campsite, i) => (
        <SuggestionTile key={campsite.prn} position={i + 1} {...campsite} />
      ))}
    </div>
  );
};

export default PromotedSuggestions;
