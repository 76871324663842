import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import * as ß from './styles';

const Input = ({
  hasError,
  id,
  label,
  onChange,
  onFocus,
  pattern,
  refProp,
  type,
  value,
  ...rest
}) => (
  <div css={ß.wrapper}>
    <input
      css={[ß.input, hasError ? ß.error : undefined]}
      id={id}
      onChange={onChange}
      onFocus={onFocus}
      pattern={pattern}
      // Placeholder is hidden to achieve the floaty label style
      placeholder="&nbsp;"
      ref={refProp}
      type={type}
      value={value}
      {...rest}
    />
    {label && (
      <label css={ß.label} htmlFor={id}>
        {label}
      </label>
    )}
  </div>
);

Input.defaultProps = {
  hasError: false,
  type: 'text',
};

Input.propTypes = {
  hasError: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  pattern: PropTypes.string,
  refProp: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any,
    }),
  ]),
  type: PropTypes.string,
  value: PropTypes.string,
};

export default Input;

export const InputForwardedRef = forwardRef(function InputForwardedRef(
  props,
  ref
) {
  return <Input refProp={ref} {...props} />;
});
