import { withPrefix } from 'gatsby';

import { css } from '@emotion/react';

const fontPrefix = 'pt-sans-v12-latin-ext_latin_cyrillic-ext_cyrillic';
export const setupFont = css`
  /* pt-sans-regular - latin */
  @font-face {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    src: url(${withPrefix(`/fonts/${fontPrefix}-regular.eot`)});
    src: local(''),
      url(${withPrefix(`/fonts/${fontPrefix}-regular.woff2`)}) format('woff2'),
      url(${withPrefix(`/fonts/${fontPrefix}-regular.woff`)}) format('woff'),
      url(${withPrefix(`/fonts/${fontPrefix}-regular.ttf`)}) format('truetype');
  }
  /* pt-sans-700 - latin */
  @font-face {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 700;
    src: url(${withPrefix(`/fonts/${fontPrefix}-700.eot`)});
    src: local(''),
      url(${withPrefix(`/fonts/${fontPrefix}-700.woff2`)}) format('woff2'),
      url(${withPrefix(`/fonts/${fontPrefix}-700.woff`)}) format('woff'),
      url(${withPrefix(`/fonts/${fontPrefix}-700.ttf`)}) format('truetype');
  }
  /* pt-sans-700italic - latin */
  @font-face {
    font-family: 'PT Sans';
    font-style: italic;
    font-weight: 700;
    src: url(${withPrefix(`/fonts/${fontPrefix}-700italic.eot`)});
    src: local(''),
      url(${withPrefix(`/fonts/${fontPrefix}-700italic.woff2`)}) format('woff2'),
      url(${withPrefix(`/fonts/${fontPrefix}-700italic.woff`)}) format('woff'),
      url(${withPrefix(`/fonts/${fontPrefix}-700italic.ttf`)})
        format('truetype');
  }
  /* pt-sans-italic - latin */
  @font-face {
    font-family: 'PT Sans';
    font-style: italic;
    font-weight: 400;
    src: url(${withPrefix(`/fonts/${fontPrefix}-italic.eot`)});
    src: local(''),
      url(${withPrefix(`/fonts/${fontPrefix}-italic.woff2`)}) format('woff2'),
      url(${withPrefix(`/fonts/${fontPrefix}-italic.woff`)}) format('woff'),
      url(${withPrefix(`/fonts/${fontPrefix}-italic.ttf`)}) format('truetype');
  }
`;

export const fontFace = css`
  font-family: 'PT Sans', sans-serif;
  font-size-adjust: 0.5;
`;

export const regular = '400';
export const bold = '700';

export const fontSizeBody = '1em';
export const fontSizeExtraLarge = '24px';
export const fontSizeLarge = '18px';
export const fontSizeMedium = '16px';
export const fontSizeSmall = '14px';
export const fontSizeXSmall = '12px';
export const lineHeightBody = '24px';
export const lineHeightDefault = '1.25';

export const h2 = css`
  margin-left: 16px;
`;
