import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { T } from '@fire/i18n/i18n-constants';
import { MomentType } from '@types';
import { formatDate } from '@utils/dateTime';

export const PREFIX = T.Accommodations ?? '';

import * as ß from './styles';

const TravelDates = ({
  dateFrom,
  dateTo,
  totalGuests,
  clearDates,
  onClick,
}) => {
  const { t } = useTranslation(PREFIX);
  const dates = `${formatDate(dateFrom)} - ${formatDate(dateTo)}`;

  return (
    <div css={ß.travelDetails}>
      <span css={ß.travelDates} onClick={onClick}>
        {dates} | {t('total-guests', { count: totalGuests })}
      </span>
      {clearDates && <button onClick={clearDates}>{t('clear')}</button>}
    </div>
  );
};

TravelDates.propTypes = {
  dateFrom: MomentType,
  dateTo: MomentType,
  totalGuests: PropTypes.number,
  clearDates: PropTypes.func,
  onClick: PropTypes.func,
};

export default TravelDates;
