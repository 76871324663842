import React from 'react';
import { curry, map, pipe, propOr } from 'ramda';

import Link from './Link';

export const asProp = curry((prop, x) => ({ [prop]: x }));

export const renderFooterList = pipe(
  propOr([], 'items'),
  map(([text, link]) => (
    <li key={`${text}-${link}`}>
      <Link to={link}>{text}</Link>
    </li>
  ))
);

export const getClassificationProps =
  ({ rating, showTcsRating, tcsRating }) =>
  t =>
    showTcsRating
      ? {
          color: 'PINK',
          label: t('Swisscamps Klassifikation'),
          rating: tcsRating,
        }
      : {
          color: 'YELLOW',
          label: t('ADAC Klassifikation'),
          rating,
        };
