import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { assocPath, dissocPath, isEmpty } from 'ramda';

import Link from '@components/Link';
import MaybeData from '@contexts/MaybeData';
import Arrow from '@fire/assets/pageArrow.svg';
import useProsciutto from '@hooks/useProsciutto';
import { makeURLSearchParams } from '@utils/prosciutto';
import { goToTop } from '@utils/scroll';

import NextPageLinkWrapper from './NextPageLinkWrapper';
import PreviousPageLinkWrapper from './PreviousPageLinkWrapper';
import * as ß from './styles';

const namespace = 'pt-search-result';

const Pagination = ({ pageSize }) => {
  const [prosciuttoState, prosciuttoDispatch] = useProsciutto();
  const pageContext = useContext(MaybeData);
  const { t } = useTranslation(namespace);
  const { pagePath = '' } = pageContext;
  const currentPage = prosciuttoState?.params?.page ?? 1;
  const itemCount = prosciuttoState?.lists?.[0]?.count ?? 0;

  const goToPage = useCallback(
    page => {
      prosciuttoDispatch({
        type: 'setUrlParams',
        params: { ...prosciuttoState.params, page },
      });
      goToTop();
    },
    [prosciuttoDispatch, prosciuttoState.params]
  );

  const pageCount = Math.ceil(itemCount / pageSize);

  const handlePrevious = () => {
    const newPage = currentPage - 1;
    if (newPage > 0) goToPage(newPage);
  };

  const handleNext = () => {
    const newPage = currentPage + 1;
    if (newPage <= pageCount) goToPage(newPage);
  };

  const getFormattedRange = () => {
    const min = (currentPage - 1) * pageSize + 1;
    const max = Math.min(currentPage * pageSize, itemCount);
    return `${min} - ${max}`;
  };

  const getPageLink = useCallback(
    pageNumber => {
      const nextProsciuttoState =
        pageNumber === 1
          ? dissocPath(['params', 'page'], prosciuttoState)
          : assocPath(['params', 'page'], pageNumber, prosciuttoState);
      const nextUrlParams = makeURLSearchParams(nextProsciuttoState);

      return (
        <Link
          css={ß.buttonAnchor}
          key={pageNumber}
          to={
            isEmpty(nextUrlParams) ? pagePath : `${pagePath}?${nextUrlParams}`
          }
        >
          <button
            css={ß.button}
            title={t('page-number', { number: pageNumber })}
            onClick={() => goToPage(pageNumber)}
          >
            <div css={ß.pageButton({ current: currentPage === pageNumber })}>
              {pageNumber}
            </div>
          </button>
        </Link>
      );
    },
    [currentPage, goToPage, pagePath, prosciuttoState, t]
  );

  const getPageLinks = () => {
    const pageLinks = [];

    let start = Math.min(currentPage - 1, pageCount - 2);
    start = Math.max(start, 2);
    // special for left side of the pagination, use one page more then instead of ...
    if (start === 3) {
      start = 2;
    }

    let end = Math.min(currentPage + 1, pageCount - 1);
    // special case for right side of the pagination, use one page more then instead of ...
    if (end === pageCount - 2) {
      end += 1;
    }

    for (let p = start; p <= end; p += 1) {
      pageLinks.push(getPageLink(p));
    }

    return (
      <div css={ß.flexCenter}>
        {getPageLink(1)}
        {start > 3 && <span css={ß.dots}>...</span>}
        {pageLinks}
        {end < pageCount - 2 && <span css={ß.dots}>...</span>}
        {getPageLink(pageCount)}
      </div>
    );
  };

  const isPrevPageButtonDisabled = currentPage === 1;
  const isNextPageButtonDisabled = currentPage === pageCount;

  return itemCount > 0 ? (
    <div css={ß.navigator}>
      <div css={ß.resultInfos}>
        {t('{{range}} von {{count}} Campingplätzen', {
          range: getFormattedRange(),
          count: itemCount,
        })}
      </div>
      {pageCount > 1 && (
        <div css={ß.styledButtons}>
          <PreviousPageLinkWrapper
            itemCount={itemCount}
            pageCount={pageCount}
            currentPage={currentPage}
            pagePath={pagePath}
            prosciuttoState={prosciuttoState}
          >
            <button
              css={ß.button}
              title={t('previous-page')}
              onClick={handlePrevious}
              disabled={isPrevPageButtonDisabled}
            >
              <div css={ß.arrow}>
                <Arrow css={ß.chevronLeft} />
              </div>
            </button>
          </PreviousPageLinkWrapper>
          <div css={ß.flexCenter}>{getPageLinks()}</div>
          <NextPageLinkWrapper
            itemCount={itemCount}
            pageCount={pageCount}
            currentPage={currentPage}
            pagePath={pagePath}
            prosciuttoState={prosciuttoState}
          >
            <button
              css={ß.button}
              title={t('next-page')}
              disabled={isNextPageButtonDisabled}
              onClick={handleNext}
            >
              <div css={ß.arrow}>
                <Arrow css={ß.chevronRight} />
              </div>
            </button>
          </NextPageLinkWrapper>
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};

Pagination.propTypes = {
  pageSize: PropTypes.number.isRequired,
};

export default React.memo(Pagination);
