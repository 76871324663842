import { css } from '@emotion/react';

export const safetyMargins = css`
  width: 100%;
  padding: 12px;
`;

export const centerHack = css`
  // it already has margin-top so don't mess with it
  margin-left: -12px;
  margin-right: -12px;
`;
