import React, { memo, useContext, useEffect } from 'react';
import { fork } from 'fluture';
import { graphql, useStaticQuery, withPrefix } from 'gatsby';
import { __, equals, pathOr, pipe, propOr } from 'ramda';

import MaybeData from '@contexts/MaybeData';
import { GATSBY_IP_TO_LOCATION_SERVICE, PLATFORMS } from '@fire/constants';
import useModal from '@hooks/useModal';
import { makeRequest } from '@utils/http';
import { localStorage } from '@utils/local-storage';
import { getPlatform } from '@utils/platform';

import 'lazysizes';
import * as ß from './style';

// map country iso codes to targeted platform
const TARGET_PLATFORM_MAP = {
  DE: PLATFORMS.DE,
  CH: PLATFORMS.CH,
};

const STORAGE_KEY_ALREADY_CHECKED = 'platform-selector-checked';

const maybeCountry = resolve =>
  pipe(
    makeRequest,
    fork(() => resolve({}))(res =>
      resolve(pathOr('UNKNOWN', ['data', 'country'], res))
    )
  )({ url: GATSBY_IP_TO_LOCATION_SERVICE });

const matchesPlatform = platform =>
  pipe(propOr(platform, __, TARGET_PLATFORM_MAP), equals(platform));

const pincampLogo = withPrefix('/icons/logo-pincamp.svg');

const flagDe = withPrefix('/icons/flag-de.svg');

const flagCh = withPrefix('/icons/flag-ch.svg');

export const PlatformSelector = memo(function PlatformSelector() {
  const data = useStaticQuery(graphql`
    query {
      picture: file(relativePath: { eq: "homepage/platform-selector.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 540, maxHeight: 540, toFormat: JPG, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);
  const pageContext = useContext(MaybeData);

  const linkDe = `https://www.pincamp.de${
    pageContext?.page?.availableLanguages?.de?.de ?? ''
  }`;

  const linkCh = `https://www.pincamp.ch/de${
    pageContext?.page?.availableLanguages?.ch?.de ?? ''
  }`;

  const { show, Modal } = useModal();
  useEffect(() => {
    const isChecked = localStorage.getItem(STORAGE_KEY_ALREADY_CHECKED);
    if (isChecked) {
      return;
    }
    localStorage.setItem(STORAGE_KEY_ALREADY_CHECKED, true);
    maybeCountry(country => {
      if (!matchesPlatform(getPlatform(pageContext))(country)) {
        show();
      }
    });
  }, [pageContext, show]);
  return (
    <Modal type="big">
      <div css={ß.content}>
        <div>
          <picture>
            <source
              type="image/webp"
              data-srcset={data.picture.childImageSharp.fluid.srcSetWebp}
            />
            <source
              type="image/jpg"
              data-srcset={data.picture.childImageSharp.fluid.srcSet}
            />
            <img
              data-src={data.picture.childImageSharp.fluid.src}
              className="lazyload"
              loading="eager"
            />
          </picture>
        </div>
        <div>
          <img src={pincampLogo} css={ß.logo} alt="PiNCAMP" />
          <ul>
            <li>Welche Seite von PiNCAMP möchtest du besuchen?</li>
            <li>Quale sito PiNCAMP vorresti visitare?</li>
            <li>Quelle page PiNCAMP souhaitez-vous visiter?</li>
          </ul>
          <div css={ß.buttonList}>
            <a href={linkDe}>
              <img src={flagDe} alt="Deutschland" />
              pincamp.de
            </a>
            <a href={linkCh}>
              <img src={flagCh} alt="Schweiz" />
              pincamp.ch
            </a>
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default PlatformSelector;
