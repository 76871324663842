import { withPrefix } from 'gatsby';

import { css } from '@emotion/react';
import { blue9, blue10, gray5 } from '@fire/_color';
import { bold, fontSizeLarge } from '@fire/_fonts';
import { media } from '@fire/_mediaQueries';

const GAP_MOBILE = '10px';
const GAP_DESKTOP = '14px';

export const mobileHomes = css`
  display: grid;
  gap: ${GAP_MOBILE};
  grid-template-rows: 160px 160px auto;
  margin-top: -36px;

  ${media.md} {
    grid-template-columns: 50%;
    grid-template-rows: auto;
    margin-top: unset;
  }
  ${media.lg} {
    gap: {$GAP_DESKTOP};
    grid-template-columns: 30% 20% calc(50% - 28px);
    grid-template-rows: auto;
  }
`;

export const bigImage = css`
  background-image: url(${withPrefix(
    '/homepage/mobile-homes/mobilehome01.jpg'
  )});
  background-position: center;
  background-size: cover;
  border-radius: 8px 8px 0 0;
  min-height: 100%;
  overflow: hidden;

  ${media.md} {
    border-radius: 8px 0 0 8px;
    grid-column-start: 1;
    grid-column-end: 2;
  }

  ${media.lg} {
    grid-column-start: auto;
    grid-column-end: auto;
  }
`;

export const smallImages = css`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: ${GAP_MOBILE};

  ${media.md} {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  ${media.lg} {
    flex-wrap: nowrap;
    gap: {$GAP_DESKTOP};
    grid-column-start: auto;
    grid-column-end: auto;
  }

  span {
    background-position: center;
    background-size: cover;
    height: 100%;
    overflow: hidden;

    :nth-of-type(1) {
      background-image: url(${withPrefix(
        '/homepage/mobile-homes/mobilehome02.jpg'
      )});
    }

    :nth-of-type(2) {
      background-image: url(${withPrefix(
        '/homepage/mobile-homes/mobilehome03.jpg'
      )});
    }
  }
`;

export const content = css`
  background: ${gray5};
  border-radius: 0 0 8px 8px;
  font-size: ${fontSizeLarge};
  margin: -${GAP_MOBILE} 0 0;
  padding: 12px;

  ${media.md} {
    border-radius: 0 8px 8px 0;
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: 3;
    margin: 0 0 0 -${GAP_DESKTOP};
    padding: 18px 60px;
  }

  ${media.lg} {
    grid-column-start: auto;
    grid-row-start: auto;
    grid-row-end: auto;
  }

  li {
    align-items: center;
    display: flex;
    gap: ${GAP_DESKTOP};
    margin-bottom: 16px;
  }

  p a {
    color: ${blue9};
    font-size: ${fontSizeLarge};
    font-weight: bold;
    padding: 0;
    transition: color 0.25s ease;

    :hover {
      color: ${blue10};
    }
  }
`;

export const title = css`
  font-size: 26px;
  font-weight: ${bold};
  line-height: 28px;
  margin-bottom: 32px;

  ${media.md} {
    font-size: 34px;
    line-height: 38px;
  }
`;

export const buttonContainer = css`
  margin: 24px 0;
`;
