const LOCALIZED_MOBILE_HOME_LINKS = {
  de: [
    '/mobilheime/deutschland',
    '/mobilheime/italien',
    '/mobilheime/frankreich',
  ],
  'de-CH': [
    '/mobilheime/deutschland',
    '/mobilheime/italien',
    '/mobilheime/frankreich',
  ],
  'fr-CH': [
    '/mobil-homes/allemagne',
    '/mobil-homes/italie',
    '/mobil-homes/france',
  ],
  'it-CH': [
    '/case-mobili/germania',
    '/case-mobili/italia',
    '/case-mobili/francia',
  ],
};

export const getLocalizedMobileHomeLinks = language =>
  LOCALIZED_MOBILE_HOME_LINKS?.[language ?? 'de'];

export const getLocalizedMobileHomeButton = language =>
  ({
    de: '/mobilheime/kroatien',
    'de-CH': '/mobilheime/kroatien',
    'fr-CH': '/mobil-homes/croatie',
    'it-CH': '/case-mobili/croazia',
  }[language ?? 'de']);
