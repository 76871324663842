import { pathOr, propOr } from 'ramda';

import { BREAKPOINTS } from '@fire/_mediaQueries';

export const goTo = url => (window.location.href = url);

export const goToNewWindow = url =>
  window.open(url, '_blank', 'noopener,noreferrer');

/**
 * Read https://joshwcomeau.com/react/the-perils-of-rehydration
 * before thinking about using this function (TLDR; it's a bad solution)
 *
 * @deprecated
 */
export const checkWindowExists = () => typeof window !== 'undefined';

export const getBrowserLanguage = () =>
  (checkWindowExists() && pathOr(false, ['navigator', 'language'], window)) ||
  pathOr(false, ['navigator', 'userLanguage'], window);

export const isNotDesktop = () =>
  window.innerWidth <= propOr(1200, 'xl', BREAKPOINTS);

export const isMobile = () =>
  window.innerWidth <= propOr(760, 'md', BREAKPOINTS);

export const isTablet = () =>
  window.innerWidth <= propOr(990, 'lg', BREAKPOINTS);
