import React from 'react';
import PropTypes from 'prop-types';

import Link from '@components/Link';

import * as ß from './styles';

const Logo = ({ logo, name, url, onClick }) => (
  <Link css={ß.logo} onClick={onClick} to={url} openInNewWindow>
    <img src={logo} alt={`Logo of ${name}`} />
  </Link>
);

Logo.propTypes = {
  logo: PropTypes.string,
  url: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
};

export default Logo;
