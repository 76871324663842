import React from 'react';
import PropTypes from 'prop-types';

import * as ß from './styles';

const Container = ({
  children,
  fullWidth,
  hasLargePadding,
  id,
  isBlue,
  isGrey,
  isYellow,
  hasMarginBottom,
  className,
}) => {
  const colors = [
    isGrey && ß.greyBg,
    isBlue && ß.blueBg,
    isYellow && ß.yellowBg,
  ].filter(Boolean);

  return (
    <div
      css={[ß.wrapper(hasLargePadding, hasMarginBottom), ...colors]}
      {...(id ? { id } : {})}
      className={className}
    >
      <div css={fullWidth ? ß.containerFullWidth : ß.container}>{children}</div>
    </div>
  );
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
  fullWidth: PropTypes.bool,
  hasLargePadding: PropTypes.bool,
  id: PropTypes.string,
  isBlue: PropTypes.bool,
  isGrey: PropTypes.bool,
  isYellow: PropTypes.bool,
  hasMarginBottom: PropTypes.bool,
  className: PropTypes.string,
};

Container.defaultProps = {
  fullWidth: false,
  hasLargePadding: false,
  isBlue: false,
  isGrey: false,
  isYellow: false,
  hasMarginBottom: false,
};

export default Container;
