import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { T } from '@fire/i18n/i18n-constants';

import * as ß from './styles';

const PREFIX = T?.SearchResultSummary;

const ResultSummary = ({ count, showOnlyCampsiteCount }) => {
  const { t } = useTranslation(PREFIX);
  return (
    <div>
      {showOnlyCampsiteCount && (
        <h1 css={ß.title}>
          {count} {t(count !== 1 ? 'Campingplätze' : 'Campingplatz')}
        </h1>
      )}
    </div>
  );
};

ResultSummary.defaultProps = {
  showOnlyCampsiteCount: false,
  isLoading: false,
};

ResultSummary.propTypes = {
  count: PropTypes.number,
  showOnlyCampsiteCount: PropTypes.bool,
};

export default ResultSummary;
