import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';

import { T } from '@fire/i18n/i18n-constants';
import useAvocado from '@hooks/useAvocado';

import aboutUs from './aboutUs.json';
import Accordion, { renderFooterList } from './accordion';
import bookWithUs from './bookWithUs.json';
import * as ß from './styles';

const namespace = pathOr('', ['Footer'], T);

export const FooterWithLinks = ({ links: items, title, id }) => {
  const { t } = useTranslation(namespace);
  const showIt = items.length > 0;
  return showIt ? (
    <div css={ß.aboutFooter}>
      <div css={ß.wrapperMobile}>
        <Accordion data={[{ title, items }]} />
      </div>
      <div css={ß.about}>
        <h5 css={ß.title}>{t(title)}</h5>
        <ul id={id}>{renderFooterList({ title, items })}</ul>
      </div>
    </div>
  ) : (
    <></>
  );
};

FooterWithLinks.propTypes = {
  links: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.string,
};

const FooterAbout = () => {
  const aboutUsLinks = useAvocado(aboutUs);
  return <FooterWithLinks links={aboutUsLinks} title="Über PiNCAMP" />;
};

export const FooterBookWithUs = () => {
  const bookWithPincampLinks = useAvocado(bookWithUs);
  return (
    <FooterWithLinks
      links={bookWithPincampLinks}
      title="Buchbare Campingplätze"
      id="booking-with-us-section"
    />
  );
};

export default FooterAbout;
