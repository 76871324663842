import React from 'react';
import { useTranslation } from 'react-i18next';

import { T } from '@fire/i18n/i18n-constants';

const PREFIX = T?.Tile;

import { ICON } from './utils';

const FavIcon = () => {
  const { t } = useTranslation(PREFIX);

  const altText = t('favorite-icon');

  return (
    <>
      <img src={ICON} alt={altText} width="21" height="19" />
      <span>{altText}</span>
    </>
  );
};

export default FavIcon;
