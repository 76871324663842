import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Header from '@components/HeaderContainer';
import { HrefLang } from '@components/Meta';
import { NAMESPACE } from '@components/ReservationRequestConfirmation/constants';
import SEO from '@components/seo';
import MaybeData from '@contexts/MaybeData';
import { GlobalStyles } from '@fire/globalStyles';
import * as ß from '@templates/StandardPage/styles';
import { PageView } from '@utils/tracking';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    console.error(info.componentStack);
    console.error(error);
  }

  render() {
    return process.env.NODE_ENV === 'development' && this.state.hasError ? (
      <strong>
        Something is wrong with this page, please see logs for more details
      </strong>
    ) : (
      this.props.children
    );
  }
}
ErrorBoundary.propTypes = { children: PropTypes.node };

const ReservationRequestPage = ({ children, pageContext }) => {
  const { t } = useTranslation(NAMESPACE);

  return (
    <>
      <MaybeData.Provider value={pageContext}>
        <GlobalStyles />
        <PageView />
        <SEO pageContext={pageContext} />
        <HrefLang />
        <Helmet>
          <title>{t('title')}</title>
        </Helmet>
        <ErrorBoundary>
          <Header minimal hidePlatformSelector />
          <div css={ß.container} id="page-container">
            {children}
          </div>
        </ErrorBoundary>
      </MaybeData.Provider>
    </>
  );
};

ReservationRequestPage.propTypes = {
  children: PropTypes.node,
  pageContext: PropTypes.object,
};

ReservationRequestPage.defaultProps = {
  pageContext: {
    prn: 'prn:page:reservation-request',
  },
};

export default ReservationRequestPage;
