import { css } from '@emotion/react';

export const header = css`
  margin: 24px 0;
`;
export const container = css`
  margin: 15px auto 24px;
  padding-left: 24px;
  padding-right: 24px;
  max-width: 760px;
`;

export const searchBarWrapper = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 48px 12px;
  width: 100%;

  div {
    max-width: 100%;
    margin-left: 0;
  }
`;

export const checkListwrapper = css`
  margin: 24px 0 48px 0;
  word-break: normal;
`;

export const explanationWrapper = css`
  margin: 0 12px 36px 12px;
`;

export const checkList = css`
  display: flex;

  ul {
    display: flex;
    flex-direction: column;

    li {
      border: 1px solid red;
    }
  }
`;

export const accordionWrapper = css`
  a {
    text-decoration: none;
  }
  & > div {
    margin 0;
    max-width: none;
    width: 100%;
  }
`;

export const checkListNoBoldTitle = css`
  h4 {
    font-weight: normal;
  }
`;
