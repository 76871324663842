export const T = {
  Accommodations: 'accommodations',
  Alerts: 'alerts',
  AvailabilityBox: 'availability-box',
  Bookings: 'bookings',
  CampCardList: 'pt-csp-campcardlist',
  CampingHeader: 'pt-csp-maininfos',
  CoronaInfos: 'corona-infos',
  CookieBanner: 'pt-cookie-banner',
  CookieOptOutPage: 'pt-cookie-optout-page',
  CSP: 'pt-csp',
  CSPAds: 'pt-csp-ads-carousel',
  CSPFAQ: 'csp-faq',
  CSPMap: 'pt-csp-map',
  CTABox: 'pt-csp-cta-box',
  CurrencyDisclaimer: 'currency-disclaimer',
  FeatureList: 'pt-csp-feature-icons',
  FaqListPage: 'faq-list-page',
  Footer: 'pt-footer',
  FooterPremiumPartner: 'pt-premium-partners-footer',
  FooterSignup: 'pt-signup-newsletter',
  HeroMenu: 'pt-hero-menu',
  HomePage: 'pt-home-page',
  InfrastructureAndOffers: 'pt-csp-infrastructure-and-offers',
  InfrastructureCheckList: 'pt-csp-infrastructure-list-item',
  LanguageSelector: 'pt-language-selector',
  MenuMap: 'pt-map-country',
  PaymentCheckList: 'pt-csp-price-infos',
  PincampTemplates: 'pincamp-templates',
  PlaceAttributes: 'place-attributes',
  PlaceInfoTexts: 'pt-csp-platzinfo',
  PricingList: 'pt-csp-pricinglist',
  ProfileImageGallery: 'pt-csp-image-gallery',
  ProfileStage: 'pt-csp-profile-stage',
  ReportAbuse: 'pt-csp-review-list-flag-modal',
  ReservationFee: 'pt-csp-reservationfee',
  ReservationRequestConfirmation: 'reservation-request-confirmation',
  ReviewLandingPage: 'pt-review-landing-page',
  ReviewPage: 'pt-review-page',
  Reviews: 'pt-csp-review-list',
  SearchBar: 'pt-search-box',
  SearchResult: 'pt-search-result',
  SearchResultFilter: 'pt-search-result-filter',
  SearchResultSummary: 'pt-search-result-summary',
  SEOText: 'pt-seo-text',
  StatBox: 'pt-csp-platzinfo',
  SuggestionsList: 'pt-suggestion-list',
  Tile: 'pt-search-result-list-tile',
  TopBanner: 'pt-review-banner',
  PartnerAds: 'pt-partner-ads',
  DestinationPage: 'pt-destination-page',
  FlexibleDates: 'flexible-dates',
};
