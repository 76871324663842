import { trackObject } from '@utils/tracking';

const category = 'review';

export const getReviewData = ({ campsiteData, pageContext }) => {
  const { language } = pageContext;
  const { name, prn, plan } = campsiteData;

  return {
    products: [
      {
        name,
        id: prn,
        country: language,
        category: plan,
        variant: 'campsite',
      },
    ],
    page: {
      id: prn,
      language,
    },
  };
};

export const trackStartReview = props =>
  trackObject({
    event: 'review_start-review',
    category,
    action: 'start review',
    label: '(not set)',
    ...getReviewData(props),
  });

export const trackSendReview = ({
  campsiteData,
  pageContext,
  travelDateMonth,
  travelDateYear,
}) =>
  trackObject({
    event: 'review_send-review',
    category,
    action: 'send review',
    label: 'email',
    ...getReviewData({ campsiteData, pageContext }),
    'travel type': {
      'travel dates': `${travelDateMonth} ${travelDateYear}`,
    },
  });

export const trackVerifyReview = props =>
  trackObject({
    event: 'review_verify-review',
    category,
    action: 'verify review',
    label: 'email',
    ...getReviewData(props),
  });
