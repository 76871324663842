import { trackObject } from '@utils/tracking';

import { MAIN_LIST_MINIMUM } from './FlexibleDates/consts';

export const generatePurchaseId = campsiteId =>
  `${Math.round(Date.now() / 1000)}-${campsiteId}`;

const isMap = description => description === 'map';

export const getCategory = description =>
  description === 'search' ? 'search' : 'list';

export const getLabel = description =>
  isMap(description) ? 'map' : getCategory(description);

const getTravelTypeObject = ({
  date_from,
  date_to,
  adults: totalAdults = 0,
  children: kids = [],
}) => {
  const totalKids = kids.length;
  const travellers = `${totalAdults};${totalKids}`;
  if (date_from && date_to) {
    return {
      'travel type': {
        date_from,
        date_to,
        travellers,
      },
    };
  }
  return {};
};

const getOnViewLabel = (availabilities, hasFlexibleDates) => {
  if (availabilities > MAIN_LIST_MINIMUM) {
    return 'availability shown';
  }
  if (hasFlexibleDates) {
    return 'flexible dates shown';
  }
  return 'no availability shown - not even flexible dates';
};

export const trackAmountOfBookableCampsites = ({
  tiles,
  params,
  hasFlexibleDates,
}) => {
  const {
    home: numberOfRentals,
    pitch: numberOfPitches,
    total,
  } = tiles.reduce(
    (acc, tile) => {
      const availabilities = tile?.availabilities;
      if (availabilities) {
        availabilities.forEach(({ category }) => {
          acc[category] += 1;
          return acc;
        });
        acc.total += 1;
      }

      return acc;
    },
    { home: 0, pitch: 0, total: 0 }
  );

  trackObject({
    action: 'tiles with availabilities loaded',
    category: 'list',
    label: getOnViewLabel(total, hasFlexibleDates),
    event: 'list_page_availabilities-loaded',
    content: {
      'number of campsites': total,
      'number of campsites with pitches': numberOfPitches,
      'number of campsites with rentals': numberOfRentals,
    },
    ...getTravelTypeObject(params),
  });
};
