import React, { useContext } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import * as ß from '@components/FAQ/styles';
import MaybeData from '@contexts/MaybeData';
import { GATSBY_ENTRYPOINT } from '@fire/constants';
import { T } from '@fire/i18n/i18n-constants';

import {
  getQAJson,
  i18nDefaults,
  remappedTranslation,
  shouldShowFAQ,
} from './utils';

const PREFIX = T?.FaqListPage;

import { resetMargin } from './styles';

const FAQListPage = () => {
  const pageContext = useContext(MaybeData);
  const { t } = useTranslation(PREFIX);

  const questions = pageContext?.faqListPage?.questions ?? [];
  const pagePath = pageContext?.pagePath;

  const id = pagePath ? `"@id": "${GATSBY_ENTRYPOINT}${pagePath}#faq",` : '';

  const getTranslatedQAJson = getQAJson(t);
  const name = pageContext?.h1;
  if (questions && shouldShowFAQ(pageContext)) {
    return (
      <>
        <Helmet>
          <script type="application/ld+json">
            {`
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              ${id}
              "mainEntity": ${getTranslatedQAJson(questions)}
            }
          `}
          </script>
        </Helmet>
        {name && (
          <h3 css={ß.headerTitle} id="faq">
            {t('{{name}}: Frequently asked questions', {
              name,
              ...i18nDefaults,
            })}
          </h3>
        )}
        <Accordion
          allowMultipleExpanded
          allowZeroExpanded
          preExpanded={['item-0', 'item-1']}
        >
          {questions.map(({ question, answer }, index) => {
            const uuid = `item-${index}`;
            const shouldShow =
              question?.resources?.length >= 1 &&
              answer?.resources?.length >= 1;
            return (
              shouldShow && (
                <AccordionItem key={uuid} css={ß.item} uuid={uuid}>
                  <AccordionItemHeading>
                    <AccordionItemButton css={[ß.button, ß.title, resetMargin]}>
                      <p>{remappedTranslation(t, question)}</p>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel css={ß.answer}>
                    {remappedTranslation(t, answer)}
                  </AccordionItemPanel>
                </AccordionItem>
              )
            );
          })}
        </Accordion>
      </>
    );
  }
  return <></>;
};

export default FAQListPage;
