import React from 'react';

import { dateRangePicker } from '@components/DatePicker/styles';
import { css, Global } from '@emotion/react';
import { zIndex } from '@fire/_const';

import { charcoal, white } from './_color';
import {
  bold,
  fontFace,
  fontSizeBody,
  lineHeightBody,
  lineHeightDefault,
  regular,
  setupFont,
} from './_fonts';
import { media } from './_mediaQueries';

export const globalStyles = css`
  ${setupFont};
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
  }

  button,
  input[type='button'],
  input[type='submit'],
  input[type='reset'],
  input[type='file'] {
    border-radius: 0;
  }

  input[type='text']::-ms-clear {
    display: none;
  }

  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  html,
  body {
    min-height: 100%; // needed for intersection observer polyfill
    /* font-size: 16px; */
  }

  body {
    font-size: ${fontSizeBody};
    line-height: ${lineHeightBody};
    position: relative;
    ${fontFace};
    font-weight: ${regular};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${charcoal};
    background: ${white};
    overflow-x: hidden;
  }

  sup {
    vertical-align: super;
  }

  sub {
    vertical-align: sub;
  }

  ol,
  ul {
    list-style: none;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote::before,
  blockquote::after,
  q::before,
  q::after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  * {
    box-sizing: border-box;
  }

  a {
    color: ${charcoal};
    text-decoration: underline;
    font-weight: ${regular};
    cursor: pointer;

    &.primary {
      font-weight: ${bold};
    }
  }

  strong {
    font-weight: ${bold};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5 {
    ${fontFace};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: ${bold};
    color: ${charcoal};
    line-height: ${lineHeightDefault};

    &.hero {
      font-size: 38px;

      ${media.md} {
        font-size: 74px;
      }

      ${media.lg} {
        font-size: 74px;
      }
    }
  }

  h1,
  .h1 {
    font-size: 30px;

    ${media.md} {
      font-size: 48px;
    }

    ${media.lg} {
      font-size: 48px;
    }
  }

  h2,
  .h2 {
    font-size: 26px;

    ${media.md} {
      font-size: 32px;
    }

    ${media.lg} {
      font-size: 32px;
    }
  }

  h3,
  .h3 {
    font-size: 24px;

    ${media.md} {
      font-size: 22px;
    }

    ${media.lg} {
      font-size: 22px;
    }
  }

  h4,
  .h4 {
    font-size: 20px;
  }

  h5,
  .h5 {
    font-size: 20px;

    ${media.md} {
      font-size: 20px;
    }

    ${media.lg} {
      font-size: 20px;
    }
  }

  .has-modal #___gatsby {
    height: 100vh;
    overflow-y: hidden;
  }

  // As the mobile version of the <DateRangePicker /> opens inside a
  // Portal, it is out of the app's scope, so it needs to be here.
  .DateRangePicker_picker__portal {
    padding-top: 20px;
    z-index: ${zIndex.layerSeven};

    ${dateRangePicker}

    .DayPicker_transitionContainer {
      margin-top: 10px;
    }

    .CalendarMonthGrid {
      margin-top: 30px;
    }

    .DayPicker__withBorder {
      box-shadow: none;
    }
    .DayPicker_weekHeader {
      background: ${white};
      line-height: ${lineHeightDefault};
      margin-top: 42px;
      padding: 10px 0 !important;
      text-align: center;
      z-index: 3;

      ${media.lg} {
        background: inherit;
        border-bottom: 1px solid #dbdbdb;
        padding-top: 0;
        top: inherit;
      }
    }

    .DateRangePicker_closeButton {
      position: fixed;
      z-index: ${zIndex.layerTop};
    }

    .DayPickerNavigation {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      padding: 0 10px;
      position: absolute;
      width: 100%;
    }
  }

  .DayPicker_portal__vertical {
    padding-top: 50px;
  }

  .has-tabs .DayPicker > div > div {
    padding-top: 48px;
  }
`;

export const GlobalStyles = () => <Global styles={globalStyles} />;
