export const heroMenuContentFRCH = [
  {
    title: 'Destinations',
    url: '/vacances-camping',
    titles: {
      leftTitle: 'Carte géographique interactive',
      upperRightTitle: 'Destinations',
      downRightTitle: 'Régions populaires',
    },
    children: [
      {
        title: 'Suisse',
        url: '/suisse',
        image: {
          name: 'schweiz-alpenland',
          handle: 'IDJYCsrTfCWE8zFc9OKw',
        },
      },
      {
        title: 'Italie',
        url: '/italie',
        image: {
          name: 'italien-weingut-toskana-destination-italien',
          handle: 'ALmxRNZoSGWXQvIUMEwJ',
        },
      },
      {
        title: 'France',
        url: '/france',
        image: {
          name: '',
          handle: 's02gTlEOT8i4m13mhIq2',
        },
      },
      {
        title: 'Croatie',
        url: '/croatie',
        image: {
          name: '',
          handle: 'Kl54itb5QLO8uhqIBCXz',
        },
      },
      {
        title: 'Espagne',
        url: '/espagne',
        image: {
          name: 'spanien-mittelmeer-bucht-mallorca-country',
          handle: 'pfpHE79LQSW0X1zHCDXH',
        },
      },
      {
        title: 'Allemagne',
        url: '/allemagne',
        image: {
          name: 'deutschland-stage.jpg',
          handle: 'j3j56n35TvK7yAI1E0yU',
        },
      },
      {
        title: 'Tous les pays',
        url: '/tous-les-pays',
        image: {
          name: '',
          handle: 'tBphsgeSQNazBt7j3j9o',
        },
      },
    ],
    links: [
      {
        title: 'Jura Trois-Lacs',
        url: '/vacances-camping/jura-trois-lacs',
      },
      {
        title: 'Lac Majeur',
        url: '/vacances-camping/lac-majeur',
      },
      {
        title: 'Grisons',
        url: '/vacances-camping/grisons',
      },
      {
        title: 'Oberland bernois',
        url: '/vacances-camping/oberland-bernois',
      },
      {
        title: 'Tyrol du Sud',
        url: '/vacances-camping/tyrol-du-sud',
      },
      {
        title: 'Toscane',
        url: '/italie/toscane',
      },
      {
        title: "Côte d'Azur",
        url: '/vacances-camping/cote-d-azur',
      },
      {
        title: 'Ardèche',
        url: '/vacances-camping/ardeche',
      },
    ],
  },
  {
    title: 'Les meilleurs campings',
    url: '/camping-par-thematiques/5-etoiles',
    titles: {
      leftTitle: 'Les meilleurs campings',
      upperRightTitle: '',
      downRightTitle: 'Les meilleurs campings des régions populaires',
    },
    children: [
      {
        title: 'Italie',
        url: '/camping-par-thematiques/5-etoiles/italie',
        image: {
          name: '',
          handle: 'BUIczBzESXGepAluWfuv',
        },
      },
      {
        title: 'France',
        url: '/camping-par-thematiques/5-etoiles/france',
        image: {
          name: '',
          handle: 'TrgzuwdFRc6goGXcH2LS',
        },
      },
      {
        title: 'Croatie',
        url: '/camping-par-thematiques/5-etoiles/croatie',
        image: {
          name: '',
          handle: 'qiw6kYvlR0aZQyg2jzL8',
        },
      },
      {
        title: 'Espagne',
        url: '/camping-par-thematiques/5-etoiles/espagne',
        image: {
          name: 'spanien-mittelmeer-bucht-mallorca-country',
          handle: 'pfpHE79LQSW0X1zHCDXH',
        },
      },
      {
        title: 'Suisse',
        url: '/camping-par-thematiques/4-etoiles/suisse',
        image: {
          name: 'schweiz-alpenland',
          handle: 'IDJYCsrTfCWE8zFc9OKw',
        },
      },
    ],
    links: [
      {
        title: 'Lac Majeur',
        url: '/camping-par-thematiques/4-etoiles/lac-majeur',
      },
      {
        title: 'Tyrol du Sud',
        url: '/camping-par-thematiques/5-etoiles/tyrol-du-sud',
      },
      {
        title: 'Adriatique',
        url: '/camping-par-thematiques/5-etoiles/adriatique',
      },
      {
        title: 'Toscane',
        url: '/camping-par-thematiques/4-etoiles/toscane',
      },
      {
        title: "Côte d'Azur",
        url: '/camping-par-thematiques/5-etoiles/cote-d-azur',
      },
      {
        title: 'Nouvelle-Aquitaine',
        url: '/camping-par-thematiques/4-etoiles/nouvelle-aquitaine',
      },
      {
        title: 'Meilleurs campings à la mer',
        url: '/camping-par-thematiques/5-etoiles/en-bord-de-mer',
      },
      {
        title: 'Meilleurs campings acceptant les chiens',
        url: '/camping-par-thematiques/5-etoiles/avec-un-chien',
      },
      {
        title: 'Meilleurs campings avec piscine',
        url: '/camping-par-thematiques/5-etoiles/avec-piscine',
      },
    ],
  },
  {
    title: 'Thématiques',
    url: '/camping-par-thematiques',
    titles: {
      leftTitle: 'Thématiques',
      upperRightTitle: '',
      downRightTitle: 'Recherche courante: Camping...',
    },
    children: [
      {
        title: 'Camping avec piscine',
        url: '/camping-par-thematiques/avec-piscine',
        image: {
          name: 'Swimmingpool.jpg',
          handle: '7vZO0msRRVCzhxRJoLW9',
        },
      },
      {
        title: 'Camping à la mer',
        url: '/camping-par-thematiques/en-bord-de-mer',
        image: {
          name: '',
          handle: 'wwNDHth2SMC2Y1cTf74O',
        },
      },
      {
        title: 'Camping avec un chien',
        url: '/camping-par-thematiques/avec-un-chien',
        image: {
          name: '',
          handle: 'gj1nXuXjTAGhcfYOUnf3',
        },
      },
      {
        title: 'Camping avec des enfants',
        url: '/camping-par-thematiques/avec-des-enfants',
        image: {
          name: '',
          handle: 'PRabDvOTjq9NwBmHrDNt',
        },
      },
      {
        title: 'Glamping',
        url: '/camping-par-thematiques/glamping',
        image: {
          name: 'Glamping.jpg',
          handle: 'NVMvDXjlRmqSBCvjESFS',
        },
      },
      {
        title: 'Camping au bord du lac',
        url: '/camping-par-thematiques/en-bord-de-lac',
        image: {
          name: '',
          handle: 'ud7WjvQaT8yGSPKd3lkD',
        },
      },
      {
        title: 'Tous les thèmes de camping A-Z',
        url: '/camping-thematiques',
        image: {
          name: '',
          handle: 'BUa4r7zJT8mM3TeLb99w',
        },
      },
    ],
    links: [
      {
        title: 'avec des enfants en Suisse',
        url: '/camping-par-thematiques/avec-des-enfants/suisse',
      },
      {
        title: 'avec des enfants en Italie',
        url: '/camping-par-thematiques/avec-des-enfants/italie',
      },
      {
        title: 'en bord de mer en Italie',
        url: '/camping-par-thematiques/en-bord-de-mer/italie',
      },
      {
        title: 'en bord de lac en Suisse',
        url: '/camping-par-thematiques/en-bord-de-lac/suisse',
      },
      {
        title: 'avec un chien en Italie',
        url: '/camping-par-thematiques/avec-un-chien/italie',
      },
      {
        title: 'au lac en France',
        url: '/camping-par-thematiques/en-bord-de-mer/france',
      },
    ],
  },
  {
    title: 'TCS Magazine',
    url: 'https://www.tcs.ch/fr/camping-voyages/camping-insider/',
    linkout: true,
  },
  {
    title: 'Produits TCS',
    url: 'https://www.tcs.ch/fr/produits/',
    linkout: true,
  },
];
