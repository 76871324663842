import { css } from '@emotion/react';
import { blue5, gray5, yellow5 } from '@fire/_color';
import { BREAKPOINTS, media } from '@fire/_mediaQueries';

export const wrapper = (hasLargePadding, hasMarginBottom = true) => css`
  position: relative;
  display: flex;
  justify-content: center;
  padding: ${hasLargePadding ? '24px 12px' : '8px'};

  ${media.xxs} {
    padding: ${hasLargePadding ? '48px 12px' : '16px'};
  }

  ${media.md} {
    padding: ${hasLargePadding ? '48px 24px' : '16px'};
    margin-bottom: ${hasMarginBottom ? '20px' : '0'};
  }
`;

export const container = css`
  position: relative;
  width: 100%;
  max-width: ${BREAKPOINTS.xl}px;
`;

export const containerFullWidth = css`
  z-index: 0;
  width: 100%;
  max-width: ${BREAKPOINTS.xl}px;
`;

const background = bgColor => css`
  ::before {
    content: '';
    left: 0;
    right: 0;
    height: 100%;
    background-color: ${bgColor};
    position: absolute;
    bottom: 0;
    padding: 0;
    // This is to negate the padding to achieve full width without overflow
    margin: 0 0 0 -8px;
  }
`;

export const blueBg = background(blue5);
export const greyBg = background(gray5);
export const yellowBg = background(yellow5);

export const hide = hideOnMobile => css`
  ${media.md} {
    display: ${hideOnMobile ? 'none' : 'inherit'};
  }
`;
