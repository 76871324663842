import { RESERVATION_REQUEST_API } from '@fire/constants';

export const RESERVATION_REQUEST_CAMPSITE_ENDPOINT = `${RESERVATION_REQUEST_API}campsite`;
export const RESERVATION_REQUEST_GUEST_ENDPOINT = `${RESERVATION_REQUEST_API}guest`;

export const ENDPOINT_CAMPSITE_DETAILS = `${RESERVATION_REQUEST_CAMPSITE_ENDPOINT}/show-detail`;
export const ENDPOINT_CAMPSITE_ACCEPT = `${RESERVATION_REQUEST_CAMPSITE_ENDPOINT}/accept`;
export const ENDPOINT_CAMPSITE_REFUSE = `${RESERVATION_REQUEST_CAMPSITE_ENDPOINT}/refuse`;
export const ENDPOINT_GUEST_ACCEPT = `${RESERVATION_REQUEST_GUEST_ENDPOINT}/accept`;
export const ENDPOINT_GUEST_RESOLVE = `${RESERVATION_REQUEST_GUEST_ENDPOINT}/resolve`;

export const URL_CAMPSITE_ACCEPTED = '/reservation-request/campsite/accepted';
export const URL_CAMPSITE_REFUSED = '/reservation-request/campsite/refused';
