import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { AvailabilityStateContext } from '@contexts/AvailabilityState';

import { PREFIX } from './constants';
import * as ß from './styles';

const Header = ({ isBookingBox }) => {
  const { t } = useTranslation(PREFIX);
  const availabilityState = useContext(AvailabilityStateContext);
  const isEditing = availabilityState?.isEditState;
  const title =
    isBookingBox || !isEditing
      ? t('Reisedetails')
      : t('Please enter travel dates');

  return (
    <header css={ß.header}>
      <strong css={ß.title}>{title}</strong>
    </header>
  );
};

Header.propTypes = {
  isBookingBox: PropTypes.bool,
};

Header.defaultProps = {
  isBookingBox: false,
};

export default Header;
