import React from 'react';
import {
  Accordion as ReactAccordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import PropTypes from 'prop-types';
import { ifElse, is, map, pipe, propOr } from 'ramda';

import { renderFooterList } from '@components/utils';
import { HTMLText } from '@utils/HTMLText';

import * as ß from './styles';

const mkdown = x => <HTMLText key={x} source={x} />;

export const renderWithMarkdown = pipe(
  propOr([], 'items'),
  map(([text]) => ifElse(is(Array), map(mkdown), mkdown)(text))
);

const Accordion = ({ data = [], hasMarkdown, accordionId }) => (
  <ReactAccordion allowMultipleExpanded allowZeroExpanded>
    {data &&
      data.map((list, index) => (
        <AccordionItem css={ß.item} key={`${list.title}-${index}`}>
          <AccordionItemHeading data-accordion-id={accordionId}>
            <AccordionItemButton css={[ß.button, ß.title]}>
              {list.title}
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel key={`${list.title}-item-panel-${index}`}>
            {hasMarkdown ? (
              renderWithMarkdown(list)
            ) : (
              <ul css={ß.footerLinks}>{renderFooterList(list)}</ul>
            )}
          </AccordionItemPanel>
        </AccordionItem>
      ))}
  </ReactAccordion>
);

Accordion.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node,
      items: PropTypes.arrayOf(Array).isRequired,
    }).isRequired
  ).isRequired,
  hasMarkdown: PropTypes.bool,
  accordionId: PropTypes.string,
  trackingLabel: PropTypes.string,
};

Accordion.defaultProps = {
  hasMarkdown: false,
  trackingLabel: 'accordion',
};

export default Accordion;
