import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { fork } from 'fluture';
import { graphql, navigate } from 'gatsby';
import queryString from 'query-string';
import { isEmpty, merge, pathOr, pipe, propOr } from 'ramda';

import { Footer } from '@components/Footer';
import Header from '@components/HeaderContainer';
import LinkedResources from '@components/LinkedResources';
import SocialMedia, { HrefLang } from '@components/Meta';
import ReviewForm from '@components/ReviewForm';
import SEO from '@components/seo';
import MaybeCampsite from '@contexts/MaybeCampsite';
import MaybeData from '@contexts/MaybeData';
import { TILES_API } from '@fire/constants';
import { GlobalStyles } from '@fire/globalStyles';
import withI18next from '@fire/i18n/withI18next';
import { makeRequest } from '@utils/http';
import { getReviewLandingPageUrl } from '@utils/platform';
import { PageView } from '@utils/tracking';

export const fetchCSP = prn =>
  makeRequest({
    url: `${TILES_API}?prn=${prn}`,
  });

const ReviewPage = props => {
  // rehydration ceremony: do stuff when window isn't present
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);

  const pageContext = merge(
    propOr({}, 'pageContext', props),
    pathOr({}, ['data', 'currentPage', 'context'], props)
  );
  const [campsiteData, setCampsiteData] = useState();
  const parsedUri = hasMounted && queryString.parse(location.search);
  const prn = hasMounted && propOr('', 'prn', parsedUri);
  const validValue = hasMounted && propOr('', 'valid', parsedUri);
  const duplicateEntry = hasMounted && propOr('', 'duplicate', parsedUri);

  // redirect to review landing page if no PRN
  useEffect(() => {
    if (hasMounted && isEmpty(prn)) {
      navigate(getReviewLandingPageUrl(pageContext));
    }
  }, [hasMounted, pageContext, prn]);

  // fetch campsite data ;)
  useEffect(() => {
    if (hasMounted && prn) {
      // TODO: handle error with 404/502 page redirect
      fork(console.error)(
        pipe(pathOr({}, ['data', 'tiles', 0]), setCampsiteData)
      )(fetchCSP(prn));
    }
  }, [hasMounted, prn]);

  if (campsiteData) {
    return (
      <MaybeCampsite.Provider value={campsiteData}>
        <MaybeData.Provider value={pageContext}>
          <SEO pageContext={pageContext} />
          <HrefLang />
          <LinkedResources pageContext={pageContext} />
          <GlobalStyles />
          <PageView />
          <Helmet>
            <meta name="robots" content="noindex, nofollow" />
            <title>{pageContext.title}</title>
          </Helmet>
          <SocialMedia pageContext={pageContext} />
          <Header minimal hideReview hideCookieBanner />
          <ReviewForm validValue={validValue} duplicateEntry={duplicateEntry} />
          <Footer pageContext={pageContext} />
        </MaybeData.Provider>
      </MaybeCampsite.Provider>
    );
  }
  return <></>;
};

export const query = graphql`
  query ReviewPageData($prn: String!) {
    currentPage: campfirePage(context: { prn: { eq: $prn } }) {
      path
      component
      context {
        pageType
        graphCMSLocale
        headline
        titleDropdownConditions
        dropdownConditions
        titleDropdownRaffle
        dropdownRaffle

        breadcrumbs
        entity
        metaDescription
        metaRobots
        name
        h1
        seoText
        siteUrl
        title
        prn
        partners {
          clickoutUrl
          id
          logo {
            altText
            alt_text
            url
            title
            campsite
            copyright
            fileName
            handle
            isMain
            name
            position
          }
          trackingId
        }
      }
    }
    locize: allLocale(
      filter: {
        namespace: {
          in: [
            "pt-hero-menu"
            "pt-footer"
            "pt-premium-partners-footer"
            "pt-language-selector"
            "pt-review-banner"
            "pt-cookie-banner"
            "pt-review-page"
            "pt-search-box"
            "pt-suggestion-list"
            "pt-signup-newsletter"
          ]
        }
      }
    ) {
      edges {
        node {
          data
          id
          language
          namespace
        }
      }
    }
  }
`;

export default withI18next()(ReviewPage);
