import React from 'react';
import PropTypes from 'prop-types';

import { CampsiteDataType } from '@types';

import * as ß from './styles';

const ReviewFormHeader = ({ campsiteData, headline }) => {
  const { name: campsiteName } = campsiteData;
  return (
    <div css={ß.headerContainer}>
      <div css={ß.header}>
        <h1 css={ß.headerTitle}>
          {headline} <br />
          {campsiteName}
        </h1>
      </div>
    </div>
  );
};

ReviewFormHeader.propTypes = {
  campsiteData: CampsiteDataType,
  headline: PropTypes.string,
};

export default ReviewFormHeader;
