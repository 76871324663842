import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { curry, propOr } from 'ramda';

import Link from '@components/Link';
import { T } from '@fire/i18n/i18n-constants';
import { slugify } from '@utils/string';
import { trackObject } from '@utils/tracking';

import { link } from './styles';

export const initOnClick = curry(
  (track, { tile, parentTitle: label }) =>
    () =>
      track({
        event: 'navigation-portal_click-navigation',
        category: 'navigation-portal',
        action: `click blue10 navigation: ${propOr(
          'HeroMenuLink',
          'title',
          tile
        )} - as link`,
        label,
      })
);

const onClickable = initOnClick(trackObject);

const HeroMenuLink = ({ tile, parentTitle }) => {
  const prefix = propOr('', 'HeroMenu', T);
  const { t } = useTranslation();
  return (
    <Link to={tile.url} onClick={onClickable({ tile, parentTitle })} css={link}>
      {t(`${prefix}:links.${slugify(tile.title)}`, tile.title)}
    </Link>
  );
};

HeroMenuLink.propTypes = {
  tile: PropTypes.shape({
    url: PropTypes.string,
    title: PropTypes.string,
  }),
  parentTitle: PropTypes.string,
};

export default HeroMenuLink;
