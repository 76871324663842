import { equals, omit } from 'ramda';

import { ACCOMMODATION_PARAMS } from '@fire/constants';

export default function useHasActiveFilters({
  contextParams = {},
  params = {},
}) {
  const paramsWithNoBookingStuffAndPage = omit(
    [...ACCOMMODATION_PARAMS, 'page'],
    params
  );

  return !equals(paramsWithNoBookingStuffAndPage, contextParams);
}
