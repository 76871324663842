import { css } from '@emotion/react';
import { whiteHalfTransparent } from '@fire/_color';

export const wrapper = css`
  align-items: center;
  background: ${whiteHalfTransparent};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 38px;
  justify-content: center;
  position: absolute;
  right: 6px;
  top: 5px;
  width: 38px;
  z-index: 10;
  img {
    margin-top: 3px;
    margin-left: 1px;
  }
`;

export const innerWrapper = css`
  position: relative;
`;
