import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { contains, pathOr, when } from 'ramda';

import Link from '@components/Link';
import { GATSBY_ENTRYPOINT } from '@fire/constants';
import { T } from '@fire/i18n/i18n-constants';
import { slugify } from '@utils/string';
import { trackObject } from '@utils/tracking';
import { isAbsoluteUrl } from '@utils/url';

import { item, listItem } from './styles';

export const NavigationItem = ({
  title,
  id,
  changeActiveItem,
  active,
  linkout,
  url,
}) => {
  const prefix = pathOr('', ['HeroMenu'], T);
  const { t } = useTranslation();
  const handleClick = event => {
    if (!linkout) {
      event.preventDefault();
    }
    changeActiveItem(id);
    trackObject({
      event: 'navigation-portal_click-open-navigation-dropdown',
      category: 'navigation-portal',
      action: 'click open navigation dropdown',
      label: title,
    });
    when(contains(`${GATSBY_ENTRYPOINT}/magazin`), () => {
      window.location.href = '/magazin';
    })(url);
  };

  return (
    <li css={listItem}>
      {/* TODO: change to param back to url for all cases when A-Z pages exist */}
      <Link
        css={item(active)}
        to={url}
        onClick={handleClick}
        isExternalUrl={isAbsoluteUrl(url)}
        openInNewWindow={linkout}
      >
        {t(`${prefix}:header-links.${slugify(title)}`, title)}
      </Link>
    </li>
  );
};

NavigationItem.propTypes = {
  active: PropTypes.bool,
  title: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  changeActiveItem: PropTypes.func.isRequired,
  linkout: PropTypes.bool,
  url: PropTypes.string,
};

NavigationItem.defaultProps = {
  active: false,
};

export default NavigationItem;
