import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import { DEFAULT_KIDS_AGES } from '@fire/constants';
import useEventListener from '@hooks/useEventListener';

import * as ß from '../styles';
import GuestPickerButtons from './Buttons';
import GuestPickerForm from './Form';

const GuestPicker = ({
  hasDefaultValues,
  isMobile,
  kidsAges,
  mobileModal,
  prefix,
  resetGuests,
  saveBookingData,
  setKidsAges,
  setShowGuestPicker,
  setTotalAdults,
  totalAdults,
}) => {
  const containerRef = useRef();
  const handleOutsideClick = useCallback(
    event =>
      containerRef.current &&
      !containerRef.current.contains(event.target) &&
      !isMobile &&
      setShowGuestPicker(false),
    [containerRef, setShowGuestPicker, isMobile]
  );

  const handleSubmit = () => {
    setShowGuestPicker(false);
    saveBookingData?.();
  };

  useEventListener('mousedown', handleOutsideClick);

  return (
    <div
      css={ß.guestContainer}
      ref={containerRef}
      className="guestPickerContainer"
    >
      <div>
        {mobileModal && (
          <span css={ß.close} onClick={() => setShowGuestPicker(false)}>
            &times;
          </span>
        )}
        <GuestPickerForm
          hasDefaultValues={hasDefaultValues}
          kidsAges={kidsAges}
          prefix={prefix}
          setKidsAges={setKidsAges}
          setTotalAdults={setTotalAdults}
          totalAdults={totalAdults}
        />
        <div css={mobileModal ? ß.bottomBox : ß.actionBox}>
          <GuestPickerButtons
            kidsAges={kidsAges}
            prefix={prefix}
            resetGuests={resetGuests}
            onClick={handleSubmit}
            totalAdults={totalAdults}
          />
        </div>
      </div>
    </div>
  );
};

GuestPicker.defaultProps = {
  hasDefaultValues: true,
  isDisabled: false,
  kidsAges: DEFAULT_KIDS_AGES,
  mobileModal: false,
};

GuestPicker.propTypes = {
  hasDefaultValues: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isMobile: PropTypes.bool,
  kidsAges: PropTypes.array,
  prefix: PropTypes.string,
  resetGuests: PropTypes.func,
  saveBookingData: PropTypes.func,
  setKidsAges: PropTypes.func,
  setShowGuestPicker: PropTypes.func,
  setTotalAdults: PropTypes.func,
  totalAdults: PropTypes.number,
  mobileModal: PropTypes.bool,
};

export default GuestPicker;
