import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withPrefix } from 'gatsby';

import SearchSuggestionsContext from '@contexts/SearchSuggestions';

import { NAMESPACE } from './constants';
import * as ß from './styles';

const search = withPrefix('/icons/search.svg');
const close = withPrefix('/icons/close.svg');

const SearchBarElement = ({
  autofocus,
  handleChange,
  handleClose,
  handleFocus,
  isMobile,
}) => {
  const { t } = useTranslation(NAMESPACE);
  const searchSuggestionContext = useContext(SearchSuggestionsContext);
  const {
    inputRefDesktop,
    inputRefMobile,
    searchTerm,
    setSearchTerm,
    setSelectedSuggestion,
  } = searchSuggestionContext;

  const inputRef = isMobile ? inputRefMobile : inputRefDesktop;
  const inputId = `search-input${isMobile ? '-mobile' : ''}`;
  const onChange = event => {
    const term = event?.target?.value ?? '';
    setSearchTerm(term);
    setSelectedSuggestion({});
    handleChange(term);
  };

  return (
    <div css={ß.searchInputWrapper}>
      <img
        alt={t('Suche')}
        css={ß.searchIcon}
        height={25}
        scale={1}
        src={search}
        width={25}
      />
      <input
        autoFocus={autofocus}
        css={ß.searchInput}
        id={inputId}
        onChange={onChange}
        onFocus={handleFocus}
        placeholder={t('Reiseziel, Campingplatz, ADAC Suchnummer')}
        ref={inputRef}
        type="text"
        value={searchTerm}
        autoComplete="off"
      />
      {searchTerm && (
        <img
          css={ß.closeIcon}
          height={20}
          id="suggestionCloseButton"
          onClick={handleClose}
          scale={1}
          src={close}
          width={20}
        />
      )}
    </div>
  );
};

SearchBarElement.propTypes = {
  autofocus: PropTypes.bool,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleClose: PropTypes.func,
  handleFocus: PropTypes.func,
  isMobile: PropTypes.bool,
};

SearchBarElement.defaultProps = {
  autofocus: false,
  isMobile: false,
};

export default SearchBarElement;
