import React from 'react';
import PropTypes from 'prop-types';
// import { hydrateDataModel } from '../../utils/gatsby';
import {
  always,
  ap,
  apply,
  complement,
  cond,
  curry,
  equals,
  F,
  filter,
  identity as I,
  isEmpty,
  map,
  pick,
  pipe,
  propOr,
  propSatisfies,
  reduce,
  T,
  toPairs,
  when,
} from 'ramda';

// import camelCase from 'camel-case';
import { HTMLText } from '@utils/HTMLText';

const isObject = x => x && typeof x === 'object';
const isArray = Array.isArray;
const isNotArray = complement(isArray);
const merge = curry((a, b) => Object.assign({}, a, b));

const hydrateDataModel = curry((model, item) =>
  pipe(
    toPairs,
    reduce((acc, [k, v]) => {
      const ii = item[k];
      return merge(
        acc,
        isObject(v) && isNotArray(v) && ii && isObject(ii)
          ? { [k]: hydrateDataModel(v, ii) }
          : { [k]: ii || v }
      );
    }, {})
  )(model)
);

import {
  TOP_DESTINATIONS,
  TOP_REGIONS,
  TOP_TOPICS,
  TOP_TOURING_AREAS,
} from './constants';

export const getLocizedMetas = curry(
  (
    t,
    count,
    { namespace: ns, title, metaDescription, name, geoType, type }
  ) => {
    const graphCMSTitleDescription = [title, metaDescription];

    if (
      title &&
      !isEmpty(title) &&
      metaDescription &&
      !isEmpty(metaDescription)
    ) {
      return graphCMSTitleDescription;
    }

    return cond([
      [
        equals('country'),
        always([
          t(`${ns}:title.country`, { name }),
          t(`${ns}:description.country`, { count, name }),
        ]),
      ],
      [
        equals('region'),
        always([
          t(`${ns}:title.region`, { name }),
          t(`${ns}:description.region`, { name }),
        ]),
      ],
      [
        equals('shape'),
        always([
          t(`${ns}:title.shape`, { name }),
          t(`${ns}:description.shape`, { count, name }),
        ]),
      ],
      [
        equals('destination'),
        always([
          t(`${ns}:title.destination`, { name }),
          t(`${ns}:description.destination`, { name }),
        ]),
      ],
      [T, always([title, metaDescription])],
    ])(geoType || type);
  }
);

export const getMetaTemplate = () => {};

const Interlinking = p => <>{JSON.stringify(p, undefined, 2)}</>;

const renderTopSectionWithConfig = curry((t, namespace, children, title) =>
  pipe(g =>
    g && g.length > 0 ? (
      <Interlinking title={t(namespace + ':' + title)} group={g} />
    ) : (
      <></>
    )
  )(children)
);
const topicHasChildren = curry((topic, x) =>
  pipe(
    propOr([], topic),
    propSatisfies(l => l > 0, 'length')
  )(x)
);

const nullSoak = filter(I);
const callRender = curry((render, topic, x) =>
  pipe(
    propOr([], topic),
    nullSoak,
    map(pick(['images', 'title'])),
    apply(render)
  )(x)
);

const generateTopSection = curry((render, x, topic) =>
  when(topicHasChildren(topic), callRender(render, topic), x)
);

const jeex = kids => <>{kids}</>;

const mooshmash = ap(map(when(isEmpty, F), filter(I)));

export const getInterLinkContent = ({ namespace, interlinking: ii }) => {
  const t = () => namespace + ':figure out how to jam i18n into here';
  const renderTopSection = renderTopSectionWithConfig(t, namespace);
  const gts = generateTopSection(renderTopSection, ii);
  const [destinations, regions, topics, touristicRegions] = map(gts)([
    'interlinkingTopDestinations',
    'interlinkingTopRegions',
    'interlinkingTopTopics',
    'interlinkingTopTouristicRegions',
  ]);
  const links = mooshmash([destinations, regions, topics, touristicRegions]);
  return jeex(links);
};
getInterLinkContent.propTypes = {
  namespace: PropTypes.string,
  interlinking: PropTypes.object,
};

export const hydrateItems = curry((model, x) =>
  pipe(filter(I), map(hydrateDataModel(model)))(x)
);

export const buildInterlinks = curry((t, seed, namespace) =>
  pipe(
    toPairs,
    reduce((acc, [k, v]) => {
      const children = acc[k] ? hydrateItems(acc[k]) : [];
      const title = t(namespace + ':' + v);
      return { ...acc, ...{ [k]: { children, title } } };
    }, seed)
  )({
    [TOP_DESTINATIONS]: 'Populäre Orte',
    [TOP_REGIONS]: 'Populäre Regionen',
    [TOP_TOPICS]: 'Populäre Themen',
    [TOP_TOURING_AREAS]: 'Populäre Urlaubsregionen',
  })
);

const CONTENT = [
  'camping',
  'journey',
  'topDestinations',
  'gastronomy',
  'weather',
  'topPointsOfInterest',
  'topTips',
  'activities',
  'events',
  'campingTypes',
  'targetGroups',
];
export const renderHTMLByKeys = curry((ks, pageContext) =>
  pipe(
    map(x => {
      const c = propOr(false, x, pageContext);
      return !c ? <React.Fragment key={x} /> : <HTMLText key={x} source={c} />;
    }),
    filter(I)
  )(ks)
);

export const getIntro = renderHTMLByKeys(['intro']);
export const getContent = renderHTMLByKeys(CONTENT);
