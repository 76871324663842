import { media } from '@fire/_mediaQueries';
import { GATSBY_ENTRYPOINT, IMGIX_URL } from '@fire/constants';

const defaultAspectRatio = 1.3333;

export const campsiteImageUrl = (campsitePrn, imageName) =>
  `${GATSBY_ENTRYPOINT}/campsites/${campsitePrn}/images/${imageName}`;

const makeImgQueryString = (aspectRatio = '3:2', width = 1200) =>
  `?crop=entropy&w=${width}&fit=max&auto=compress,format&ar=${aspectRatio}&q=30`;

const getSrcSet = (aspectRatio, maxWidth, image) =>
  [
    `${image}${makeImgQueryString(aspectRatio, 0.4 * maxWidth)} 200w`,
    `${image}${makeImgQueryString(aspectRatio, 0.6 * maxWidth)} 400w`,
    `${image}${makeImgQueryString(aspectRatio, 0.8 * maxWidth)} 800w`,
    `${image}${makeImgQueryString(aspectRatio, maxWidth)} 1023w`,
  ].join(', ');

export const getFluidSrcSet = (
  imageUrl,
  aspectRatio = defaultAspectRatio,
  maxWidth = 1200
) => {
  const queryString = makeImgQueryString(undefined, 800);
  const query = imageUrl.includes('?')
    ? `&${queryString.slice(1)}`
    : `${queryString}`;

  return {
    aspectRatio,
    sizes: `${media.xs}, ${media.md}, ${media.lg}`,
    src: `${imageUrl}${query}`,
    srcSet: getSrcSet(aspectRatio, maxWidth, imageUrl),
  };
};

const getFakeSrcSet = image =>
  [`${image} 200w`, `${image} 400w`, `${image} 800w`, `${image} 1023w`].join(
    ', '
  );

export const getFixedSrcSet = (imageUrl, disableQueryString = false) => {
  const queryString = makeImgQueryString(undefined, 300);
  const query = imageUrl.includes('?')
    ? `&${queryString.slice(1)}`
    : `${queryString}`;

  return {
    aspectRatio: defaultAspectRatio,
    sizes: `${media.xs}, ${media.md}, ${media.lg}`,
    src: `${imageUrl}${disableQueryString ? '' : query}`,
    srcSet: getFakeSrcSet(imageUrl),
  };
};

export const getPlaceholderImage = (width = 1600) =>
  `${IMGIX_URL}/placeholder-image.jpg?handle=KXy7SucqTjGP8gSNZEPR&w=${width}&fit=max&auto=compress,format`;
