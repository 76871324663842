import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Button from '@components/Button';
import { useFilters } from '@contexts/Filters';
import { useProsciuttoState } from '@hooks/useProsciutto';

import GeneralFilters from './GeneralFilters';
import RatingFilters from './RatingFilters';
import * as ß from './styles';
import { toggleBodyOverflowStyle } from './utils';

const FilterModal = ({
  handleApplyFilters,
  handleCancel,
  isGeneralModalOpen,
  isRatingsModalOpen,
  namespace,
}) => {
  const prosciuttoState = useProsciuttoState();
  const [, dispatchContext] = useFilters();

  useEffect(() => {
    toggleBodyOverflowStyle(true);

    return () => toggleBodyOverflowStyle(false);
  }, []);

  useEffect(() => {
    dispatchContext({ type: 'setFilters', payload: prosciuttoState.params });

    return () => {
      dispatchContext({ type: 'clearFilters' });
    };
  }, [dispatchContext, prosciuttoState.params]);

  const { t } = useTranslation(namespace);

  return (
    <>
      <div css={ß.filterModal}>
        <div
          css={ß.filterModalOverlay}
          id="filterModalOverlay"
          onClick={handleCancel}
        />
        <div css={ß.filterModalContent}>
          <div css={ß.mobileFilters}>
            <RatingFilters />
            <GeneralFilters />
          </div>
          <div css={ß.desktopFilters}>
            {isRatingsModalOpen && <RatingFilters />}
            {isGeneralModalOpen && <GeneralFilters />}
          </div>
        </div>
        <div css={ß.filterModalFooter}>
          <span css={ß.textActingLikeAButton} onClick={handleCancel}>
            {t('Abbrechen')}
          </span>
          <Button variant="primary" size="large" onClick={handleApplyFilters}>
            {t('Filter anwenden')}
          </Button>
        </div>
      </div>
    </>
  );
};

FilterModal.propTypes = {
  handleApplyFilters: PropTypes.func,
  handleCancel: PropTypes.func,
  isGeneralModalOpen: PropTypes.bool,
  isRatingsModalOpen: PropTypes.bool,
  namespace: PropTypes.string,
};

FilterModal.defaultProps = {
  namespace: 'pt-search-result-filter',
};

export default FilterModal;
