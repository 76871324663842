import React from 'react';
import PropTypes from 'prop-types';

import Link from '@components/Link';
import { trackObject } from '@utils/tracking';

import MenuMap from '../MenuMap';
import { bigTile, headline } from './styles';

const HeroMenuBigTile = ({
  activeChild,
  image,
  title,
  url,
  description,
  parentTitle,
}) => (
  <div>
    <p css={headline}>{title}</p>
    {activeChild === 0 ? (
      <MenuMap />
    ) : (
      <Link
        to={url}
        css={bigTile(image)}
        id="MenuMap"
        onClick={() => {
          trackObject({
            event: 'navigation-portal_click-navigation',
            category: 'navigation-portal',
            action: `click navigation: ${description}`,
            label: parentTitle,
          });
        }}
      >
        {description}
      </Link>
    )}
  </div>
);

HeroMenuBigTile.propTypes = {
  activeChild: PropTypes.number.isRequired,
  parentTitle: PropTypes.string.isRequired,
  image: PropTypes.shape({
    name: PropTypes.string.isRequired,
    handle: PropTypes.string.isRequired,
  }),
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default HeroMenuBigTile;
