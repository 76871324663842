import { css } from '@emotion/react';
import { white } from '@fire/_color';
import { bold } from '@fire/_fonts';

export const container = css`
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 1000;
`;

export const menu = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 80vw;
  height: 100%;
  background-color: ${white};
`;

export const menuItem = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 50px;
  border-bottom: 1px solid #dfe3e6;
  background-color: #fff;
  font-size: 16px;

  color: #484848;
  padding: 16px 21px 16px 28px;
  cursor: pointer;
  strong {
    margin-left: 4px;
    text-decoration: underline;
  }

  &:hover {
    background-color: #f1f1f1;
  }
`;

export const menuHeader = css`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 50px;
  border-bottom: 1px solid #dfe3e6;
  background-color: #fff;
  font-size: 20px;
  font-weight: ${bold};
  color: #484848;
  text-transform: uppercase;
  cursor: auto;
  padding-left: 32px;
  strong {
    margin-left: 4px;
  }
`;

export const arrowDown = css`
  height: 16px;
  width: 16px;
  cursor: pointer;
  margin: 0;
  transform: rotate(-90deg);
`;

export const arrowLeft = css`
  height: 16px;
  width: 16px;
  cursor: pointer;
  margin: 8px;
  margin-left: -24px;
  transform: rotate(90deg);
`;

export const hidden = css`
  display: none;
`;
