import React, { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Link from '@components/Link';
import StarRating from '@components/StarRating';
import { getClassificationProps } from '@components/utils';
import MaybeData from '@contexts/MaybeData';
import { T } from '@fire/i18n/i18n-constants';
import useTracking from '@hooks/useTracking';
import { isSwiss } from '@utils/platform';

import * as ß from './styles';
import { partnerClickout } from './tracking';

const namespace = T?.SearchResultFilter ?? '';

const HeroImageContent = ({ name, region, rating, url, prn, tcsRating }) => {
  const {
    prepareToTrackEvent: { clickEvent },
  } = useTracking('hero image');
  const pageContext = useContext(MaybeData);
  const { t } = useTranslation(namespace);
  const showTcsRating = isSwiss(pageContext) && tcsRating > 0;
  const getClassificationPropsWithTranslation = getClassificationProps({
    rating,
    tcsRating,
    showTcsRating,
  });

  const trackClickHeroImage = clickEvent('hero image clicked');

  const handleClick = () => {
    trackClickHeroImage();
    partnerClickout({ prn });
  };

  return (
    <Link to={url} openInNewWindow css={ß.wrapper} onClick={handleClick}>
      <p css={ß.region}>{region}</p>
      <h5 css={ß.title}>{name}</h5>
      <div css={ß.stars}>
        <StarRating {...getClassificationPropsWithTranslation(t)} />
      </div>
    </Link>
  );
};

HeroImageContent.defaultProps = {
  url: '',
};

HeroImageContent.propTypes = {
  name: PropTypes.string,
  region: PropTypes.string,
  rating: PropTypes.string,
  tcsRating: PropTypes.number,
  url: PropTypes.string,
  prn: PropTypes.string,
};

export default memo(HeroImageContent);
