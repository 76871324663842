import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withPrefix } from 'gatsby';

import {
  checkAvailabilities,
  useFlexibleDates,
} from '@components/AccommodationList/utils';
import Button from '@components/Button';
import DatePicker from '@components/DatePicker';
import TravelDates from '@components/TravelDates';
import CampsitePageContext from '@contexts/CampsitePage';
import DatePickerContext from '@contexts/DatePicker';
import MaybeData from '@contexts/MaybeData';
import ADACLogo from '@fire/assets/powered-by-ADAC.svg';
import {
  DATE_FORMAT_ISO8601,
  DEFAULT_KIDS_AGES,
  DEFAULT_TOTAL_ADULTS,
} from '@fire/constants';
import useAvailabilitiesApi from '@hooks/useAvailabilitiesApi/useAvailabilitiesApi';
import useHasMounted from '@hooks/useHasMounted';
import useTracking from '@hooks/useTracking';
import { MomentType } from '@types';
import { getAvailabilityLink, saveBookingData } from '@utils/availability';
import { numberOfNights } from '@utils/dateTime';
import { everyTruthy } from '@utils/generic';
import { isADACByTarget } from '@utils/platform';
import { scrollToElemById } from '@utils/scroll';
import { objectToQuery } from '@utils/url';

import { INFO_ICON, PREFIX, TILE_PREFIX } from './constants';
import FilterBar from './FilterBar';
import GuestForm from './GuestForm';
import GuestPicker from './GuestPicker';
import Message from './Message';
import * as ß from './styles';
import { hasEdited, hasSearchChanged, resetGuests } from './utils';

const CALENDAR_ICON = withPrefix('icons/booking-box/calendar.svg');

export const AvailabilityBox = ({
  dateFrom,
  dateTo,
  focusOnLoad,
  hideBookingModal,
  isEditState,
  isMobile,
  kidsAges,
  setDateFrom,
  setDateTo,
  setIsEditState,
  setKidsAges,
  setShowGuestPicker,
  setTotalAdults,
  showAvailabilityFooter,
  showGuestPicker,
  submitText,
  totalAdults,
}) => {
  const { availabilities, fallbackSearchPath, getSearchFallbackPath } =
    useContext(CampsitePageContext);
  const datePickerContext = useContext(DatePickerContext);
  const {
    bookable,
    pagePath,
    entity: prn,
    related: { latitude, longitude } = {},
  } = useContext(MaybeData);
  const isAdac = isADACByTarget();

  const [isLoading, setIsLoading] = useState(false);

  const {
    prepareToTrackEvent: { clickEvent },
  } = useTracking('booking box');
  const { hasFlexibleDates } = useFlexibleDates();
  const hasMounted = useHasMounted();
  const { t } = useTranslation(PREFIX);
  const { fetch, hasList, isLoading: dataLoading } = useAvailabilitiesApi();

  const bookingData = {
    dateFrom: dateFrom?.format(DATE_FORMAT_ISO8601),
    dateTo: dateTo?.format(DATE_FORMAT_ISO8601),
    kidsAges,
    totalAdults,
  };
  const hasDatesSelected = everyTruthy([dateFrom, dateTo]);
  const hasChangedParams = hasMounted && hasSearchChanged(bookingData);

  const totalChildren = kidsAges?.length || 0;
  const totalGuests = totalChildren + totalAdults;

  const hasAvailabilities = checkAvailabilities(availabilities || {});

  const shouldOpenDatePicker = !hasDatesSelected || !hasChangedParams;
  const bookableWithoutAvailabilities = bookable && hasChangedParams && hasList;

  const getSearchPath = () => {
    if (hasChangedParams) {
      return getSearchFallbackPath(latitude, longitude, bookingData, prn);
    }

    return fallbackSearchPath;
  };

  const availabilityLink =
    !bookable || bookableWithoutAvailabilities
      ? getSearchPath()
      : getAvailabilityLink({
          dateFrom,
          dateTo,
          kidsAges,
          hasHash: true,
          pagePath,
          totalAdults,
        });

  const submitBookingData = event => {
    clickEvent('click show offer')();

    if ((!bookable && hasDatesSelected) || bookableWithoutAvailabilities) {
      setIsLoading(true);
    }

    if (bookable && hasAvailabilities && !hasChangedParams) {
      event?.preventDefault?.();
      scrollToElemById('accommodations');

      return;
    }

    if (
      (!bookable && !hasDatesSelected) ||
      (bookable &&
        shouldOpenDatePicker &&
        !hasChangedParams &&
        !hasAvailabilities)
    ) {
      event?.preventDefault?.();
      datePickerContext?.openDatePicker?.();
      setIsEditState(true);

      return;
    }

    if (bookable && !bookableWithoutAvailabilities) {
      scrollToElemById('accommodations');
      everyTruthy([dateFrom, dateTo]) && setIsEditState(false);
    }

    datePickerContext?.closeDatePicker?.();
    saveBookingData(bookingData);
  };

  const getSubmitText = () =>
    hasChangedParams || hasAvailabilities ? t('see-offers') : submitText;

  useLayoutEffect(() => {
    // edit state means show calendar, we show when there's no dates and availabilities
    const showEditState = !everyTruthy([hasDatesSelected, hasAvailabilities]);

    setIsEditState(showEditState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    // hasEdited has to be true, meaning it already edited
    // but setIsEditState waits for false to exit edit phase (no calendar shown)
    const isEditable = hasEdited() && !hasFlexibleDates;

    isEditable && setIsEditState(isEditable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsEditState(!hasFlexibleDates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFlexibleDates]);

  useEffect(() => {
    // non-editable state for non-bookale when dates are provided
    if (!bookable && hasDatesSelected && hasEdited && !hasChangedParams) {
      setIsEditState(false);
    }
  }, [hasChangedParams, hasDatesSelected, bookable, setIsEditState]);

  useEffect(() => {
    setIsLoading(dataLoading);
  }, [dataLoading]);

  const handleSaveBookingData = () => {
    // fire availabilities api if bookable CS has no availabilities for selected date

    if (bookable) {
      const query = objectToQuery({
        date_from: dateFrom.format(DATE_FORMAT_ISO8601),
        date_to: dateTo.format(DATE_FORMAT_ISO8601),
        children: kidsAges,
        adults: totalAdults,
        edited: true,
      });

      fetch(prn, query);
    }
  };

  return (
    <form css={[ß.form, ß.visibleOn(isMobile)]}>
      <div css={ß.content}>
        {!isEditState && (
          <TravelDates
            dateFrom={dateFrom}
            dateTo={dateTo}
            totalGuests={totalGuests}
            onClick={() => setIsEditState(true)}
          />
        )}
        <Message isEditState={isEditState} availabilities={availabilities} />
        {isEditState && (
          <>
            <div css={[ß.datePickerRow, ß.formRow]}>
              <div css={ß.datePicker}>
                <div css={ß.calendarIconWrapper}>
                  <img
                    src={CALENDAR_ICON}
                    alt="Calendar"
                    width={24}
                    height={24}
                  />
                </div>
                <div css={ß.labels}>
                  <label>{t('Anreise')}</label>
                  <label>{t('Abreise')}</label>
                </div>
                <DatePicker
                  dateFrom={dateFrom}
                  dateTo={dateTo}
                  focusOnLoad={focusOnLoad}
                  hideBookingModal={hideBookingModal}
                  isMobile={isMobile}
                  setDateFrom={setDateFrom}
                  setDateTo={setDateTo}
                  saveBookingData={handleSaveBookingData}
                />
              </div>
            </div>
            <GuestForm
              kidsAges={kidsAges}
              setShowGuestPicker={setShowGuestPicker}
              setTotalAdults={setTotalAdults}
              totalAdults={totalAdults}
            />
            {showGuestPicker && (
              <div css={ß.guestPickerWrapper}>
                <GuestPicker
                  isMobile={isMobile}
                  kidsAges={kidsAges}
                  mobileModal={isMobile}
                  prefix={PREFIX}
                  resetGuests={() => resetGuests(setTotalAdults, setKidsAges)}
                  setKidsAges={setKidsAges}
                  setShowGuestPicker={setShowGuestPicker}
                  setTotalAdults={setTotalAdults}
                  totalAdults={totalAdults}
                  saveBookingData={handleSaveBookingData}
                />
              </div>
            )}
          </>
        )}
        {(!isMobile || !showAvailabilityFooter) && (
          <>
            <div css={[ß.row, ß.formRow]}>
              {!bookable && hasDatesSelected && !isEditState && (
                <div css={ß.noAvailabilitiesInfo}>
                  <img height={25} scale={1} src={INFO_ICON} width={25} />
                  {t('no-bookable-info', { ns: TILE_PREFIX })}
                </div>
              )}
              <Button
                isFullWidth
                isLoading={isLoading}
                onClick={submitBookingData}
                size="large"
                to={availabilityLink}
                variant="primary"
              >
                {getSubmitText()}
              </Button>
            </div>
            {isAdac && (
              <div css={ß.logoWrapper}>
                <ADACLogo />
              </div>
            )}
          </>
        )}
      </div>
      {isMobile && showAvailabilityFooter && (
        <FilterBar
          bookingUrl={availabilityLink}
          dateFrom={dateFrom}
          dateTo={dateTo}
          handleNext={
            showGuestPicker ? () => setShowGuestPicker(false) : undefined
          }
          handleReset={() => resetGuests(setTotalAdults, setKidsAges)}
          hideBookingModal={hideBookingModal}
          isGuestPicker={showGuestPicker}
          isResetDisabled={kidsAges.length === 0 && totalAdults === 2}
          kidsAges={kidsAges}
          nights={numberOfNights(dateFrom, dateTo)}
          prefix={PREFIX}
          totalAdults={totalAdults}
        />
      )}
    </form>
  );
};

AvailabilityBox.propTypes = {
  dateFrom: MomentType,
  dateTo: MomentType,
  focusOnLoad: PropTypes.bool,
  hideBookingModal: PropTypes.func,
  isMobile: PropTypes.bool,
  kidsAges: PropTypes.arrayOf(PropTypes.number),
  setDateFrom: PropTypes.func,
  setDateTo: PropTypes.func,
  setKidsAges: PropTypes.func,
  setShowGuestPicker: PropTypes.func,
  setTotalAdults: PropTypes.func,
  shadow: PropTypes.bool,
  showAvailabilityFooter: PropTypes.bool,
  showGuestPicker: PropTypes.bool,
  showTitle: PropTypes.bool,
  submitText: PropTypes.string,
  totalAdults: PropTypes.number,
  trackClickBook: PropTypes.func,
  trackDateSearch: PropTypes.func,
  isEditState: PropTypes.bool,
  setIsEditState: PropTypes.func,
};

AvailabilityBox.defaultProps = {
  focusOnLoad: false,
  isMobile: false,
  kidsAges: DEFAULT_KIDS_AGES,
  shadow: false,
  showAvailabilityFooter: true,
  showTitle: false,
  submitText: '',
  totalAdults: DEFAULT_TOTAL_ADULTS,
  isEditState: true,
  setIsEditState: () => {},
};

export default AvailabilityBox;
