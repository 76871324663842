import { isEmptyOrNil } from '@fire/utils/generic';

import { DIMENSIONS_MAP } from '../consts';

// shameless copied from https://www.freecodecamp.org/news/30-seconds-of-code-rename-many-object-keys-in-javascript-268f279c7bfa/ :haha:
const dimensionsTranslator = obj => {
  if (isEmptyOrNil(obj)) return {};

  return Object.entries(obj).reduce(
    (acc, [key, value]) => ({
      ...acc,
      ...{ [DIMENSIONS_MAP[key] || key]: value },
    }),
    {}
  );
};

export default dimensionsTranslator;
