import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';

import Link from '@components/Link';
import Info from '@fire/assets/info.svg';
import { T } from '@fire/i18n/i18n-constants';

const namespace = pathOr('', ['Tile'], T);

import { trackObject } from '@fire/utils/tracking';

import * as ß from './styles';

export const InfoMessage = ({
  ctaLink,
  clickOutObject,
  isCustomer,
  message,
  name,
  path,
  prn,
  showMessage,
}) => {
  const { t } = useTranslation(namespace);
  const linkProps = {
    openInNewWindow: true,
    isExternalUrl: false,
    to: path,
  };

  useEffect(() => {
    showMessage &&
      trackObject({
        event: 'list_notification-campsite-not-bookable',
        category: 'list',
        action: 'notification campsite not bookable',
        label: prn,
      });
  }, [showMessage, prn]);

  const trackClickOut = () => {
    trackObject({ ...clickOutObject, label: 'notification not bookable' });
  };

  return showMessage ? (
    <div css={ß.container}>
      <div css={ß.wrapper}>
        <Info />
        <div css={ß.messageContainer}>
          <span css={ß.message}>
            <Link {...linkProps}>{name}</Link> {message}
          </span>
          {isCustomer && (
            <Link
              css={ß.homepage}
              isExternalUrl
              openInNewWindow
              to={ctaLink}
              onClick={trackClickOut}
            >
              {t('Campingplatz Homepage')}
            </Link>
          )}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

InfoMessage.propTypes = {
  ctaLink: PropTypes.string,
  clickOutObject: PropTypes.object,
  isCustomer: PropTypes.bool,
  message: PropTypes.string,
  name: PropTypes.string,
  path: PropTypes.string,
  prn: PropTypes.string,
  showMessage: PropTypes.bool,
};

export default InfoMessage;
