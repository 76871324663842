import { MIN_TILES } from './consts';

export const shouldShowFAQ = context => {
  const tilesLength = context?.initialState?.tiles?.length ?? 0;
  return tilesLength >= MIN_TILES;
};

export const i18nDefaults = {
  keySeparator: '>',
  nsSeparator: '|',
};

export const mapResources = (t, resources) => {
  if (resources && resources.length > 0) {
    const remapped = resources.map((resource, index) => [
      index + 1,
      t(resource, i18nDefaults),
    ]);
    return Object.fromEntries(remapped);
  }
  return {};
};

export const remappedTranslation = (t, obj) =>
  t(obj?.phrase, {
    ...i18nDefaults,
    ...mapResources(t, obj?.resources),
  });

export const getQAJson = t => questions =>
  JSON.stringify(
    questions.map(({ question, answer }) => ({
      '@type': 'Question',
      name: remappedTranslation(t, question),
      acceptedAnswer: {
        '@type': 'Answer',
        text: remappedTranslation(t, answer),
      },
    }))
  );
