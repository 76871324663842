import { css } from '@emotion/react';
import { skeletonGrey, white } from '@fire/_color';
import { hoverImage } from '@fire/_const';
import { bold } from '@fire/_fonts';
import { nonTouch } from '@fire/_mediaQueries';

const borderRadius = '4px';

export const squareImageWrapper = css`
  font-size: 34px;
  font-weight: ${bold};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${white};
  word-break: break-word;
  padding: 2px;
  text-decoration: none;
  order: 1;
  box-sizing: inherit;
  width: 216px;
  height: 216px;

  ${nonTouch} {
    ${hoverImage}
    &:hover {
      & > img {
        z-index: -1;
      }
    }
  }
`;

export const squareHeading = css`
  color: ${white};
  white-space: pre-line;
  z-index: 0;
`;

export const squareImage = css`
  position: absolute;
  border-radius: ${borderRadius};
  width: 100%;
  height: 100%;
  filter: brightness(0.7);
  top: 0;
  left: 0;
  background: ${skeletonGrey};
  font-size: 12px;
`;
