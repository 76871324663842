import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';

import Button from '@components/Button';
import StarRating from '@components/StarRating';
import { PageContextType } from '@types';
import { getReviewLandingPageUrl, isADAC } from '@utils/platform';

import * as ß from './styles';

const ReviewLink = ({ averageUserRating, title, buttonText, pageContext }) => (
  <>
    <h5 css={ß.title}>{title}</h5>
    <div css={ß.ratingWrapper}>
      <StarRating
        rating={
          isEmpty(averageUserRating) ? 0 : Number.parseFloat(averageUserRating)
        }
      />
      <Button
        variant="primary"
        size="small"
        to={getReviewLandingPageUrl(pageContext)}
        isExternalUrl={isADAC(pageContext)}
      >
        {buttonText}
      </Button>
    </div>
  </>
);

ReviewLink.propTypes = {
  title: PropTypes.string,
  buttonText: PropTypes.string,
  averageUserRating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pageContext: PageContextType,
};

export default ReviewLink;
