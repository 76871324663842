import { css } from '@emotion/react';

export const subtitle = css`
  margin: 12px 0;
`;

export const container = css`
  display: flex;
  flex-wrap: wrap;
`;

export const textAlignRight = css`
  text-align: right;
`;

export const noLineBreak = css`
  white-space: nowrap;
`;

export const verticalAlignBaseline = css`
  align-self: flex-end;
`;
