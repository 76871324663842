import { css } from '@emotion/react';

export const blockHeader = css`
  text-align: center;
  h2 {
    margin-bottom: 16px;
  }
  p {
    margin-bottom: 48px;
  }
`;
