import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { filter, head, pathOr, pipe, propEq } from 'ramda';

import { X } from './Icons';
import * as ß from './styles';
import { ALL_FILTERS, fixI18nLabelForStars, ICON_LIST } from './utils';

const FilterPin = memo(function FilterPin({
  filterId,
  namespace,
  removeActiveFilter,
  value,
}) {
  const { t } = useTranslation(namespace);
  const preLabel = pipe(filter(propEq('id', filterId)), head)(ALL_FILTERS);
  const label = pathOr(
    pathOr('', ['label'], preLabel),
    ['i18nLabel'],
    preLabel
  );
  const removeFilter = removeActiveFilter(filterId);
  const icon = pathOr('', [filterId], ICON_LIST);

  return (
    <div css={ß.activeFilter}>
      {icon}
      {t(fixI18nLabelForStars(label, value), { value })}
      <div css={ß.deleteActiveFilterBtn} onClick={removeFilter}>
        <X width="10px" height="10px" />
      </div>
    </div>
  );
});

FilterPin.propTypes = {
  filterId: PropTypes.string.isRequired,
  namespace: PropTypes.string.isRequired,
  removeActiveFilter: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
};

export default FilterPin;
