import { isEmptyOrNil } from '@fire/utils/generic';
import { trackObject } from '@utils/tracking';

export const trackOnView = (campsites = []) => {
  const track = {
    action: 'view product microcamping',
    category: 'list_microcamping',
    event: 'list_microcamping_view-product-list',
    label: 'microcamping carousel',
    impressions_list_microcamping: campsites,
  };
  return !isEmptyOrNil(campsites) && trackObject(track);
};
