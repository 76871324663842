import { T } from '@fire/i18n/i18n-constants';

export const NAMESPACE = T?.ReservationRequestConfirmation;

const trackingQuery =
  '?utm_source=system-mail&utm_medium=email&utm_campaign=reservation-reminder';

export const links = {
  app: `https://www.pincamp.de/produkte/adac-camping-und-stellplatzfuehrer/app${trackingQuery}`,
  magazin: `https://www.pincamp.de/magazin/${trackingQuery}`,
  review: {
    'de/de': `https://www.pincamp.de/campingplaetze-bewerten${trackingQuery}`,
    'ch/dech': `https://www.pincamp.ch/de/campingplaetze-bewerten${trackingQuery}`,
    'ch/frch': `https://www.pincamp.ch/fr/evaluer-campings${trackingQuery}`,
    'ch/itch': `https://www.pincamp.ch/it/valuta-camping${trackingQuery}`,
  },
};
