import escQuotes from 'escape-quotes';
import { addIndex, curry, curryN, map, pipe } from 'ramda';

import { isEmptyOrNil } from '@fire/utils/generic';
import { getLocaleData, isADAC } from '@utils/platform';

import { orgLDJsonCH, orgLDJsonDE } from './consts';

const escapeQuotes = curryN(2, escQuotes);
const esc = x => pipe(z => escapeQuotes(z, `"`))(x);

export const poweredBy = (title, pageContext) => {
  const partner = isADAC(pageContext) ? 'ADAC' : 'TCS';
  const suffix = `PiNCAMP by ${partner}`;
  const prefix = !isEmptyOrNil(title) ? `${title} | ` : '';

  return `${prefix}${suffix}`;
};

export const imgWithAttrs = curry(
  ({ width, height }, img) =>
    `${img}?w=${width}&h=${height}&crop=edges&fit=crop`
);

export const getUrl = url => {
  if (typeof window === 'undefined') return;
  return url || window.location.href;
};
const j2 = x => JSON.stringify(x, undefined, 2);

export const schemaWithType = curry((type, id, itemListElement) =>
  j2({
    '@context': 'https://schema.org',
    '@type': type,
    '@id': id,
    itemListElement,
  })
);
const imap = addIndex(map);

export const schemaItemsWithType = curry((pagePath, type, items) =>
  pipe(
    imap((data, i) => {
      const [text, url] = typeof data === 'string' ? [data, pagePath] : data;
      return {
        '@type': type,
        position: i + 1,
        item: { '@id': esc(url), name: esc(text) },
      };
    })
  )(items)
);

export const getOrgLDJson = pageContext => {
  if (isADAC(pageContext)) {
    return orgLDJsonDE;
  }
  const { language } = getLocaleData(pageContext);
  return orgLDJsonCH?.[language] || orgLDJsonDE;
};
