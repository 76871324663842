import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const TileHighlightContext = createContext();

export const TileHighlightProvider = ({ children }) => {
  const [highlightedTile, setHighlightedTile] = useState('');

  return (
    <TileHighlightContext.Provider
      value={{ highlightedTile, setHighlightedTile }}
    >
      {children}
    </TileHighlightContext.Provider>
  );
};

TileHighlightProvider.propTypes = {
  children: PropTypes.node,
};

export default TileHighlightContext;
