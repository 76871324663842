export const USERCENTRICS_ID_CH = process.env.GATSBY_UC_CH || '';
export const USERCENTRICS_ID_DE = process.env.GATSBY_UC_DE || '';

const userCentricsScripts = [
  'https://app.usercentrics.eu/browser-ui/latest/loader.js',
];

export const USERCENTRICS_PRELOAD = showConsentBanner =>
  showConsentBanner
    ? userCentricsScripts.map(href => ({
        rel: 'preload',
        as: 'script',
        href,
      }))
    : [];

const isLiveOrStage = branchSlug =>
  branchSlug?.includes('/dyn') ? 'stage' : 'live';

export const getJentisScriptName =
  process.env.TARGET === 'de/de' ? 'bkyu8i.js' : 'v2w3wj.js';

export const JENTIS_PRELOAD = branchSlug => {
  const environment = isLiveOrStage(branchSlug);
  return process.env.TARGET === 'de/de'
    ? `https://w5a2p2.pincamp.de/get/${environment}/web/_/${getJentisScriptName}`
    : `https://7bkvzs.pincamp.ch/get/${environment}/web/_/${getJentisScriptName}`;
};

export const getJentisConfig = branchSlug => {
  const environment = isLiveOrStage(branchSlug);

  return process.env.TARGET === 'de/de'
    ? `"https://w5a2p2.pincamp.de", "pincampde", "${environment}", "_"`
    : `"https://7bkvzs.pincamp.ch", "pincampch", "${environment}", "_"`;
};
