import React, { useContext, useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { dissoc, isEmpty, mergeAll, nth, propOr } from 'ramda';

import FormDataContext, { reducer } from '@contexts/FormData';
import MaybeCampsite from '@contexts/MaybeCampsite';
import MaybeData from '@contexts/MaybeData';
import { localStorage } from '@utils/local-storage';

import ReviewFormHeader from './ReviewFormHeader';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import * as ß from './styles';
import { trackStartReview } from './tracking';

const ReviewForm = ({ validValue, duplicateEntry }) => {
  const [pageInitialized, setPageInitialized] = useState(false);
  const [trackingInitialized, setTrackingInitialized] = useState(false);
  const campsiteData = useContext(MaybeCampsite);
  const pageContext = useContext(MaybeData);
  const prn = propOr('', 'prn', campsiteData);
  const {
    headline,
    titleDropdownConditions,
    dropdownConditions,
    titleDropdownRaffle,
    dropdownRaffle,
  } = pageContext;
  const localStorageKey = `${prn}-reviewForm`;
  const initialState = {
    step: 0,
    formData: {
      content: '',
      headline: '',
      // R.complements expects an array, which plays well
      // with checkbox elements which return arrays
      newsletterAccepted: [false], // PIN-1676: don't do newletters here for now
      nickname: '',
      prn,
      rating: '0',
      termsAccepted: [false],
      travelDateMonth: '',
      travelDateYear: '',
      username: '',
      email: '',
      localStorageKey,
    },
    campsiteData,
    validValue,
    localStorageKey,
    titleDropdownConditions,
    dropdownConditions,
    titleDropdownRaffle,
    dropdownRaffle,
    duplicateEntry,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (!pageInitialized) {
      setPageInitialized(true);
      if (!isEmpty(validValue) && state.step !== 3) {
        dispatch({ type: 'setStep', payload: 3 });
      } else if (localStorage.checkLocalStorage()) {
        const storedState = localStorage.getItem(localStorageKey);
        if (storedState) {
          try {
            const previousState = JSON.parse(storedState);
            const previousStep = propOr(1, 'step', previousState);
            dispatch({ type: 'setStep', payload: previousStep });
            dispatch({
              type: 'setFormData',
              payload: mergeAll([
                initialState.formData,
                dissoc('step', previousState),
              ]),
            });
          } catch (e) {
            dispatch({ type: 'setStep', payload: 1 });
            console.error(e);
          }
        } else {
          dispatch({ type: 'setStep', payload: 1 });
        }
      }
    }
  }, [
    initialState.formData,
    localStorageKey,
    pageInitialized,
    state,
    validValue,
  ]);

  useEffect(() => {
    if (!trackingInitialized) {
      trackStartReview({ campsiteData, pageContext });
      setTrackingInitialized(true);
    }
  }, [campsiteData, trackingInitialized, pageContext]);

  return (
    <FormDataContext.Provider value={{ state, dispatch }}>
      <div css={ß.container}>
        <ReviewFormHeader campsiteData={campsiteData} headline={headline} />
        {nth(state.step, [
          <></>,
          <Step1 key={'step1'} />,
          <Step2 key={'step2'} />,
          <Step3 key={'step3'} />,
        ])}
      </div>
    </FormDataContext.Provider>
  );
};

ReviewForm.propTypes = {
  validValue: PropTypes.string,
  duplicateEntry: PropTypes.string,
};

export default ReviewForm;
